import React from 'react';
import Fade from 'react-reveal/Fade';

const WelcomeScreen = ({
  debrand,
  debrandResellLogo,
  logo,
  altText,
  mainHeading,
  classes,
  setStepState,
  subHeading,
  buttonText,
}) => {
  return (
    <>
      <div
        style={{
          color: 'rgb(40, 42, 48)',
          maxWidth: '800px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Fade duration={1000} bottom>
          <img
            className={classes.centered}
            style={{
              display: 'block',
            }}
            width={100}
            height={100}
            src={debrand ? debrandResellLogo : logo}
            alt={altText}
          />
          <h3
            style={{
              fontSize: 42,
              margin: '1rem 0',
              textAlign: 'center',
            }}
          >
            {mainHeading}
          </h3>
          <p
            className={classes.centered}
            style={{
              color: 'rgb(107, 111, 118)',
              marginBottom: 32,
            }}
          >
            {subHeading}
          </p>
          <button type="button" onClick={() => setStepState(1)} className={classes.button + ' ' + classes.centered}>
            {buttonText}
          </button>
        </Fade>
      </div>
    </>
  );
};

export default WelcomeScreen;
