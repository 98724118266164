import { TShortUrlAnalytics } from '@/types/TShortUrl';
import apiClient from '@/utils/apiClient';

const REDIRECT_SHORT_URL_API_PATH = '/api/v2/receptionist-dashboard/short-url/redirect';
const SHORT_URL_ANALYTICS_API_PATH = '/api/v2/receptionist-dashboard/analytics/link-tracking';

export const getShortURLRedirect = async (
  shortCode: string,
  encryptedKey: string | null
): Promise<{
  success: boolean;
  originalURL?: string;
}> => {
  try {
    let query = '';
    if (encryptedKey) {
      query += `?encryptedKey=${encryptedKey}`;
    }

    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${REDIRECT_SHORT_URL_API_PATH}/${shortCode}${query}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const getShortURLAnalytics = async (
  forwardedFrom: string
): Promise<{ success: boolean; data: TShortUrlAnalytics }> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${SHORT_URL_ANALYTICS_API_PATH}/${forwardedFrom}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
