import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { Reseller, ResellerInitialState } from '@/types/TReseller';

const initialState: ResellerInitialState = {
  data: {
    voiceSubscriptionStatus: '',
    subscribedProductId: '',
    auto_scale_receptionists: false,
    purchase_quotes: '',
    unreservedReceptionists: 0,
    timestamp: '',
    reseller_type: '',
    theme_color: '',
    email_address: '',
    termsOfUseURL: '',
    subscriptionStatus: '',
    password: '',
    privacyPolicyURL: '',
    voiceSubscriptionId: '',
    totalReceptionists: 0,
    logo_s3: '',
    reseller_name: '',
    reservedReceptionists: '',
    stripe_subscriptionId: '',
    customerId: '',
    branded: true,
    logoUrl: '',
    twilio_account_id: '',
    twilio_access_token: '',
  },
  clients: [],
  isAuthenticated: false,
  authToken: undefined,
};

export const slice: Slice<
  ResellerInitialState,
  {
    setReseller: (state: ResellerInitialState, action: PayloadAction<Reseller>) => void;
    updateReseller: (state: ResellerInitialState, action: PayloadAction<Partial<Reseller>>) => void;
    updateResellerClients: (state: ResellerInitialState, action: PayloadAction<Partial<any[]>>) => void;
  },
  'reseller'
> = createSlice({
  name: 'reseller',
  initialState,
  reducers: {
    setReseller: (state: ResellerInitialState, action: PayloadAction<Reseller>) => {
      state.data = action.payload;
      state.authToken = action.payload.token;
      state.isAuthenticated = !!action.payload.token;
    },
    updateReseller(state: ResellerInitialState, action: PayloadAction<Partial<Reseller>>) {
      state.data = { ...state.data, ...action.payload };
    },
    updateResellerClients(state: ResellerInitialState, action: PayloadAction<Partial<any[]>>) {
      state.clients = action.payload;
    },
  },
});

export const { setReseller, updateReseller, updateResellerClients } = slice.actions;

export default slice.reducer;
