import React from 'react';
import { Button, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import logo from '@/assets/images/logo.png';
import './AffiliateProgram.scss';

const { Paragraph } = Typography;

const AffiliateProgramPage: React.FC = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });

  return (
    <div className="gradient-background">
      <div style={{ maxWidth: '900px', margin: '0 auto' }}>
        <Paragraph
          style={{
            fontFamily: 'roboto',
            color: 'white',
            marginBottom: '16px',
            lineHeight: '1.2',
            fontWeight: '300',
            fontSize: isSmallScreen ? '2rem' : '3.75rem',
          }}
        >
          You can earn 40% commissions by sharing your affiliate link. Join our Affiliate program and starting earning
          passive income.
        </Paragraph>
        <Button
          type="default"
          ghost
          href="https://www.myaifrontdesk.com/affiliate"
          target="_blank"
          rel="noopener noreferrer"
          className="styled-button  hover:!text-white hover:!bg-[#1479de77]"
        >
          CLICK HERE TO LEARN MORE
        </Button>
      </div>
      <img src={logo} alt="logo" className="logo" />
    </div>
  );
};

export default AffiliateProgramPage;
