import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import useAuth from '@/routes/hooks/useAuth';
import { Loading } from '@/components/Loading';
import { AppRoutes, UNBRANDED_DOMAINS } from 'src/enums/ERoutes';
import { useEffect } from 'react';

const PublicReceptionistRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  const domain = window.location.hostname;

  const isUnbrandedPath =
    location.pathname === AppRoutes.UNBRANDED_RECEPTIONIST ||
    location.pathname === AppRoutes.UNBRANDED_DASHBOARD ||
    UNBRANDED_DOMAINS.some(unbrandedDomain => domain.includes(unbrandedDomain));

  const [searchParams] = useSearchParams();
  const resellerCode = searchParams.get('reseller_code') || searchParams.get('code');

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (location.pathname === AppRoutes.UNBRANDED_RECEPTIONIST || location.pathname === AppRoutes.UNBRANDED_DASHBOARD)
    ) {
      try {
        // Update the document title (tab title)
        document.title = `Portal`;

        // Update the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `Dashboard/Portal`);
        }

        // If you still need to update an element with id 'page-title'
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
          pageTitle.textContent = `Portal`;
        }
      } catch (err) {
        console.error('Error updating page metadata:', err);
      }
    }
  }, [isUnbrandedPath]);

  if (isLoading) {
    return <Loading />;
  }

  // return isAuthenticated ? <Navigate to="/" /> : <Outlet />;

  if (!isAuthenticated && isUnbrandedPath) {
    return <Outlet context={{ debrand: isUnbrandedPath }} />;
  } else if (!isAuthenticated && !isUnbrandedPath) {
    <Outlet />;
  }

  if (isAuthenticated && isUnbrandedPath) {
    return (
      <Navigate to="/dashboard" state={{ from: location.pathname, debrand: isUnbrandedPath, resellerCode }} replace />
    );
  } else if (isAuthenticated && !isUnbrandedPath) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PublicReceptionistRoute;
