import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import clsx from 'clsx';
import Papa from 'papaparse';
import axios from 'axios';
import classes from './DashboardV2.module.css';
import PurchaseDialog from './PurchaseDialog';
import BulkSettings from './BulkSettings';
import { TextareaAutosize } from '@material-ui/core';
import moment from 'moment-timezone';
import { prod, dev_tier } from './config';
import { conversationData } from './constants';
import useOnClickOutside from './hooks/useOnClickOutside';
import { sanitizeString } from './utils/sanitizeString';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../store/account/slice';
import { ReceptionistDashboardSiderItem } from '../enums/EReceptionistDashboardSiderItem';

// Allowed extensions for input file
const allowedExtensions = ['csv'];

const TextingPlatform = ({
  themeColor = '#007bff',
  premium_customer,
  theirNumber,
  username,
  selectedTimeZone,
  debrand,
  resellerObject,
}) => {
  const dispatch = useDispatch();
  const [filterSearch, setFilterSearch] = useState('');
  const [filteredUnreplied, setFilteredUnreplied] = useState(false);
  const [isSameMsgChecked, setIsSameMsgChecked] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [isInfoBannerActive, setIsInfoBannerActive] = useState(true);
  const [isBadgeActive, setIsBadgeActive] = useState(true);
  const [recepients, setRecepients] = useState([]);
  const [messagesData, setMessagesData] = useState([]);
  const [dontAutoReplyToTheseNumbers, setDontAutoReplyToTheseNumbers] = useState([]);
  const [isBulkModalActive, setIsBulkModalActive] = useState(false);
  const [isBulkAIModalActive, setIsBulkAIModalActive] = useState(false);
  const [contactAddingNumber, setContactAddingNumber] = useState('');
  const [savedContacts, setSavedContacts] = useState({});
  const [createContactVal, setCreateContactVal] = useState('');
  const handleMenuSelect = key => {
    dispatch(setCurrentPage(key));
  };

  var serverURL = prod ? 'https://myaifrontdeskadmin.com' : 'http://localhost:8080';

  // This state will store the parsed data
  const [data, setData] = useState([{ phone_number: '+1', contact_name: '', message: '' }]);

  const [textingGk, setTextingGk] = useState(false);

  useEffect(() => {
    console.log(JSON.stringify(resellerObject));
    // Download logo
    if (resellerObject && resellerObject.email_address && resellerObject.email_address.S == 'tom@prorfp.com') {
      setTextingGk(true);
    }
  }, [resellerObject]);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState('');

  // It will store the file uploaded by the user
  const [file, setFile] = useState('');

  // This function will be called when
  // the file input changes
  const handleFileChange = e => {
    setError('');

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please input a csv file');
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    // if (!file) return alert("Enter a valid file");

    try {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const sanitizedData = results.data.map(el => {
            return {
              phone_number: sanitizeString(el.phone_number || ''),
              message: sanitizeString(el.message || ''),
              contact_name: sanitizeString(el.contact_name || ''),
            };
          });
          setData(sanitizedData);
        },
      });
    } catch (err) {
      console.log(err);
      setError('There was an error parsing your file');
    }
  };

  const [msgs, setMsgs] = useState([]);
  useEffect(() => {
    const msgsArr = [];
    for (const key in messagesData) {
      if (Object.hasOwnProperty.call(messagesData, key)) {
        const element = messagesData[key];
        msgsArr.push(element);
      }
    }
    const msgs = sortMessages(msgsArr);
    setMsgs(msgs);
  }, [messagesData]);

  const getContacts = useCallback(async () => {
    try {
      const res2 = await axios.post(`${serverURL}/get-contacts`, {
        username: username,
      });

      setSavedContacts(res2.data.result);
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    }
  }, [serverURL, theirNumber]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const [selectedClient, setSelectedClient] = useState('');
  const [isReserveNumberModalOpen, setIsReserveNumberModalOpen] = useState(false);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
  const [provisionedNumber, setProvisionedNumber] = useState('');
  const [msgVal, setMsgVal] = useState('');

  const openReserveNumberModal = () => {
    setIsReserveNumberModalOpen(true);
  };

  const closeReserveNumberModal = () => {
    setIsReserveNumberModalOpen(false);
  };

  const [isBulkSettingsModalOpen, setIsBulkSettingsModalOpen] = useState(false);

  const openBulkSettingsModal = () => {
    setIsBulkSettingsModalOpen(true);
  };

  const closeBulkSettingsModal = () => {
    setIsBulkSettingsModalOpen(false);
  };

  const renderPSTDate = utcDate => {
    console.log(selectedTimeZone);
    const pstDate = moment.utc(utcDate).tz(selectedTimeZone);
    var convertedDate = pstDate.format('M/D/YYYY, h:mm:ss A');
    return convertedDate;
  };

  const renderPSTDateTruncated = utcDate => {
    const pstDate = moment.utc(utcDate).tz(selectedTimeZone);
    const currentDate = moment().tz(selectedTimeZone);

    if (pstDate.isSame(currentDate, 'day')) {
      // If it's today, display only the time
      const convertedTime = pstDate.format('h:mm:ss A');
      return convertedTime;
    } else {
      // If it's a previous date, display only the date
      const convertedDate = pstDate.format('M/D/YYYY');
      return convertedDate;
    }
  };

  const reloadProvisionedNumber = async () => {
    console.log('Their number is ' + theirNumber);
    try {
      const response = await fetch(`${serverURL}/get-provisioned-number`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: theirNumber,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status && data.status === 'success' && data.provisionedNumber) {
          setProvisionedNumber(data.provisionedNumber);
          console.log('Already have a number, show different steps');
        }
      } else {
        console.error('Failed to fetch referee information');
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    reloadProvisionedNumber();
  }, []);

  function sortMessages(data) {
    return data.sort((a, b) => {
      if (b.messages.length > 0 && a.messages.length === 0) return 1;
      return (
        new Date(b.messages[b.messages.length - 1].timestamp) - new Date(a.messages[a.messages.length - 1].timestamp)
      );
    });
  }

  const getTextingConvos = async () => {
    if (dev_tier) {
      setMessagesData(conversationData);
      // setSelectedClient(Object.keys(conversationData)[0]);
      return;
    }
    if (!provisionedNumber) return;
    try {
      const response = await fetch(`${serverURL}/get-texting-convos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          provisionedNumber,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status && data.status === 'success' && data.conversations) {
          console.log('Got texting convos', data);
          // toast.success("Successfully fetched texting convos");
          if (Object.keys(data.conversations).length !== 0) {
            console.log('SettFing convos');
            setMessagesData(data.conversations);
            // setSelectedClient(Object.keys(data.conversations)[0]);
          }
          return;
        } else {
          console.log('Error response' + JSON.stringify(data));
        }
      }
      toast.error('Failed to fetch texting conversations, please contact support');
    } catch (error) {
      toast.error('Failed to fetch texting conversations, please contact support');
      console.error(error);
    }
  };

  useEffect(() => {
    getTextingConvos();
  }, [provisionedNumber]);

  const getDisabledAutoReplySettings = async () => {
    if (!provisionedNumber) return;
    try {
      const response = await fetch(`${serverURL}/get-disabled-autoreply-settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status && data.status === 'success' && data.dontAutoReplyToTheseNumbers) {
          setDontAutoReplyToTheseNumbers(JSON.parse(data.dontAutoReplyToTheseNumbers));
          console.log('Just set dontAutoReplyToTheseNumbers ' + data.dontAutoReplyToTheseNumbers);
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setBulkAIAutoreply = enable => {
    // Make API call to update-ai-auto-reply
    fetch(`${serverURL}/update-ai-auto-replys`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        toNumbers: selectedMsgs,
        disableAiAutoReply: !enable,
        username: username,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 'success') {
          toast.success('Settings updated successfully');
          setIsBulkAIModalActive(false);
          setSelectedMsgs([]);
          setIsInSelectMode(false);
          getDisabledAutoReplySettings();
        } else {
          toast.error('Failed to update AI Autoreply Settings');
        }
      })
      .catch(err => {
        toast.error('Failed to update AI Autoreply Settings');
        console.error(err);
      });
  };

  const checkBlastValidity = (recepients, msgs) => {
    recepients = recepients.map(el => sanitizeString(el));
    if (msgs.length === 0) {
      toast.error('Please enter a message');
      return false;
    }
    if (recepients.length === 0) {
      toast.error('Please add recipient/s');
      return false;
    }
    if (msgs.some(el => typeof el !== 'string' || el.trim().length === 0)) {
      toast.error('One or more messages are empty');
      return false;
    }
    if (recepients.some(el => typeof el !== 'string' || el.trim().length === 0)) {
      toast.error('One or more recepients are empty');
      return false;
    }
    if (recepients.some(el => el.length !== 12)) {
      toast.error('One or more recipients does not have a valid length');
      return false;
    }
    if (recepients.some(el => el[0].charCodeAt(0) !== '+'.charCodeAt(0) || el[1].charCodeAt(0) !== '1'.charCodeAt(0))) {
      toast.error('One or more recipients does not start with +1');
      return false;
    }
    if (recepients.some(el => isNaN(Number(el.replace('+1', ''))))) {
      toast.error('One or more recipients are not valid');
      return false;
    }

    return true;
  };

  function sendBulkMessages(recepients, msgs = []) {
    if (!checkBlastValidity(recepients, msgs)) return;
    fetch(`${serverURL}/send-manual-sms-blast`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: msgs,
        from: provisionedNumber,
        theirNumber: theirNumber,
        recepients: recepients,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 'success') {
          toast.success('Successfully sent messages');
          getTextingConvos();
          setMsgVal('');
          setRecepients([]);
        } else {
          //throw
          toast.error('Failed to send one or more messages');
          throw new Error('Couldnt send ');
        }
      })
      .catch(err => {
        toast.error('Failed to send one or more messages');
        console.error(err);
      });
  }

  const addMessage = () => {
    if (isSameMsgChecked && data.length > 1)
      setData(prev => [...prev, { phone_number: '+1', message: data[0].message }]);
    else setData(prev => [...prev, { phone_number: '+1', message: '' }]);
  };

  const updateContactName = async () => {
    try {
      const res = await axios.post(`${serverURL}/update-contact`, {
        contact: { [contactAddingNumber]: createContactVal },
        username: username,
      });
      setContactAddingNumber('');
      setCreateContactVal('');
      toast.success('Contact added successfully');
      getContacts();
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    }
  };

  const updateContacts = async data => {
    data = data.filter(el => el.contact_name && el.contact_name.trim().length > 0);
    console.log(data);
    if (!data.length) return;
    let mp = {};
    data.forEach(el => {
      mp[el.phone_number] = el.contact_name;
    });
    try {
      await axios.post(`${serverURL}/update-contacts`, {
        mapping: mp,
        username: username,
      });
      toast.success('Contacts added successfully');
      getContacts();
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    }
  };

  const dropdownRef = useRef();
  const [isInSelectMode, setIsInSelectMode] = useState(false);
  const [selectedMsgs, setSelectedMsgs] = useState([]);
  const [isMsgMenuActive, setIsMsgMenuActive] = useState(false);
  const [bulkStep, setBulkStep] = useState(0);
  const [isCSVBulk, setIsCSVBulk] = useState(true);

  useOnClickOutside(dropdownRef, () => setIsMsgMenuActive(false));

  useEffect(() => {
    getDisabledAutoReplySettings();
  }, [provisionedNumber]);

  const bulk_switch_ai_autoreply = (numbers, isOn) => {
    console.log(numbers, isOn);
  };

  const messages = (
    <div
      className={classes.messageWrap}
      style={{
        display: 'flex',
        marginLeft: '-16px',
        marginRight: '-16px',
        marginTop: '-16px',
        background: '#fff',
      }}
    >
      <BulkSettings
        isOpen={isBulkSettingsModalOpen}
        onClose={closeBulkSettingsModal}
        onSave={isOn => {
          const nos = msgs.map(el => el.client_number);

          bulk_switch_ai_autoreply(nos, isOn);
          if (isOn) setSelectedPhoneNumbers(nos);
          else setSelectedPhoneNumbers([]);
        }}
      />
      <div
        className={classes.msgLeft}
        style={{
          position: 'relative',
          background: '#F9F9F9',
          paddingRight: '20px',
          boxSizing: 'border-box',
          padding: '1rem 0',
          flexShrink: 0,
        }}
      >
        <div style={{ position: 'relative' }} ref={dropdownRef}>
          <div
            onClick={() => {
              setIsMsgMenuActive(true);
            }}
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textAlign: 'right',
              cursor: 'pointer',
              padding: '0 1rem',
            }}
          >
            ...
          </div>
          <div
            style={{
              background: '#fff',
              position: 'absolute',
              zIndex: 1,
              right: 0,
              top: '100%',
              padding: '10px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
              borderRadius: '8px',
              height: isMsgMenuActive ? '185px' : '0px',
              opacity: isMsgMenuActive ? '1' : '0',
              pointerEvents: isMsgMenuActive ? 'all' : 'none',
              overflow: 'hidden',
              transition: '.4s ease',
            }}
          >
            {textingGk && (
              <div
                style={{
                  padding: '8px 0',
                  borderBottom: '1px solid #ccc',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedClient('');
                  setIsMsgMenuActive(false);
                }}
              >
                📝 Compose
              </div>
            )}
            {textingGk && (
              <div
                style={{
                  padding: '8px 0',
                  borderBottom: '1px solid #ccc',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsBulkModalActive(true);
                  setFile('');
                  setBulkStep(0);
                  setIsCSVBulk(true);
                  setConsentChecked(false);
                  setData([{ phone_number: '+1', contact_name: '', message: '' }]);
                  setIsMsgMenuActive(false);
                }}
              >
                💣 Send text blast
              </div>
            )}
            <div
              style={{
                padding: '8px 0',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '16px',
                cursor: 'pointer',
                borderBottom: '1px solid #ccc',
              }}
              onClick={() => {
                getTextingConvos();
                setIsMsgMenuActive(false);
              }}
            >
              🔁 Refresh
            </div>
            <div
              style={{
                padding: '8px 0',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsInSelectMode(true);
                setIsMsgMenuActive(false);
              }}
            >
              Select Messages
            </div>
          </div>
        </div>

        {isInSelectMode && (
          <>
            {/* <div
              style={{
                padding: "0 1rem",
              }}
            >
              <label className={classes.switch}>
                <span className={classes.slider}></span>
                <input
                  type="checkbox"
                  checked={
                    !dontAutoReplyToTheseNumbers.includes(selectedClient)
                  }
                  onChange={(event) => {
                    const newCheckedState = event.target.checked;

                    // Make API call to update-ai-auto-reply
                    fetch(`${serverURL}/update-ai-auto-reply`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        toNumber: selectedClient,
                        disableAiAutoReply: !newCheckedState,
                        username: username,
                      }),
                    })
                      .then((res) => res.json())
                      .then((data) => {
                        if (data.status == "success") {
                          getDisabledAutoReplySettings();
                        } else {
                          toast.error("Failed to update AI Autoreply Settings");
                        }
                      })
                      .catch((err) => {
                        toast.error("Failed to update AI Autoreply Settings");
                        console.error(err);
                      });
                  }}
                />
              </label>
              <span
                style={{
                  marginLeft: "1rem",
                }}
              >
                Bulk AI Autoreply
              </span>
            </div> */}
            <div
              style={{
                padding: '.5rem 1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: '38px',
                    top: '7px',
                    position: 'relative',
                    display: 'inline-block',
                    marginRight: '10px',
                    cursor: 'pointer',
                    lineHeight: '10px',
                  }}
                  onClick={() => {
                    setIsInSelectMode(false);
                    setSelectedMsgs([]);
                  }}
                >
                  &times;
                </span>
                {selectedMsgs.length} selected
              </div>

              <button
                style={{
                  backgroundColor: themeColor,
                  color: 'white',
                  padding: '0 8px',
                  borderRadius: '4px',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer',
                  height: '30px',
                }}
                onClick={() => {
                  setIsBulkAIModalActive(true);
                }}
              >
                ✨
              </button>
            </div>
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {/* <button
            style={{
              backgroundColor: "rgb(235 230 253)",
              color: "white",
              padding: "8px 8px",
              borderRadius: "4px",
              border: "none",
              textAlign: "left",
              marginBottom: "12px",
              marginRight: "8px", // Add margin-right for spacing
              cursor: "pointer",
            }}
            onClick={openBulkSettingsModal}
          >
            ✏️
          </button> */}
          {/* <button
            style={{
              backgroundColor: "#3498db",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "none",
              textAlign: "center",
              marginBottom: "12px",
              marginTop: "12px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              textTransform: "uppercase",
              boxShadow: "0 4px 8px rgba(52, 152, 219, 0.3)",
              transition: "background-color 0.3s ease",
              outline: "none", // Remove the outline when focused
            }}
            onClick={getTextingConvos}
          >
            Refresh 🔁
          </button> */}
        </div>
        {isBadgeActive && (
          <div
            style={{
              border: '1px solid #17a2b8',
              padding: '10px',
              marginTop: '8px',
              borderRadius: '8px',
              fontSize: '12px',
              background: '#17a2b8',
              color: '#fff',
              position: 'relative',
              paddingRight: '18px',
              margin: '16px',
            }}
          >
            <div
              style={{
                fontSize: '24px',
                cursor: 'pointer',
                position: 'absolute',
                right: '6px',
                top: 0,
              }}
              onClick={() => setIsBadgeActive(false)}
            >
              &times;
            </div>
            All texts are sent from your AI Receptionist Number, which is <strong>{provisionedNumber}</strong>
          </div>
        )}

        <div
          style={{
            padding: '0 16px 8px',
          }}
        >
          <input
            type="text"
            style={{
              height: '35px',
              borderRadius: '8px',
              border: '1px solid #ddd',
              padding: '0.5rem',
              width: '100%',
              outline: 'none',
            }}
            placeholder="Search..."
            value={filterSearch}
            onChange={e => setFilterSearch(e.target.value)}
          />
        </div>

        <div
          style={{
            display: 'flex',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 16px 8px',
          }}
        >
          Filter unreplied messages
          <label className={classes.switch}>
            <input
              type="checkbox"
              checked={filteredUnreplied}
              onChange={e => {
                // setSelectedClient('')
                setFilteredUnreplied(e.target.checked);
              }}
            />
            <span className={classes.slider} />
          </label>
        </div>

        {msgs.map((el, idx) => {
          const contactNameOrNumber = (savedContacts && savedContacts[el.client_number]) || el.client_number || '';
          if (filteredUnreplied) {
            if (el?.messages?.length === 0 || el.messages[el.messages.length - 1].from !== provisionedNumber) {
              return <React.Fragment key={`messages-names-${idx}`}></React.Fragment>;
            }
          }
          if (filterSearch)
            if (
              !el.client_number.includes(filterSearch) &&
              !((savedContacts && savedContacts[el.client_number]) || '').toLowerCase().includes(filterSearch)
            )
              return <React.Fragment key={`messages-names-${idx}`}></React.Fragment>;
          return (
            <div
              key={`messages-names-${idx}`}
              style={{
                padding: '1rem',
                borderBottom: '1px solid #ddd',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                background:
                  isInSelectMode && selectedMsgs.includes(el.client_number) ? 'rgb(221, 221, 221)' : 'transparent',
              }}
              onClick={() => {
                if (isInSelectMode) {
                  if (selectedMsgs.includes(el.client_number)) {
                    setSelectedMsgs(prev => {
                      const idx = prev.findIndex(el2 => el2 === el.client_number);
                      const newState = [...prev];

                      if (idx > -1) {
                        newState.splice(idx, 1);
                      }

                      return newState;
                    });
                  } else setSelectedMsgs(prev => [...prev, el.client_number]);
                } else {
                  setSelectedClient(el.client_number);
                }
                console.log(`Selecting client ${el.client_number}`);
              }}
            >
              {/* 
    Uncomment the following block if checkbox functionality is needed
    <input
      type="checkbox"
      checked={selectedPhoneNumbers.includes(el.client_number)}
      onChange={(e) => {
        const idx = selectedPhoneNumbers.findIndex(
          (el2) => el2 === el.client_number
        );
        if (e.target.checked) {
          if (idx >= 0) return;
          setSelectedPhoneNumbers((prev) => [...prev, el.client_number]);
        } else {
          if (idx < 0) return;
          setSelectedPhoneNumbers((prev) => {
            const newArr = [...prev];
            newArr.splice(idx, 1);
            return newArr;
          });
        }
      }}
    />
    */}
              <div
                style={{
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ fontWeight: 'bold', fontSize: '15px' }}>{contactNameOrNumber}</div>
                  <button
                    style={{
                      border: '1px solid #007BFF',
                      color: 'white',
                      padding: '0 8px',
                      borderRadius: '4px',
                      // border: "none",
                      textAlign: 'left',
                      cursor: 'pointer',
                      height: '30px',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setContactAddingNumber(el.client_number);
                      setCreateContactVal((savedContacts && savedContacts[el.client_number]) || '');
                    }}
                  >
                    👤
                  </button>
                </div>
                <div style={{ fontSize: '12px', marginTop: '2px' }}>
                  {el.messages[el.messages.length - 1].message.slice(0, 30)}
                  {el.messages[el.messages.length - 1].message.length > 30 && '...'}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: '10px',
                      color: 'gray',
                      borderRadius: '10px',
                      margin: '.75rem 0',
                      width: '100%',
                      maxWidth: 'max-content',
                    }}
                  >
                    {renderPSTDateTruncated(el.messages[0].timestamp)}
                  </div>
                  <div style={{ fontSize: '10px' }}>
                    {!dontAutoReplyToTheseNumbers.includes(el.client_number) && '✨ AI AutoReply Enabled'}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        {selectedClient ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1rem',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
                padding: '0 16px',
                minHeight: '72px',
                marginTop: '16px',
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {(selectedClient && savedContacts[selectedClient]) || selectedClient}
                </div>
                {selectedClient && savedContacts[selectedClient] && (
                  <small>
                    <strong>({selectedClient})</strong>
                  </small>
                )}
              </div>
              <h3
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '1rem',
                  margin: 0,
                }}
              >
                <div>AI Autoreply</div>
                <label className={classes.switch}>
                  <input
                    type="checkbox"
                    checked={!dontAutoReplyToTheseNumbers.includes(selectedClient)}
                    onChange={event => {
                      const newCheckedState = event.target.checked;

                      // Make API call to update-ai-auto-reply
                      fetch(`${serverURL}/update-ai-auto-reply`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          toNumber: selectedClient,
                          disableAiAutoReply: !newCheckedState,
                          username: username,
                        }),
                      })
                        .then(res => res.json())
                        .then(data => {
                          if (data.status == 'success') {
                            getDisabledAutoReplySettings();
                          } else {
                            toast.error('Failed to update AI Autoreply Settings');
                          }
                        })
                        .catch(err => {
                          toast.error('Failed to update AI Autoreply Settings');
                          console.error(err);
                        });
                    }}
                  />
                  <span className={classes.slider}></span>
                </label>
              </h3>
            </div>

            <div
              style={{
                padding: '0 1rem',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'calc(100vh - 70px)',
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  overflow: 'auto',
                }}
              >
                {messagesData[selectedClient].messages.map((el, idx) => {
                  return (
                    <div>
                      <div
                        key={'conversation-' + idx}
                        style={{
                          padding: '.5rem .75rem',
                          borderRadius: '10px',
                          background: el.from === selectedClient ? 'rgb(255 245 245)' : 'rgb(0, 123, 255)',
                          margin: '.75rem 0',
                          color: el.from === selectedClient ? 'inherit' : '#fff',
                          // textAlign: el.from === selectedClient ? "left" : "right",
                          marginLeft: el.from === selectedClient ? 0 : 'auto',
                          width: '100%',
                          maxWidth: 'max-content',
                          boxSizing: 'border-box',
                        }}
                      >
                        {el.message}
                      </div>
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'gray',
                          borderRadius: '10px',
                          margin: '.75rem 0',
                          marginLeft: el.from === selectedClient ? 0 : 'auto',
                          width: '100%',
                          maxWidth: 'max-content',
                        }}
                      >
                        {renderPSTDate(el.timestamp)}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                }}
              >
                <TextareaAutosize
                  minRows={2}
                  value={msgVal}
                  onChange={e => setMsgVal(e.target.value)}
                  style={{
                    borderRadius: '10px',
                    flexGrow: 1,
                    padding: '8px',
                    border: '1px solid #ccc',
                  }}
                />

                <button
                  style={{
                    backgroundColor: themeColor,
                    color: 'white',
                    padding: '8px 8px',
                    borderRadius: '4px',
                    fontSize: '1.5rem',
                    border: 'none',
                    textAlign: 'left',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!msgVal) {
                      toast.error('Please enter a message');
                      return;
                    }
                    fetch(`${serverURL}/send-manual-sms`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        message: msgVal,
                        from: provisionedNumber,
                        theirNumber: theirNumber,
                        to: selectedClient,
                      }),
                    })
                      .then(res => res.json())
                      .then(data => {
                        if (data.status == 'success') {
                          toast.success('Successfully sent message');
                          getTextingConvos();
                          setMsgVal('');
                        } else {
                          //throw
                          toast.error('Failed to send message');
                          throw new Error('Couldnt send ');
                        }
                      })
                      .catch(err => {
                        toast.error('Failed to send message');
                        console.error(err);
                        setMsgVal('');
                      });
                  }}
                >
                  ✈
                </button>
              </div>
            </div>
          </div>
        ) : (
          textingGk && (
            <div
              style={{
                // textAlign: "center",
                height: '100vh', // Set height to 100vh for full screen vertical fill
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginTop: '16px',
              }}
            >
              <div
                style={{
                  padding: '10px',
                  borderBottom: '1px solid #ccc',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '20px',
                  position: 'relative',
                }}
              >
                {isInfoBannerActive && (
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '100%',
                      transform: 'translateX(-50%)',
                      background: 'rgb(23, 162, 184)',
                      color: '#fff',
                      padding: '1rem 1.5rem',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '10px',
                        right: '16px',
                        color: '#fff',
                        fontSize: '24px',
                      }}
                      onClick={() => setIsInfoBannerActive(false)}
                    >
                      &times;
                    </div>
                    Messages will be sent seperately to each recepient
                  </div>
                )}
                <div
                  style={{
                    fontWeight: 'bold',
                    paddingTop: '6px',
                  }}
                >
                  TO:
                </div>
                <div
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <CreatableSelect
                    isClearable
                    isMulti
                    value={recepients}
                    onChange={val => setRecepients(val)}
                    placeholder="+1xxxxxxxxxx, +1xxxxxxxxxx"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '100%',
                        boxShadow: 'none',
                        borderWidth: '0px 0 1px 0',
                        borderRadius: '0',
                      }),
                      indicatorsContainer: baseStyles => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                      menu: baseStyles => ({
                        ...baseStyles,
                        display: 'none',
                      }),
                    }}
                  ></CreatableSelect>
                </div>

                {/* {messagesData ? (
              <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "1.4rem", marginBottom: "10px" }}>
                  No Texts yet!
                </h2>
                <p>
                  Once your receptionist sends some texts, the conversations
                  will show up here. <br />
                  <br /> To simulate sending a text, click `Edit Receptionist`
                  and setup a Texting Workflow. Then, call your receptionist at{" "}
                  {provisionedNumber} and trigger the workflow. You should see
                  the conversation appear here.
                </p>
              </div>
            ) : (
              <p>Please select a client to see the conversation</p>
            )} */}
              </div>{' '}
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  padding: '0 1rem',
                }}
              >
                <TextareaAutosize
                  minRows={2}
                  value={msgVal}
                  onChange={e => setMsgVal(e.target.value)}
                  style={{
                    borderRadius: '10px',
                    flexGrow: 1,
                    padding: '8px',
                  }}
                />

                <button
                  style={{
                    backgroundColor: themeColor,
                    color: 'white',
                    padding: '8px 8px',
                    borderRadius: '4px',
                    fontSize: '1.5rem',
                    border: 'none',
                    textAlign: 'left',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    sendBulkMessages(
                      recepients.map(el => el.label),
                      recepients.map(el => msgVal)
                    );
                  }}
                >
                  ✈️
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );

  return (
    <div style={{ width: '100%' }}>
      {contactAddingNumber && <div className={classes.modalOverlay} onClick={() => setContactAddingNumber('')}></div>}
      <div className={clsx(classes.modal, contactAddingNumber && classes.active)}>
        <div className={classes.close} onClick={() => setContactAddingNumber('')}>
          &times;
        </div>
        <h3
          style={{
            textAlign: 'center',
            fontSize: '20px',
          }}
        >
          Create Contact
        </h3>
        <input
          style={{
            border: '1px solid #007BFF',
            height: '40px',
            padding: '0 0.5rem',
            maxWidth: '500px',
            display: 'block',
            margin: '0 auto',
            borderRadius: '8px',
          }}
          placeholder="John Doe"
          value={createContactVal}
          onChange={e => setCreateContactVal(e.target.value)}
        />{' '}
        <button
          style={{
            backgroundColor: themeColor,
            color: 'white',
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            textAlign: 'left',
            cursor: 'pointer',
            margin: '1rem auto 0',
            display: 'block',
            fontSize: '20px',
          }}
          onClick={updateContactName}
        >
          Create!
        </button>
      </div>
      {isBulkAIModalActive && (
        <div className={classes.modalOverlay} onClick={() => setIsBulkAIModalActive(false)}></div>
      )}
      {
        <div className={clsx(classes.modal, isBulkAIModalActive && classes.active)}>
          <div className={classes.close} onClick={() => setIsBulkAIModalActive(false)}>
            &times;
          </div>

          <h3
            style={{
              textAlign: 'center',
              fontSize: '20px',
            }}
          >
            Bulk AI Autoreply
          </h3>

          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '20px',
            }}
          >
            <button
              style={{
                backgroundColor: themeColor,
                color: 'white',
                padding: '8px 8px',
                borderRadius: '4px',
                fontSize: '1.5rem',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onClick={() => {
                setBulkAIAutoreply(false);
              }}
            >
              Disable
            </button>
            <button
              style={{
                backgroundColor: themeColor,
                color: 'white',
                padding: '8px 8px',
                borderRadius: '4px',
                fontSize: '1.5rem',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onClick={() => {
                setBulkAIAutoreply(true);
              }}
            >
              Enable
            </button>
          </div>
        </div>
      }
      {isBulkModalActive && <div className={classes.modalOverlay} onClick={() => setIsBulkModalActive(false)}></div>}
      <div className={clsx(classes.modal, isBulkModalActive && classes.active)}>
        <div className={classes.close} onClick={() => setIsBulkModalActive(false)}>
          &times;
        </div>
        {bulkStep === 0 ? (
          <div>
            <div
              style={{
                textAlign: 'center',
                borderBottom: '1px solid #000',
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              Send Bulk Texts!
            </div>

            <div>
              <label
                style={{
                  marginTop: '16px',
                  display: 'block',
                }}
              >
                <input
                  style={{
                    marginRight: '16px',
                  }}
                  type="checkbox"
                  value={consentChecked}
                  onChange={e => setConsentChecked(e.target.checked)}
                />
                By sending bulk text messages, I agree that all my contacts have opted in to receiving this messaging
                from me. If my texts are marked as spam or cold outreach, I understand my account will immediately be
                banned and I may be charged a fee for abuse.
              </label>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '24px',
              }}
            >
              <button
                style={{
                  backgroundColor: themeColor,
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontSize: '16px',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!consentChecked) {
                    toast.error('Please check the consent to continue');
                    return;
                  }
                  setBulkStep(1);
                  setIsCSVBulk(true);
                }}
              >
                Upload CSV
              </button>
              <button
                style={{
                  backgroundColor: themeColor,
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  fontSize: '16px',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (!consentChecked) {
                    toast.error('Please check the consent to continue');
                    return;
                  }
                  setBulkStep(1);
                  setIsCSVBulk(false);
                }}
              >
                Enter manually
              </button>
            </div>
          </div>
        ) : (
          <>
            {bulkStep < 4 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '16px',
                  marginTop: '16px',
                  maxWidth: '300px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '1px',
                    background: '#aeaeae',
                    top: '25px',
                  }}
                ></div>
                {[
                  { label: isCSVBulk ? 'Upload CSV' : 'Enter Blast' },
                  { label: 'Verify Blast' },
                  { label: 'Send!' },
                ].map((el, idx) => {
                  return (
                    <div
                      key={'bulk-step-' + idx}
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (idx + 1 < bulkStep) setBulkStep(idx + 1);
                      }}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '50%',
                          border: '1px solid #eee',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: idx + 1 === bulkStep ? '#484848' : '#fff',
                          color: idx + 1 === bulkStep ? '#fff' : '#484848',
                          fontWeight: 'bold',
                          position: 'relative',
                        }}
                      >
                        {idx + 1}
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '100px',
                          textAlign: 'center',
                          top: '100%',
                          fontWeight: idx + 1 === bulkStep ? 'bold' : '400',
                        }}
                      >
                        {el.label}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              style={{
                marginTop: '40px',
              }}
            >
              {bulkStep === 1 && isCSVBulk && (
                <div>
                  <div>
                    Make sure your file has columns number (phone_number) and message (message). It may have another
                    optional column for contact name (contact_name).
                  </div>
                  <strong>Example file format:</strong>
                  <div
                    style={{
                      padding: '2px 12px',
                      border: '1px solid #a8a8a8',
                      background: 'rgb(233 233 233)',
                      marginTop: '10px',
                      borderRadius: '8px',
                    }}
                  >
                    <pre
                      style={{
                        fontFamily: 'monospace',
                      }}
                    >
                      phone_number,message
                      <br />
                      +1xxxxxxxxxx,Hi there
                      <br />
                      +1xxxxxxxxxx,You there?
                      <br />
                      +1xxxxxxxxxx,I think you are busy
                    </pre>
                  </div>
                  <input id="upload" type="file" accept="text/csv" onChange={handleFileChange} />
                  <label
                    htmlFor="upload"
                    style={{
                      border: '1px dashed #ccc',
                      borderRadius: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '26px',
                      textAlign: 'center',
                      paddingBottom: '30px',
                      cursor: 'pointer',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '90px',
                      }}
                    >
                      ☁️
                    </span>
                    <span style={{ fontSize: '20px' }}>{file?.name || 'Upload CSV'}</span>
                  </label>{' '}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '24px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setBulkStep(0);
                      }}
                    >
                      Go Back
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleParse();
                        if (!file) toast.error('Please select a file to continue');
                        else setBulkStep(2);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {bulkStep === 1 && !isCSVBulk && (
                <div>
                  <div>
                    <input
                      id="same-msg"
                      value={isSameMsgChecked}
                      type="checkbox"
                      onChange={e => {
                        setIsSameMsgChecked(e.target.checked);
                        if (e.target.checked && data.length > 1) {
                          setData(prev => {
                            const newData = [...prev];
                            newData.forEach(el => {
                              el.message = newData[0].message;
                            });

                            return newData;
                          });
                        }
                      }}
                    />{' '}
                    <label htmlFor="same-msg">Use same message for every recepient</label>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      paddingTop: '10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: '0px',
                        margin: '6px 0',
                        paddingTop: '10px',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '10px',
                          flexGrow: 1,
                          width: '20%',
                          textAlign: 'left',
                        }}
                      >
                        Name (optional)
                      </div>
                      <div
                        style={{
                          fontSize: '10px',
                          flexGrow: 1,
                          width: '40%',
                        }}
                      >
                        Phone Number
                      </div>
                      <div
                        style={{
                          fontSize: '10px',
                          flexGrow: 1,
                          width: '40%',
                        }}
                      >
                        Message
                      </div>
                    </div>
                    {data.map((el, idx) => {
                      return (
                        <div
                          key={'bulk-message-custom-' + idx}
                          style={{
                            display: 'flex',
                            gap: '16px',
                            margin: '6px 0',
                            paddingTop: '10px',
                          }}
                        >
                          <input
                            style={{ flexGrow: 1, width: '20%' }}
                            value={el.contact_name}
                            onChange={e =>
                              setData(prev => {
                                const newData = [...prev];

                                newData[idx].contact_name = e.target.value;
                                return newData;
                              })
                            }
                          />
                          <input
                            style={{ flexGrow: 1, width: '40%' }}
                            value={el.phone_number}
                            onChange={e =>
                              setData(prev => {
                                const newData = [...prev];
                                let value = e.target.value.replace(/[^0-9+]/g, ''); // Remove non-digit characters except '+'

                                if (!value.startsWith('+1')) {
                                  value = '+1';
                                }

                                newData[idx].phone_number = value;
                                return newData;
                              })
                            }
                          />
                          <input
                            style={{ flexGrow: 1, width: '40%' }}
                            value={el.message}
                            disabled={idx > 0 && isSameMsgChecked}
                            placeholder="Hi there!"
                            onChange={e => {
                              setData(prev => {
                                const newData = [...prev];
                                if (!isSameMsgChecked) newData[idx].message = e.target.value;
                                else newData.forEach(el => (el.message = e.target.value));
                                return newData;
                              });
                            }}
                          />
                        </div>
                      );
                    })}
                    <button
                      style={{
                        backgroundColor: 'grey',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                        marginTop: '10px',
                        marginRight: '10px',
                      }}
                      onClick={() => {
                        setData([{ phone_number: '+1', contact_name: '', message: '' }]);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}
                      onClick={() => {
                        addMessage();
                      }}
                    >
                      + Add
                    </button>
                  </div>{' '}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '24px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setBulkStep(0);
                      }}
                    >
                      Go Back
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const users = data.map(el => el.phone_number);
                        const msgs = data.map(el => el.message);
                        if (checkBlastValidity(users, msgs)) setBulkStep(2);
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {bulkStep === 2 && (
                <div>
                  <div
                    style={{
                      maxHeight: '200px',
                      overflow: 'auto',
                    }}
                  >
                    <table
                      style={{
                        width: '100%',
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            background: 'rgb(58, 58, 58)',
                            color: '#fff',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          Phone number (phone_number)
                        </td>
                        <td
                          style={{
                            background: 'rgb(58, 58, 58)',
                            color: '#fff',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          Message (message)
                        </td>
                        <td
                          style={{
                            background: 'rgb(58, 58, 58)',
                            color: '#fff',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          Name (contact_name)
                        </td>
                      </tr>
                      <tbody>
                        {error
                          ? error
                          : data.map((e, i) => (
                              <tr key={'data-row-' + i} className="item">
                                <td className="item">{e.phone_number}</td>
                                <td className="item">{e.message}</td>
                                <td className="item">{e.contact_name}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '24px',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setBulkStep(1);
                      }}
                    >
                      Back
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '16px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const users = data.map(el => el.phone_number);
                        const msgs = data.map(el => el.message);
                        if (checkBlastValidity(users, msgs)) setBulkStep(3);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              )}
              {bulkStep === 3 && (
                <div>
                  <div
                    style={{
                      fontSize: '30px',
                      textAlign: 'center',
                      padding: '1.5rem 0',
                    }}
                  >
                    Send{' '}
                    <span
                      style={{
                        color: themeColor,
                        fontWeight: 'bold',
                      }}
                    >
                      {data.length}
                    </span>{' '}
                    messages?
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '0',
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: themeColor,
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        fontSize: '36px',
                        border: 'none',
                        textAlign: 'left',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setBulkStep(4);
                        const users = data.map(el => el.phone_number);
                        const msgs = data.map(el => el.message);
                        updateContacts(data);
                        sendBulkMessages(users, msgs);
                      }}
                    >
                      🚀 Send
                    </button>
                  </div>
                </div>
              )}
              {bulkStep === 4 && (
                <div>
                  <div
                    style={{
                      fontSize: '30px',
                      textAlign: 'center',
                    }}
                  >
                    🎉{' '}
                    <span
                      style={{
                        color: themeColor,
                        fontWeight: 'bold',
                      }}
                    >
                      {data.length}
                    </span>{' '}
                    Messages Sent!
                  </div>
                  <div
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '2rem',
                    }}
                  >
                    Woohooo! You've just sent a text blast
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div
        style={{
          background: '#FFFFFF',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Quicksand, sans-serif',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          color: '#333',
          padding: !(premium_customer && provisionedNumber) && '24px',
          marginBottom: '16px',
        }}
      >
        {!premium_customer ? (
          <div>
            <PurchaseDialog isOpen={isReserveNumberModalOpen} onClose={closeReserveNumberModal} />
            <div
              style={{
                fontSize: '1.5rem',
                color: '#ff5733',
                textAlign: 'center',
                marginTop: '1rem',
                padding: '1rem',
                backgroundColor: '#f8d7da',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              You must begin your free trial before accessing the texting platform. Click the "Test it out!" tab on the
              left to do so.
            </div>
          </div>
        ) : provisionedNumber ? (
          <div
            style={{
              width: '100%',
              margin: '-16px -16px 0',
            }}
          >
            {messages}
          </div>
        ) : (
          <div>
            <div
              style={{
                fontSize: '1.5rem',
                color: '#ff5733',
              }}
            >
              You must setup your standalone number first
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <button
                style={{
                  backgroundColor: themeColor,
                  color: 'white',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: 'none',
                  textAlign: 'left',
                  marginBottom: '12px',
                }}
                onClick={() => {
                  // TODO : navigate to the setup number page
                  handleMenuSelect(ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS);
                }}
              >
                Set up now
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextingPlatform;
