import { Content, Header } from 'antd/es/layout/layout';
import './ContentComponent.scss';
import Branding from '../Branding/Branding';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import NoviCampaigns from '../Campagins/Campaigns';
import { Button, Layout } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaignId } from '@/store/novi/user/selector';
import { useDispatch } from 'react-redux';
import { setNoviSelectedCampaignId } from '@/store/novi/user/slice';
import OverviewCard from '@/pages/ReceptionistDashboard/Overview/Cards/OverviewCard';
import { Analytics } from '@/pages/ReceptionistDashboard/Analytics';
import VoiceLibrary from '@/pages/ReceptionistDashboard/VoiceLibrary/voiceLibrary';
import IntakeForms from '@/pages/ReceptionistDashboard/IntakeForms/IntakeForms';
import { TextingPlatform } from '@/pages/ReceptionistDashboard/TextingPlatForm';
import CallLogs from '@/pages/ReceptionistDashboard/LogsComponent/CallLogs/CallLogs';
import TextLogs from '@/pages/ReceptionistDashboard/LogsComponent/TextLogs/TextLogs';
import VoiceMailLogs from '@/pages/ReceptionistDashboard/LogsComponent/VoiceMailLogs/VoiceMailLogs';
import CampaignInfo from '../Campagins/CampaignInfo/CampaignInfo';
import CrmDashboard from '@/pages/ReceptionistDashboard/CrmDashboard/CrmDashboard';

interface ContentComponentProps {
  currentPage: ENoviDashboardSider;
}

const NoviContent: React.FC<ContentComponentProps> = ({ currentPage }) => {
  const dispatch = useDispatch();
  const selectedCampaignId = useAppSelector(getSelectedCampaignId);

  const handleBack = () => {
    dispatch(setNoviSelectedCampaignId(null));
  };

  const ContentWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Layout>
      <Header style={{ background: '#fff', padding: '0 16px' }}>
        <Button icon={<ArrowLeftOutlined />} style={{ marginRight: 16 }} onClick={handleBack}>
          Back to Campaigns
        </Button>
      </Header>
      <Content style={{ padding: '24px' }}>{children}</Content>
    </Layout>
  );

  const renderContent = () => {
    switch (currentPage) {
      case ENoviDashboardSider.MY_CAMPAIGNS:
        return <NoviCampaigns />;
      case ENoviDashboardSider.BRANDING:
        return <Branding />;
      case ENoviDashboardSider.LEADS:
        return (
          <ContentWrapper>
            <CampaignInfo campaignId={selectedCampaignId as string} />
          </ContentWrapper>
        );
      case ENoviDashboardSider.CONFIGURE:
        return (
          <ContentWrapper>
            <OverviewCard isNoviUser={true} onCompletionChange={() => {}} debrand={true} />
          </ContentWrapper>
        );
      case ENoviDashboardSider.ANALYTICS:
        return (
          <ContentWrapper>
            <Analytics debrand={true} />
          </ContentWrapper>
        );
      case ENoviDashboardSider.VOICE_LIBRARY:
        return (
          <ContentWrapper>
            <VoiceLibrary />
          </ContentWrapper>
        );
      case ENoviDashboardSider.INTAKE_FORMS:
        return (
          <ContentWrapper>
            <IntakeForms />
          </ContentWrapper>
        );
      case ENoviDashboardSider.TEXTING_PLATFORM:
        return (
          <ContentWrapper>
            <TextingPlatform debrand={true} isNoviUser={true} />
          </ContentWrapper>
        );
      case ENoviDashboardSider.CALL_LOGS:
        return (
          <ContentWrapper>
            <CallLogs debrand={true} isNoviUser={true} campaignId={selectedCampaignId as string} />
          </ContentWrapper>
        );
      case ENoviDashboardSider.TEXT_LOGS:
        return (
          <ContentWrapper>
            <TextLogs />
          </ContentWrapper>
        );
      case ENoviDashboardSider.VOICE_MAIL_LOGS:
        return (
          <ContentWrapper>
            <VoiceMailLogs />
          </ContentWrapper>
        );
      case ENoviDashboardSider.CRM:
        return (
          <ContentWrapper>
            <CrmDashboard isNoviUser={true} />
          </ContentWrapper>
        );
      default:
        break;
    }
  };
  const baseClass = 'content-component';
  return (
    <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, overflow: 'auto' }}>
      <div className={`${baseClass}_content`}>{renderContent()}</div>
    </Content>
  );
};

export default NoviContent;
