import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Row, Spin, Table, Tooltip } from 'antd';
import { OverViewCardForm } from '../../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { deletePronunciation, generatePronunciation, updateUser } from '@/api/user';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { DeleteOutline, VolumeUp } from '@mui/icons-material';
import { FaRobot } from 'react-icons/fa';
import { ipaToWord } from '@/utils/helper';
import { User } from '@/types/TAccounts';

type GeneratePronunciationVariables = {
  word: string;
  phonetic: string;
  fieldName: (string | number)[];
};

const PronunciationGuideForm: React.FC<{
  word_pronunciation: {
    pronunciation: string;
    word: string;
  }[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ word_pronunciation, refetch }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [dataSource, setDataSource] = useState(word_pronunciation);
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const [speaking, setSpeaking] = useState<Record<string, { word: boolean; pronunciation: boolean }>>({});

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const { mutateAsync: deletePronunciationFn, isPending } = useMutation({
    mutationKey: ['delete-pronunciation'],
    mutationFn: deletePronunciation,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Pronunciation deleted successfully!',
      });
      setIsFieldTouchReset(false);
    },
  });

  const { mutate: generatePronunciationFn, isPending: isPendingAutoGen } = useMutation({
    mutationKey: ['generate-pronunciation'],
    mutationFn: async (variables: GeneratePronunciationVariables) => {
      const response = await generatePronunciation({
        word: variables.word,
        phonetic: variables.phonetic,
      });
      return { data: response.data, fieldName: variables.fieldName };
    },
    onSuccess: result => {
      if (result.data) {
        form.setFields([
          {
            name: [...result.fieldName, 'pronunciation'],
            value: result.data,
          },
        ]);
      }
    },
  });

  const handleSubmit = async (values: { wordsList: { pronunciation: string; word: string }[] }) => {
    const newEntries = values.wordsList.filter(item => item.word && item.pronunciation);
    const newData = [...dataSource, ...newEntries];
    setDataSource(newData);

    await mutation.mutateAsync({ word_pronunciation: newEntries });

    form.resetFields();
    dispatch(closeModal());
  };

  const handleDelete = async (word: string) => {
    await deletePronunciationFn({ word });
    const filteredData = dataSource.filter(item => item.word !== word);
    setDataSource(filteredData);
    form.resetFields();
    dispatch(closeModal());
  };

  const autoGeneratePhonetic = (word: string, fieldName: (string | number)[]) => {
    generatePronunciationFn({
      word,
      phonetic: 'IPA',
      fieldName,
    });
  };

  const speak = (text: string, type: string, index: number) => {
    if ('speechSynthesis' in window) {
      setSpeaking(prev => ({
        ...prev,
        [index]: { ...prev[index], [type]: true },
      }));

      const utterance = new SpeechSynthesisUtterance(type === 'pronunciation' ? ipaToWord(text) : text);

      utterance.onend = () => {
        setSpeaking(prev => ({
          ...prev,
          [index]: { ...prev[index], [type]: false },
        }));
      };

      speechSynthesis.speak(utterance);
    } else {
      alert("Sorry, your browser doesn't support text to speech!");
    }
  };

  const columns = [
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      onHeaderCell: () => ({
        style: { background: '#E8EFFF' },
      }),
    },
    {
      title: 'Pronunciation',
      dataIndex: 'pronunciation',
      key: 'pronunciation',
      onHeaderCell: () => ({
        style: { background: '#E8EFFF' },
      }),
    },
    {
      title: 'Action',
      width: '80px',
      key: 'action',
      render: (_: any, record: any) => (
        <Button type="link" onClick={() => handleDelete(record.word)}>
          <DeleteOutline className="hover:text-red-500" />
        </Button>
      ),
      onHeaderCell: () => ({
        style: { background: '#E8EFFF' },
      }),
    },
  ];

  const FormItems = () => (
    <Row gutter={8} align="top">
      <Form.List name="wordsList" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={16} align="middle" className="w-full">
                <Col span={10}>
                  <Form.Item
                    {...restField}
                    label="Word"
                    name={[name, 'word']}
                    rules={[
                      { required: true, message: 'Please enter a word' },
                      {
                        validator: async (_, value) => {
                          if (!value) return;

                          const allWords = form
                            .getFieldValue('wordsList')
                            .map((item: any, i: number) => (i !== index ? item?.word : null))
                            .filter(Boolean);

                          if (allWords.some((word: string) => word.toLowerCase() === value.trim().toLowerCase())) {
                            throw new Error('This word is already in use');
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Word"
                      className="w-full"
                      suffix={
                        <Form.Item noStyle shouldUpdate>
                          {() => {
                            const word = form.getFieldValue(['wordsList', name, 'word']);
                            return word ? (
                              <VolumeUp
                                fontSize="small"
                                onClick={() => speak(word, 'word', index)}
                                className={`${speaking[index]?.word ? 'text-[#007bff]' : 'text-gray-400'} cursor-pointer`}
                              />
                            ) : null;
                          }}
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={11}>
                  <Form.Item
                    {...restField}
                    label="Pronunciation"
                    name={[name, 'pronunciation']}
                    rules={[{ required: true, message: 'Please enter pronunciation' }]}
                  >
                    <Input
                      placeholder="Enter Pronunciation"
                      className="w-full"
                      suffix={
                        <Form.Item noStyle shouldUpdate>
                          {() => {
                            const pronunciation = form.getFieldValue(['wordsList', name, 'pronunciation']);
                            return pronunciation ? (
                              <VolumeUp
                                fontSize="small"
                                onClick={() => speak(pronunciation, 'pronunciation', index)}
                                className={`${speaking[index]?.pronunciation ? 'text-[#007bff]' : 'text-gray-400'} cursor-pointer`}
                              />
                            ) : null;
                          }}
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={2} className="mt-2">
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const word = form.getFieldValue(['wordsList', name, 'word']);
                      return (
                        <Tooltip
                          color="white"
                          title={<span className="text-black">Auto-generate your phonetic for the word with AI</span>}
                        >
                          <FaRobot
                            size={30}
                            className={`${word ? 'cursor-pointer text-blue-500' : 'cursor-not-allowed text-gray-400'}`}
                            onClick={() => word && autoGeneratePhonetic(word, ['wordsList', name])}
                          />
                        </Tooltip>
                      );
                    }}
                  </Form.Item>
                </Col>

                <Col span={1} className="mt-4">
                  {fields.length > 1 && (
                    <MinusCircleOutlined onClick={() => remove(name)} className="text-red-500 text-lg cursor-pointer" />
                  )}
                </Col>
              </Row>
            ))}

            <Button type="dashed" className="my-2" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Row
            </Button>
          </>
        )}
      </Form.List>

      <span className="text-xs mb-4 ml-2">
        <span className="font-bold italic">Examples: </span> <span className="font-semibold">/njuː ˈjɔrk/</span> for{' '}
        <span className="font-semibold text-gray-500">New York</span>, <span className="font-semibold">/ˈpɛrɪs/</span>{' '}
        for <span className="font-semibold text-gray-500">Paris</span>,{' '}
        <span className="font-semibold">/ˈtoʊ.kioʊ/</span> for{' '}
        <span className="font-semibold text-gray-500">Tokyo</span>
      </span>
    </Row>
  );

  return (
    <>
      <Spin
        spinning={mutation.isPending || isPending || isPendingAutoGen}
        indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}
      >
        <OverViewCardForm
          form={form}
          initialValues={{
            wordsList: [{}],
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
        <Table dataSource={dataSource} columns={columns} scroll={{ y: 240 }} pagination={false} />
      </Spin>
    </>
  );
};

export default PronunciationGuideForm;
