export enum ReceptionistDashboardSiderItem {
  HOME = 'Overview',
  GET_STARTED = 'Get Started',
  CRM = 'CRM',
  SETUP_INSTRUCTIONS = 'Phone Number',
  CONFIGURE = 'Configure',
  ANALYTICS = 'Analytics',
  VOICE_LIBRARY = 'Voice Library',
  NOTIFICATIONS = 'Notifications',
  ADVANCED_FEATURES = 'Advanced Features',
  TEXTING_PLATFORM = 'Texting Platform',
  INTAKE_FORMS = 'Intake Forms',
  CALL_LOGS = 'Call Logs',
  LOGS = 'Logs',
  TEXT_LOGS = 'Text Logs',
  VOICE_MAIL_LOGS = 'Voicemail Logs',
  REFERRAL_PROGRAM = 'Referral Program',
  CONTACT_SUPPORT = 'Contact Support',
  SUBSCRIPTION_MANAGEMENT = 'Subscription',
  RESELLER_SUPPORT_BUTTON_1 = 'Reseller Support Button 1',
  RESELLER_SUPPORT_BUTTON_2 = 'Reseller Support Button 2',
  EXPLORE_FEATURES = 'Explore Features',
}
