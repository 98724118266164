import { Button, ButtonProps } from 'antd';

const CustomOnboardingButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      className={`sm:text-xl text-sm rounded-3xl h-14 px-12 bg-button-success text-white font-semibold ${props.className}`}
    >
      {props.children}
    </Button>
  );
};

export default CustomOnboardingButton;
