import React, { useState } from 'react';
import { Badge, Button, Card, Checkbox, Tooltip } from 'antd';
import { ChatBubbleOutline, DiamondOutlined, MailOutline, WorkspacePremiumOutlined } from '@mui/icons-material';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { PlusOutlined } from '@ant-design/icons';
import AddEmails from '../components/AddEmails';
import AddPhones from '../components/AddPhones';
import { InfoTypeCrm } from '@/interfaces/INotifications';
import PreviewEmail from '../components/PreviewEmail';
import PreviewSms from '../components/PreviewSms';
import { NotifKey } from 'src/enums/ENotifKey';
import { getResellerData } from '@/store/reseller/selector';

interface props {
  crmNotif: InfoTypeCrm[];
  updateUser: (val: string) => void;
}

const CrmBasedNotification: React.FC<props> = ({ crmNotif, updateUser }) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const [categories, setCategories] = useState<InfoTypeCrm[]>(crmNotif);
  const [emailAddClicked, setEmailAddClicked] = useState<number | null>(null);
  const [phoneAddClicked, setPhoneAddClicked] = useState<number | null>(null);
  const [previewEmail, setPreviewEmail] = useState(false);
  const [previewSms, setPreviewSms] = useState(false);
  const currentReseller = useAppSelector(getResellerData);

  const handleCheckboxChange = (categoryIndex: number, method: 'email' | 'sms' | 'voicemail') => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex][method] = !updatedCategories[categoryIndex][method];
    setCategories(updatedCategories);
    updateUser(JSON.stringify(updatedCategories));
  };

  const saveEmailsFn = (val: string[], categoryIndex: number) => {
    const newCat = categories.map((item, index) => {
      let emails = item.emails;
      if (index === categoryIndex) {
        emails = val;
      }
      return {
        ...item,
        emails,
      };
    });
    setCategories(newCat);
    updateUser(JSON.stringify(newCat));
  };

  const savePhonesFn = (val: string[], categoryIndex: number) => {
    const newCat = categories.map((item, index) => {
      let phones = item.phones;
      if (index === categoryIndex) {
        phones = val;
      }
      return {
        ...item,
        phones,
      };
    });
    setCategories(newCat);
    updateUser(JSON.stringify(newCat));
  };

  return (
    <Card className='w-full mx-auto bg-white rounded-lg shadow-lg'>
      <div className='flex flex-col mb-4'>
        <div className='flex justify-between items-center'>
          <h3 className='text-lg font-semibold'>Crm Based Notification Settings</h3>
          <div className='flex items-center space-x-4'>
            <Tooltip title='Customize Email Notification'>
              <MailOutline fontSize='small' className='cursor-pointer' onClick={() => setPreviewEmail(true)} />
            </Tooltip>
            <Tooltip title='Customize Text SMS Notification'>
              <ChatBubbleOutline fontSize='small' className='cursor-pointer' onClick={() => setPreviewSms(true)} />
            </Tooltip>
          </div>
        </div>
        <span>Receive email and phone notifications when any time a lead enter a group in your CRM.</span>
      </div>
      {categories.map((category, categoryIndex) => (
        <div key={categoryIndex}>
          <div className='my-4'>
            <div className={`flex justify-between items-center`}>
              <div>
                <h4 className='font-semibold'>{category.group_name}</h4>
                <p className='text-sm text-gray-500'>
                  {/* {item.description} */}
                  Select where we should notify you
                </p>
              </div>

              <div className='flex space-x-8'>
                <div className='flex items-center space-x-1'>
                  <Tooltip title='Included from Basic plan'>
                    <WorkspacePremiumOutlined fontSize='small' className='text-blue-500 cursor-pointer' />
                  </Tooltip>
                  <label>Email</label>
                  <Checkbox
                    checked={category.email}
                    onChange={() => handleCheckboxChange(categoryIndex, 'email')}
                    disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
                  />
                </div>

                <div className='flex items-center space-x-1'>
                  <Tooltip title='Included from Pro plan'>
                    <DiamondOutlined fontSize='small' className='text-yellow-500 cursor-pointer' />
                  </Tooltip>
                  <label>SMS</label>
                  <Checkbox
                    checked={category.sms}
                    onChange={() => handleCheckboxChange(categoryIndex, 'sms')}
                    disabled={
                      loggedInUser?.resellerClient
                        ? false
                        : loggedInUser.subscriptionStatus !== 'active' || loggedInUser.subscriptionType !== 'Pro'
                    }
                  />
                </div>

                <div className='flex items-center space-x-1'>
                  <Tooltip title='Included from Pro plan'>
                    <DiamondOutlined fontSize='small' className='text-yellow-500 cursor-pointer' />
                  </Tooltip>
                  <label>Voicemail</label>
                  <Badge count={'Upcoming'} className='badge-info' />
                  <Checkbox
                    checked={category.voicemail}
                    onChange={() => handleCheckboxChange(categoryIndex, 'voicemail')}
                    disabled={
                      true
                      //loggedInUser.subscriptionStatus !== 'active' || loggedInUser.subscriptionType !== 'Pro'
                    }
                  />
                </div>
              </div>
            </div>
            {category.email && (
              <div className='flex flex-col px-4'>
                <span className='font-semibold italic text-xs'>Emails to be notified:</span>
                <div className='flex gap-2 items-center'>
                  {category.emails.map((item: string, index: number) => {
                    return <Badge key={index} count={item} className='badge-disabled' />;
                  })}
                  <Button type='link' icon={<PlusOutlined />} onClick={() => setEmailAddClicked(categoryIndex)}>
                    Add Email
                  </Button>
                  <AddEmails
                    open={emailAddClicked === categoryIndex}
                    setOpen={val => {
                      setEmailAddClicked(val as null);
                    }}
                    emails={category.emails}
                    saveEmails={(val: string[]) => saveEmailsFn(val, categoryIndex)}
                  />
                </div>
              </div>
            )}
            {category.sms && (
              <div className='flex flex-col mt-2 px-4'>
                <span className='font-semibold italic text-xs'>Phones to be notified:</span>
                <div className='flex gap-2 items-center'>
                  {category.phones.map((item: string, index: number) => {
                    return (
                      <Badge
                        key={index}
                        className='badge-disabled font-medium text-xs py-1 px-2 rounded-lg hover:bg-blue-100'
                      >
                        {item}
                      </Badge>
                    );
                  })}
                  <Button type='link' icon={<PlusOutlined />} onClick={() => setPhoneAddClicked(categoryIndex)}>
                    Add Phone
                  </Button>
                  <AddPhones
                    open={phoneAddClicked === categoryIndex}
                    setOpen={val => setPhoneAddClicked(val as null)}
                    phones={category.phones}
                    savePhones={(val: string[]) => savePhonesFn(val, categoryIndex)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <PreviewEmail
        open={previewEmail}
        setOpen={setPreviewEmail}
        disabled={loggedInUser?.resellerClient ? false : loggedInUser.subscriptionStatus !== 'active'}
        notifKey={NotifKey.CRM}
        show={null}
        resellerName={currentReseller?.reseller_name}
        resellerLogo={currentReseller?.logoUrl}
        resellerClient={loggedInUser?.resellerClient}
      />
      <PreviewSms
        open={previewSms}
        setOpen={setPreviewSms}
        disabled={
          loggedInUser?.resellerClient
            ? false
            : loggedInUser.subscriptionStatus !== 'active' || loggedInUser.subscriptionType !== 'Pro'
        }
        notifKey={NotifKey.CRM}
        show={null}
      />
    </Card>
  );
};

export default CrmBasedNotification;
