export const languageExamples = {
  English: 'Hello, how may I help you?',
  Spanish: 'Hola, ¿en qué puedo ayudarte?',
  French: 'Bonjour, comment puis-je vous aider ?',
  Italian: 'Ciao, come posso aiutarti?',
  German: 'Hallo, wie kann ich Ihnen helfen?',
  Portuguese: 'Olá, como posso ajudá-lo?',
  Japanese: 'こんにちは、どのようなお手伝いができますか？',
  Mandarin: '你好，我可以帮助你吗？',
  Arabic: 'مرحبًا، كيف يمكنني مساعدتك؟',
  Russian: 'Привет, чем я могу помочь вам?',
  Hindi: 'नमस्ते, मैं आपकी कैसे मदद कर सकता हूँ?',
  Dutch: 'Hallo, hoe kan ik je helpen?',
};
