import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Checkbox, Modal, Typography, Tag, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import cloneDeep from 'clone-deep';

const { Text } = Typography;

const TextEditor = ({ value, onChange, html, vvariables = {}, placeholder }) => {
  const inputRef = useRef();
  const rootRef = useRef();
  const selectionRef = useRef();
  const [initialHTML] = useState(html || value || ''); // Use form value if provided
  const [isEmpty, setIsEmpty] = useState((html || value || '').length === 0);
  const [internalValue, setInternalValue] = useState(html || value || '');

  // Modal states
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  // Add variable states
  const [newVariable, setNewVariable] = useState({
    text: '',
    isMandatory: true,
    defaultValue: '',
  });

  // Edit variable states
  const [editingVariable, setEditingVariable] = useState({
    originalText: '',
    text: '',
    isMandatory: true,
    defaultValue: '',
  });

  // Selection handling functions
  const saveSelection = () => {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    }
    return null;
  };

  const restoreSelection = range => {
    if (range && window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const insertTextAtCursor = element => {
    const range = selectionRef.current;
    if (range && window.getSelection) {
      range.deleteContents();
      range.insertNode(element);
      range.setStartAfter(element);
      range.setEndAfter(element);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const placeCaretAtEnd = el => {
    el.focus();
    if (window.getSelection && document.createRange) {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== internalValue) {
      setInternalValue(value);
      if (inputRef.current) {
        inputRef.current.innerHTML = value;
      }
    }
  }, [value]);

  // Event handlers
  const handleContentChange = useCallback(() => {
    const newValue = inputRef?.current?.innerHTML || '';
    setIsEmpty(newValue.length === 0);
    setInternalValue(newValue);
    // Call the form's onChange with the new value
    onChange?.(newValue);
    selectionRef.current = saveSelection();
  }, [onChange]);

  const handleVariableClick = useCallback(
    e => {
      console.log('clicked the variable');
      const variableText = e?.target?.textContent?.replace('{{', '').replace('}}', '');
      setEditingVariable({
        originalText: variableText,
        text: variableText,
        isMandatory: vvariables[variableText]?.isMandatory ?? true,
        defaultValue: vvariables[variableText]?.defaultValue ?? '',
      });
      setEditModalVisible(true);
    },
    [vvariables, setEditingVariable]
  );

  // Variable management functions
  const insertVariable = useCallback(
    (varData, useExisting = false) => {
      const { text, isMandatory, defaultValue } = varData;

      if (!useExisting) {
        if (!text) {
          message.error('Variable name cannot be empty');
          return;
        }
        if (!isMandatory && !defaultValue) {
          message.error('Default value is required for non-mandatory variables');
          return;
        }
      }

      const element = document.createElement('span');
      element.innerHTML = `{{${text}}}`;
      element.classList.add('highlight');
      element.contentEditable = false;
      element.addEventListener('click', handleVariableClick);

      restoreSelection(selectionRef.current);
      insertTextAtCursor(element);

      const updatedVariables = cloneDeep(vvariables);
      if (!useExisting) {
        updatedVariables[text] = { isMandatory, defaultValue };
      }

      onChange(inputRef?.current?.innerHTML || '', updatedVariables);
      setAddModalVisible(false);
      setNewVariable({ text: '', isMandatory: true, defaultValue: '' });
    },
    [vvariables, onChange, handleVariableClick]
  );

  const handleSaveEdit = useCallback(() => {
    const { originalText, text, isMandatory, defaultValue } = editingVariable;

    if (!text) {
      message.error('Variable name cannot be empty');
      return;
    }
    if (!isMandatory && !defaultValue) {
      message.error('Default value is required for non-mandatory variables');
      return;
    }

    const elements = rootRef.current.querySelectorAll('span.highlight');
    for (let element of elements) {
      if (element.textContent === `{{${originalText}}}`) {
        element.innerHTML = `{{${text}}}`;
      }
    }

    const updatedVariables = { ...vvariables };
    delete updatedVariables[originalText];
    updatedVariables[text] = { isMandatory, defaultValue };

    onChange(inputRef?.current?.innerHTML || '', updatedVariables);
    setEditModalVisible(false);
  }, [editingVariable, vvariables, onChange]);

  // Effect hooks
  useEffect(() => {
    if (rootRef.current) {
      const elements = rootRef.current.querySelectorAll('span.highlight');
      elements.forEach(el => el.addEventListener('click', handleVariableClick));
    }
  }, [handleVariableClick]);

  useEffect(() => {
    if (inputRef.current) {
      const events = ['click', 'input', 'DOMNodeInserted', 'DOMNodeRemoved', 'DOMCharacterDataModified'];
      events.forEach(event => {
        inputRef.current.addEventListener(event, handleContentChange);
      });

      return () => {
        events.forEach(event => {
          inputRef.current?.removeEventListener(event, handleContentChange);
        });
      };
    }
  }, [handleContentChange]);

  return (
    <div className="relative" ref={rootRef}>
      <div className="relative mb-4">
        <div
          className="w-full min-h-[100px] p-4 border rounded resize-y overflow-auto focus:outline-none focus:border-primary"
          contentEditable
          ref={inputRef}
          onInput={handleContentChange}
          dangerouslySetInnerHTML={{ __html: initialHTML }}
        />
        {isEmpty && <Text className="absolute left-4 top-4 text-gray-400 pointer-events-none">{placeholder}</Text>}
      </div>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          const sel = saveSelection();
          if (inputRef?.current?.contains(sel?.startContainer)) {
            selectionRef.current = sel;
          } else {
            placeCaretAtEnd(inputRef.current);
            selectionRef.current = saveSelection();
          }
          setAddModalVisible(true);
        }}
      >
        Add Variable
      </Button>

      <Modal
        title="Add Variable"
        open={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={() => insertVariable(newVariable)}
        width={400}
      >
        <Space direction="vertical" className="w-full">
          <Input
            placeholder="Variable name"
            value={newVariable.text}
            onChange={e => setNewVariable({ ...newVariable, text: e.target.value })}
          />
          {/* <Checkbox
            checked={newVariable.isMandatory}
            onChange={e => setNewVariable({ ...newVariable, isMandatory: e.target.checked })}
          >
            Is mandatory
          </Checkbox>
          {!newVariable.isMandatory && (
            <Input
              placeholder="Default value"
              value={newVariable.defaultValue}
              onChange={e => setNewVariable({ ...newVariable, defaultValue: e.target.value })}
            />
          )} */}
          {Object.keys(vvariables).length > 0 && (
            <div>
              <Text strong>Existing variables:</Text>
              <div className="mt-2 flex flex-wrap gap-2">
                {Object.keys(vvariables).map(varName => (
                  <Tag
                    key={varName}
                    color="blue"
                    className="cursor-pointer"
                    closable
                    onClose={e => {
                      e.preventDefault(); // Prevent the default close behavior
                      const newVariables = { ...vvariables };
                      delete newVariables[varName];
                      onChange(inputRef?.current?.innerHTML || '', newVariables);
                    }}
                    onClick={() => insertVariable({ text: varName }, true)}
                  >
                    {varName}
                  </Tag>
                ))}
              </div>
            </div>
          )}
        </Space>
      </Modal>

      <Modal
        title="Edit Variable"
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        onOk={handleSaveEdit}
      >
        <Space direction="vertical" className="w-full">
          <Input
            placeholder="Variable name"
            value={editingVariable.text}
            onChange={e => setEditingVariable({ ...editingVariable, text: e.target.value })}
          />
          {/* <Checkbox
            checked={editingVariable.isMandatory}
            onChange={e => setEditingVariable({ ...editingVariable, isMandatory: e.target.checked })}
          >
            Is mandatory
          </Checkbox>
          {!editingVariable.isMandatory && (
            <Input
              placeholder="Default value"
              value={editingVariable.defaultValue}
              onChange={e => setEditingVariable({ ...editingVariable, defaultValue: e.target.value })}
            />
          )} */}
        </Space>
      </Modal>
    </div>
  );
};

export default TextEditor;
