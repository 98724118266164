import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, notification, Spin } from 'antd';
import { useState } from 'react';
import { OverViewCardForm } from '../../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { User } from '@/types/TAccounts';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { handlePrefixPlusOnPhone } from '@/utils/helper';

const BlockedNumbersForm: React.FC<{
  blockedNumbers: string[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ blockedNumbers, refetch }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Details have been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const handleSubmit = async (values: { blockedNumbers: string[] }) => {
    const parsedPlus = values.blockedNumbers.map(item => handlePrefixPlusOnPhone(item));
    const formattedValues = {
      blockedNumbers: JSON.stringify(parsedPlus),
    };

    await mutation.mutateAsync(formattedValues);
    dispatch(closeModal());
  };

  const FormItems = () => (
    <div className="max-h-[60vh] overflow-y-auto px-2">
      <Form.List name="blockedNumbers" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className="flex items-center gap-2 w-full">
                <Form.Item
                  className="pt-0 w-full"
                  name={name}
                  rules={[{ required: true, message: 'Missing phone number' }]}
                  label="Phone Number"
                >
                  <PhoneNumberInput name={name} />
                </Form.Item>

                {fields.length > 1 && (
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    className="text-red-600 text-lg pt-0 cursor-pointer"
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Number
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            blockedNumbers,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  );
};

export default BlockedNumbersForm;
