import React, { useEffect, useState } from 'react';
import { Button, Table, Input, Spin, Tag, Dropdown, Modal, Space, notification } from 'antd';
import { SearchOutlined, EditOutlined, AuditOutlined } from '@ant-design/icons';
import AddNewLeads from './AddLeads/AddNewLeads';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchCampaignEventsByCampaignId } from '@/api/novi/outbondAnalytics';

import { CallMadeOutlined } from '@mui/icons-material';
import { TableRowSelection } from 'antd/es/table/interface';
import { deleteLeadFn } from '@/api/novi/noviLeads';
import { callNow } from '@/api/novi/CamapaignPortalApis';
import moment from 'moment';
import { Lead, LeadsInfoProps } from '@/pages/Novi/types/Leads';
import { outboundCallEvent } from '@/pages/Novi/types/Events';
import LeadsInfoForm from './LeadsInfoForm';
import OutboundAnalyticsDashboard from '../OutboundAnalytics/OutboundAnalytics';

const StatusBadge: React.FC<{ status: Lead['status'] }> = ({ status }) => {
  let color;
  switch (status) {
    case 'CONTACTED':
      color = 'green';
      break;
    case 'PROCESSING':
      color = 'yellow';
      break;
    case 'FAILED':
    case 'FAILED_TERMINAL':
      color = 'red';
      break;
    case 'CANCELLED':
      color = 'blue';
      break;
    case 'NEW':
      color = 'cyan';
      break;
    default:
      color = 'grey';
  }
  return <Tag color={color}>{status}</Tag>;
};

const menuItems = [
  { key: 'All', label: 'All' },
  { key: 'NEW', label: 'New' },
  { key: 'CONTACTED', label: 'Contacted' },
  { key: 'CANCELLED', label: 'Cancelled' },
  { key: 'PROCESSING', label: 'Processing' },
  { key: 'FAILED', label: 'Failed' },
  { key: 'FAILED_TERMINAL', label: 'Failed Terminal' },
];

const LeadsInfo: React.FC<LeadsInfoProps> = ({ initialContacts, refetch, isLoading, isRefetching }) => {
  const [loading, setLoading] = useState(false);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [contacts, setContacts] = useState<Lead[]>([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [addLeadModal, setAddLeadModal] = useState(false);
  const [leadManagementKey, setLeadManagementKey] = useState(0);
  const [leadDetailSheetKey, setLeadDetailSheetKey] = useState(0);
  const [analyticsModal, setAnalyticsModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [makeCallToNumber, setMakeCallToNumber] = useState<string | null>(null);
  const [callInProgressLeadId, setCallInProgressLeadId] = useState<string | null>(null);
  const [scheduledEvents, setScheduledEvents] = useState<outboundCallEvent[]>([]);
  const [immediateCallEvents, setImmediateCallEvents] = useState<outboundCallEvent[]>([]);

  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const variables = selectedCampaign?.variables ? JSON.parse(selectedCampaign.variables) : [];

  const { data: outboundAnalytics, isPending: isLoadingAnyalytics } = useQuery({
    queryKey: ['outboundEvents', selectedCampaign?.number],
    queryFn: () => {
      if (selectedCampaign) {
        return fetchCampaignEventsByCampaignId(selectedCampaign?.campaign_user_email, selectedCampaign?.number);
      }
      return [];
    },
  });

  const { mutate, isPending: deletingLeads } = useMutation({
    mutationKey: ['deleteLeads'],
    mutationFn: () => deleteLeadFn(selectedCampaign?.number as string, selectedRowKeys as string[]),
    onSuccess: () => {
      refetch();
      setSelectedRowKeys([]);
      notification.success({ message: 'Leads deleted successfully' });
    },
    onError: () => {
      notification.error({ message: 'Error deleting leads' });
    },
  });

  const { mutate: immediateCall } = useMutation({
    mutationKey: ['immediateCall'],
    mutationFn: callNow,
    onSuccess: () => {
      notification.success({ message: 'Call initiated successfully' });
      localStorage.setItem('callToNumber', makeCallToNumber as string);
      setTimeout(() => {
        localStorage.removeItem('callToNumber');
      }, 60000);
    },
    onError: () => {
      notification.error({ message: 'Error initiating call' });
    },
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const closeAddLeadModal = () => {
    setAddLeadModal(false);
    setLeadManagementKey(prevKey => prevKey + 1);
  };

  const handleCloseAnalyticsModal = () => {
    setAnalyticsModal(false);
  };

  useEffect(() => {
    setContacts(initialContacts);
  }, [initialContacts]);

  const filteredContacts = contacts.filter(contact => {
    const matchesSearch = contact?.phone_number?.includes(searchText);

    const matchesFilter =
      filterStatus === 'All' && searchText === '' ? true : filterStatus ? contact.status === filterStatus : true;

    return matchesSearch && matchesFilter;
  });

  const displayedContacts = filteredContacts.slice(0, visibleCount);

  const handleRowClick = (contact: Lead) => {
    setSelectedLead(contact);
  };

  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      if (displayedContacts.length < filteredContacts.length) {
        setVisibleCount(prevCount => prevCount + 5);
      }
      setLoading(false);
    }, 1000);
  };

  const closeSheet = () => {
    setSelectedLead(null);
    setLeadDetailSheetKey(prevKey => prevKey + 1);
  };

  const handleCloseMakeACallModal = () => {
    setMakeCallToNumber(null);
  };

  const initiateCall = () => {
    immediateCall({
      lead: initialContacts.find(contact => contact.phone_number === makeCallToNumber),
      campaign_id: selectedCampaign?.number,
      campaign_user_email: selectedCampaign?.campaign_user_email,
      timeZone: moment.tz.guess(),
    });
    handleCloseMakeACallModal();
    refetch();
  };

  const handleAnalyticsClick = (scheduleEvent: outboundCallEvent[], immediateEvent: outboundCallEvent[]) => {
    if (scheduleEvent && Object.keys(scheduleEvent).length) {
      setScheduledEvents(scheduleEvent);
      setAnalyticsModal(true);
    }

    if (immediateEvent.length) {
      setImmediateCallEvents(immediateEvent);
      setAnalyticsModal(true);
    }
  };

  const handleMakeACall = (record: Lead) => {
    setMakeCallToNumber(record.phone_number);
  };

  const columns = [
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    ...Object.keys(variables).map((variable: string) => ({
      title: variable.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
      dataIndex: ['variables', variable],
      key: `variables.${variable}`,
    })),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: Lead['status']) => <StatusBadge status={status} />,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: Lead) => {
        const immediateCallEvents = outboundAnalytics?.filter(
          (event: outboundCallEvent) => event.campaign_sort_key === 'Immediate Call' && event.lead_id === record.lead_id
        );
        const scheduledCallEvents = outboundAnalytics?.filter(
          (event: outboundCallEvent) => event.campaign_sort_key !== 'Immediate Call' && event.lead_id === record.lead_id
        );

        const isCallInProgress = record.lead_id === callInProgressLeadId;

        return (
          <Space>
            {(scheduledCallEvents?.length || immediateCallEvents?.length) && (
              <Button
                type="link"
                icon={<AuditOutlined />}
                onClick={() => handleAnalyticsClick(scheduledCallEvents, immediateCallEvents)}
              >
                Analytics
              </Button>
            )}
            <Button type="link" icon={<EditOutlined />} onClick={() => handleRowClick(record)}>
              Edit
            </Button>
            <Button
              type="primary"
              icon={<CallMadeOutlined />}
              onClick={() => handleMakeACall(record)}
              disabled={isCallInProgress || !!localStorage.getItem('callToNumber')}
            >
              Make A Call
            </Button>
          </Space>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<Lead> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  useEffect(() => {
    const callToNumber = localStorage.getItem('callToNumber');
    if (callToNumber) {
      const callInProgressLead = initialContacts.find(contact => contact.phone_number === callToNumber);
      if (callInProgressLead) {
        setCallInProgressLeadId(callInProgressLead.lead_id);
      }
    } else {
      setCallInProgressLeadId(null);
    }
  }, [initialContacts]);

  return (
    <div className="w-full">
      <div className="tab-controls flex justify-between items-center p-4">
        <div className="tab-controls-left">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search phone number..."
            className="search-input"
            onChange={(e: any) => setSearchText(e.target.value)}
          />
        </div>
        <div className="tab-controls-right flex items-center gap-2">
          <Dropdown.Button
            menu={{
              items: menuItems,
              onClick: (e: any) => {
                setFilterStatus(e.key);
              },
            }}
          >
            Filter
          </Dropdown.Button>
          <Button type="primary" onClick={() => setAddLeadModal(true)}>
            Add New Lead
          </Button>
          {selectedRowKeys.length > 0 && (
            <Button type="primary" danger onClick={() => mutate()} loading={deletingLeads}>
              Delete Selected
            </Button>
          )}
        </div>
      </div>
      <div className="max-h-[600px] overflow-y-auto">
        <Spin spinning={isLoadingAnyalytics || loading || isLoading || isRefetching}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={displayedContacts}
            pagination={false}
            rowKey="lead_id"
            locale={{
              emptyText: (
                <div style={{ color: 'black' }}>🌟 No Leads Available! Click Add New Lead To Add Leads 🌟</div>
              ),
            }}
          />
        </Spin>
      </div>
      <div className="flex items-center justify-center mt-4">
        <div className="m-2 text-sm text-gray-500">
          Showing {displayedContacts.length} of {filteredContacts.length} Leads
        </div>
        {filteredContacts.length > 5 && (
          <Button
            onClick={handleLoadMore}
            disabled={loading || displayedContacts.length >= filteredContacts.length}
            type="primary"
          >
            {loading ? <Spin size="small" /> : 'Load more'}
          </Button>
        )}
      </div>
      <Modal open={!!selectedLead} title="Lead Details" onCancel={closeSheet} footer={null} width={800}>
        <LeadsInfoForm
          lead={selectedLead as Lead}
          refetchLeads={refetch}
          onClose={closeSheet}
          key={leadDetailSheetKey}
        />
      </Modal>
      <Modal title="Add New Lead" open={addLeadModal} onCancel={closeAddLeadModal} footer={null} width={800}>
        <AddNewLeads key={leadManagementKey} refetch={refetch} closeModal={closeAddLeadModal} leads={initialContacts} />
      </Modal>
      <Modal open={analyticsModal} onCancel={handleCloseAnalyticsModal} footer={null} width={800}>
        <OutboundAnalyticsDashboard scheduledEvent={scheduledEvents[0]} immediateCallEvents={immediateCallEvents} />
      </Modal>
      <Modal
        open={!!makeCallToNumber}
        onCancel={handleCloseMakeACallModal}
        footer={
          <Space>
            <Button onClick={handleCloseMakeACallModal}>Cancel</Button>
            <Button type="primary" onClick={initiateCall}>
              Make a call
            </Button>
          </Space>
        }
        width={800}
      >
        <div className="p-4">
          <h1 className="text-lg font-semibold">Do you want to make a call to this {makeCallToNumber}?</h1>
        </div>
      </Modal>
    </div>
  );
};

export default LeadsInfo;
