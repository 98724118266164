import { Button, Form, Input, Alert, notification } from 'antd';
import PublicLayout from '@/components/Layout/PublicLayout';
import './ResetPasswordForm.scss';
import { TResetPassword } from '@/types/TResetPassword';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isResetPasswordLinkValid, resetPassword } from '@/api/user';
import { useMutation, useQuery } from '@tanstack/react-query';

export const ResetPasswordForm: React.FC = () => {
  const { username, token } = useParams();
  const [form] = Form.useForm<TResetPassword>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);

  const handleInputChange = () => {
    const password = form.getFieldValue('password');
    const confirmPassword = form.getFieldValue('confirmPassword');
    if (password && confirmPassword && password !== confirmPassword) {
      setPasswordsMatch(false);
      setErrorMessage('Passwords do not match');
    } else {
      setPasswordsMatch(true);
      setErrorMessage(null);
    }
  };

  const {
    data: isResetLinkValid,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['isResetPasswordLinkValid', { username, token }],
    queryFn: () => {
      if (username && token) {
        return isResetPasswordLinkValid({ username, token });
      }
    },
  });

  const { mutate: passwordReset, isPending } = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: resetPassword,
    onSuccess: () => {
      notification.success({
        message: 'Password reset successfully',
        description: 'Redirecting to login page',
      });
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
    },
  });

  const submitNewPassword = (values: TResetPassword) => {
    if (username && token) {
      passwordReset({ username, password: values.password });
    }
  };

  useEffect(() => {
    if ((isResetLinkValid && !isResetLinkValid.isValid) || isError) {
      setErrorMessage('Invalid reset password link');
    }
  }, [isResetLinkValid]);

  const baseClass = 'reset-password-form-container';

  return (
    <PublicLayout debrand={false} items={[]}>
      <Form
        onFinish={(values: TResetPassword) => submitNewPassword(values)}
        form={form}
        layout="vertical"
        name="login-form"
        className={baseClass}
      >
        <Form.Item className={`${baseClass}__heading`}>
          <h2>Reset Password</h2>
          <p>Create a new password for your account</p>
        </Form.Item>

        {errorMessage && (
          <Form.Item className={`${baseClass}__error-response`}>
            <Alert message={errorMessage} type="error" />
          </Form.Item>
        )}

        <Form.Item
          label="Password"
          name="password"
          className={`${baseClass}__password-input-item`}
          normalize={value => value.trim()}
          rules={[
            {
              required: true,
              message: 'Password is required!',
            },
          ]}
        >
          <Input.Password placeholder="Password" onChange={handleInputChange} />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          className={`${baseClass}__password-input-item`}
          normalize={value => value.trim()}
          rules={[
            {
              required: true,
              message: 'Confirm password is required!',
            },
          ]}
        >
          <Input.Password placeholder="Confirm Password" onChange={handleInputChange} />
        </Form.Item>

        <Form.Item>
          <Button
            loading={isLoading || isPending}
            disabled={!passwordsMatch || isLoading || isError}
            type="primary"
            htmlType="submit"
            className={`${baseClass}__submit-button`}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </PublicLayout>
  );
};
