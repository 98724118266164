import apiClient from '@/utils/apiClient';
import { toast } from 'react-toastify';

export interface Client {
  items: any[];
}

export interface CreateCampaignParams {
  username: string;
  campaignName: string;
  greetingPhrase: string;
  variables: Record<string, { isMandatory: boolean; Value: string }>;
}

export interface CreateCampaignPayload {
  campaignName: string;
  greetingPhrase: string;
  username: string;
  variables: { [key: string]: { isMandatory: boolean; Value: string } };
}

interface CreateCampaignResponse {
  message: string;
}

export interface UpdateLeadDataPayload {
  phone?: string;
  name?: string;
  status?: string;
  lead_id?: string;
  campaign_id?: string;
  custom_fields?: { name: string; value: string }[];
}

export interface updateLeadDataPayload {
  phone_number?: string;
  status?: string;
  lead_id?: string;
  campaign_id?: string;
  variables?: { [key: string]: string };
}

export const fetchCampaignClientByEmail = async (emailAddress: string): Promise<Client> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: '/api/campaigns/clients',
      body: { email_address: emailAddress },
    });
    return response;
  } catch (error) {
    throw new Error('Error fetching campaign client by email: ' + (error as Error).message);
  }
};

export const updateLeadDataFn = async (payload: updateLeadDataPayload): Promise<CreateCampaignResponse> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/leads/${payload?.campaign_id}/updateLead/${payload?.lead_id}`,
      body: { ...payload },
    });
    return response.data;
  } catch (error) {
    toast.error('Error uploading leads.');
    throw new Error('Error fetching campaign client by email: ' + (error as Error).message);
  }
};

export const updateLeadData = async (payload: UpdateLeadDataPayload): Promise<CreateCampaignResponse> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/campaign/${payload.campaign_id}/updateLead/${payload.lead_id}`,
      body: payload,
    });
    return response.data;
  } catch (error) {
    const errorMessage = (error as Error).message || 'An unknown error occurred';
    toast.error(`Error updating leads: ${errorMessage}`);
    throw new Error(`Error updating lead data: ${errorMessage}`);
  }
};

export const saveCampaignSettings = async (settings: any, user_id: string, id?: string): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/campaigns/save`,
      body: { id, user_id, settings },
    });
    return response.data;
  } catch (error) {
    toast.error('Error saving campaign settings.');
    throw new Error('Error saving campaign settings: ' + (error as Error).message);
  }
};

export const fetchCampaignSettings = async (campaign_id: string): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `/api/campaigns/getCampaignsSettings/${campaign_id}`,
    });
    return response;
  } catch (error) {
    throw new Error('Error fetching campaign settings: ' + (error as Error).message);
  }
};

export const initiateCampaign = async (id: string, campaign_id: string, email: string): Promise<string> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/campaigns/${campaign_id}/launch`,
      body: { id, email },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error launching campaign: ' + (error as Error).message);
  }
};

export const updateCampaignVariables = async (
  campaign_id: string,
  variables: any
): Promise<{
  message: string;
  variables: any;
}> => {
  if (!campaign_id) {
    return { message: 'Campaign ID is required', variables: [] };
  }
  try {
    const response = await apiClient.request({
      method: 'PUT',
      endPoint: `/api/campaigns/${campaign_id}/updateVariables`,
      body: { variables },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating campaign variables: ' + (error as Error).message);
  }
};

export const cancelCampaignAPI = async (id: string, campaign_id: string): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/campaigns/cancel`,
      body: { id, campaign_id },
    });

    return response.data;
  } catch (error) {
    throw new Error('Error cancelling campaign: ' + (error as Error).message);
  }
};

export const getCampaignVariables = async (campaign_id: string): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `/api/campaigns/${campaign_id}/variables`,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching campaign variables: ' + (error as Error).message);
  }
};

export const callNow = async (payload: {
  lead: any;
  campaign_id: string;
  campaign_user_email: string;
  timeZone: string;
}): Promise<{ message: string }> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/campaigns/call/immediate`,
      body: payload,
    });

    return response.data;
  } catch (error) {
    throw new Error('Error immediate campaign: ' + (error as Error).message);
  }
};
