import React, { useState, useEffect, useRef } from 'react';
import classes from './Login.module.css';
import gradient from '../Whitelabel/gradient.jpg';
import logo from './../Blue_Logo.png';
import { Link, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReCaptcha from 'react-google-recaptcha';
import { prod } from '../config';
import toast from 'react-hot-toast';

const Login = ({ setUser, refreshInt, debrand, isCampaign, title }) => {
  const [searchParams] = useSearchParams();
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const [comingFromRegister, setComingFromRegister] = useState(false);
  const captchaRef = useRef(null);
  const [isStayLoggedIn, setIsStayLoggedIn] = useState(false);

  // Campaign User Functions

  var serverURL = prod ? 'https://myaifrontdeskadmin.com' : 'http://localhost:8080';

  const login = async (emailAddress, password) => {
    if (!emailAddress || !password) return;
    try {
      const response = await fetch(`${serverURL}/api/campaigns/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress: emailAddress,
          password: password,
        }),
      });

      const data = await response.json();

      if (data.success) {
        const campaignUserObject = data.campaignUser;
        if (isStayLoggedIn) {
          localStorage.setItem('CAMPAIGN_USER_PORTAL_USER', JSON.stringify(campaignUserObject));
        }
        setUser(campaignUserObject);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      localStorage.removeItem('CAMPAIGN_USER_PORTAL_USER');
      console.log("Couldn't get campaignUserObject");
    }
  };

  async function getStuff() {
    if (prod && !comingFromRegister) {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();

      const captchaResponse = await fetch(`${serverURL}/captcha`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
        }),
      });
      if (!captchaResponse.ok) {
        throw new Error('Network response was not ok');
        return;
      }
      const captchaResult = await captchaResponse.json();
      if (captchaResult.status != 'human') {
        alert('Please complete the captcha');
        return;
      }
    }

    await login(emailAddress, password);
  }

  useEffect(() => {
    const doStuff = async () => {
      const user = JSON.parse(localStorage.getItem('CAMPAIGN_USER_PORTAL_USER'));
      if (user) {
        setUser(user);
        await login(user?.email_address?.S, user?.password?.S);
      }
    };
    doStuff();
  }, []);

  useEffect(() => {
    window.gtag('event', 'LoginPageView', {
      event_category: 'Pageview',
    });

    if (location.state && location.state.emailAddress && location.state.password) {
      setEmailAddress(location.state.emailAddress);
      setPassword(location.state.password);
      setComingFromRegister(true);
      window.history.replaceState({}, '');
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      // Get the values of emailAddress and password from the URL
      const emailAddress = urlParams.get('emailAddress');
      const password = urlParams.get('password');

      if (emailAddress !== null && password !== null) {
        var num = emailAddress.toString().trim();
        setEmailAddress(num);
        setPassword(password.toString());
      }
    }
  }, []);

  useEffect(() => {
    // This will run whenever the `comingFromRegister` state changes

    if (comingFromRegister) {
      getStuff();
    }
  }, [comingFromRegister]);

  useEffect(() => {
    const username = searchParams.get('username');
    const password = searchParams.get('password');
    setEmailAddress(username);
    setPassword(password);

    login(username, password);
  }, []);

  const formSubmitHandler = async e => {
    e.preventDefault();

    getStuff();
  };

  // Campaign Functions

  const CampaignFormSubmitHandler = async e => {
    e.preventDefault();
    console.log('campaign login');
  };

  return (
    <div>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}
      <div className={classes.loginContainer}>
        <div className={classes.left}>
          <div className={classes.leftMain}>
            {!debrand && (
              <Link to="/">
                <img className={classes.logo} src={logo} alt="logo" />
              </Link>
            )}

            {!debrand && <h3>My AI Front Desk</h3>}
            {title}

            {isCampaign ? (
              // Campaign Login Form
              <form action="" onSubmit={CampaignFormSubmitHandler}>
                <label htmlFor="email" className={classes.inputBox}>
                  <div className={classes.inputBoxMain}>
                    <div className={classes.label}>Email address</div>
                    <input
                      id="email"
                      type="email"
                      value={emailAddress}
                      onChange={e => setEmailAddress(e.target.value)}
                    />
                  </div>
                </label>
                <label htmlFor="password" className={classes.inputBox}>
                  <div className={classes.inputBoxMain}>
                    <div className={classes.label}>Password</div>
                    <input
                      id="password"
                      type="password"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </label>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginTop: '10px',
                  }}
                >
                  {<ReCaptcha sitekey={'6LfFf64lAAAAADNRXnw6Vq0RdkARDgrfHsZc6-Ir'} ref={captchaRef} />}
                </div>

                <label
                  style={{
                    marginTop: '1rem',
                    display: 'block',
                  }}
                >
                  <label className={classes.switch}>
                    <input
                      type="checkbox"
                      checked={isStayLoggedIn}
                      onChange={e => {
                        setIsStayLoggedIn(e.target.checked);
                      }}
                    />
                    <span className={classes.slider + ' ' + classes.round}></span>
                  </label>

                  <div
                    style={{
                      marginLeft: '.5rem',
                      display: 'inline-block',
                    }}
                  >
                    Stay logged in
                  </div>
                </label>

                <button>Login</button>
              </form>
            ) : (
              // Campaign User Login Form
              <form action="" onSubmit={formSubmitHandler}>
                <label htmlFor="email" className={classes.inputBox}>
                  <div className={classes.inputBoxMain}>
                    <div className={classes.label}>Email address</div>
                    <input
                      id="email"
                      type="email"
                      value={emailAddress}
                      onChange={e => setEmailAddress(e.target.value)}
                    />
                  </div>
                </label>
                <label htmlFor="password" className={classes.inputBox}>
                  <div className={classes.inputBoxMain}>
                    <div className={classes.label}>Password</div>
                    <input
                      id="password"
                      type="password"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </label>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginTop: '10px',
                  }}
                >
                  {<ReCaptcha sitekey={'6LfFf64lAAAAADNRXnw6Vq0RdkARDgrfHsZc6-Ir'} ref={captchaRef} />}
                </div>

                <label
                  style={{
                    marginTop: '1rem',
                    display: 'block',
                  }}
                >
                  <label className={classes.switch}>
                    <input
                      type="checkbox"
                      checked={isStayLoggedIn}
                      onChange={e => {
                        setIsStayLoggedIn(e.target.checked);
                      }}
                    />
                    <span className={classes.slider + ' ' + classes.round}></span>
                  </label>

                  <div
                    style={{
                      marginLeft: '.5rem',
                      display: 'inline-block',
                    }}
                  >
                    Stay logged in
                  </div>
                </label>

                <button>Login</button>
              </form>
            )}

            {debrand ? (
              <div>
                Don't have an account? <Link to="/register_campaign_unbranded">Sign up</Link>
              </div>
            ) : (
              <div>
                Don't have an account? <Link to="/register_campaign">Sign up</Link>
              </div>
            )}
          </div>
        </div>
        <div className={classes.right}>
          <img src={gradient} alt="gradient" />
        </div>
      </div>
    </div>
  );
};

export default Login;
