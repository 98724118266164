import React, { useState } from 'react';
import { Button, Typography, Row, Col } from 'antd';
import { PhoneOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { InlineWidget } from 'react-calendly';
import logo from '@/assets/images/logo.png';
import { CustomModal } from '@/components/CustomModal';

const { Title, Paragraph, Text } = Typography;

interface LocationState {
  fromAd?: boolean;
  fromEmail?: boolean;
}

const TestReceptionistPage: React.FC = () => {
  const location = useLocation();
  const { fromAd, fromEmail } = (location.state as LocationState) || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleCall = () => {
    window.location.href = 'tel:+18646190619';
  };

  const getCalendlyUrl = () => {
    if (fromAd) return 'https://calendly.com/d/cpt7-w5k-553/ai-front-desk-white-label-consultation-google-meet';
    if (fromEmail) return 'https://calendly.com/d/ckrr-rv9-qmv/ai-front-desk-white-label-program-discovery-call';
    return 'https://calendly.com/d/cqb8-y7w-74c/ai-front-desk-white-label-discovery-call';
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#2196F3] to-[#21CBF3] text-white">
      <Row className="min-h-screen">
        <Col xs={24} md={12} className="flex flex-col justify-center items-center p-8 order-2 md:order-1">
          <Title level={1} className="text-4xl md:text-5xl lg:text-6xl text-center  !text-white">
            Let's chat!
          </Title>
          <Paragraph className="text-center  text-base !text-white px-10">
            Book a call on the {isMobile ? 'top' : 'right'}. If you haven't tried the receptionist yet, click the button
            below to give it a test.
          </Paragraph>
          <Button
            size="large"
            className="bg-white text-[#2196F3] hover:bg-[#F0F0F0] rounded-[5px] shadow-md p-3 px-5"
            onClick={handleOpenModal}
          >
            TEST RECEPTIONIST
          </Button>
        </Col>
        <Col
          xs={24}
          md={12}
          className="bg-white h-[50vh] md:h-screen order-1 md:order-2 [&_.calendly-inline-widget]:!h-[100%]"
        >
          <InlineWidget
            url={getCalendlyUrl()}
            pageSettings={{
              hideEventTypeDetails: true,
              hideGdprBanner: true,
              hideLandingPageDetails: true,
            }}
          />
        </Col>
      </Row>

      <CustomModal
        title={null}
        isModalOpen={isModalOpen}
        cancelAction={handleCloseModal}
        footer={null}
        width={isMobile ? '100%' : 600}
        className="rounded-2xl shadow-lg [&_.ant-modal-content]:bg-gradient-to-br [&_.ant-modal-content]:to-blue-500 [&_.ant-modal-content]:from-cyan-400 [&_*]:text-white"
        closeIcon={<CloseOutlined className="text-white opacity-70 hover:opacity-100" />}
      >
        <div className="p-6 text-white">
          <Title level={2} className="max-w-[400px] lg:text-[34px] mb-4 !text-white !font-normal">
            Connect with Our AI Receptionist
          </Title>
          <Paragraph className="lg:text-base">
            Experience firsthand how our AI can handle calls with precision and warmth. Dial our demo line anytime to
            interact with the future of customer service.
          </Paragraph>
          <Text strong className="text-[22px] bg-white bg-opacity-20 px-4 py-2 rounded-md inline-block my-4">
            +1 (864) 619-0619
          </Text>
          <Paragraph className="opacity-90 text-white">
            Ready to see it in action? Click below to call directly from your device if it supports calling otherwise
            use any phone to dial the number.
          </Paragraph>
          <div className="text-center mt-6">
            <Button
              size="large"
              icon={<PhoneOutlined />}
              onClick={handleCall}
              className="bg-white [&_*]:!text-blue-500 hover:bg-gray-100"
            >
              Start AI Interaction
            </Button>
          </div>
        </div>
      </CustomModal>

      <img src={logo} alt="logo" className="fixed top-4 left-4 w-16 h-16" />
    </div>
  );
};

export default TestReceptionistPage;
