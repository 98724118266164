import React from 'react';

import classes from '../../../Portal/Portal.module.css';
import { serverURL } from '../../../config';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';
import { Switch } from '@mui/material';

const MinutesControlModal = ({
  themeColor,
  selectedClient,
  setResellerMinutesControlModalOpen,
  setSelectedClient,
  updateClientDataInState,
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleMinutesControlSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      if (e.target.minutesLimitEnabled.checked) {
        if (e.target.maxMinutes.value === '') {
          toast.error('Please provide max minutes limit');
          return;
        }
      }

      const res = await fetch(serverURL + '/api/reseller/update-managed-receptionist', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            max_minutes: parseInt(e.target.maxMinutes.value, 10),
            usage_notification_emails: [selectedClient.reseller_email],
            enabled: e.target.minutesLimitEnabled.checked,
          },
          receptionist_username: selectedClient.number,
        }),
      });

      const data = await res.json();

      if (data.success) {
        toast.success('Maximum minutes updated successfully');

        //update max_minutes on frontend as to not make an api call again to show updated data
        updateClientDataInState(selectedClient.number, {
          max_minutes: parseInt(e.target.maxMinutes.value, 10),
          minutesLimitEnabled: e.target.minutesLimitEnabled.checked,
        });
      } else {
        toast.error('Request Failed try again.');
      }
      setResellerMinutesControlModalOpen(false);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          setResellerMinutesControlModalOpen(false);
          setSelectedClient(null);
        }}
        className={classes.modalOverlay}
      ></div>
      <div className={classes.modal + ' '}>
        <div
          className={classes.close}
          onClick={() => {
            setResellerMinutesControlModalOpen(false);
            setSelectedClient(null);
          }}
          style={{ zIndex: 100 }}
        >
          &times;
        </div>

        <div className="flex flex-col gap-7">
          <div className="flex flex-col gap-2 items-center">
            <p className="text-2xl font-bold">Minutes Control</p>
            <p className="text-center">
              This sets the number of minutes the{' '}
              <span
                className="font-bold"
                style={{
                  color: themeColor,
                }}
              >
                {selectedClient?.business_name}'s
              </span>{' '}
              receptionist can answer the phone each month.
            </p>
          </div>
          <form method="POST" onSubmit={handleMinutesControlSubmit} className="flex flex-col gap-4 items-center">
            <div className="flex flex-row items-center gap-1 ">
              <p>Enable Minutes Limit</p>
              <div className="flex items-center justify-center">
                <Switch
                  size="medium"
                  name="minutesLimitEnabled"
                  defaultChecked={selectedClient?.minutesLimitEnabled ? true : false}
                  disabled={loading}
                />
              </div>
            </div>

            <div className="flex flex-row gap-2 items-center">
              <p>Max minutes: </p>
              <input
                type="number"
                name="maxMinutes"
                placeholder="1"
                min={1}
                defaultValue={selectedClient?.max_minutes}
                required
                disabled={loading}
              />
            </div>
            <Button text="Apply" themeColor={themeColor} type="submit" className="w-1/2 min-w-72" loading={loading} />
          </form>
        </div>
      </div>
    </>
  );
};

export default MinutesControlModal;
