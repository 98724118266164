import { useRef, useState, useCallback, memo, useMemo, useEffect } from 'react';
import { InstantSearch, Configure, useSearchBox, useHits } from 'react-instantsearch';
import { Hit } from 'instantsearch.js';
import { algoliasearch } from 'algoliasearch';
import { Input, Spin } from 'antd';
import { SearchOutlined, ClockCircleOutlined, PhoneOutlined, SwapRightOutlined } from '@ant-design/icons';
import { AlgoliaSearchProps, BaseHit } from './types';
import './AlgoliaSearch.scss';

const CustomSearchBox = memo(
  ({ onFocus, onReset, isLoading }: { onFocus: () => void; onReset: () => void; isLoading: boolean }) => {
    const { query, refine, isSearchStalled } = useSearchBox();

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        refine(e.target.value);
      },
      [refine]
    );

    const handleClear = useCallback(() => {
      refine('');
      onReset();
    }, [refine, onReset]);

    const suffix = isSearchStalled || isLoading ? <Spin size="small" /> : null;

    return (
      <div className="relative">
        <Input
          prefix={<SearchOutlined />}
          suffix={suffix}
          className="algolia-searchbox"
          placeholder="Search logs by number, call summary, etc."
          value={query}
          onChange={handleChange}
          onFocus={onFocus}
          allowClear
          onClear={handleClear}
          disabled={isLoading}
        />
      </div>
    );
  }
);

CustomSearchBox.displayName = 'CustomSearchBox';

const HitItem = memo(
  ({
    hit,
    onSelect,
    isLoading,
    selectedCallSid,
  }: {
    hit: Hit<BaseHit>;
    onSelect: (hit: Hit<BaseHit>) => void;
    isLoading: boolean;
    selectedCallSid: string | null;
  }) => {
    const provider =
      hit._highlightResult?.voiceConfig?.value
        ?.replace(/&quot;/g, '"')
        .split('"provider":')[1]
        ?.split('}')[0]
        ?.replace(/"/g, '')
        ?.trim() || 'N/A';

    const isSelected = selectedCallSid === hit.callSid;

    return (
      <div
        onClick={() => !isLoading && onSelect(hit)}
        className={`hit-item ${isLoading ? 'pointer-events-none' : ''} ${isSelected ? 'selected-hit' : ''}`}
      >
        {isSelected && isLoading ? (
          <div className="absolute inset-0 bg-black/5 flex items-center justify-center">
            <Spin size="small" />
          </div>
        ) : null}
        <div className="hit-content">
          {hit._highlightResult?.callSummary?.value && (
            <div
              className="hit-summary"
              dangerouslySetInnerHTML={{
                __html: hit._highlightResult.callSummary.value,
              }}
            />
          )}

          <div className="hit-metadata">
            <div className="hit-info">
              <span>
                <PhoneOutlined /> From:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: hit._highlightResult?.from?.value || hit.from,
                  }}
                />
              </span>
              <span>
                <SwapRightOutlined /> To:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: hit._highlightResult?.to?.value || hit.to,
                  }}
                />
              </span>
              <span>
                <ClockCircleOutlined /> Duration:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: hit._highlightResult?.duration?.value?.toString() || hit.duration?.toString(),
                  }}
                />
              </span>
            </div>
          </div>

          <div className="hit-metadata voice-provider">
            <div className="hit-info">
              <span>
                Voice Provider:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: provider,
                  }}
                />
              </span>
              <span>
                Transfered call duration:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      hit._highlightResult?.transferredDuration?.value?.toString() ||
                      hit.transferredDuration?.toString() ||
                      'N/A',
                  }}
                />
              </span>
              {hit.date && <div className="hit-date">UTC: {hit.date}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

HitItem.displayName = 'HitItem';

const CustomHits = memo(
  ({
    onSelect,
    isVisible,
    isLoading,
    selectedCallSid,
  }: {
    onSelect: (hit: Hit<BaseHit>) => void;
    isVisible: boolean;
    isLoading: boolean;
    selectedCallSid: string | null;
  }) => {
    const { hits } = useHits<Hit<BaseHit>>();

    if (!isVisible || !hits?.length) return null;

    return (
      <div className="hits-container">
        {hits.map(hit => (
          <HitItem
            key={hit.objectID}
            hit={hit}
            onSelect={onSelect}
            isLoading={isLoading}
            selectedCallSid={selectedCallSid}
          />
        ))}
      </div>
    );
  }
);

CustomHits.displayName = 'CustomHits';

const AlgoliaSearch: React.FC<AlgoliaSearchProps> = ({
  userNumber,
  algoliaSearchToken,
  onLogSelect,
  onReset,
  isLoading = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showHits, setShowHits] = useState(false);
  const [selectedCallSid, setSelectedCallSid] = useState<string | null>(null);

  const algoliaSearchClient: any = useMemo(
    () => algoliasearch(import.meta.env.VITE_APP_ALGOLIA_APP_ID, algoliaSearchToken),
    [algoliaSearchToken]
  );

  const handleSelect = useCallback(
    (hit: Hit<BaseHit>) => {
      setSelectedCallSid(hit.callSid);
      setShowHits(false); // Close hits section immediately
      // Blur the search input
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      onLogSelect(hit.callSid);
    },
    [onLogSelect]
  );

  const handleFocus = useCallback(() => {
    if (!isLoading) {
      setShowHits(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      setSelectedCallSid(null);
    }
  }, [isLoading]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowHits(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const configureProps = useMemo(
    () => ({
      filters: `(NOT isDeleted:true)`,
      hitsPerPage: 50,
      attributesToHighlight: [
        'from',
        'to',
        'callSummary',
        'transcript',
        'duration',
        'transferredDuration',
        'voiceConfig',
      ],
      highlightPreTag: '<mark>',
      highlightPostTag: '</mark>',
    }),
    [userNumber]
  );

  return (
    <div className="algolia-wrapper" ref={containerRef}>
      <InstantSearch
        searchClient={algoliaSearchClient}
        indexName="callLogs"
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure {...configureProps} />
        <div className="search-container">
          <CustomSearchBox onFocus={handleFocus} onReset={onReset} isLoading={isLoading} />
          <CustomHits
            onSelect={handleSelect}
            isVisible={showHits}
            isLoading={isLoading}
            selectedCallSid={selectedCallSid}
          />
        </div>
      </InstantSearch>
    </div>
  );
};

export default AlgoliaSearch;
