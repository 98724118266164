import React from 'react';
import StepForm from './StepForm';

const ResellerQualificationForm: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const utm_source = query.get('utm_source');

  return <StepForm fromAd={utm_source === 'google'} fromEmail={utm_source === 'email'} />;
};

export default ResellerQualificationForm;
