export enum ReceptionistTestingSteps {
  STEP_0 = 'Test your Receptionist',
  STEP_1 = 'Take a tour',
  STEP_2 = 'Start Free Trial',
  STEP_3 = 'Forward Calls',
}

export enum DebrandReceptionistTestingSteps {
  STEP_0 = 'Forward Calls',
}

export enum OnboardingSteps {
  STEP_0 = 'Goodbye, Missed Calls. 👋',
  STEP_1 = 'Your AI Will Call You! 🤖',
  STEP_2 = 'Did you speak with your AI? 🤔',
  STEP_3 = 'Let’s Explore! 🔍',
  STEP_4 = 'Activate your Receptionist! 🔓',
  STEP_5 = 'Welcome to the AI Front Desk Family! 🤗',
  STEP_6 = 'Get your AI Phone Number. ☎️',
  STEP_ADDITIONAL_TESTING_OPTIONS = 'Hmm, let’s fix this. Select one of the options below 🛠️!',
  STEP_AI_CALLING = 'Ring Ring! 🔔',
}
