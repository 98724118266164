import React from 'react';
import EllipsisText from '@/components/LabelWithdescription/EllipsisText';
import { TVoiceModel } from '@/types/TVoiceModel';
import { UseMutateFunction } from '@tanstack/react-query';
import { Card, Popconfirm } from 'antd';
import userPng from '../../../assets/images/user.png';
import { DeleteOutlineRounded, ModeOutlined } from '@mui/icons-material';
import { User } from '@/types/TAccounts';
import AudioPlayer from './AudioPlayer';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

interface VoiceLibraryCardProps {
  index: number;
  data: TVoiceModel;
  isSelected: boolean;
  onApplyClick: (value: string) => void;
  currentlyPlayingIndex: number | null;
  onPlayClick: (index: number | null) => void;
  user: User | undefined;
  deleteVoiceMutation: UseMutateFunction<
    {
      success: boolean;
      message?: string;
    },
    Error,
    {
      voiceId: string;
      fileName: string;
    },
    unknown
  >;
}

const VoiceLibraryCard: React.FC<VoiceLibraryCardProps> = ({
  index,
  data,
  isSelected,
  onApplyClick,
  currentlyPlayingIndex,
  user,
  deleteVoiceMutation,
}) => {
  const handleApplyClick = () => {
    if (!isSelected) {
      onApplyClick(data.voiceId);
    }
  };

  return (
    <Card
      className={`flex flex-col w-70 border-[2px] rounded-xl gap-4 p-0 relative group my-3 ${
        currentlyPlayingIndex !== null && currentlyPlayingIndex !== index ? 'opacity-50 cursor-not-allowed' : ''
      } ${isSelected ? 'border-blue-500' : 'border-gray-300'}`}
    >
      <>
        <div className="flex gap-2 w-full">
          <div className="flex flex-col gap-1 w-3/4">
            <div className="flex gap-1">
              <h1 className="font-semibold">{data?.name}</h1>
              <CopyableText text={data?.name} hideText />
            </div>
            {data?.gender && data?.accent && (
              <span className="text-sm">
                {data?.gender} | {data.accent}
              </span>
            )}
            <EllipsisText text={data.description || ''} length={35} extraClass="text-xs text-gray-500" />
          </div>
          <div className="flex flex-col items-center justify-center gap-2 w-1/4">
            <img className="w-[3rem]" src={data.avatarUrl || userPng} alt={data.name} />
            <span className="cursor-pointer font-semibold w-[60px]" onClick={handleApplyClick}>
              {isSelected ? (
                <div className="border p-1 text-xs text-red-500 rounded-lg text-center">Selected</div>
              ) : (
                <div className="border p-1 text-xs text-gray-500 rounded-lg text-center">Select</div>
              )}
            </span>
          </div>
        </div>
        <div className="mt-4">
          <AudioPlayer
            fileName={data.fileName}
            onPlay={() => console.log('Audio started playing')}
            onPause={() => console.log('Audio paused')}
          />
        </div>
      </>
      {user?.isSuperAdmin && (
        <div className="top-2 right-1 absolute">
          {!data.isCloned && (
            <ModeOutlined
              fontSize="large"
              className="opacity-0 text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100"
            />
          )}
          <Popconfirm
            title="Are you sure to delete this voice?"
            onConfirm={() => deleteVoiceMutation({ voiceId: data.voiceId, fileName: data.fileName })}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlineRounded
              fontSize="large"
              className="opacity-0 text-red-500 cursor-pointer group-hover:opacity-100 transition-opacity duration-300 rounded-full p-1.5 bg-green-50 hover:bg-green-100"
            />
          </Popconfirm>
        </div>
      )}
    </Card>
  );
};

export default VoiceLibraryCard;
