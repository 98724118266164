import React from 'react';
import { QuestionAndAnswerForm } from './QuestionAndAnswerForm';
import { OverViewCardInfo } from '../OverviewCardInfo';
import './QuestionAndAnswerInfo.scss';
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { User } from '@/types/TAccounts';
import { formatQuestionnaire } from '@/utils/helper';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

interface QuestionAndAnswerInfoProps {
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}

export const QuestionAndAnswerInfo: React.FC<QuestionAndAnswerInfoProps> = ({ data, refetch }) => {
  const questionsAndAnswersData = formatQuestionnaire(data?.sample_qa);
  const formattedString = questionsAndAnswersData
    .map(item => `Question: ${item.question}\nAnswer: ${item.answer}\n\n`)
    .join('');

  const formattedStringHtml = questionsAndAnswersData
    .map(item => `<strong>Question:</strong> ${item.question}<br /><strong>Answer:</strong> ${item.answer}<br /><br />`)
    .join('');

  const InfoComponent = () => {
    return (
      <div className="pl-4 w-9/12 mt-1 pt-2 pb-4">
        <span dangerouslySetInnerHTML={{ __html: formattedStringHtml.replace(/\n/g, '<br />') }}></span>
        <CopyableText text={formattedStringHtml} hideText />
      </div>
    );
  };

  return (
    <div>
      <OverViewCardInfo
        info={<InfoComponent />}
        formActionType="Edit"
        form={<QuestionAndAnswerForm sampleQA={formattedString} refetch={refetch} />}
        width={'80%'}
        footer={null}
        modalId={MODAL_IDS.GREETING_FORM_MODAL}
      />
    </div>
  );
};
