export enum AppRoutes {
  // non-protected routes
  CALL_INFORMATION = '/call-information/:key',
  FORGOT_PASSWORD = '/forgot-password',
  RECEPTIONIST_LOGIN = '/login',
  NOT_FOUND = '/*',
  PRIVACY_POLICY = '/privacy-policy',
  REGISTER = '/register',
  RESET_PASSWORD = '/reset-password/:username/:token',
  TERMS_OF_USE = '/terms-of-use',
  PAYMENT_SUCCESS = '/paymentSuccessful',
  REACTIVATE_ACCOUNT = '/reactivate-account/:username',
  WATERMARK_REMOVAL_SUCCESS = '/watermark-removal-success',
  REGISTER_RESELLER = '/register_reseller',
  REGISTER_CAMPAIGN = '/register_campaign',
  UNBRANDED_REGISTER = '/unbranded_register',
  GENERIC_DOMAIN_REGISTER = '/register-receptionist',
  REGISTER_RESELLER_UNBRANDED = '/register_reseller_unbranded',
  UNBRANDED_RECEPTIONIST = '/unbranded_receptionist',
  CAMPAIGN_PORTAL = '/campaign_portal',
  RESELLER_PORTAL_UNBRANDED = '/reseller_portal_unbranded',
  SHORT_URL_REDIRECT = '/short-url/redirect/:shortCode',
  BLOGS = '/blogs',
  BLOG_PAGE = '/blog/:slug',
  THANK_YOU_RESELLER = '/thank-you-reseller',
  CUSTOM_OFFER = '/custom-offer-reseller/:encodedData',

  NOVI_PORTAL_LOGIN = '/novi-portal/login',
  UNBRANDED_CLIENT_TRIAL = '/client-trial',

  // protected routes
  DASHBOARD = '/',
  UNBRANDED_DASHBOARD = '/dashboard',

  // Reseller Routes
  RESELLER_PORTAL = '/reseller_portal',
  RESELLER_EMBED = `${RESELLER_PORTAL}/embed`,
  RESELLER_BRANDING = `${RESELLER_PORTAL}/branding`,
  RESELLER_TUTORIALS = `${RESELLER_PORTAL}/tutorials`,
  RESELLER_SUBSCRIPTION = `${RESELLER_PORTAL}/subscription`,
  TRIAL_RECEPTIONIST = `${RESELLER_PORTAL}/trial-receptionist`,
  RESELLER_PHONE_NUMBERS = `${RESELLER_PORTAL}/phone-numbers`,
  RESELLER_IMPORT_NUMBER = `${RESELLER_PORTAL}/import-number`,
  RESELLER_FORGOT_PASSWORD = `${RESELLER_PORTAL}/forgot-password`,
  RESELLER_RESET_PASSWORD = `${RESELLER_PORTAL}/reset-password/:email_address/:token`,
  NOVI_PORTAL = '/novi-portal/*',
  NOVI_PORTAL_CAMPAIGN_REGISTER = '/novi-portal/register',
  RESELLER_SURVEY_FORM = '/reseller-survey-form',
  AFFILIATE_PROGRAM = '/affiliate-program',
  TEST_RECEPTIONIST = '/test-receptionist',
  PAYMENT_SUCCESS_RESELLER = '/paymentSuccessfulReseller',
}

// static outside routes
export const APP_LANDING_PAGE = 'https://www.myaifrontdesk.com';
export const BOOK_CONSULTATION_LANDING_PAGE = `${APP_LANDING_PAGE}/book-a-consultation`;
export const PRIVACY_POLICY_LANDING_PAGE = `${APP_LANDING_PAGE}/privacy-policy`;
export const PRICING_LANDING_PAGE = `${APP_LANDING_PAGE}/pricing`;
export const TERMS_OF_USE_LANDING_PAGE = `${APP_LANDING_PAGE}/terms-of-service`;
export const CALL_TRANSFERING_WF_TUTORIAL_LP = `${APP_LANDING_PAGE}/features/call-transferring-workflows-real-time-intelligent-call-routing`;
export const APP_LANDING_PAGE_FEATURES = `${APP_LANDING_PAGE}/features`;

// static constants
export const MAX_TRIAL_TEST_CALLS = 15;
export const UNBRANDED_DOMAINS = ['aiphonereceptionist.com', 'smallurls.net'];
