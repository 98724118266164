import { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Typography,
  Row,
  Col,
  DatePicker,
  Card,
  Divider,
  InputNumber,
  Space,
  Spin,
  Tag,
  Modal,
  notification,
} from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import momentTimeZone from 'moment-timezone';
import {
  cancelCampaign,
  getCampaignSettings,
  launchCampaign,
  useSaveCampaignSettings,
} from '../../hooks/useCampaignClient';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign, getSelectedCampaignId } from '@/store/novi/user/selector';
import { RocketLaunchOutlined, StopOutlined } from '@mui/icons-material';

const { Title, Text } = Typography;
const timeZoneList = momentTimeZone.tz.names();

interface CampaignSettings {
  id?: string;
  name: string;
  allowed_retries: string;
  time_between_retries: string;
  allowed_times_start: string;
  allowed_times_end: string;
  timezone: string;
  start_date: any;
  end_date: any;
  days: number[];
  allowedStatuses: string[];
  status?: string;
}

const initialSettings: CampaignSettings = {
  name: '',
  allowed_retries: '',
  time_between_retries: '',
  allowed_times_start: '',
  allowed_times_end: '',
  timezone: '',
  start_date: '',
  end_date: '',
  days: [],
  allowedStatuses: [],
};

const allowedStatusesOptions = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Contacted', label: 'Contacted' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Interested', label: 'Interested' },
  { value: 'Not Interested', label: 'Not Interested' },
];

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  let color;
  let displayStatus;
  switch (status) {
    case 'COMPLETED':
      color = 'green';
      displayStatus = 'COMPLETED';
      break;
    case 'IN_PROGRESS':
      color = 'orange';
      displayStatus = 'IN PROGRESS';
      break;
    case 'FAILED':
    case 'FAILED_TERMINAL':
      color = 'red';
      displayStatus = 'FAILED';
      break;
    case 'CANCELLED':
      color = 'blue';
      displayStatus = 'CANCELLED';
      break;
    default:
      color = 'grey';
      displayStatus = status;
  }
  return <Tag color={color}>{displayStatus}</Tag>;
};

const daysOptions = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

const ScheduleInfoForm = ({ refetchLeads }: { refetchLeads: () => void }) => {
  const [form] = Form.useForm();
  const [existingSettings, setExistingSettings] = useState<CampaignSettings[] | null>(null);
  const [launchModalVisible, setLaunchModalVisible] = useState(false);
  const [createCampaignModalVisible, setCreateCampaignModalVisible] = useState(false);
  const [editCampaignID, setEditCampaignID] = useState<string | null>(null);
  const [launchingCampaignID, setLaunchingCampaignID] = useState<string | null>(null);
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [cancellingCampaignData, setCancellingCampaignData] = useState<{ id: string; campaign_id: string } | null>(
    null
  );
  const selectedCampaign = useAppSelector(getSelectedCampaign);
  const campaignID = useAppSelector(getSelectedCampaignId);

  const { mutate: campaignStatus, isPending: isLaunching } = launchCampaign({
    onSuccess: () => {
      setLaunchModalVisible(false);
      refetch();
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      setLaunchModalVisible(false);
    },
  });

  const { mutate: saveCampaignSettings, isPending } = useSaveCampaignSettings({
    onSuccess: () => {
      refetch();
      setCreateCampaignModalVisible(false);
    },
  });

  const { mutate: cancelThisCampign, isPending: isCancelling } = cancelCampaign({
    onSuccess: () => {
      setCancelModalVisible(false);
      setCancellingCampaignData(null);
      refetch();
      refetchLeads();
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      setCancelModalVisible(false);
    },
  });

  const { data, isLoading, refetch, isRefetching } = getCampaignSettings(campaignID as string);

  const handleLaunchCampaign = async () => {
    campaignStatus({
      // @ts-ignore
      id: launchingCampaignID,
      campaign_id: campaignID as string,
      email: selectedCampaign.campaign_user_email,
    });
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      setExistingSettings(data);
    }
  }, [data, form]);

  const onFinish = (values: CampaignSettings) => {
    let newSettings = {
      user_id: selectedCampaign.number,
      settings: {
        campaign_id: campaignID,
        start_date: values.start_date,
        end_date: values.end_date,
        timezone: values.timezone,
        allowed_retries: values.allowed_retries,
        time_between_retries: values.time_between_retries,
        allowed_times_start: values.allowed_times_start,
        allowed_times_end: values.allowed_times_end,
        days: values.days,
        allowedStatuses: values.allowedStatuses,
        name: values.name,
      },
    };

    if (editCampaignID) {
      // @ts-ignore
      newSettings = { ...newSettings, id: editCampaignID };
    }
    console.log(newSettings);
    saveCampaignSettings(newSettings);
    setEditCampaignID(null);
  };

  const timeOptions = Array.from({ length: 24 }, (_, idx) => {
    const hour = idx.toString().padStart(2, '0');
    return { value: `${hour}:00`, label: moment(`${hour}:00`, 'HH:mm').format('hh:mm A') };
  });

  const handleCreateCampaign = () => {
    form.resetFields();
    setCreateCampaignModalVisible(true);
  };

  const handleEditCampaign = (setting: CampaignSettings) => {
    form.setFieldsValue({
      ...setting,
      start_date: moment(setting.start_date),
      end_date: moment(setting.end_date),
    });
    setEditCampaignID(setting.id as string);
    setCreateCampaignModalVisible(true);
  };

  const handleCancelCampaign = () => {
    console.log(cancellingCampaignData);
    cancelThisCampign(cancellingCampaignData as { id: string; campaign_id: string });
  };

  const disablePastDates = (current: Moment | null): boolean => {
    return current ? current < moment().startOf('day') : false;
  };

  const disableEndDate = (current: Moment | null): boolean => {
    const startDate = form.getFieldValue('start_date');
    if (disablePastDates(current)) {
      return true;
    }
    return startDate ? (current ? current < startDate : false) : false;
  };

  return (
    <Spin spinning={isLoading || isRefetching}>
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title level={3}>Schedules</Title>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateCampaign}>
            Create Schedule
          </Button>
        </div>

        {existingSettings ? (
          existingSettings?.map(setting => (
            <Card style={{ marginTop: '20px' }}>
              <Space direction="vertical" style={{ width: '100%' }} size="large">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Title level={4}>{setting.name}</Title>
                  <Space>
                    {setting.status === 'PENDING' ? (
                      <>
                        <Button
                          type="primary"
                          danger
                          icon={<RocketLaunchOutlined />}
                          onClick={() => {
                            setLaunchModalVisible(true);
                            setLaunchingCampaignID(setting.id as string);
                          }}
                        >
                          Launch Schedule
                        </Button>
                        <Button type="primary" icon={<EditOutlined />} onClick={handleEditCampaign.bind(null, setting)}>
                          Edit Settings
                        </Button>
                      </>
                    ) : setting.status === 'IN_PROGRESS' ? (
                      <>
                        <Button
                          type="primary"
                          danger
                          icon={<StopOutlined />}
                          onClick={() => {
                            setCancelModalVisible(true);
                            setCancellingCampaignData({ id: setting.id as string, campaign_id: campaignID as string });
                          }}
                        >
                          Cancel Campaign
                        </Button>
                        <StatusBadge status={setting.status as string} />
                      </>
                    ) : (
                      <StatusBadge status={setting.status as string} />
                    )}
                  </Space>
                </div>
                <Row gutter={24}>
                  <Col span={12}>
                    <Title level={4}>General Settings</Title>
                    <Text strong>Schedule Name:</Text> <Text>{setting.name}</Text>
                    <br />
                    <Text strong>Max Retries:</Text> <Text>{setting.allowed_retries}</Text>
                    <br />
                    <Text strong>Hours Between Retries:</Text> <Text>{setting.time_between_retries}</Text>
                    <br />
                    <Text strong>Time Zone:</Text> <Text>{setting.timezone}</Text>
                  </Col>
                  <Col span={12}>
                    <Title level={4}>Schedule Settings</Title>
                    <Text strong>Allowed Times to Call:</Text>{' '}
                    <Text>
                      {moment(setting.allowed_times_start, 'HH:mm').format('hh:mm A')} -{' '}
                      {moment(setting.allowed_times_end, 'HH:mm').format('hh:mm A')}
                    </Text>
                    <br />
                    <Text strong>Active Dates:</Text>{' '}
                    <Text>
                      {moment(setting.start_date).format('YYYY-MM-DD')} to{' '}
                      {moment(setting.end_date).format('YYYY-MM-DD')}
                    </Text>
                    <br />
                    <Text strong>Active Days:</Text>{' '}
                    <Text>{setting.days.map(day => daysOptions.find(opt => opt.value === day)?.label).join(', ')}</Text>
                    <br />
                    <Text strong>Skip Leads with Status:</Text> <Text>{setting.allowedStatuses.join(', ')}</Text>
                  </Col>
                </Row>
              </Space>
            </Card>
          ))
        ) : (
          <Text>No campaign settings found</Text>
        )}

        <Modal
          title={
            <Title level={4} style={{ margin: 0 }}>
              {editCampaignID ? 'Edit Schedule' : 'Create Schedule'}
            </Title>
          }
          open={createCampaignModalVisible}
          footer={null}
          onCancel={() => {
            setCreateCampaignModalVisible(false);
            setEditCampaignID(null);
          }}
          width={800}
          centered
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialSettings}
            className="schedule-form"
          >
            <Row gutter={[24, 0]}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Schedule Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input the schedule name!' }]}
                >
                  <Input placeholder="Enter schedule name" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[{ required: true, message: 'Please select start date!' }]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    // @ts-ignore
                    disabledDate={disablePastDates}
                    placeholder="Select start date"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[{ required: true, message: 'Please select end date!' }]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    // @ts-ignore
                    disabledDate={disableEndDate}
                    placeholder="Select end date"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Max Retries"
                  name="allowed_retries"
                  rules={[{ required: true, message: 'Please input max retries!' }]}
                >
                  <InputNumber min={1} max={5} style={{ width: '100%' }} placeholder="Enter max retries" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Hours Between Retries"
                  name="time_between_retries"
                  rules={[{ required: true, message: 'Please input hours between retries!' }]}
                >
                  <InputNumber min={0.5} max={24} step={0.5} style={{ width: '100%' }} placeholder="Enter hours" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Call Hours Start"
                  name="allowed_times_start"
                  rules={[{ required: true, message: 'Please select start time!' }]}
                >
                  <Select options={timeOptions} placeholder="Select start time" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Call Hours End"
                  name="allowed_times_end"
                  rules={[{ required: true, message: 'Please select end time!' }]}
                >
                  <Select options={timeOptions} placeholder="Select end time" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label="Time Zone"
                  name="timezone"
                  rules={[{ required: true, message: 'Please select time zone!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Select a time zone"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={timeZoneList.map(zone => ({ label: zone, value: zone }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label="Active Days"
                  name="days"
                  rules={[{ required: true, message: 'Please select at least one day!' }]}
                >
                  <Select mode="multiple" placeholder="Select active days" options={daysOptions} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <Form.Item
                  label="Skip Leads with Status"
                  name="allowedStatuses"
                  rules={[{ required: true, message: 'Please select at least one status!' }]}
                >
                  <Select mode="multiple" placeholder="Select statuses to skip" options={allowedStatusesOptions} />
                </Form.Item>
              </Col>
            </Row>

            <Divider style={{ margin: '12px 0 24px' }} />

            <Row justify="end">
              <Space>
                <Button type="primary" htmlType="submit" loading={isPending}>
                  {editCampaignID ? 'Update' : 'Create'}
                </Button>
              </Space>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Launch Schedule"
          open={launchModalVisible}
          onCancel={() => {
            setLaunchModalVisible(false);
            setLaunchingCampaignID(null);
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setLaunchModalVisible(false);
                setLaunchingCampaignID(null);
              }}
            >
              Cancel
            </Button>,
            <Button key="submit" type="primary" danger onClick={handleLaunchCampaign} loading={isLaunching}>
              Launch
            </Button>,
          ]}
        >
          <Text strong>Are you sure you want to launch this campaign?</Text>
        </Modal>
        <Modal
          title="Cancel Campaign"
          open={cancelModalVisible}
          onCancel={() => {
            setCancelModalVisible(false);
            setCancellingCampaignData(null);
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                setCancelModalVisible(false);
                setCancellingCampaignData(null);
              }}
            >
              Cancel
            </Button>,
            <Button key="confirm" type="primary" danger loading={isCancelling} onClick={handleCancelCampaign}>
              Confirm
            </Button>,
          ]}
        >
          <Text strong>Are you sure you want to cancel this campaign?</Text>
        </Modal>
      </Card>
    </Spin>
  );
};

export default ScheduleInfoForm;
