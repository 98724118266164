export const WEATHER_API_TEMPLATE = {
  id: 'weather-api',
  name: 'Weather API Webhook',
  scenario: 'Trigger this action whenever the caller asks anything about the weather condition.',
  webhookInformation: {
    url: 'https://api.openweathermap.org/data/2.5/weather',
    method: 'GET',
    headers: [
      { key: 'Authorization', value: 'Bearer <API_KEY>' },
      { key: 'Content-Type', value: 'application/json' },
    ],
    responseData: [
      { variableName: 'temperature', jsonPath: '$.main.temp', prompt: 'The current temperature is {{temperature}}°C.' },
      { variableName: 'humidity', jsonPath: '$.main.humidity', prompt: 'The humidity level is {{humidity}}%.' },
      {
        variableName: 'weatherCondition',
        jsonPath: '$.weather[0].description',
        prompt: 'The weather is described as {{weatherCondition}}.',
      },
    ],
  },
  extractedCallInfo: [{ name: 'city', type: 'string', description: 'City for which weather is requested.' }],
  timeoutSeconds: 30,
  speechToEmit: 'Fetching current weather details. Please hold on for a moment.',
  isRepeatable: true,
};
