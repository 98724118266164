import { Button, Col, Divider, notification, Progress, Row, Spin, Statistic, Typography } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { cancelSubReason, createClientPortalSession, getBillingOverview, getBillingPortal } from '@/api/user';
import { ClockCircleOutlined, DollarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import CancelSubReasonModal from '@/components/CancelSubReasonModal/CancelSubReasonModal';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';
import { FaExclamationTriangle } from 'react-icons/fa';

const { Text } = Typography;

const ManageUsage = () => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const username = loggedInUser.number as string;
  const { data: overviewData, isLoading: overviewLoading } = useQuery({
    queryKey: ['billing-overview'],
    queryFn: getBillingOverview,
  });

  const CancelReasonMutation = useMutation({
    mutationKey: ['cancel-subscription-reason'],
    mutationFn: cancelSubReason,
    onSuccess: data => {
      if (!data?.success) {
        notification.error({ message: 'Error canceling subscription' });
      } else {
        cancelSubMutation.mutate({ username: username, action: 'subscription_cancel' });
      }
    },
    onError: () => {
      notification.error({ message: 'Error canceling subscription' });
    },
  });

  const cancelSubMutation = useMutation({
    mutationKey: ['cancel-subscription'],
    mutationFn: createClientPortalSession,

    onSuccess: data => {
      if (data?.status !== 'success') {
        notification.error({ message: 'Error canceling subscription' });
      } else {
        notification.success({
          message: 'Redirecting to subscription portal...',
        });
        window.location.href = data.redirectPaymentURL as string;
      }
    },
  });

  const clientPortalMutation = useMutation({
    mutationKey: ['client-portal'],
    mutationFn: getBillingPortal,
    onSuccess: data => {
      if (!data?.success) {
        notification.error({ message: 'Error processing request' });
      } else {
        window.location.href = data.data?.redirectionURL as string;
      }
    },
    onError: () => {
      notification.error({ message: 'Error processing request' });
    },
  });

  const handleBillingPortal = () => {
    if (loggedInUser.subscriptionStatus !== 'active') {
      notification.error({
        message:
          "You don't have an active subscription to view billing portal. Please subscribe to a subscription or contact support.",
      });
      return;
    }
    clientPortalMutation.mutate();
  };

  const baseClass = 'manage-subscription';

  return (
    <>
      <div className={`${baseClass}__item py-2`}>
        <Spin spinning={overviewLoading}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Statistic
                title='Current Voice Usage'
                value={overviewData?.data?.currentVoiceUsage}
                suffix='minutes'
                prefix={<DollarOutlined />}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Statistic
                title='Usage Charge'
                value={overviewData?.data?.voiceUsageCharge || 0}
                precision={2}
                prefix='$'
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Statistic
                title={
                  <div className='flex items-center gap-2'>
                    <span>Remaining Free Minutes</span>
                    <LearnMore tutorialId={TUTORIALS.MINUTES_INCLUDED} />
                  </div>
                }
                value={overviewData?.data?.remainingFreeMinutes || 0}
                suffix={`/ ${overviewData?.data?.freeMinutes || 0}`}
                prefix={<ClockCircleOutlined />}
              />
              <Progress
                percent={
                  ((overviewData?.data?.remainingFreeMinutes || 0) / (overviewData?.data?.freeMinutes || 0)) * 100
                }
                showInfo={false}
                strokeColor='#1890FF'
              />
            </Col>
            <Typography.Paragraph className='space-y-3 p-3 rounded-md border border-gray-200'>
              <div className='flex items-center gap-2'>
                <InfoCircleOutlined className='text-blue-600 text-lg' />
                <Text strong className='text-base'>
                  About Your Minutes
                </Text>
              </div>

              <div className='space-y-2'>
                <Text className='block'>
                  Each billing cycle, your plan provides a fresh set of free calling minutes.
                </Text>

                <div className='flex items-start gap-2 p-2 bg-amber-50 rounded'>
                  <FaExclamationTriangle className='text-amber-500 text-lg mt-0.5' />
                  <Text type='warning'>
                    Important: Transferred calls are charged from the beginning and do not count towards your free
                    minutes allocation.
                  </Text>
                </div>
              </div>
            </Typography.Paragraph>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Button type='primary' onClick={handleBillingPortal} loading={clientPortalMutation.isPending}>
                View Billing Portal
              </Button>
            </Col>
          </Row>
        </Spin>
      </div>
      <CancelSubReasonModal mutation={CancelReasonMutation} />
    </>
  );
};

export default ManageUsage;
