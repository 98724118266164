import React, { useState } from 'react';
import { Tabs, Typography, Space, Spin, Row, Col, Card, Statistic, TabsProps, Tooltip } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getSelectedCampaign } from '@/store/novi/user/selector';
import { useLeadsHooks } from '../hooks/campaginHooks';
import LeadsInfo from './Leads/LeadsInfo';
import ScheduleInfoForm from './Schedules/ScheduleInfoForm';

const { Title } = Typography;

const CampaignInfo: React.FC<{ campaignId: string }> = ({ campaignId }) => {
  const campaignDetails = useAppSelector(getSelectedCampaign);
  const [activeTab, setActiveTab] = useState<string>('leads');

  const { data, isLoading, error, refetch, isRefetching } = useLeadsHooks(campaignId);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size='large' />
      </div>
    );
  }

  if (error) return <div>Error fetching data</div>;

  const statsTooltips = {
    totalLeads: 'Total number of leads in your campaign that need to be contacted',
    contacted: 'Number of leads that have been successfully contacted',
    conversionRate: 'Percentage of leads that have been successfully contacted out of total leads',
  };

  const items: TabsProps['items'] = [
    {
      label: 'Leads',
      key: 'leads',
      children: (
        <LeadsInfo
          initialContacts={data?.leads || []}
          campaignId={campaignId}
          refetch={refetch}
          isLoading={isLoading}
          isRefetching={isRefetching}
        />
      ),
    },
    {
      label: 'Schedule',
      key: 'schedule',
      children: <ScheduleInfoForm refetchLeads={refetch} />,
    },
  ];

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Space>
          <Title level={3} style={{ margin: 0 }}>
            {campaignDetails?.business_name}
          </Title>
        </Space>
      </div>
      <Content style={{ padding: '24px' }}>
        <Row gutter={[16, 16]} className='mb-6'>
          <Col span={8}>
            <Card>
              <Statistic
                title={
                  <div className='flex items-center gap-2'>
                    Total Leads
                    <Tooltip title={statsTooltips.totalLeads}>
                      <InfoCircleOutlined className='text-gray-400 cursor-help' />
                    </Tooltip>
                  </div>
                }
                value={data?.leads?.length || 0}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title={
                  <div className='flex items-center gap-2'>
                    Contacted
                    <Tooltip title={statsTooltips.contacted}>
                      <InfoCircleOutlined className='text-gray-400 cursor-help' />
                    </Tooltip>
                  </div>
                }
                value={data?.leads?.filter((lead: any) => lead.status === 'CONTACTED').length || 0}
                prefix={<PhoneOutlined />}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title={
                  <div className='flex items-center gap-2'>
                    Conversion Rate
                    <Tooltip title={statsTooltips.conversionRate}>
                      <InfoCircleOutlined className='text-gray-400 cursor-help' />
                    </Tooltip>
                  </div>
                }
                value={(
                  ((data?.leads?.filter((lead: any) => lead.status === 'CONTACTED').length || 0) /
                    (data?.leads?.length || 1)) *
                  100
                ).toFixed(2)}
                suffix='%'
                prefix={<MailOutlined />}
              />
            </Card>
          </Col>
        </Row>
        <Card>
          <Tabs activeKey={activeTab} onChange={handleTabChange} type='card' size='large' items={items} />
        </Card>
      </Content>
    </Space>
  );
};

export default CampaignInfo;
