import { LabelWithDescription } from '@/components/LabelWithdescription';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { Button, Form, Input, notification, Select, Spin, Switch, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { updateUser } from '@/api/user';
import { User } from '@/types/TAccounts';
import { WEATHER_API_TEMPLATE } from './data';
import TextArea from 'antd/es/input/TextArea';

interface ApiWorkflowFormProps {
  call_webhook_workflows?: {
    call_webhook_workflows: {
      id: string;
      name: string;
      scenario: string;
      webhookInformation: {
        url: string;
        method: 'GET' | 'POST' | 'PUT';
        body: string;
        headers: [];
        responseData: {
          variableName: string;
          jsonPath: any;
          prompt: string;
        }[];
      };
      extractedCallInfo: {
        name: string;
        type: 'string' | 'number' | 'boolean';
        description: string;
      }[];
      timeoutSeconds: number;
      speechToEmit: string;
      isRepetable: boolean;
    }[];
  };
  showAddFieldButton?: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}

const ApiWorkflowForm: React.FC<ApiWorkflowFormProps> = ({
  call_webhook_workflows,
  showAddFieldButton = true,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const [headersEnabled, setHeadersEnabled] = useState<boolean>(true);
  const [bodyEnabled, setBodyEnabled] = useState<boolean>(false);
  const [responseEnabled, setResponseEnabled] = useState<boolean>(true);
  console.info('call_webhook_workflows', call_webhook_workflows);
  useEffect(() => {
    form.setFieldsValue({ call_webhook_workflows: call_webhook_workflows || [WEATHER_API_TEMPLATE] });
  }, [call_webhook_workflows]);

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'API Workflow has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
    },
  });

  const handleMethodChange = (value: 'GET' | 'POST' | 'PUT', name: any) => {
    setBodyEnabled(value === 'POST' || value === 'PUT');

    // Update form state when HTTP method changes
    form.setFields([
      {
        name: [name, 'webhookInformation', 'method'],
        value: value,
      },
    ]);
  };

  const handleSwitchChange = (checked: boolean, index: number, fieldName: string) => {
    switch (fieldName) {
      case 'headers':
        setHeadersEnabled(checked);
        break;
      case 'responseData':
        setResponseEnabled(checked);
        break;
      case 'body':
        setBodyEnabled(checked);
        break;
      default:
        break;
    }
    if (fieldName === 'body') {
      return;
    }
    // Directly update form state based on the switch change using the index
    const updatedFieldValue = checked ? [] : undefined;

    // Fetch existing form values
    const formValues = form.getFieldValue('call_webhook_workflows');

    // Modify the specific workflow at the given index
    const updatedWorkflows = formValues.map((workflow: any, i: number) => {
      if (i === index) {
        return {
          ...workflow,
          webhookInformation: {
            ...workflow.webhookInformation,
            [fieldName]: updatedFieldValue,
          },
        };
      }
      return workflow;
    });

    // Set the updated form values
    form.setFieldsValue({
      call_webhook_workflows: updatedWorkflows,
    });
  };

  const handleSubmit = (values: any) => {
    // Check if 'extractedCallInfo' is used in URL or Body
    const updatedValues = { ...values };
    updatedValues?.call_webhook_workflows.forEach((workflow: any) => {
      if (workflow.webhookInformation.method === 'GET') {
        // Append extracted variables to URL for GET method
        const queryParams = workflow.extractedCallInfo.map((info: any) => `${info.name}={{${info.name}}}`).join('&');
        workflow.webhookInformation.url += `?${queryParams}`;
      }
    });
    mutation.mutate({
      call_webhook_workflows: JSON.stringify(updatedValues),
    });
  };

  const ColumnHeader = ({ title, tooltip }: { title: string; tooltip: string }) => (
    <div className='flex items-center mb-2'>
      <span className='font-medium mr-1'>{title}</span>
      <Tooltip title={tooltip}>
        <InfoCircleOutlined className='text-blue-500 cursor-help' />
      </Tooltip>
    </div>
  );

  const FormItems = () => (
    <>
      <div className='mb-8 bg-gray-100 p-6 rounded'>
        <h2 className='text-2xl font-semibold mb-4 text-gray-800'>🪄 Real-Time API Integration</h2>
        <p className='mb-6 text-gray-600 leading-relaxed'>
          Your AI receptionist just got superpowers. It can now tap into external APIs during the call, referencing live
          data into conversations. It's like giving your AI a smartphone and the internet while it's on the phone.
        </p>
        <div className='space-y-6'>
          <div className='flex items-start'>
            <div className='flex-shrink-0 w-6 h-6 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-medium text-sm'>
              1
            </div>
            <p className='ml-4 text-gray-700'>Define the information your AI needs to collect from the caller.</p>
          </div>
          <div className='flex items-start'>
            <div className='flex-shrink-0 w-6 h-6 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-medium text-sm'>
              2
            </div>
            <p className='ml-4 text-gray-700'>Specify how to send this information to an external API.</p>
          </div>
          <div className='flex items-start'>
            <div className='flex-shrink-0 w-6 h-6 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-medium text-sm'>
              3
            </div>
            <p className='ml-4 text-gray-700'>
              Extract data from the API response and incorporate it into the ongoing conversation.
            </p>
          </div>
        </div>
      </div>

      <Form.List name='call_webhook_workflows' initialValue={[{ ...WEATHER_API_TEMPLATE }]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              return (
                <div key={key} className='mb-8 bg-white p-8 rounded-lg shadow-sm border border-gray-200'>
                  <div className='flex justify-between items-center mb-6'>
                    <span className='text-lg font-semibold'>API Workflow {index + 1}</span>
                    {fields.length > 1 && showAddFieldButton && (
                      <Button type='primary' danger icon={<DeleteOutline />} onClick={() => remove(name)}>
                        Delete
                      </Button>
                    )}
                  </div>

                  {/* Workflow Name */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Workflow Name'
                      description='Give this API interaction a memorable name.'
                    />
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Please enter a workflow name' }]}
                    >
                      <Input placeholder='e.g., Check Customer Balance' className='w-full' />
                    </Form.Item>
                  </div>

                  {/* Workflow Trigger Scenario */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Workflow Trigger Scenario'
                      description='Define the scenario on based of which you want to trigger this API interaction.'
                    />
                    <Form.Item
                      {...restField}
                      name={[name, 'scenario']}
                      rules={[{ required: true, message: 'Please enter a workflow trigger scenario.' }]}
                    >
                      <Input
                        placeholder='e.g., Trigger this action whenever the caller asks anything about the weather condition.'
                        className='w-full'
                      />
                    </Form.Item>
                  </div>

                  {/* API URL */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Request URL / Address'
                      description='Choose where to send data to the API (The URL/Address to the Server API)'
                    />
                    <span className='mb-4'>
                      <Form.Item
                        className='flex-auto !mb-0'
                        name={[name, 'webhookInformation', 'url']}
                        rules={[{ required: true, message: 'Missing URL.' }]}
                      >
                        <Input placeholder='Server Address / URL' />
                      </Form.Item>
                    </span>
                  </div>
                  {/* Request Method */}
                  <div className='mb-8'>
                    <LabelWithDescription label='Request Method' description='Choose how to send data to the API' />
                    <Form.Item
                      {...restField}
                      name={[name, 'webhookInformation', 'method']}
                      rules={[{ required: true, message: 'Please select a request method' }]}
                    >
                      <Select
                        options={[
                          { value: 'GET', label: 'GET (Fetch data)' },
                          { value: 'POST', label: 'POST (Send data)' },
                          { value: 'PUT', label: 'PUT (Update data)' },
                        ]}
                        onChange={value => handleMethodChange(value, name)}
                        className='w-full'
                      />
                    </Form.Item>
                  </div>
                  {/* Repeatable Switch */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Repeatable'
                      description='Specify if the workflow can trigger multiple times over a single call.'
                    />
                    <Form.Item {...restField} name={[name, 'isRepeatable']} valuePropName='checked'>
                      <Switch className='mb-2' />
                    </Form.Item>
                  </div>

                  {/* Information to Collect */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Information to Collect'
                      description='Define the data you need from the caller before making the API request. Then, in the Request Body section, you can choose to send this data to your API in the body.'
                    />
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-2'>
                      <ColumnHeader
                        title='Variable Name'
                        tooltip='The name of the variable to be used in the API request'
                      />
                      <ColumnHeader title='Type' tooltip='The data type of the variable' />
                      <ColumnHeader
                        title='Question to Ask'
                        tooltip='The question the AI will ask to collect this information'
                      />
                    </div>
                    <Form.List name={[name, 'extractedCallInfo']} initialValue={[{}]}>
                      {(variableFields, { add: addVariable, remove: removeVariable }) => (
                        <>
                          {variableFields.map(({ key: vKey, name: vName, ...qRestField }) => (
                            <div key={vKey} className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4 items-center'>
                              <Form.Item {...qRestField} name={[vName, 'name']} className='mb-0'>
                                <Input placeholder='e.g., account_number' />
                              </Form.Item>
                              <Form.Item {...qRestField} name={[vName, 'type']} className='mb-0'>
                                <Select
                                  options={[
                                    { value: 'string', label: 'Text' },
                                    { value: 'number', label: 'Number' },
                                    { value: 'boolean', label: 'Yes/No' },
                                  ]}
                                />
                              </Form.Item>
                              <div className='flex items-center'>
                                <Form.Item {...qRestField} name={[vName, 'description']} className='mb-0 flex-grow'>
                                  <Input placeholder="e.g., What's your account number?" />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => removeVariable(vName)}
                                  className='ml-2 flex-shrink-0'
                                />
                              </div>
                            </div>
                          ))}
                          <Form.Item>
                            <Button type='dashed' onClick={() => addVariable()} block icon={<PlusOutlined />}>
                              Add Another Question
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>

                  {/* Request Headers */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Request Headers'
                      description='Add any necessary headers for your API call'
                    />
                    <Switch
                      checked={headersEnabled}
                      onChange={checked => handleSwitchChange(checked, index, 'headers')}
                      className='mb-2'
                    />
                    {headersEnabled && (
                      <>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-2'>
                          <ColumnHeader title='Header Name' tooltip='The name of the HTTP header' />
                          <ColumnHeader title='Header Value' tooltip='The value of the HTTP header' />
                        </div>
                        <Form.List name={[name, 'webhookInformation', 'headers']}>
                          {(headerFields, { add: addHeader, remove: removeHeader }) => (
                            <>
                              {headerFields.map(({ key: hKey, name: hName, ...hRestField }) => (
                                <div key={hKey} className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 items-center'>
                                  <Form.Item
                                    {...hRestField}
                                    name={[hName, 'key']}
                                    rules={[{ required: true, message: 'Required' }]}
                                    className='mb-0'
                                  >
                                    <Input placeholder='e.g., Authorization' />
                                  </Form.Item>
                                  <div className='flex items-center'>
                                    <Form.Item
                                      {...hRestField}
                                      name={[hName, 'value']}
                                      rules={[{ required: true, message: 'Required' }]}
                                      className='mb-0 flex-grow'
                                    >
                                      <Input placeholder='e.g., Bearer token123' />
                                    </Form.Item>
                                    {headerFields.length > 1 && (
                                      <MinusCircleOutlined
                                        onClick={() => removeHeader(hName)}
                                        className='ml-2 flex-shrink-0'
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                              <Form.Item>
                                <Button type='dashed' onClick={() => addHeader()} block icon={<PlusOutlined />}>
                                  Add Header
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </>
                    )}
                  </div>

                  {/* Request Body */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label={
                        <span>
                          Request Body{' '}
                          <Tooltip title='The request body is where you send data to the API. You can use {{variable_name}} to include information collected from the caller.'>
                            <InfoCircleOutlined className='text-blue-500 cursor-help' />
                          </Tooltip>
                        </span>
                      }
                      description='Specify the data to send with POST or PUT requests. This is where you can include information collected from the caller that you defined earlier'
                    />
                    <Switch
                      // checked={bodyEnabled}
                      defaultChecked={
                        call_webhook_workflows &&
                        !!call_webhook_workflows.call_webhook_workflows[index]?.webhookInformation?.body
                      }
                      onChange={checked => handleSwitchChange(checked, index, 'body')}
                      className='mb-2'
                    />
                    {((call_webhook_workflows &&
                      call_webhook_workflows.call_webhook_workflows[index]?.webhookInformation?.body) ||
                      bodyEnabled) && (
                      <Form.Item
                        name={[name, 'webhookInformation', 'body']}
                        rules={[{ required: true, message: 'Missing request body' }]}
                      >
                        <TextArea
                          placeholder={`{ "date": "{{date}}", "time": "{{time}}" }`}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                        />
                      </Form.Item>
                    )}
                  </div>

                  {/* Extract Data from API Response */}
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Extract Data from API Response'
                      description='Specify which data to extract from the API response and how your AI should use it in the conversation.'
                    />
                    <Switch
                      checked={responseEnabled}
                      onChange={checked => handleSwitchChange(checked, index, 'responseData')}
                      className='mb-2'
                    />
                    {responseEnabled && (
                      <>
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-2'>
                          <ColumnHeader
                            title='Variable Name'
                            tooltip='The name of the variable to store the extracted data'
                          />
                          <ColumnHeader
                            title='JSON Path'
                            tooltip='The JSON path to extract the specific data from the API response'
                          />
                          <ColumnHeader
                            title='AI Instruction'
                            tooltip='How the AI should interpret and use this data in the conversation'
                          />
                        </div>
                        <Form.List name={[name, 'webhookInformation', 'responseData']}>
                          {(responseFields, { add: addResponse, remove: removeResponse }) => (
                            <>
                              {responseFields.map(({ key: rKey, name: rName, ...rRestField }) => (
                                <div key={rKey} className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4 items-center'>
                                  <Form.Item
                                    {...rRestField}
                                    name={[rName, 'variableName']}
                                    rules={[{ required: true, message: 'Required' }]}
                                    className='mb-0'
                                  >
                                    <Input placeholder='e.g., temperature' />
                                  </Form.Item>
                                  <Form.Item
                                    {...rRestField}
                                    name={[rName, 'jsonPath']}
                                    rules={[{ required: true, message: 'Required' }]}
                                    className='mb-0'
                                  >
                                    <Input placeholder='e.g., $.main.temp' />
                                  </Form.Item>
                                  <div className='flex items-center'>
                                    <Form.Item
                                      {...rRestField}
                                      name={[rName, 'prompt']}
                                      rules={[{ required: true, message: 'Required' }]}
                                      className='mb-0 flex-grow'
                                    >
                                      <Input placeholder='e.g., The temperature is {{temperature}}' />
                                    </Form.Item>
                                    {responseFields.length > 1 && (
                                      <MinusCircleOutlined
                                        onClick={() => removeResponse(rName)}
                                        className='ml-2 flex-shrink-0'
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                              <Form.Item>
                                <Button type='dashed' onClick={() => addResponse()} block icon={<PlusOutlined />}>
                                  Add Response Mapping
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </>
                    )}
                  </div>
                  <div className='mb-8'>
                    <LabelWithDescription
                      label='Speech to Emit'
                      description='Specify what you want the AI to say when the process of API communication starts.'
                    />

                    <span className='mb-4'>
                      <Form.Item
                        className='flex-auto !mb-0'
                        name={[name, 'speechToEmit']}
                        rules={[{ required: true, message: 'Missing Speech to Emit.' }]}
                      >
                        <Input placeholder='Please wait while I fetch the information for you.' />
                      </Form.Item>
                    </span>
                  </div>
                </div>
              );
            })}
            {showAddFieldButton && (
              <Form.Item>
                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Another API Workflow
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={call_webhook_workflows}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  );
};

export default ApiWorkflowForm;
