import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';
import { User } from '@/types/TAccounts';
import { KnowledgeBaseRevamped } from './KnowledgeBaseRevamped';

export const KnowledgeBaseInfo: React.FC<{
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  isNoviUser: boolean;
}> = ({ data, refetch, isNoviUser }) => {
  return <KnowledgeBaseRevamped data={data} refetch={refetch} isNoviUser={isNoviUser} />;
};
