import { getAuthenticationStatus } from '@/store/account/selector';
import { setAccount } from '@/store/account/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import LocalStorageUtility from '@/utils/localStorage';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getUser } from 'src/api/user';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';

const useAuth = () => {
  let isAuthenticated = useAppSelector(getAuthenticationStatus);
  const dispatch = useAppDispatch();

  const authenticationStatus = LocalStorageUtility.getLocalData(LocalStorageKeys.AUTH_TOKEN) ?? isAuthenticated;

  const { data, isLoading } = useQuery({
    queryKey: ['account'],
    queryFn: getUser,
    enabled: !!authenticationStatus,
  });

  useEffect(() => {
    // check token in query param
    let authToken = new URLSearchParams(window.location.search).get('AUTH_TOKEN');
    if (authToken) {
      LocalStorageUtility.setLocalData(LocalStorageKeys.AUTH_TOKEN, authToken);
      window.location.href = window.location.origin;
    }
    if (isAuthenticated) return;

    if (data) {
      dispatch(
        setAccount({
          isAuthenticated: true,
          //@ts-ignore
          user: data,
          authToken: data.token,
          currentPage: '',
          activeConfigureTab: '',
        })
      );
    }
  }, [data]);

  return {
    isAuthenticated: authenticationStatus,
    isLoading,
  };
};

export default useAuth;
