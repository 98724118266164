import React from 'react';

import classes from '../../../Portal/Portal.module.css';
import { serverURL } from '../../../config';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
// import {
//   businessInformationsTemplates,
//   businessNameTemplates,
//   callTransferTemplates,
//   greetingsPromptTemplates,
//   textingWorkflowsTemplates,
// } from "../../../constants/prompt-templates";

const CopyReceptionistModal = ({
  user,
  themeColor,
  selectedClient,
  setCopyReceptionistModalOpen,
  setSelectedClient,
  isCampaign,
  campaign_user_email = '',
  campaign_user_password = '',
  campaign_user_name = '',
  debrand,
  debrand_modal_instructions_setter,
  reroute_to_unbranded_login = undefined,
}) => {
  const navigate = useNavigate();
  const [passwordMatchError, setPasswordMatchError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);
    let email = formData.get('email');
    let username = formData.get('username');
    const password = formData.get('password');
    const confirmPassword = formData.get('confirm-password');

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      setLoading(false);
      toast.error('Passwords do not match.');
      return;
    }
    const d = Date.now();
    const campaignUsername = (username + '-' + selectedClient.business_name + '-' + d).replace(/ /g, '-');
    // Before registering user, make sure client does not exist
    try {
      let usernameTaken = false;
      const response = await fetch(serverURL + '/clientExistsNoPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: isCampaign ? campaignUsername : username,
        }),
      })
        .then(response => response.json())
        .then(data => {
          const status = data.status;
          console.log('Status: ' + status);
          if (status == 'true') {
            console.log('Client already exists');
            alert('This username is already registered. Please login or register with a different phone number.');
            usernameTaken = true; // Set the variable to true if the phone number is taken
            window.gtag('event', 'phone_number_exists', {
              event_category: 'RegisterPage.js',
            });
          }
        })
        .catch(error => {
          console.error(error);
          window.gtag('event', 'couldnt_create_client', {
            event_category: 'RegisterPage.js',
            event_label: 'error',
          });
          alert('We are having trouble registering you. Please try again or email contact@myaifrontdesk.com.');
        });
      if (usernameTaken) {
        console.log('Phone num taken, returning');
        return; // Return from the main function if the phone number is taken
      }
    } catch (error) {
      console.error(error);
    }
    if (user.email_address.S) {
      email = user.email_address.S;
    }
    if (campaign_user_email) {
      email = campaign_user_email;
    }
    // Make server call using fetch to /createClient
    try {
      const bodyObject = {
        email,
        businessName: selectedClient.business_name,
        testingNumber: selectedClient.testing_number,
        systemPhrase: selectedClient.system_phrase,
        variables: JSON.parse(selectedClient.variables || '{}'),
        companyInfo: selectedClient.business_information,
        password: isCampaign ? campaign_user_password || Date.now() : password,
        referralCode: selectedClient.referral,
        findOutAboutUs: selectedClient?.find_out_about_us,
        sampleQA: selectedClient.sample_qa,
        selectedLanguages: JSON.parse(selectedClient.selected_languages), // List of selected languages
        languageGreetings: JSON.parse(selectedClient.language_greetings), // Dict of language to greeting phrase
        workflows: JSON.parse(selectedClient.workflows),
        callWorkflows: JSON.parse(selectedClient.callWorkflows),
        businessType: selectedClient.businessType,
        reseller_email: selectedClient.reseller_email,
        campaign_user_email: campaign_user_email,
        purchase_quotes: user.purchase_quotes.S.toString(),
        username: isCampaign ? campaignUsername : username,
        voiceSelected: selectedClient.voice_selected,
      };
      let body = JSON.stringify(bodyObject);
      // if (isCampaign) {
      //   const filteredCampaignVariables = {};
      //   Object.keys(campaignVariables || {}).forEach((el) => {
      //     if (body.includes(`{{${el}}}`)) {
      //       filteredCampaignVariables[el] = campaignVariables[el];
      //     }
      //   });
      //   bodyObject.variables = filteredCampaignVariables;
      //   body = JSON.stringify(bodyObject);
      // }
      const response = await fetch(`${serverURL}/createClient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });
      if (!response.ok) {
        window.gtag('event', 'couldnt_create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'error',
        });
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const status = data.success;
      if (status) {
        window.gtag('event', 'create_client', {
          event_category: 'RegisterPage.js',
          event_label: 'success',
        });
        // Debranded post-creation flow is different, keep them on the same page
        // No need to navigate to login page
        if (debrand && reroute_to_unbranded_login) {
          navigate('/unbranded_receptionist', {
            state: { username: username, password: password },
          });
        }
        if (debrand) {
          debrand_modal_instructions_setter(true); // Open debrand modal telling reseller client is created
          setCopyReceptionistModalOpen(false); // Close existing registration modal
          return;
        }
        navigate('/', {
          state: { username: username, password: password },
        });
      } else {
        toast.error(data.error || 'Error creating client. Please try again.');
      }
    } catch (error) {
      window.gtag('event', 'couldnt_create_client', {
        event_category: 'RegisterPage.js',
        event_label: 'error',
      });
      toast.error('Error creating client. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          setCopyReceptionistModalOpen(false);
          setSelectedClient(null);
        }}
        className={classes.modalOverlay}
      ></div>
      <div className={classes.modal + ' '}>
        <div
          className={classes.close}
          onClick={() => {
            setCopyReceptionistModalOpen(false);
            setSelectedClient(null);
          }}
          style={{ zIndex: 100 }}
        >
          &times;
        </div>

        <div className="flex flex-col gap-7">
          <div className="flex flex-col gap-2 items-center">
            <p className="text-2xl font-bold">Configure new receptionist</p>
            <p className="text-center">
              This will copy the{' '}
              <span
                className="font-bold"
                style={{
                  color: themeColor,
                }}
              >
                {selectedClient?.business_name}'s
              </span>{' '}
              receptionist configurations and apply them to the new receptionist.
            </p>
          </div>
          <form method="POST" onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
            <div className="flex flex-row gap-2 items-center">
              <p className="min-w-[100px] max-w-[100px]">Email: </p>
              <input
                type="email"
                name="email"
                placeholder="example@example.com"
                required
                defaultValue={user.email_address?.S || ''}
                disabled={user.email_address?.S}
              />
            </div>
            <div className="flex flex-row gap-2 items-center">
              <p className="min-w-[100px] max-w-[100px]">Username: </p>
              <input type="text" name="username" placeholder="username" required />
            </div>
            <div className="flex flex-row gap-2 items-center">
              <p className="min-w-[100px] max-w-[100px]">Password: </p>
              <input type="password" name="password" placeholder="password" required />
            </div>
            <div className="flex flex-row gap-2 items-center">
              <p className="min-w-[100px] max-w-[100px]">Confirm Password: </p>
              <input type="password" name="confirm-password" placeholder="password" required />
            </div>
            {/* <button
              type="submit"
              style={{
                fontSize: "16px",
                background: themeColor,
                border: "1px solid #ccc",
                borderRadius: "6px",
                minHeight: 40,
                width: "50%",
                minWidth: 300,
                maxWidth: 300,
                color: "white",
                flex: 1,
                marginRight: "10px",
              }}
              onClick={() => {}}
              disabled={loading}
            >
              {loading ? "Loading..." : "Configure"}
            </button> */}
            <Button
              text="Configure"
              themeColor={themeColor}
              type="submit"
              className="w-1/2 min-w-72"
              loading={loading}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default CopyReceptionistModal;
