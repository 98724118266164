import { Alert, Button, Form, Input, Spin } from 'antd';
import { LoginFormData } from '@/types/TLogin';
import './ReceptionistLoginForm.scss';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { loginApi } from 'src/api/user';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setAccount } from '@/store/account/slice';
import LocalStorageUtility from '@/utils/localStorage';
import { LocalStorageKeys } from 'src/enums/ELocalStorage';
import PublicLayout from '@/components/Layout/PublicLayout';
import { getResellerByCode, getResellerLogo } from '@/api/unbrandedReceptionist';
import { useEffect, useMemo } from 'react';
import { LoginForm } from '@/components/Form/LoginForm/LoginForm';
import {
  APP_LANDING_PAGE,
  AppRoutes,
  BOOK_CONSULTATION_LANDING_PAGE,
  PRIVACY_POLICY_LANDING_PAGE,
  TERMS_OF_USE_LANDING_PAGE,
} from 'src/enums/ERoutes';

export const ReceptionistLoginForm: React.FC = () => {
  const items = [
    { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
    { key: 2, target: '_self', url: AppRoutes.RECEPTIONIST_LOGIN, label: 'Login' },
    { key: 3, target: '_self', url: AppRoutes.REGISTER, label: 'Register' },
    { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
    { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
    { key: 6, target: '_blank', url: PRIVACY_POLICY_LANDING_PAGE, label: 'Privacy Policy' },
    { key: 7, target: '_blank', url: TERMS_OF_USE_LANDING_PAGE, label: 'Terms of Use' },
  ];

  const [form] = Form.useForm<LoginFormData>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const outletContext = useOutletContext<any>();
  const {
    from,
    debrand: debrandFromLocation,
    resellerCode,
  } = (location.state as {
    from: string;
    debrand: boolean;
    resellerCode: string;
  }) || {
    from: '/',
    debrand: false,
  };

  const debrand = outletContext?.debrand || debrandFromLocation;

  const { data } = useQuery({
    queryKey: ['reseller-code', resellerCode],
    queryFn: () => getResellerByCode({ resellerCode: resellerCode || '' }),
    enabled: !!(debrand && resellerCode),
  });

  const { data: resellerLogo, isLoading } = useQuery({
    queryKey: ['reseller-logo'],
    queryFn: () => getResellerLogo({ key: data.reseller.logo_s3 }),
    enabled: !!(debrand && data?.reseller?.logo_s3),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const resellerLogoUrl = useMemo(() => {
    if (!resellerLogo) return;

    return URL.createObjectURL(resellerLogo);
  }, [resellerLogo]);

  const mutation = useMutation({
    mutationKey: ['loginApi'],
    mutationFn: loginApi,
    onSuccess(data: any) {
      LocalStorageUtility.setLocalData(LocalStorageKeys.AUTH_TOKEN, data.token);
      dispatch(
        setAccount({
          isAuthenticated: true,
          user: data,
          authToken: data.token,
          currentPage: '',
          activeConfigureTab: '',
        })
      );
      navigate(debrand && from === '/' ? AppRoutes.UNBRANDED_DASHBOARD : from, { replace: true });
    },
  });

  const handleSubmit = (values: LoginFormData) => {
    mutation.mutate(values);
  };

  const filteredItems = useMemo(
    () =>
      items.filter(item => {
        if (location.pathname === AppRoutes.RECEPTIONIST_LOGIN && item.label === 'Login') {
          return false;
        }
        if (location.pathname === AppRoutes.REGISTER && item.label === 'Register') {
          return false;
        }
        return true;
      }),
    [location.pathname]
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && data?.reseller?.reseller_name) {
      try {
        // Update the document title (tab title)
        document.title = `${data?.reseller.reseller_name} Portal`;

        // Update the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${data?.reseller.reseller_name} Dashboard/Portal`);
        }

        // If you still need to update an element with id 'page-title'
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
          pageTitle.textContent = `${data?.reseller.reseller_name} Portal`;
        }
      } catch (err) {
        console.error('Error updating page metadata:', err);
      }
    }
  }, [data?.reseller]);

  const baseClass = 'login-form-container';

  const FormItems = () => (
    <>
      {debrand && (
        <div className=' flex justify-center items-center'>
          {isLoading ? (
            <Spin className='mt-20' />
          ) : resellerLogoUrl ? (
            <img
              style={{
                width: `${data?.reseller?.logoDimensions?.width?.N || 64}px`,
                height: `${data?.reseller?.logoDimensions?.height?.N || 64}px`,
                objectFit: 'contain',
              }}
              src={resellerLogoUrl}
              alt='logo'
            />
          ) : (
            <h1> {data?.reseller && data?.reseller?.reseller_name} </h1>
          )}
        </div>
      )}

      <Form.Item className={`${baseClass}__heading`}>
        <h2>Welcome Back</h2>
        <span>Log in to view all your call logs and messages</span>
      </Form.Item>
      <Form.Item
        label='Username'
        name='username'
        className={`${baseClass}__input-item`}
        rules={[
          {
            required: true,
            message: 'Username is required!',
          },
        ]}
      >
        <Input placeholder='Username' />
      </Form.Item>
      <Form.Item
        className={`${baseClass}__password-input-item`}
        name='password'
        label='Password'
        rules={[
          {
            required: true,
            message: 'Password is required!',
          },
        ]}
      >
        <Input.Password placeholder='Password' type={debrand ? 'text' : 'password'} />
      </Form.Item>

      <Form.Item>
        <Button loading={mutation.status === 'pending'} htmlType='submit' className={`${baseClass}__submit-button`}>
          Submit
        </Button>
      </Form.Item>
      {mutation.isError && (
        <Form.Item className={`${baseClass}__error-response`}>
          <Alert message={mutation.error?.message} type='error' />
        </Form.Item>
      )}

      {debrand ? (
        ''
      ) : (
        <Form.Item className={`${baseClass}__footer`}>
          <span className='register-now'>
            Don't have an account? <Link to='/register'>Register Now</Link>
          </span>
          <br />
          <span className='forgot-password'>
            <Link to='/forgot-password'>Forgot Password?</Link>
          </span>
        </Form.Item>
      )}

      {debrand && (data?.reseller?.privacyPolicyURL || data?.reseller?.termsOfUseURL) && (
        <div className=' text-sm text-center'>
          Please check our{' '}
          {debrand && data?.reseller?.privacyPolicyURL && (
            <Link to={data?.reseller?.privacyPolicyURL} className='text-blue-600 font-medium hover:font-semibold'>
              Privacy Policy
            </Link>
          )}
          {(debrand && data && data?.reseller?.termsOfUseURL) || !debrand ? (
            <>
              {debrand && data?.reseller?.privacyPolicyURL && data?.reseller?.termsOfUseURL && ' & '}
              {debrand && data?.reseller?.termsOfUseURL && (
                <Link to={data?.reseller?.termsOfUseURL} className='text-blue-600 font-medium hover:font-semibold'>
                  Terms of Use
                </Link>
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );

  return (
    <PublicLayout debrand={debrand} items={filteredItems}>
      <LoginForm
        form={form}
        customClassName='!my-auto'
        formItems={<FormItems />}
        handleSubmit={handleSubmit}
        autoComplete={debrand ? 'off' : 'on'}
      />
    </PublicLayout>
  );
};
