export const greetingsPromptTemplates = [
  {
    label: 'HVAC',
    template:
      'Welcome to [BUSINESS NAME HERE], your premier HVAC experts dedicated to ensuring your indoor climate is just right. I can help you with heating system installations, air conditioning repairs, indoor air quality solutions, and more. How may I help you today?',
  },
  {
    label: 'Beauty',
    template:
      'Welcome to [BUSINESS NAME HERE], where beauty meets serenity. I can help you with appointments, skincare recommendations, beauty services, and more. How may I help you?',
  },
  {
    label: 'Construction',
    template:
      'Welcome to [BUSINESS NAME HERE], where craftsmanship meets excellence. I can assist you with project inquiries, scheduling consultations, obtaining quotes, and addressing any construction-related queries. How may I help you today?',
  },
  {
    label: 'Automotive',
    template:
      'Welcome to [BUSINESS NAME HERE], your go-to destination for automotive excellence. I can help you with vehicle maintenance, repair services, tire replacements, and more. How may I assist you today?',
  },
  {
    label: 'Retail',
    template:
      'Welcome to [BUSINESS NAME HERE], where your shopping experience comes first. I can assist you with product inquiries, order updates, returns, and any other inquiries you may have. How may I help you today?',
  },
  {
    label: 'Food',
    template:
      'Welcome to [BUSINESS NAME HERE], your go-to destination for culinary excellence. I can help you with placing orders, catering inquiries, menu suggestions, and more. How may I assist you today?',
  },
  {
    label: 'Legal',
    template:
      'Welcome to [BUSINESS NAME HERE]. Your gateway to legal expertise, strategic counsel, and comprehensive solutions. I can help you with consultations, case assessments, document preparation, and more. How may I assist you today?',
  },
  {
    label: 'Marketing',
    template:
      'Welcome to [BUSINESS NAME HERE], where strategic marketing meets unparalleled creativity. I can help you with brand development, digital campaigns, social media management, and more. How may I assist you today?',
  },
  {
    label: 'Technology',
    template:
      'Welcome to [BUSINESS NAME HERE], your gateway to cutting-edge innovation and seamless tech support. I can help you with software troubleshooting, hardware inquiries, IT consulting, and more. How may I assist you today?',
  },
  {
    label: 'Entertainment',
    template:
      'Welcome to [BUSINESS NAME HERE], where every moment is a celebration! I can help you with event planning, ticket reservations, artist bookings, and more. How may I help you make your next entertainment experience unforgettable?',
  },
  {
    label: 'Real Estate',
    template:
      'Welcome to [BUSINESS NAME HERE], where your property dreams come true. I can assist you with buying, selling, property valuation, and expert real estate advice. How may I help you today?',
  },
  {
    label: 'Financial Services',
    template:
      'Welcome to [BUSINESS NAME HERE], where expertise meets excellence. I can help you with investment strategies, financial planning, tax optimization, and more. How may I assist you today?',
  },
  {
    label: 'Nonprofit',
    template:
      'Welcome to [BUSINESS NAME HERE], where compassion meets action. I can assist you with our mission-driven initiatives, volunteer opportunities, fundraising events, and more. How may I help you make a positive impact today?',
  },
  {
    label: 'Healthcare',
    template:
      'Welcome to [BUSINESS NAME HERE], your trusted partner in healthcare excellence. I can help you with appointments, medical inquiries, insurance details, and more. How may I assist you today?',
  },
  {
    label: 'Education',
    template:
      'Welcome to [BUSINESS NAME HERE], your dedicated resource for educational excellence. I can help you with course registrations, tutoring services, curriculum inquiries, and more. How may I assist you today?',
  },
  {
    label: 'Consulting',
    template:
      'Welcome to [BUSINESS NAME HERE], your premier destination for top-notch Consulting Services. I can assist you with strategic planning, market analysis, business optimization, and more. How may I help you today?',
  },
  {
    label: 'Other',
    template:
      'Welcome to [BUSINESS NAME HERE], your go-to hub for exceptional services. I can assist you with [Service 1], [Service 2], [Service 3], and more. How may I help you today?',
  },
];

export const businessInformationsTemplates = [
  {
    label: 'HVAC',
    template:
      "[BUSINESS NAME HERE] is a leading HVAC business that specializes in providing top-notch heating, ventilation, and air conditioning solutions. With a team of highly skilled technicians, we offer a comprehensive range of services, including installation, maintenance, and repairs for residential and commercial clients. Our commitment to quality is evident in our use of cutting-edge technology and energy-efficient products, ensuring optimal performance and cost savings for our customers.\n\nAt ABC Heating and Cooling, customer satisfaction is our priority, and we pride ourselves on delivering prompt and reliable service. Whether it's a routine maintenance check or a complex HVAC system installation, our dedicated team is equipped to handle any challenge. With a focus on innovation and sustainability, we strive to exceed industry standards and provide our clients with the most efficient and environmentally friendly HVAC solutions.\n\nTo learn more about our services and schedule an appointment, please visit our website or contact our friendly customer service team.\n\nServices offered:\n- HVAC installation\n- HVAC maintenance\n- HVAC repairs\n- Energy-efficient solutions\n- Residential and commercial services\n\nPrices:\n- HVAC installation: Starting from $1000\n- HVAC maintenance: Starting from $150\n- HVAC repairs: Starting from $200\n- Energy-efficient solutions: Starting from $500\n- Residential services: Starting from $200\n- Commercial services: Starting from $500",
  },
  {
    label: 'Beauty',
    template:
      "[BUSINESS NAME HERE], a thriving establishment in the beauty industry, is dedicated to delivering exceptional services that enhance clients' natural beauty and boost their confidence. Our team of skilled and licensed professionals stays at the forefront of beauty trends, offering a comprehensive range of services, including hairstyling, skincare, and makeup application. Located in a chic and welcoming environment, ABC Beauty Studio prioritizes customer satisfaction and utilizes premium products to ensure optimal results.\n\nFrom personalized consultations to expertly executed treatments, we create a transformative experience for each client. Embracing innovation, our studio also introduces the latest technologies and techniques to stay ahead in the dynamic beauty landscape. At ABC Beauty Studio, we are committed to making every individual feel and look their best, setting new standards for excellence in the beauty industry.\n\nTo book an appointment and experience our top-notch beauty services, please visit our website or give us a call.\n\nServices offered:\n- Hairstyling\n- Skincare\n- Makeup application\n- Personalized consultations\n- Premium products\n\nPrices:\n- Hairstyling: Starting from $50\n- Skincare: Starting from $40\n- Makeup application: Starting from $60\n- Personalized consultations: Starting from $30\n- Premium products: Starting from $20",
  },
  {
    label: 'Construction',
    template:
      "[BUSINESS NAME HERE] is a leading player in the construction industry, specializing in residential and commercial projects. With a robust team of skilled architects, engineers, and construction professionals, we deliver high-quality and innovative solutions tailored to our clients' unique needs. Our commitment to excellence is reflected in every phase of the construction process, from meticulous planning and efficient project management to top-notch craftsmanship.\n\nUtilizing cutting-edge technology and sustainable building practices, ABC Construction ensures not only the timely completion of projects but also the adherence to the highest standards of quality and safety. Our client-centric approach fosters strong partnerships, and our reputation for reliability and integrity has positioned us as a trusted choice in the dynamic and competitive construction market.\n\nTo discuss your construction project and receive a personalized quote, please visit our website or contact our dedicated team.\n\nServices offered:\n- Residential construction\n- Commercial construction\n- Architectural design\n- Engineering services\n- Project management\n- Sustainable building practices\n\nPrices:\n- Residential construction: Starting from $50000\n- Commercial construction: Starting from $100000\n- Architectural design: Starting from $5000\n- Engineering services: Starting from $10000\n- Project management: Starting from $20000\n- Sustainable building practices: Starting from $5000",
  },
  {
    label: 'Automotive',
    template:
      '[BUSINESS NAME HERE] is a leading player in the automotive industry, specializing in providing comprehensive solutions for vehicle maintenance and repair services. With state-of-the-art facilities equipped with cutting-edge diagnostic tools and a team of highly skilled technicians, we ensure top-notch service for our customers. Our extensive range of services includes routine maintenance, engine diagnostics, brake repairs, and more, catering to both individual vehicle owners and fleet management needs.\n\nAs a customer-centric automotive business, we prioritize quality, efficiency, and transparency, aiming to deliver unparalleled service experiences. With a commitment to staying abreast of industry advancements, XYZ Auto Solutions continues to be a trusted partner for all automotive needs, offering reliable solutions that keep vehicles running smoothly on the road.\n\nTo schedule a service appointment or inquire about our offerings, please visit our website or give us a call.\n\nServices offered:\n- Routine maintenance\n- Engine diagnostics\n- Brake repairs\n- Transmission services\n- Fleet management\n\nPrices:\n- Routine maintenance: Starting from $50\n- Engine diagnostics: Starting from $100\n- Brake repairs: Starting from $150\n- Transmission services: Starting from $200\n- Fleet management: Starting from $500',
  },
  {
    label: 'Retail',
    template:
      '[BUSINESS NAME HERE], a thriving retail business, specializes in providing a diverse array of high-quality consumer goods. Located strategically in the heart of the bustling city, our store caters to a broad customer base seeking trendy apparel, cutting-edge electronics, and stylish home decor.\n\nOur commitment to customer satisfaction is evident through personalized shopping experiences and a meticulously curated product selection. With a keen focus on staying ahead of market trends, we constantly update our inventory to showcase the latest and most sought-after products. Our knowledgeable and friendly staff ensures a pleasant shopping journey for every customer, while our convenient online platform extends our reach to a broader audience.\n\nTo explore our wide range of products and experience exceptional retail service, visit our store or shop online through our website.\n\nProducts and services offered:\n- Apparel\n- Electronics\n- Home decor\n- Personalized shopping experiences\n- Meticulously curated product selection\n\nPrices:\n- Apparel: Starting from $20\n- Electronics: Starting from $50\n- Home decor: Starting from $30\n- Personalized shopping experiences: Starting from $10',
  },
  {
    label: 'Food',
    template:
      '[BUSINESS NAME HERE] is a dynamic player in the food industry, specializing in the production and distribution of high-quality, gourmet snacks. Our state-of-the-art facilities leverage cutting-edge technology to ensure the freshness and authenticity of our products. With a commitment to sourcing only the finest ingredients, we cater to the evolving tastes of our discerning customers.\n\nFrom artisanal chips to exotic nut blends, our diverse product range reflects our dedication to culinary innovation. Our sustainable practices echo our responsibility towards the environment, making us a conscientious choice for consumers who prioritize both flavor and ethical consumption.\n\nTo discover our delightful range of snacks and place an order, visit our website or find our products in select retail stores near you.\n\nProducts offered:\n- Artisanal chips\n- Exotic nut blends\n- Gourmet snacks\n- Diverse product range\n- Sustainable practices\n\nPrices:\n- Artisanal chips: Starting from $5\n- Exotic nut blends: Starting from $10\n- Gourmet snacks: Starting from $8',
  },
  {
    label: 'Legal',
    template:
      "[BUSINESS NAME HERE] is a leading firm in the legal industry, specializing in a wide array of legal solutions tailored to meet the diverse needs of our clients. Our team of experienced attorneys is dedicated to providing high-quality legal representation, offering expert advice and strategic counsel.\n\nWith a focus on client satisfaction, we leverage our deep understanding of the legal landscape to deliver innovative and effective solutions. Whether it's corporate law, litigation, intellectual property, or regulatory compliance, we pride ourselves on our commitment to excellence. Our cutting-edge technology and client-centric approach ensure streamlined processes, efficient communication, and optimal outcomes.\n\nTo schedule a consultation and discuss your legal needs with our experienced attorneys, please visit our website or contact us directly.\n\nServices offered:\n- Corporate law\n- Litigation\n- Intellectual property\n- Regulatory compliance\n- Legal representation\n\nPrices:\n- Corporate law: Starting from $500\n- Litigation: Starting from $1000\n- Intellectual property: Starting from $800\n- Regulatory compliance: Starting from $700\n- Legal representation: Starting from $500",
  },
  {
    label: 'Marketing',
    template:
      '[BUSINESS NAME HERE] is a dynamic player in the marketing industry, offering a comprehensive suite of services to propel businesses forward in the digital age. Leveraging cutting-edge strategies and a team of seasoned experts, we specialize in crafting impactful marketing campaigns that resonate with target audiences.\n\nFrom social media management and search engine optimization to innovative content creation and data-driven analytics, our firm is dedicated to delivering measurable results. As industry pioneers, we stay ahead of trends, ensuring our clients benefit from the latest advancements in digital marketing.\n\nTo discuss your marketing objectives and explore how our solutions can accelerate your business growth, please visit our website or contact our knowledgeable team.\n\nServices offered:\n- Social media management\n- Search engine optimization\n- Content creation\n- Data-driven analytics\n- Marketing campaigns\n\nPrices:\n- Social media management: Starting from $500\n- Search engine optimization: Starting from $800\n- Content creation: Starting from $200\n- Data-driven analytics: Starting from $1000\n- Marketing campaigns: Starting from $1000',
  },
  {
    label: 'Technology',
    template:
      '[BUSINESS NAME HERE] is a cutting-edge technology business at the forefront of innovation, specializing in providing bespoke software development and IT consulting services. With a team of highly skilled engineers and developers, we leverage the latest advancements in artificial intelligence, machine learning, and data analytics to deliver tailored solutions that meet the unique needs of our clients.\n\nOur commitment to staying ahead of the technological curve ensures that our clients benefit from state-of-the-art solutions that enhance efficiency, productivity, and overall business performance. From custom software applications to scalable IT infrastructure, XYZ Tech Solutions is dedicated to empowering businesses with the tools and technology they need to thrive in the rapidly evolving digital landscape.\n\nTo learn more about our technology offerings and how they can transform your business, visit our website or connect with our expert team.\n\nServices offered:\n- Software development\n- IT consulting\n- Artificial intelligence\n- Machine learning\n- Data analytics\n\nPrices:\n- Software development: Starting from $5000\n- IT consulting: Starting from $2000\n- Artificial intelligence: Starting from $3000\n- Machine learning: Starting from $4000\n- Data analytics: Starting from $3000',
  },
  {
    label: 'Entertainment',
    template:
      '[BUSINESS NAME HERE] is a dynamic and innovative company at the forefront of the entertainment industry. Specializing in live event production, digital content creation, and artist management, we seamlessly blend creativity with cutting-edge technology to deliver unparalleled experiences.\n\nOur team of seasoned professionals collaborates with top-tier talent to produce captivating concerts, festivals, and immersive multimedia content. With a finger on the pulse of emerging trends, XYZ Entertainment stays ahead of the curve, ensuring that our offerings remain fresh, engaging, and tailored to diverse audiences.\n\nTo explore our upcoming events, enjoy our digital content, or inquire about artist collaborations, please visit our website or reach out to our enthusiastic team.\n\nServices offered:\n- Live event production\n- Digital content creation\n- Artist management\n- Concert production\n- Festival production\n\nPrices:\n- Live event production: Starting from $10000\n- Digital content creation: Starting from $5000\n- Artist management: Starting from $2000\n- Concert production: Starting from $5000\n- Festival production: Starting from $10000',
  },
  {
    label: 'Real Estate',
    template:
      "[BUSINESS NAME HERE] is a dynamic player in the real estate industry, specializing in residential and commercial property transactions. With a commitment to excellence and customer satisfaction, we offer a comprehensive range of services, including property sales, leasing, and property management.\n\nOur team of experienced real estate professionals leverages market insights and cutting-edge technology to provide clients with tailored solutions that meet their unique needs. Whether you're buying, selling, or renting, ABC Realty is dedicated to delivering exceptional results and ensuring a seamless real estate experience.\n\nTo explore our available properties, list your property with us, or discuss your real estate goals, please visit our website or get in touch with our knowledgeable team.\n\nServices offered:\n- Property sales\n- Leasing\n- Property management\n- Market insights\n\nPrices:\n- Property sales: Starting from $200000\n- Leasing: Starting from $1000\n- Property management: Starting from $500\n- Market insights: Starting from $500",
  },
  {
    label: 'Financial Services',
    template:
      '[BUSINESS NAME HERE] is a leading player in the financial industry, offering a comprehensive range of solutions tailored to meet the diverse needs of our clients. Our team of seasoned financial experts is dedicated to providing cutting-edge investment strategies, wealth management, and financial planning services.\n\nWith a client-centric approach, we prioritize transparency, integrity, and personalized attention to ensure our clients achieve their financial goals. Leveraging advanced technology and market insights, we continuously adapt to the dynamic financial landscape, delivering innovative solutions that optimize returns and mitigate risks.\n\nTo schedule a consultation and discuss your financial aspirations with our experienced team, please visit our website or contact us directly.\n\nServices offered:\n- Investment strategies\n- Wealth management\n- Financial planning\n- Market insights\n\nPrices:\n- Investment strategies: Starting from $1000\n- Wealth management: Starting from $2000\n- Financial planning: Starting from $1000\n- Market insights: Starting from $500',
  },
  {
    label: 'Nonprofit',
    template:
      "[BUSINESS NAME HERE] is a dedicated entity specializing in providing comprehensive support and strategic solutions to nonprofit organizations. With a deep understanding of the unique challenges faced by the nonprofit sector, we offer a range of services aimed at enhancing organizational efficiency and impact.\n\nOur expert team collaborates with nonprofits to streamline operations, optimize fundraising efforts, and implement effective communication strategies. From grant writing and donor management to program evaluation and capacity building, we tailor our services to meet the specific needs of each client.\n\nTo learn more about how we can support your nonprofit's mission and make a positive impact, please visit our website or contact our dedicated team of nonprofit experts.\n\nServices offered:\n- Operational support\n- Fundraising optimization\n- Communication strategies\n- Grant writing\n- Donor management\n- Program evaluation\n- Capacity building\n\nPrices:\n- Operational support: Starting from $500\n- Fundraising optimization: Starting from $1000\n- Communication strategies: Starting from $500\n- Grant writing: Starting from $1000\n- Donor management: Starting from $500\n- Program evaluation: Starting from $1000\n- Capacity building: Starting from $2000",
  },
  {
    label: 'Healthcare',
    template:
      '[BUSINESS NAME HERE] is a leading provider of comprehensive and patient-centric healthcare solutions in the present healthcare landscape. Our state-of-the-art facilities and highly skilled team of medical professionals are dedicated to delivering superior quality care across a range of specialties, including primary care, specialty consultations, diagnostic services, and preventive health programs.\n\nUtilizing the latest advancements in medical technology and adhering to the highest standards of patient safety, we strive to enhance the overall well-being of our community. Our commitment to personalized and compassionate healthcare sets us apart, ensuring that every individual receives the attention and treatment they deserve.\n\nTo schedule an appointment or learn more about our healthcare services, please visit our website or contact our friendly staff.\n\nServices offered:\n- Primary care\n- Specialty consultations\n- Diagnostic services\n- Preventive health programs\n\nPrices:\n- Primary care: Starting from $100\n- Specialty consultations: Starting from $150\n- Diagnostic services: Starting from $200\n- Preventive health programs: Starting from $50',
  },
  {
    label: 'Education',
    template:
      "[BUSINESS NAME HERE] is a dynamic player in the education industry, committed to revolutionizing learning experiences. Our comprehensive suite of services caters to diverse educational needs, ranging from personalized tutoring programs and interactive e-learning platforms to curriculum development and teacher training.\n\nBy seamlessly integrating technology with pedagogical expertise, we empower educational institutions to enhance student engagement and academic outcomes. Our innovative solutions leverage the latest advancements in educational technology, fostering a collaborative and interactive learning environment.\n\nTo explore our educational services and discuss how we can elevate your institution's teaching and learning practices, please visit our website or contact our knowledgeable team of education experts.\n\nServices offered:\n- Personalized tutoring programs\n- Interactive e-learning platforms\n- Curriculum development\n- Teacher training\n- Educational technology solutions\n\nPrices:\n- Personalized tutoring programs: Starting from $50\n- Interactive e-learning platforms: Starting from $100\n- Curriculum development: Starting from $200\n- Teacher training: Starting from $300\n- Educational technology solutions: Starting from $200",
  },
  {
    label: 'Consulting',
    template:
      "[BUSINESS NAME HERE] is a dynamic and forward-thinking consulting firm that specializes in providing strategic solutions for the ever-evolving landscape of technology and business. Our team of experienced consultants, equipped with cutting-edge industry knowledge, collaborates seamlessly to offer tailored advice and innovative strategies to meet the unique challenges faced by our clients.\n\nFrom digital transformation to process optimization, we guide businesses through the complexities of today's competitive markets. With a commitment to excellence, XYZ Consulting empowers organizations to achieve sustainable growth, maximize operational efficiency, and stay ahead in an era of constant change.\n\nTo discuss your business challenges and explore how our consulting services can propel your organization forward, please visit our website or connect with our expert team.\n\nServices offered:\n- Digital transformation\n- Process optimization\n- Strategic advice\n- Business strategies\n- Operational efficiency\n\nPrices:\n- Digital transformation: Starting from $3000\n- Process optimization: Starting from $2000\n- Strategic advice: Starting from $1000\n- Business strategies: Starting from $2000\n- Operational efficiency: Starting from $1000",
  },
  {
    label: 'Other',
    template:
      '[BUSINESS NAME HERE] is a leading player in the Other business sector, specializing in innovative solutions that cater to diverse client needs. With a dedicated team of experts, ABC Solutions consistently stays ahead of industry trends, offering cutting-edge services and products.\n\nThe company prides itself on its commitment to quality, efficiency, and customer satisfaction. Leveraging state-of-the-art technology and a customer-centric approach, ABC Solutions delivers tailored solutions that address the unique challenges faced by businesses in the contemporary market.\n\nTo learn more about our specialized offerings and discuss how our solutions can drive your business forward, please visit our website or reach out to our enthusiastic team.\n\nServices offered:\n- Innovative solutions\n- Cutting-edge services\n- Tailored solutions\n- Industry trends\n\nPrices:\n- Innovative solutions: Starting from $5000\n- Cutting-edge services: Starting from $2000\n- Tailored solutions: Starting from $1000',
  },
];

export const businessNameTemplates = [
  {
    label: 'HVAC',
    template: 'ClimateCraft Solutions',
  },
  {
    label: 'Beauty',
    template: 'Radiance Haven',
  },
  {
    label: 'Construction',
    template: 'BuildMasters Innovations',
  },
  {
    label: 'Automotive',
    template: 'Precision AutoWorks',
  },
  {
    label: 'Retail',
    template: 'Urban Emporium',
  },
  {
    label: 'Food',
    template: 'Gourmet Delight Bites',
  },
  {
    label: 'Legal',
    template: 'Apex Legal Counsel',
  },
  {
    label: 'Marketing',
    template: 'Impact360 Strategies',
  },
  {
    label: 'Technology',
    template: 'TechInnovate Hub',
  },
  {
    label: 'Entertainment',
    template: 'StarSpark Studios',
  },
  {
    label: 'Real Estate',
    template: 'Prestige Estates Realty',
  },
  {
    label: 'Financial Services',
    template: 'ProsperEdge Financial Services',
  },
  {
    label: 'Nonprofit',
    template: 'Harmony Impact Foundation',
  },
  {
    label: 'Healthcare',
    template: 'Wellness Nexus Care',
  },
  {
    label: 'Education',
    template: 'EnlightenU Academia',
  },
  {
    label: 'Consulting',
    template: 'Insightful Minds Consulting',
  },
  {
    label: 'Other',
    template: 'Diverse Ventures Hub',
  },
];

export const textingWorkflowsTemplates = [
  {
    scenarioDescription:
      'Text the user the store business hours. Execute whenever the user has a question about our hours of operation',
    textMessage:
      'Sure! Here are our business hours:\nMonday to Friday: 9 AM - 6 PM\nSaturday: 10 AM - 4 PM\nSunday: Closed',
    voiceResponse:
      'You can find our business hours in the text I just sent. If you have any more questions, feel free to ask!',
  },
  {
    scenarioDescription:
      'Send the user a link to download our latest product catalog. Execute when the user requests for a product catalog',
    textMessage: "Absolutely! I've just sent you a link to download our latest product catalog.",
    voiceResponse:
      "You'll find the link to download our product catalog in the text message. Let us know if there's anything specific you're looking for.",
  },
  {
    scenarioDescription:
      'Send the user a link to view our pricing details. Execute when the user inquires about pricing',
    textMessage: "Thank you for your interest! I've sent you a link to view our pricing details.",
    voiceResponse:
      'You should receive a text with the pricing information shortly. If you have any questions, feel free to ask when you receive the message.',
  },
];

export const callTransferTemplates = [
  {
    scenarioDescription:
      'Transfer the caller to the technical support department. Execute when the caller request for technical support',
    phoneNumber: '+19876543210',
    voiceResponse: "Certainly! I'll connect you to our technical support team now. Please hold for a moment.",
  },
  {
    scenarioDescription:
      'Transfer the caller to the sales department. Execute when the caller request for information related to sales',
    phoneNumber: '+11223344556',
    voiceResponse:
      "Great choice! I'll transfer you to our sales department for more information. Please wait a moment.",
  },
  {
    scenarioDescription:
      'Transfer the caller to customer service. Execute when the caller request for assistance related to our products or services',
    phoneNumber: '+98765432109',
    voiceResponse: "Sure thing! I'm transferring you to our customer service team now. Please hold on the line.",
  },
];
