import { LabelWithDescription } from '@/components/LabelWithdescription';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Flex, Form, Input, notification, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { PostCallWebhookFormInitialValues } from '@/types/TPostCallForm';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { URL_REGEX } from 'src/constants';
import { User } from '@/types/TAccounts';

interface PostCallWebhookFormProps {
  initialValues?: PostCallWebhookFormInitialValues;
  actionType: 'EDIT' | 'ADD';
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
}

const PostCallWebhookForm: React.FC<PostCallWebhookFormProps> = ({ initialValues, refetch, actionType, mutation }) => {
  const [form] = Form.useForm();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (actionType === 'ADD') {
      form.resetFields();
    }

    if (initialValues && actionType === 'EDIT') {
      form.setFieldsValue(initialValues);
    }

    return () => {
      form.resetFields();
    };
  }, [initialValues, form, actionType]);

  const handleSubmit = async (value: { endpoint: string; method: 'POST' | 'PUT' | 'PATCH' }) => {
    try {
      const updateEndpoint = {
        endpoint: value.endpoint,
        method: value.method,
      };

      await mutation.mutateAsync(updateEndpoint);
      refetch();
      notification.success({
        key: 'form-success',
        message: `Details has been ${actionType === 'ADD' ? 'saved' : 'edited'} successfully!`,
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Something went wrong, please try again later!',
      });
    }
  };

  const FormItems = () => (
    <>
      <Flex key={'postCallWorkflow'} className="mb-2" gap={8}>
        <Card
          className="flex-1"
          title={
            <div className="flex justify-between">
              <span className="font-bold text-base">Webhook</span>
            </div>
          }
        >
          <div>
            <LabelWithDescription
              customClassName="mt-2"
              label="Method"
              description="What type of request would you like to make?"
            />
            <Form.Item
              name={'method'}
              className=" mt-2 pt-2 "
              rules={[{ required: true, message: 'Missing your type' }]}
            >
              <Select
                options={[
                  { value: 'POST', label: 'POST' },
                  { value: 'PUT', label: 'PUT' },
                  { value: 'PATCH', label: 'PATCH' },
                ]}
              />
            </Form.Item>
          </div>
          <div className="border-b">
            <LabelWithDescription
              customClassName="mt-2"
              label="Endpoint"
              description="What is the endpoint you would like to make a request to?"
            />
            <Form.Item
              name={'endpoint'}
              className=" mt-2 pt-2 "
              rules={[
                { required: true, message: 'Missing endpoint' },
                {
                  pattern: URL_REGEX,
                  message: 'Invalid endpoint URL',
                },
              ]}
            >
              <Input placeholder="https://example.com" />
            </Form.Item>
          </div>
        </Card>
      </Flex>
    </>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={actionType === 'EDIT' && initialValues}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  );
};

export default PostCallWebhookForm;
