import tutorialImage1 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-1.png';
import tutorialImage2 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-2.png';
import tutorialImage3 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-3.png';
import tutorialImage4 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-4.png';
import tutorialImage5 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-5.png';
import tutorialImage6 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-6.png';
import tutorialImage7 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-7.png';
import tutorialImage8 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-8.png';
import tutorialImage9 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-9.png';
import tutorialImage10 from '@/assets/images/zapier-whitelabel-tutorial/zapier-whitelabel-tutorial-10.png';

const ZapierIntegrationInstruction = () => {
  return (
    <div>
      <div className="tutorial-content">
        <img
          className="block mx-auto"
          alt=""
          loading="lazy"
          src="https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM.png"
          sizes="(max-width: 479px) 93vw, (max-width: 767px) 92vw, 450px"
          srcSet="
      https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM-p-500.png   500w,
      https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM-p-800.png   800w,
      https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM-p-1080.png 1080w,
      https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM-p-1600.png 1600w,
      https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM-p-2000.png 2000w,
      https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM-p-2600.png 2600w,
      https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d68fe5fcf1188e3a0663_Screenshot%202024-05-27%20at%2011.52.58%20AM.png        2818w
    "
        />
        <div className="rich-text-block-2 w-richtext mt-3">
          <p className="text-xl mb-2">
            <strong>Introduction</strong>
          </p>
          <p>
            You can use Zapier to integrate AI Call Receptionist to 6000+ apps. It takes minutes.
            {/* We can also help you set this up for
                  free
                  <a href="https://www.myaifrontdesk.com/book-a-consultation">
                    here.
                  </a> */}
          </p>
          <br />
          <p>
            This is an AI-powered virtual receptionist that can answer incoming calls for your business. It captures
            valuable call data such as transcripts, caller details, and any information collected during the call. You
            can integrate the receptionist with Zapier to automatically send this call data to other apps and services
            you use, streamlining your workflow and making it easier to manage and act on incoming calls.
          </p>

          <p className="text-xl my-2">
            <strong>Step 1: Enable the Zapier Integration in the receptionist</strong>
          </p>
          <ol className="list-decimal pl-4">
            <li>Log in to your receptionist account and navigate to the "Advanced Settings" section.</li>
            <li>Click on the "Zapier Integration" option.</li>
            <li>
              Copy the provided "Username" and "API Key" values. You'll need these credentials to authenticate the
              integration with Zapier.
            </li>
          </ol>
          <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
            <div>
              <img className="text-center block mx-auto" src={tutorialImage1} loading="lazy" alt="" />
            </div>
          </figure>
          <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
            <div>
              <img
                className="text-center block mx-auto"
                src="https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6654d0f62705e9686f843889_Screenshot%202024-05-27%20at%2011.29.00%20AM.png"
                loading="lazy"
                alt=""
              />
            </div>
          </figure>
          <p className="text-xl my-2">
            <strong>Step 2: Create a Zapier Account</strong>
          </p>
          <ol className="list-decimal pl-4">
            <li>
              Visit{' '}
              <a href="https://zapier.com/developer/public-invite/211016/710e2cf4b2b79c7eec761732f025120d/">
                https://zapier.com/developer/public-invite/211016/710e2cf4b2b79c7eec761732f025120d/
              </a>
              to access our Zapier app.
            </li>
            <li>Click on the "Create a Zapier Account" button if you don't already have one.</li>
            <li>Click "Accept Invite &amp;&nbsp;Build a Zap" button if you are prompted to.</li>
            <li>
              Find the orange "+&nbsp;Create" button on the top-left corner of your screen. Click on it and choose
              "Zap"&nbsp;in the dropdown.
            </li>
            <p className="font-bold text-xl my-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR</p>
            <p>Go to Zaps in the left sidebar and on the extreme right, hit "+&nbsp;Create" -&gt;&nbsp;"New Zap"</p>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4">
              <div>
                <img
                  className="text-center block mx-auto"
                  src="https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6687db6a0ea4a4e659ebc1bc_Screenshot%20from%202024-07-05%2016-39-02.png"
                  loading="lazy"
                  alt=""
                />
              </div>
              <figcaption className="text-center my-3">
                Click the orange "+&nbsp;Create" button and click "Zaps"
              </figcaption>
            </figure>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4">
              <div>
                <img className="text-center block mx-auto" src={tutorialImage2} loading="lazy" alt="" />
              </div>
              <figcaption className="text-center my-3">
                On the right Click the "+ Create" button and choose "New Zap" from the dropdown
              </figcaption>
            </figure>
            <li>Hit on "Trigger" on the next screen</li>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
              <div>
                <img
                  className="text-center block mx-auto"
                  src="https://cdn.prod.website-files.com/646676446cb9dc8974098e5d/6687dcb6d83fad8903c5c526_Screenshot%20from%202024-07-05%2016-44-15.png"
                  loading="lazy"
                  alt=""
                />
              </div>
            </figure>
            <li>Search for "AI Call Receptionist" in the apps and click it</li>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
              <div>
                <img className="text-center block mx-auto" src={tutorialImage3} loading="lazy" alt="" />
              </div>
              <figcaption className="text-center my-3">Start typing "AI Call Receptionist" to find</figcaption>
            </figure>
            <li>A side panel will appear on the right. In the *&nbsp;Event field, choose "New call completed" event</li>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
              <div>
                <img className="text-center block mx-auto" src={tutorialImage4} loading="lazy" alt="" />
              </div>
            </figure>
            <li>
              Click "continue" on the bottom of the panel and you will be redirected to the Account tab. Click the
              "choose"/"change" button button.
            </li>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
              <div>
                <img className="text-center block mx-auto" src={tutorialImage6} loading="lazy" alt="" />
              </div>
            </figure>
            <li>
              Click "+ Connect a new account" from the dropdown if you haven't entered the API key and username from the
              dashboard already. Otherwise, use a previous one.
            </li>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
              <div>
                <img className="text-center block mx-auto" src={tutorialImage5} loading="lazy" alt="" />
              </div>
            </figure>
            <li>
              If you choose to connect a new account, a new window will pop-up. Enter your API key and username which
              you copied earlier and click "Yes, continue to AI Call Receptionist"
            </li>
            <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
              <div>
                <img className="text-center block mx-auto" src={tutorialImage7} loading="lazy" alt="" />
              </div>
            </figure>
          </ol>
          <br />
          <p className="text-xl my-4">
            ‍<strong>Step 3: Test the Integration</strong>
          </p>
          <ol>
            <li>
              After authenticating with your receptoinist credentials, Zapier will wait for a trigger event (an incoming
              call handled by the receptionist).
            </li>
            <li>
              <strong>To test the integration, call the phone number associated with your receptionist account.</strong>
            </li>
            <li>At the end of the call, the receptionist will send the call data to Zapier.</li>
            <li>
              If the integration is set up correctly, Zapier should display a success message indicating that it
              received the call data.
            </li>
          </ol>
          <p>‍</p>
          <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
            <div>
              <img className="text-center block mx-auto" src={tutorialImage8} loading="lazy" alt="" />
            </div>
          </figure>
          <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
            <div>
              <img className="text-center block mx-auto" src={tutorialImage9} loading="lazy" alt="" />
            </div>
          </figure>
          <p>
            <strong>Step 4: Connect our trigger to any other app</strong>
          </p>
          <ol>
            <li>
              Search for and select the app you want to send the call data to (e.g., your CRM, Slack, Google Sheets,
              etc.).
            </li>
            <li>
              Choose the specific action you want to perform in the destination app (e.g., create a new contact, send a
              message, update a spreadsheet row, etc.).
            </li>
            <li>Map the fields from the receptionist call data to the corresponding fields in the destination app.</li>
            <li>Test the integration by making another call to your receptionist number.</li>
            <li>
              If the test is successful, you can turn on the Zap, and it will start automatically sending call data to
              the chosen app whenever a new call is handled by the receptionist.
            </li>
          </ol>
          <p>‍</p>
          <figure className="w-richtext-align-center w-richtext-figure-type-image my-4 block">
            <div>
              <img className="text-center block mx-auto" src={tutorialImage10} loading="lazy" alt="" />
            </div>
          </figure>
          <p>
            <strong>Example Call Data</strong>
          </p>
          <p>Here's an example of the data that receptionist can send to Zapier after a call:</p>
          <p>‍</p>
          <p>
            <code>
              &#10100;
              <br />
              &nbsp;"timestamp": "2024-11-03T16:24:53",
              <br />
              &nbsp;"username": "johndoe",
              <br />
              &nbsp;"caller": "+12132321858",
              <br />
              &nbsp;"transcript": "assistant@ Hi, welcome to John's Barbershop. How may I help you?\nuser@ What types of
              services are provided?\nassistant@ We offer a variety of hair services, including haircuts, beard trims,
              hot towel shaves, and neck cleanups.",
              <br />
              &nbsp;"intake_response": [<br />
              &nbsp; &nbsp;&#10100;
              <br />
              &nbsp; &nbsp; &nbsp;"What is your name?": "John Doe",
              <br />
              &nbsp; &nbsp; &nbsp;"Where are you from?": "San Francisco"
              <br />
              &nbsp; &nbsp;&#10101;
              <br />
              &nbsp;],
              <br />
              &nbsp;"texts_sent": "Here is a link to book your appointment: https://calendly.com/johndoe/20min"
              <br />
              &#10101;
            </code>
          </p>
          <p>‍</p>
          <p>
            This data includes the timestamp of the call, your receptionist username, the caller's phone number, the
            full transcript of the conversation, any information collected during the call via the intake form feature
            (e.g., name, location), and any texts or links sent by the AI assistant.
          </p>
          <p>
            By integrating the receptionist with Zapier, you can automatically push this valuable call data to the apps
            and services you rely on, streamlining your workflow and ensuring that no important call information falls
            through the cracks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ZapierIntegrationInstruction;
