import React, { useState } from 'react';
import { Button, Typography, Upload, message, Card, Table, Alert, Space, Spin, Steps, notification } from 'antd';
import { CloudUploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { parsePhoneNumber } from 'libphonenumber-js';
import { CSVValidatorProps } from '@/pages/Novi/types/Leads';

const { Title, Text } = Typography;
const { Step } = Steps;

interface DataError {
  row: number;
  errors: string[];
}

const CSVValidator: React.FC<CSVValidatorProps> = ({ onClose, onSubmit, requiredColumns, validateRow }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<DataError[]>([]);
  const [missingColumns, setMissingColumns] = useState<string[]>([]);

  const validateData = (data: any[]): { isValid: boolean; errors: DataError[] } => {
    const errors: DataError[] = [];
    let isValid = true;

    data.forEach((row, index) => {
      if (validateRow) {
        const rowValidation = validateRow(row);
        if (!rowValidation.isValid) {
          errors.push({
            row: index + 1,
            errors: rowValidation.errors,
          });
          isValid = false;
        }
      }
    });

    return { isValid, errors };
  };

  const validateColumns = (headers: string[]): string[] => {
    return requiredColumns.filter(col => !headers.includes(col));
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    try {
      const parsed = parsePhoneNumber(phoneNumber, 'US');
      return parsed.format('E.164');
    } catch (error) {
      notification.error({
        message: 'Invalid phone number',
        description: `Invalid phone number format: ${phoneNumber}`,
      });
      return phoneNumber;
    }
  };

  const handleCSVParse = (file: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: results => {
            try {
              const headers = Object.keys(results.data[0] || {});
              const missingCols = validateColumns(headers);
              setMissingColumns(missingCols);

              if (missingCols.length === 0) {
                const { isValid, errors } = validateData(results.data);
                const formattedData = results.data.map((row: any) => ({
                  ...row,
                  phone_number: formatPhoneNumber(row.phone_number),
                }));
                setParsedData(formattedData);
                setErrors(errors);
                if (isValid) {
                  setCurrentStep(1);
                }
              }
              resolve();
            } catch (error) {
              reject(error);
            }
          },
          error: error => {
            reject(error);
          },
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleUpload = async (file: File) => {
    if (file.type !== 'text/csv') {
      message.error('Please upload a valid CSV file.');
      return false;
    }

    setIsLoading(true);
    setSelectedFile(file);

    try {
      await handleCSVParse(file);
    } catch (error) {
      message.error('Failed to parse CSV file. Please make sure it is a valid CSV file.');
    } finally {
      setIsLoading(false);
    }

    return false;
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      message.error('No file selected');
      return;
    }

    try {
      setIsLoading(true);
      await onSubmit(selectedFile);
      message.success('File successfully uploaded!');
      onClose();
    } catch (error) {
      message.error('Failed to submit file.');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadSampleCSV = () => {
    const headers = requiredColumns.join(',');
    const blob = new Blob([headers], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sample.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Title level={2}>Import CSV Data</Title>

      <Steps current={currentStep}>
        <Step title="Upload & Validate" />
        <Step title="Review & Confirm" />
      </Steps>

      {currentStep === 0 && (
        <>
          <Card title="Instructions">
            <Text>Required columns: {requiredColumns.join(', ')}</Text>
            <Button type="link" onClick={downloadSampleCSV}>
              Download Sample CSV
            </Button>
          </Card>

          <Upload.Dragger customRequest={({ file }) => handleUpload(file as File)} showUploadList={false} accept=".csv">
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">Click or drag CSV file to upload</p>
          </Upload.Dragger>

          {isLoading && <Spin />}

          {missingColumns.length > 0 && (
            <Alert
              type="error"
              message="Missing Required Columns"
              description={<Text>The following columns are missing: {missingColumns.join(', ')}</Text>}
            />
          )}

          {errors.length > 0 && (
            <Alert
              type="error"
              message="Validation Errors"
              description={
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>
                      Row {error.row}: {error.errors.join(', ')}
                    </li>
                  ))}
                </ul>
              }
            />
          )}
        </>
      )}

      <Alert
        type="info"
        message={
          <div className="flex items-center gap-2">
            <InfoCircleOutlined className="text-blue-500" />
            <span className="font-medium">Phone Number Format</span>
          </div>
        }
        description={
          <div className="mt-2">
            <p>Phone numbers must be in the following format:</p>
            <ul className="list-disc pl-5 mt-1">
              <li>Include country code with + prefix (e.g., +1 for US/Canada)</li>
              <li>No spaces or special characters</li>
              <li>
                <span className="font-medium">Valid example:</span> +14155552671
              </li>
              <li>
                <span className="font-medium">Invalid examples:</span> 14155552671, (415) 555-2671, 415-555-2671
              </li>
            </ul>
          </div>
        }
      />

      {currentStep === 1 && (
        <>
          <Table
            dataSource={parsedData}
            columns={requiredColumns.map(col => ({
              title: col,
              dataIndex: col,
              key: col,
            }))}
            pagination={{ pageSize: 5 }}
            scroll={{ x: true }}
          />

          <Space>
            <Button onClick={() => setCurrentStep(0)}>Back</Button>
            <Button type="primary" onClick={handleSubmit} loading={isLoading}>
              Confirm Import
            </Button>
          </Space>
        </>
      )}
    </Space>
  );
};

export default CSVValidator;
