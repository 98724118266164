import React from 'react';
import { VoiceAndLanguageForm } from './VoiceAndLanguageForm';
import { OverViewCardInfo } from '../OverviewCardInfo';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import './VoiceAndLanguageInfo.scss';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { MODAL_IDS } from 'src/enums/EModal';
import { User } from '@/types/TAccounts';
import { TUTORIALS } from 'src/enums/ETutorials';

export const VoiceAndLanguageInfo: React.FC<{
  data: any;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}> = ({ data, refetch }) => {
  const baseClass = 'voice-and-language-info';

  return (
    <OverViewCardInfo
      formActionType='Edit'
      formTitle='Select Languages'
      info={
        <>
          <div className={`${baseClass}__item`}>
            <LabelWithDescription label='Languages' />
            <strong>{data.selected_languages ? JSON.parse(data.selected_languages).join(', ') : ''}</strong>
          </div>
        </>
      }
      form={
        <VoiceAndLanguageForm
          refetch={refetch}
          formInitialValues={{
            voice_selected: (data.voice_selected && JSON.parse(data.voice_selected)?.voiceId) ?? '',
            selected_languages: (data.selected_languages && JSON.parse(data.selected_languages)) ?? [],
          }}
          data={data}
        />
      }
      footer={null}
      modalId={MODAL_IDS.CUSTOMIZE_VOICE_AND_LANGUAGES_MODAL}
      tutorialId={TUTORIALS.NON_ENGLISH_LANGUAGE_SUPPORT}
      displayTutorialActionBtn={true}
    />
  );
};
