import React from 'react';
import { Alert, Button, Form, Input, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { OverViewCardForm } from '../../Cards/OverviewCardForm';
import { DeleteOutline } from '@mui/icons-material';
import { InfoTypeScenario } from '@/interfaces/INotifications';

interface props {
  open: boolean;
  setOpen: (val: boolean) => void;
  scenarios: InfoTypeScenario[];
  updateUser: (val: string) => void;
  setCategories: (val: InfoTypeScenario[]) => void;
}

const AddScenarios: React.FC<props> = ({ open, setOpen, scenarios, updateUser, setCategories }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: { scenarios: InfoTypeScenario[] }) => {
    const workflow_notif_settings: InfoTypeScenario[] = values.scenarios.map(item => {
      return {
        scenario: item.scenario,
        emails: item.emails || [],
        phones: item.phones || [],
        email: item.email || false,
        sms: item.sms || false,
        voicemail: item.voicemail || false,
      };
    });
    setCategories(workflow_notif_settings);
    updateUser(JSON.stringify(workflow_notif_settings));
  };

  const FormItems = () => (
    <div>
      <Alert
        className='my-4'
        type='info'
        closable={false}
        message={
          <div className='flex flex-col text-xs'>
            Define scenarios using plain english to configure customized, post-call notifications. After a call, our AI
            will analyze the call transcript. If any of your scenarios are met, the relevant party will receive a
            notification via email.
          </div>
        }
      />

      <Form.List name='scenarios' initialValue={[]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className='flex flex-row gap-2 items-center'>
                <Form.Item
                  className='flex-1'
                  name={[name, 'scenario']}
                  rules={[{ required: true, message: 'Missing scenario' }]}
                  label='Scenario'
                >
                  <Input.TextArea
                    rows={2}
                    placeholder='e.g. If the caller mentions anything related to billing, refunds, or payments'
                  />
                </Form.Item>

                {fields.length > 1 && (
                  <DeleteOutline
                    fontSize='medium'
                    onClick={() => remove(name)}
                    className='text-red-600 cursor-pointer mt-2'
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                Add Scenario
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );

  return (
    <Modal
      title={<span className='text-lg'>Add Scenario</span>}
      open={open}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      width={600}
      footer={null}
    >
      <OverViewCardForm
        form={form}
        initialValues={{
          scenarios,
        }}
        formItems={<FormItems />}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default AddScenarios;
