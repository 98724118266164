const ECountryToCurrency: Record<string, string> = {
  AE: 'AED', // United Arab Emirates Dirham
  AF: 'AFN', // Afghan Afghani
  AL: 'ALL', // Albanian Lek
  AM: 'AMD', // Armenian Dram
  AN: 'ANG', // Netherlands Antillean Guilder
  AO: 'AOA', // Angolan Kwanza
  AR: 'ARS', // Argentine Peso
  AU: 'AUD', // Australian Dollar
  AW: 'AWG', // Aruban Florin
  AZ: 'AZN', // Azerbaijani Manat
  BA: 'BAM', // Bosnia and Herzegovina Convertible Mark
  BB: 'BBD', // Barbadian Dollar
  BD: 'BDT', // Bangladeshi Taka
  BG: 'BGN', // Bulgarian Lev
  BH: 'BHD', // Bahraini Dinar
  BI: 'BIF', // Burundian Franc
  BM: 'BMD', // Bermudian Dollar
  BN: 'BND', // Brunei Dollar
  BO: 'BOB', // Bolivian Boliviano
  BR: 'BRL', // Brazilian Real
  BS: 'BSD', // Bahamian Dollar
  BT: 'BTN', // Bhutanese Ngultrum
  BW: 'BWP', // Botswanan Pula
  BY: 'BYN', // Belarusian Ruble
  BZ: 'BZD', // Belize Dollar
  CA: 'CAD', // Canadian Dollar
  CD: 'CDF', // Congolese Franc
  CH: 'CHF', // Swiss Franc
  CL: 'CLP', // Chilean Peso
  CN: 'CNY', // Chinese Yuan
  CO: 'COP', // Colombian Peso
  CR: 'CRC', // Costa Rican Colón
  CU: 'CUP', // Cuban Peso
  CV: 'CVE', // Cape Verdean Escudo
  CZ: 'CZK', // Czech Koruna
  DJ: 'DJF', // Djiboutian Franc
  DK: 'DKK', // Danish Krone
  DO: 'DOP', // Dominican Peso
  DZ: 'DZD', // Algerian Dinar
  EG: 'EGP', // Egyptian Pound
  ER: 'ERN', // Eritrean Nakfa
  ET: 'ETB', // Ethiopian Birr
  EU: 'EUR', // Euro
  FJ: 'FJD', // Fijian Dollar
  FK: 'FKP', // Falkland Islands Pound
  FO: 'FOK', // Faroese Króna
  GB: 'GBP', // British Pound Sterling
  GE: 'GEL', // Georgian Lari
  GG: 'GGP', // Guernsey Pound
  GH: 'GHS', // Ghanaian Cedi
  GI: 'GIP', // Gibraltar Pound
  GM: 'GMD', // Gambian Dalasi
  GN: 'GNF', // Guinean Franc
  GT: 'GTQ', // Guatemalan Quetzal
  GY: 'GYD', // Guyanaese Dollar
  HK: 'HKD', // Hong Kong Dollar
  HN: 'HNL', // Honduran Lempira
  HR: 'HRK', // Croatian Kuna
  HT: 'HTG', // Haitian Gourde
  HU: 'HUF', // Hungarian Forint
  ID: 'IDR', // Indonesian Rupiah
  IL: 'ILS', // Israeli New Shekel
  IM: 'IMP', // Isle of Man Pound
  IN: 'INR', // Indian Rupee
  IQ: 'IQD', // Iraqi Dinar
  IR: 'IRR', // Iranian Rial
  IS: 'ISK', // Icelandic Króna
  JE: 'JEP', // Jersey Pound
  JM: 'JMD', // Jamaican Dollar
  JO: 'JOD', // Jordanian Dinar
  JP: 'JPY', // Japanese Yen
  KE: 'KES', // Kenyan Shilling
  KG: 'KGS', // Kyrgyzstani Som
  KH: 'KHR', // Cambodian Riel
  KI: 'KID', // Kiribati Dollar
  KM: 'KMF', // Comorian Franc
  KR: 'KRW', // South Korean Won
  KW: 'KWD', // Kuwaiti Dinar
  KY: 'KYD', // Cayman Islands Dollar
  KZ: 'KZT', // Kazakhstani Tenge
  LA: 'LAK', // Laotian Kip
  LB: 'LBP', // Lebanese Pound
  LK: 'LKR', // Sri Lankan Rupee
  LR: 'LRD', // Liberian Dollar
  LS: 'LSL', // Lesotho Loti
  LY: 'LYD', // Libyan Dinar
  MA: 'MAD', // Moroccan Dirham
  MD: 'MDL', // Moldovan Leu
  MG: 'MGA', // Malagasy Ariary
  MK: 'MKD', // Macedonian Denar
  MM: 'MMK', // Myanmar Kyat
  MN: 'MNT', // Mongolian Tugrik
  MO: 'MOP', // Macanese Pataca
  MR: 'MRU', // Mauritanian Ouguiya
  MU: 'MUR', // Mauritian Rupee
  MV: 'MVR', // Maldivian Rufiyaa
  MW: 'MWK', // Malawian Kwacha
  MX: 'MXN', // Mexican Peso
  MY: 'MYR', // Malaysian Ringgit
  MZ: 'MZN', // Mozambican Metical
  NA: 'NAD', // Namibian Dollar
  NG: 'NGN', // Nigerian Naira
  NI: 'NIO', // Nicaraguan Córdoba
  NO: 'NOK', // Norwegian Krone
  NP: 'NPR', // Nepalese Rupee
  NZ: 'NZD', // New Zealand Dollar
  OM: 'OMR', // Omani Rial
  PA: 'PAB', // Panamanian Balboa
  PE: 'PEN', // Peruvian Nuevo Sol
  PG: 'PGK', // Papua New Guinean Kina
  PH: 'PHP', // Philippine Peso
  PK: 'PKR', // Pakistani Rupee
  PL: 'PLN', // Polish Zloty
  PY: 'PYG', // Paraguayan Guarani
  QA: 'QAR', // Qatari Rial
  RO: 'RON', // Romanian Leu
  RS: 'RSD', // Serbian Dinar
  RU: 'RUB', // Russian Ruble
  RW: 'RWF', // Rwandan Franc
  SA: 'SAR', // Saudi Riyal
  SB: 'SBD', // Solomon Islands Dollar
  SC: 'SCR', // Seychellois Rupee
  SD: 'SDG', // Sudanese Pound
  SE: 'SEK', // Swedish Krona
  SG: 'SGD', // Singapore Dollar
  SH: 'SHP', // Saint Helena Pound
  SL: 'SLE', // Sierra Leonean Leone
  SN: 'SLL', // Sierra Leonean Leone
  SO: 'SOS', // Somali Shilling
  SR: 'SRD', // Surinamese Dollar
  SS: 'SSP', // South Sudanese Pound
  ST: 'STN', // São Tomé and Príncipe Dobra
  SY: 'SYP', // Syrian Pound
  SZ: 'SZL', // Swazi Lilangeni
  TH: 'THB', // Thai Baht
  TJ: 'TJS', // Tajikistani Somoni
  TM: 'TMT', // Turkmenistani Manat
  TN: 'TND', // Tunisian Dinar
  TO: 'TOP', // Tongan Paʻanga
  TR: 'TRY', // Turkish Lira
  TT: 'TTD', // Trinidad and Tobago Dollar
  TV: 'TVD', // Tuvaluan Dollar
  TW: 'TWD', // Taiwanese Dollar
  TZ: 'TZS', // Tanzanian Shilling
  UA: 'UAH', // Ukrainian Hryvnia
  UG: 'UGX', // Ugandan Shilling
  US: 'USD', // United States Dollar
  UY: 'UYU', // Uruguayan Peso
  UZ: 'UZS', // Uzbekistani Som
  VE: 'VES', // Venezuelan Bolívar
  VN: 'VND', // Vietnamese Đồng
  VU: 'VUV', // Vanuatu Vatu
  WS: 'WST', // Samoan Tala
  XAF: 'XAF', // Central African CFA Franc
  XCD: 'XCD', // East Caribbean Dollar
  XDR: 'XDR', // International Monetary Fund (IMF) Special Drawing Rights
  XOF: 'XOF', // West African CFA Franc
  XPF: 'XPF', // CFP Franc
  YE: 'YER', // Yemeni Rial
  ZA: 'ZAR', // South African Rand
  ZM: 'ZMW', // Zambian Kwacha
  ZW: 'ZWL', // Zimbabwean Dollar
};

export default ECountryToCurrency;
