// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { EditIcon, TrashIcon, SaveIcon, XIcon } from 'lucide-react';
import { Button, Card, Form, Input, List, notification, Typography } from 'antd';
import { useDeleteNote, useUpdateLeadNotes } from 'src/Hooks/UseHookForCrmData';

interface Note {
  id: number;
  timestamp: string;
  content: string;
}

interface NotesCardProps {
  selectedLead: {
    username: string;
    lead_id: number;
    notes: string;
  };
  onNotesUpdate?: (notes: string) => void;
}

const NotesCard: React.FC<NotesCardProps> = ({ selectedLead, onNotesUpdate }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [editingNoteId, setEditingNoteId] = useState<number | null>(null);
  const [form] = Form.useForm();

  const { mutate: addNewNotesApi } = useUpdateLeadNotes();
  const { mutate: updateLeadaNotesApi } = useUpdateLeadNotes();
  const { mutate: deleteNoteApi } = useDeleteNote();

  useEffect(() => {
    if (selectedLead.notes) {
      try {
        setNotes(selectedLead.notes);
      } catch (error) {
        console.error('Error parsing notes:', error);
        setNotes([]);
      }
    }
  }, [selectedLead.notes]);

  const handleAddNote = (values: { notes: string }) => {
    if (values.notes.trim()) {
      const newNoteObj: Note = {
        id: Date.now(),
        timestamp: new Date().toISOString(),
        content: values.notes.trim(),
      };

      const updatedNotes = [...notes, newNoteObj];
      setNotes(updatedNotes);

      try {
        const updatingDataNotest = {
          notes: JSON.stringify(updatedNotes),
          username: selectedLead.username,
          lead_id: selectedLead.lead_id,
        };

        addNewNotesApi(updatingDataNotest, {
          onError: error => {
            console.error('Error saving note:', error);
          },
        });
      } catch (error) {
        console.error('Error saving note:', error);
      }

      form.resetFields();
    }
  };

  const handleEditNote = (noteId: number, content: string) => {
    setEditingNoteId(noteId);
    form.setFieldsValue({ notes: content });
  };

  const handleCancelEdit = () => {
    setEditingNoteId(null);
    form.resetFields();
  };

  const handleUpdateNote = (values: { notes: string }) => {
    if (values.notes.trim() && editingNoteId) {
      const updatedNote: Note = {
        id: editingNoteId,
        timestamp: new Date().toISOString(),
        content: values.notes.trim(),
      };

      const updatedNotes = notes.map(note => (note.id === editingNoteId ? updatedNote : note));
      const updatingDataNotestforupdate = {
        notes: JSON.stringify(updatedNotes),
        username: selectedLead.username,
        lead_id: selectedLead.lead_id,
      };
      setNotes(updatedNotes);

      updateLeadaNotesApi(updatingDataNotestforupdate, {
        onSuccess: () => {
          setNotes(updatedNotes);
          setEditingNoteId(null);
        },
        onError: error => {
          console.error('Error updating note:', error);
        },
      });

      setEditingNoteId(null);
      form.resetFields();
    }
  };

  const handleDeleteNote = (noteId: number) => {
    try {
      const updatedNotes = notes.filter(note => note.id !== noteId);
      const updatingDataNotest = {
        notes: JSON.stringify(updatedNotes),
        note_id: noteId,
        username: selectedLead.username,
        lead_id: selectedLead.lead_id,
      };
      deleteNoteApi(updatingDataNotest, {
        onSuccess: () => {
          setNotes(updatedNotes);
        },
        onError: error => {
          console.error('Error deleting note:', error);
        },
      });
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  return (
    <Card className='w-full' title='Notes'>
      <div className='space-y-4'>
        {notes.length > 0 && (
          <div className='border rounded-lg p-4'>
            <List className='space-y-4'>
              {notes.map(note => (
                <div key={note.id} className='flex flex-col space-y-2 p-4 border rounded-lg bg-gray-50'>
                  <div className='flex justify-between items-start'>
                    <Typography className='text-sm text-gray-500'>
                      {new Date(note.timestamp).toLocaleString()}
                    </Typography>
                    <div className='flex space-x-2'>
                      <Button onClick={() => handleEditNote(note.id, note.content)}>
                        <EditIcon className='h-4 w-4' />
                      </Button>
                      <Button onClick={() => handleDeleteNote(note.id)}>
                        <TrashIcon className='h-4 w-4 text-red-500' />
                      </Button>
                    </div>
                  </div>
                  <Typography>{note.content}</Typography>
                </div>
              ))}
            </List>
          </div>
        )}

        <div className='border rounded-lg p-4'>
          <Typography className='text-lg font-semibold mb-4'>{editingNoteId ? 'Edit Note' : 'Add New Note'}</Typography>
          <Form form={form} layout='vertical' onFinish={editingNoteId ? handleUpdateNote : handleAddNote}>
            <Form.Item name='notes'>
              <Input.TextArea rows={4} className='w-full p-2 border rounded-md' placeholder='Enter your note here...' />
            </Form.Item>
            <div className='flex space-x-2'>
              <Button className='bg-blue-500 text-white' type='primary' htmlType='submit'>
                {editingNoteId ? <SaveIcon className='h-4 w-4 mr-2' /> : null}
                {editingNoteId ? 'Update Note' : 'Add Note'}
              </Button>
              {editingNoteId && (
                <Button onClick={handleCancelEdit} className='border-gray-300'>
                  <XIcon className='h-4 w-4 mr-2' />
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Card>
  );
};

export default NotesCard;
