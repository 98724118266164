import axios from 'axios';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { prod } from './config';
import { useSearchParams } from 'react-router-dom';

import RegisterPageV3 from './RegisterPageV3';
import { CircularProgress } from '@material-ui/core';
var serverURL = prod ? 'https://myaifrontdeskadmin.com' : 'http://localhost:8080';
const RegisterPageV3Wrapper = ({ test = false }) => {
  const [resellerInfo, setResellerInfo] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && resellerInfo?.reseller_name) {
      try {
        // Update the document title (tab title)
        document.title = `${resellerInfo.reseller_name} Portal Login`;

        // Update the meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', `${resellerInfo.reseller_name} Dashboard/Portal login`);
        }

        // If you still need to update an element with id 'page-title'
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
          pageTitle.textContent = `${resellerInfo.reseller_name} Portal Login`;
        }
      } catch (err) {
        console.error('Error updating page metadata:', err);
      }
    }
  }, [resellerInfo?.reseller_name]);

  useEffect(() => {
    async function fetchReseller(resellerCode) {
      setLoading(true);
      try {
        const response = await axios.post(serverURL + '/get-reseller-from-reseller-code', {
          resellerCode,
        });

        if (response?.data?.reseller?.logo_s3) {
          fetch(`${serverURL}/download_reseller_logo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              key: response?.data?.reseller?.logo_s3,
            }),
          })
            .then(response => response.blob())
            .then(blob => {
              const objectUrl = URL.createObjectURL(blob);
              setImageUrl(objectUrl);
            })
            .catch(error => {
              console.error(error);
              setLoading(false);
            });
        }

        setResellerInfo(response.data.reseller);
        console.log('reseller info', JSON.stringify(response.data.reseller));
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'something went wrong',
        });
      } finally {
        setLoading(false);
      }
    }

    const resellerCode = searchParams.get('reseller_code') || searchParams.get('code');
    if (resellerCode) fetchReseller(resellerCode);
  }, [searchParams]);

  if (loading) {
    return (
      <div className='flex w-screen h-screen items-center justify-center'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {/* <Toaster position="top-center" reverseOrder={false} /> */}

      {(resellerInfo && (resellerInfo?.unreservedReceptionists > 0 || resellerInfo?.auto_scale)) || test ? (
        <RegisterPageV3
          debrand
          reseller_email={resellerInfo.reseller_email}
          purchase_quotes={resellerInfo.purchase_quotes}
          reseller_name={resellerInfo.reseller_name}
          logo_s3={imageUrl}
          logoDimensions={resellerInfo.logoDimensions}
          reroute_to_unbranded_login={true}
          auto_scale={resellerInfo.auto_scale}
          unreservedReceptionists={resellerInfo.unreservedReceptionists}
          test={test}
        />
      ) : (
        <h1> SOLD OUT. Please contact support to create a receptionist. </h1>
      )}
    </div>
  );
};

export default RegisterPageV3Wrapper;
