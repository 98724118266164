import { LinkOutlined } from '@ant-design/icons';
import { Button, Card, Col, notification, Row, Switch } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ResellerDashboardLink, UpdateAutoScaleReceptionist } from '@/api/reseller';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { updateReseller } from '@/store/reseller/slice';
import { useRef } from 'react';
import ResellerTour from '../ResellerTour/ResellerTour';
import { AppRoutes } from 'src/enums/ERoutes';

const ResellerEmbed = () => {
  const dispatch = useAppDispatch();
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const currentReseller = useAppSelector(getResellerData);
  const { data } = useQuery({
    queryKey: ['getResellerDashboardLink', currentReseller.email_address],
    queryFn: () => ResellerDashboardLink({ email: currentReseller.email_address }),
  });

  const updateAutoScaleReceptionistMutation = useMutation({
    mutationKey: ['UpdateAutoScaleReceptionist'],
    mutationFn: (newAutoScaleValue: boolean) =>
      UpdateAutoScaleReceptionist({
        reseller_email: currentReseller.email_address,
        auto_scale: newAutoScaleValue,
        customerId: currentReseller.customerId,
      }),
    onSuccess: () => {
      notification.success({
        key: 'update-auto-scale',
        message: 'Auto scale updated',
        duration: 3,
      });
    },
  });

  const handleCopyUrl = async (route: string) => {
    if (data?.link) {
      try {
        await navigator.clipboard.writeText(`${import.meta.env.VITE_APP_WIHTELABEL_URL}${route}${data.link}`);
        notification.success({ message: 'Link copied successfully' });
      } catch {
        notification.error({ message: 'Failed to copy link' });
      }
    } else {
      notification.error({ message: 'No link available to copy' });
    }
  };

  const handleAutoScaleChange = (checked: boolean) => {
    //@ts-ignore
    dispatch(updateReseller({ auto_scale_receptionists: checked }));
    updateAutoScaleReceptionistMutation.mutate(checked);
  };

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.EMBED}>
      <div className="h-full p-6">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} ref={ref1}>
            <Card title="Embedding the Client Dashboard" className="h-full flex flex-col shadow-lg">
              <div className="flex-grow">
                <p className="text-justify mb-4 min-h-[66px]">
                  Embedding the Client Dashboard Click below to access the link to your unbranded client dashboard.
                  Embed this into your own website via iframe. Your clients can login here to view call logs and edit
                  their receptionist anytime.
                </p>
              </div>
              <div className="mt-auto flex justify-end items-center">
                <Button type="primary" icon={<LinkOutlined />} onClick={() => handleCopyUrl('/dashboard?code=')}>
                  Copy URL
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} md={12} ref={ref2}>
            <Card title="Embedding the Registration Form" className="h-full flex flex-col shadow-lg">
              <div className="flex-grow">
                <p className="text-justify mb-4 min-h-[66px]">
                  Click below to access the link to your unbranded client dashboard. Embed this into your own website
                  via iframe. We recommend putting this form behind a paywall on your own website, since anyone that
                  creates a receptionist via this form will consume one of your reserved receptionists.
                </p>
              </div>
              <div className="mt-auto flex flex-wrap justify-between items-center">
                <div className="flex items-center gap-2">
                  <span className="text-[#00000073]">Autoscale Registration</span>
                  <Switch
                    size="small"
                    loading={updateAutoScaleReceptionistMutation.isPending}
                    onChange={handleAutoScaleChange}
                    checked={currentReseller.auto_scale_receptionists}
                  />
                </div>
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  onClick={() => handleCopyUrl('/register-receptionist?code=')}
                >
                  Copy URL
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="pt-8">
          <Col xs={24} md={12}>
            <Card title="Embedding the Trial Registration Form" className="h-full flex flex-col shadow-lg">
              <div className="flex-grow">
                <p className="text-justify mb-4 min-h-[66px]">
                  Click below to access the link to your unbranded Test client dashboard. Embed this into your own
                  website via iframe.
                </p>
              </div>
              <div className="mt-auto flex flex-wrap justify-between items-center">
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  onClick={() => handleCopyUrl(AppRoutes.UNBRANDED_CLIENT_TRIAL + '?code=')}
                >
                  Copy URL
                </Button>
              </div>
            </Card>
          </Col>
        </Row>

        <ResellerTour whitelabeledClientDashboardRef={ref1} whitelabeledClientRegistrationRef={ref2} />
      </div>
    </ResellerDashboard>
  );
};

export default ResellerEmbed;
