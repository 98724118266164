import React from 'react';
import { Form } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { handlePrefixPlusOnPhone, isValidPhone } from '@/utils/helper';

const PhoneNumberInput: React.FC<{ name: string | number | (string | number)[] }> = ({ name }) => {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          validator: async (_, value) => {
            if (!value) {
              return Promise.reject(new Error('Phone number is required'));
            }
            const isValid = isValidPhone(handlePrefixPlusOnPhone(value));
            if (!isValid) {
              return Promise.reject(new Error('Invalid phone number'));
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <PhoneInput
        placeholder="Enter Phone Number"
        country={'us'}
        preferredCountries={['us', 'ca', 'gb']}
        autoFormat={true}
        containerStyle={{
          width: '100%',
          height: '30px',
        }}
        inputStyle={{
          width: '100%',
          height: '30px',
          fontSize: '14px',
        }}
        inputClass="font-medium"
      />
    </Form.Item>
  );
};

export default PhoneNumberInput;
