import React from 'react';
import { Button } from 'antd';
import { OpenInNew } from '@mui/icons-material';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';

const LearnMore: React.FC<{ tutorialId: string | undefined }> = ({ tutorialId }) => {
  const loggedInUser = useAppSelector(getLoggedInUser);

  const handleTutorialClick = () => {
    if (!tutorialId) return;
    // open the corresponding OpenInNew in a new tab
    window.open(tutorialId, '_blank');
  };

  return (
    !loggedInUser.resellerClient && (
      <Button type='primary' onClick={handleTutorialClick} icon={<OpenInNew />} className='item-edit'>
        Learn More
      </Button>
    )
  );
};

export default LearnMore;
