import { useEffect, useState } from 'react';
import { Button, Card, Tooltip } from 'antd';
import './Home.scss';
import {
  HelpOutline,
  CallOutlined,
  VoicemailOutlined,
  FeedOutlined,
  RedeemOutlined,
  PersonOutlineOutlined,
  NotificationsOutlined,
} from '@mui/icons-material';
import { getLoggedInUser } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { useQuery } from '@tanstack/react-query';
import { getProvisionedNumber } from '@/api/reservedNumbers';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setActiveConfigureTab, setCurrentPage } from '@/store/account/slice';
import { getCallLogs, getTextLogs, getVoiceMailLogs } from '@/api/logs';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import { ReceptionistDashboardConfigureTabs } from 'src/enums/EReceptionistDashboardConfigureTabs';
import { changeToCurrency } from '@/utils/currencyHelper';

const Home = () => {
  const loggedUser = useAppSelector(getLoggedInUser);
  const dispatch = useAppDispatch();
  const isCampaign = false;
  const debrand = loggedUser.resellerClient;

  const [revenueAmount, setRevenueAmount] = useState('$0');

  const { data: voiceMailLogsData } = useQuery({
    queryKey: ['getVoiceMailLogs'],
    queryFn: getVoiceMailLogs,
  });

  const { data: textLogsData } = useQuery({
    queryKey: ['getTextLogs'],
    queryFn: getTextLogs,
  });

  const { data: provisionedNumber } = useQuery({
    queryKey: ['getProvisionedNumbers'],
    queryFn: () => getProvisionedNumber(),
  });

  const { data: callLogsData } = useQuery({
    queryKey: ['getCallLogs'],
    queryFn: () => getCallLogs({ limit: 50 }),
  });

  const handleViewDetails = (key: ReceptionistDashboardSiderItem) => {
    dispatch(setCurrentPage(key));
  };
  const handleNotificationViewDetails = () => {
    dispatch(setCurrentPage(ReceptionistDashboardSiderItem.CONFIGURE));
    dispatch(setActiveConfigureTab(ReceptionistDashboardConfigureTabs.MANAGE_NOTIFICATIONS));
  };

  useEffect(() => {
    const fetchRevenue = async () => {
      const amountInUSD = (textLogsData?.textLogs?.length || 0) * 30;
      const phone = provisionedNumber?.provisioned_number || '';
      const amount = await changeToCurrency({ amountInUSD, phone });
      setRevenueAmount(amount);
    };

    fetchRevenue();
  }, [textLogsData?.textLogs?.length, provisionedNumber?.provisioned_number]);

  const baseClass = 'home-container';
  return (
    <div className={`${baseClass}`}>
      <Card className='shadow-xl text-center'>
        <h1 className='text-4xl mb-6 font-bold text-indigo-500'>Welcome back, {loggedUser.business_name}.</h1>
        <p className='text-xl'>
          This is the back-office dashboard for your AI {isCampaign ? 'Campaign' : 'Receptionist'}.
          {provisionedNumber &&
            `Your ${isCampaign ? 'Campaign' : 'Receptionist'}'s number is ` +
              provisionedNumber.provisioned_number +
              ' .'}
          Use the sidebar or buttons below to navigate.
        </p>
      </Card>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 pt-8'>
        <Card
          className='card-container bg-[#1677ff] text-center'
          extra={
            <Tooltip title='Based on an estimate of 7 minutes saved per call answered.' className='cursor-pointer'>
              <HelpOutline className='text-white mt-1' />
            </Tooltip>
          }
        >
          <h1 className='text-4xl mb-2 font-bold text-white'>
            {callLogsData?.callLogs && callLogsData?.callLogs.length > 60
              ? `${Math.floor((callLogsData?.callLogs.length * 7) / 60)} Hours ${(callLogsData?.callLogs.length * 7) % 60} Minutes`
              : callLogsData?.callLogs
                ? `${callLogsData?.callLogs.length * 7} Minutes`
                : '0 Minutes'}
          </h1>
          <span className='text-lg text-white'>Estimated Time Saved {debrand ? '' : 'from My AI Front Desk'}.</span>
        </Card>
        <Card
          className='card-container bg-[#52c41a] text-center'
          extra={
            <Tooltip title='Based on an estimate of a $30 opportunity per text sent.' className='cursor-pointer'>
              <HelpOutline className='text-white mt-1' />
            </Tooltip>
          }
        >
          <h1 className='text-4xl mb-2 font-bold text-white'>{revenueAmount}</h1>
          <span className='text-lg text-white'>Estimated Revenue {debrand ? '' : 'from My AI Front Desk'}.</span>
        </Card>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-8'>
        <Card className='shadow-xl '>
          <div>
            <CallOutlined />
            <span className='text-base ml-2'>{callLogsData?.callLogs && callLogsData?.callLogs.length}Calls</span>
          </div>
          <span className='flex-grow'>
            {debrand ? (
              <p className='text-justify mb-4 min-h-[66px]'>
                Your AI {isCampaign ? 'Campaign' : 'Receptionist'} has answered{' '}
                {callLogsData?.callLogs && callLogsData?.callLogs.length} of your calls so far.
              </p>
            ) : (
              <p className='text-justify mb-4 min-h-[66px]'>
                My AI Front Desk has answered {callLogsData?.callLogs && callLogsData?.callLogs.length} of your calls so
                far.
              </p>
            )}
          </span>
          <div className='mt-auto flex flex-wrap justify-end items-center'>
            <Button type='primary' onClick={() => handleViewDetails(ReceptionistDashboardSiderItem.CALL_LOGS)}>
              View Details
            </Button>
          </div>
        </Card>
        <Card className='shadow-xl'>
          <div>
            <VoicemailOutlined />
            <span className='text-base ml-2'>{voiceMailLogsData?.voiceMailLogs?.length} Voicemails</span>
          </div>
          <p className='text-justify mb-4 min-h-[66px]'>
            You have a total of {voiceMailLogsData?.voiceMailLogs?.length} voicemails left for you.
          </p>
          <div className='mt-auto flex justify-end items-center'>
            <Button type='primary' onClick={() => handleViewDetails(ReceptionistDashboardSiderItem.VOICE_MAIL_LOGS)}>
              View Details
            </Button>
          </div>
        </Card>
        <Card className='shadow-xl'>
          <div>
            <FeedOutlined />
            <span className='text-base ml-2'>{textLogsData?.textLogs?.length} Texts</span>
          </div>
          <span className='flex-grow'>
            {debrand ? (
              <p className='text-justify mb-4 min-h-[66px]'>
                Your AI {isCampaign ? 'Campaign' : 'Receptionist'} has sent out {textLogsData?.textLogs?.length} text
                messages for you.
              </p>
            ) : (
              <p className='text-justify mb-4 min-h-[66px]'>
                My AI Front Desk has sent out {textLogsData?.textLogs?.length} text messages for you.
              </p>
            )}
          </span>
          <div className='mt-auto flex justify-end items-center'>
            <Button type='primary' onClick={() => handleViewDetails(ReceptionistDashboardSiderItem.TEXT_LOGS)}>
              View Details
            </Button>
          </div>
        </Card>
        <Card className='shadow-xl'>
          <div>
            <RedeemOutlined />
            <span className='text-base ml-2'>Free Month</span>
          </div>
          <p className='text-justify mb-4 min-h-[66px]'>
            Invite a friend and you both get a free month. Limited time Promo.
          </p>
          <div className='mt-auto flex justify-end items-center'>
            <Button type='primary'>View Details</Button>
          </div>
        </Card>
        <Card className='shadow-xl'>
          <div>
            <PersonOutlineOutlined />
            <span className='text-base ml-2'>Edit {isCampaign ? 'Campaign' : 'Receptionist'}</span>
          </div>
          <p className='text-justify mb-4 min-h-[66px]'>
            Modify your {isCampaign ? 'Campaign' : 'Receptionist'} instantly.
          </p>
          <div className='mt-auto flex justify-end items-center'>
            <Button type='primary' onClick={() => handleViewDetails(ReceptionistDashboardSiderItem.CONFIGURE)}>
              View Details
            </Button>
          </div>
        </Card>
        <Card className='shadow-xl'>
          <div>
            <NotificationsOutlined />
            <span className='text-base ml-2'>Notifications</span>
          </div>
          <p className='text-justify mb-4 min-h-[66px]'>Set up notifications for calls, texts, and voicemails.</p>
          <div className='mt-auto flex justify-end items-center'>
            <Button type='primary' onClick={handleNotificationViewDetails}>
              View Details
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Home;
