import { Form, Input } from 'antd';

const AccountSettingForm: React.FC<{ initialValues: { business_name: string } }> = ({ initialValues }) => {
  return (
    <Form.Item label="Business Name" name="business_name">
      <Input placeholder="Please enter your business name" defaultValue={initialValues.business_name} />
    </Form.Item>
  );
};

export default AccountSettingForm;
