import apiClient from '@/utils/apiClient';

const CHECK_RESELLER_EMAIL = '/api/reseller/hubspot-get-contact';
const SEND_RESPONSE_EMAIL = '/api/reseller/survey-response';

export const checkResellerEmail = async (email: string): Promise<any> => {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${CHECK_RESELLER_EMAIL}/${email}`,
    });

    return response;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};

export const sendResponseEmail = async ({
  answers,
  score,
}: {
  answers: object;
  score: number;
}): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const resposne = await apiClient.request({
      method: 'POST',
      endPoint: SEND_RESPONSE_EMAIL,
      body: {
        answers,
        score,
      },
    });

    return resposne;
  } catch (err) {
    console.log('Error:: something went wrong', err);
    throw err;
  }
};
