import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { generateSignedUrl } from '@/api/user';

interface VoiceAudioPlayerProps {
  fileName: string;
  onPlay?: () => void;
  onPause?: () => void;
}

const AudioPlayer: React.FC<VoiceAudioPlayerProps> = ({ fileName, onPlay, onPause }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const presignedUrlMutation = useMutation({
    mutationFn: generateSignedUrl,
    onSuccess: ({ url }) => {
      if (audioRef.current && url) {
        audioRef.current.src = url;
        audioRef.current.play();
        setIsPlaying(true);
        onPlay?.();
      }
    },
    onError: error => {
      console.error('Failed to fetch presigned URL:', error);
    },
  });

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const setAudioData = () => {
      setDuration(audio.duration);
    };

    const updateProgress = () => {
      setProgress(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setProgress(0);
      onPause?.();
    };

    audio.addEventListener('loadedmetadata', setAudioData);
    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('loadedmetadata', setAudioData);
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [onPause]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        if (!audioRef.current.src) {
          // If no src is set, fetch the presigned URL
          presignedUrlMutation.mutate({ objectKey: `voices/${fileName}`, bucketType: 'voices' });
        } else {
          audioRef.current.play();
          setIsPlaying(true);
          onPlay?.();
        }
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
        onPause?.();
      }
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleSeek = (e: React.MouseEvent<HTMLDivElement>) => {
    if (audioRef.current) {
      const bounds = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - bounds.left;
      const percent = x / bounds.width;
      audioRef.current.currentTime = percent * duration;
    }
  };

  return (
    <div className="flex items-center space-x-2 bg-gray-100 rounded-full p-1 w-58">
      <audio ref={audioRef} preload="metadata" />
      <button
        onClick={togglePlayPause}
        disabled={presignedUrlMutation.isPending}
        className={`flex items-center justify-center w-8 h-8 rounded-full text-white focus:outline-none transition-colors ${
          isPlaying ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400 hover:bg-gray-500'
        } disabled:bg-gray-300 disabled:cursor-not-allowed`}
      >
        {isPlaying ? <Pause size={16} /> : <Play size={16} />}
      </button>
      <div className="flex-grow h-2 bg-gray-300 rounded-full overflow-hidden cursor-pointer" onClick={handleSeek}>
        <div
          className={`h-full transition-colors ${isPlaying ? 'bg-blue-500' : 'bg-gray-400'}`}
          style={{ width: `${(progress / duration) * 100}%` }}
        ></div>
      </div>

      <span className="text-xs text-gray-500 w-10">{formatTime(isPlaying ? progress : duration)}</span>
    </div>
  );
};

export default AudioPlayer;
