import { Card } from 'antd';
import Meta from 'antd/es/card/Meta';

type AnalyticsCardProps = {
  title: string;
  description?: string;
  children: React.ReactNode;
};

export const AnalyticsCard = ({ title, description, children }: AnalyticsCardProps) => (
  <Card className="w-full mb-6" title={title}>
    {description && <Meta description={description} />}
    {children}
  </Card>
);
