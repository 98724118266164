// src/theme/themeConfig.ts
import type { ThemeConfig } from 'antd';
import { create } from 'zustand';

interface ThemeStore {
  themeColor: string;
  setThemeColor: (color: string) => void;
}

export const useThemeStore = create<ThemeStore>(set => ({
  themeColor: '#6e79d6', // Default antd primary color
  setThemeColor: (color: string) => set({ themeColor: color }),
}));

export const getThemeConfig = (primaryColor: string): ThemeConfig => ({
  token: {
    colorPrimary: primaryColor,
    colorLink: primaryColor,
    // colorPrimaryHover: primaryColor,
    colorPrimaryActive: primaryColor,
    colorPrimaryBorder: primaryColor,
    colorPrimaryBg: primaryColor,
    // colorPrimaryText: primaryColor,
  },
  components: {
    Button: {
      colorPrimary: primaryColor,
      //   colorPrimaryHover: primaryColor,
      colorPrimaryActive: primaryColor,
      //   primaryColor: primaryColor,
      controlOutline: 'none',
      algorithm: true, // Enable algorithm
    },
  },
});
