import { LabelWithDescription } from '@/components/LabelWithdescription';
import { LoadingOutlined } from '@ant-design/icons';
import { Card, Flex, Form, Input, notification, Spin } from 'antd';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import './CallTransferingWorkflowForm.scss';
import { CallWorkflow, User } from '@/types/TAccounts';
import { useEffect } from 'react';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';
import { handlePrefixPlusOnPhone } from '@/utils/helper';

interface CallTransferingWorkflowFormProps {
  callWorkflow: CallWorkflow | null;
  callTransferingWorkflowData: {
    description: string;
    voiceResponse: string;
    workflowForwardingNumber: string;
  }[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  mutation: UseMutationResult<{}, Error, any, unknown>;
  scenarioIndex: number;
}

const CallTransferingWorkflowFormEdit: React.FC<CallTransferingWorkflowFormProps> = ({
  callWorkflow,
  callTransferingWorkflowData,
  refetch,
  mutation,
  scenarioIndex,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (callWorkflow) {
      form.setFieldsValue({
        description: callWorkflow.description,
        voiceResponse: callWorkflow.voiceResponse,
        workflowForwardingNumber: callWorkflow.workflowForwardingNumber,
        workflowForwardingDID: callWorkflow.workflowForwardingDID,
      });
    }
  }, [callWorkflow]);

  const handleSubmit = async (values: {
    description: string;
    voiceResponse: string;
    workflowForwardingNumber: string;
    workflowForwardingDID: string;
  }) => {
    try {
      let cf = callTransferingWorkflowData;
      cf[scenarioIndex] = values;

      cf = cf.map(item => {
        return {
          ...item,
          workflowForwardingNumber: handlePrefixPlusOnPhone(item.workflowForwardingNumber),
        };
      });

      await mutation.mutateAsync({ callWorkflows: cf });
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Call Transfering workflow has been created successfully!',
        duration: 3,
      });
      dispatch(closeModal());
    } catch (err) {
      console.log(err);
    }
  };

  const FormItems = () => (
    <Card className="flex-1">
      <div className="border-b">
        <LabelWithDescription
          customClassName="mt-2"
          label="Scenario Description"
          description="Describe the scenario in which you would like to transfer the call."
        />
        <Form.Item
          className=" mt-2 pt-2 "
          name="description"
          rules={[
            {
              validator: (_, value) => {
                if (!value || value.trim() === '') {
                  return Promise.reject("Description can't be blank");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="e.g. Transfer the caller to the billing department. Execute whenever caller asks for a refund or receipt." />
        </Form.Item>
      </div>

      <div>
        <LabelWithDescription
          customClassName="mt-2"
          label="Phone Number"
          description="Specify the number you want your AI receptionist to forward the call to when the scenario is triggered."
        />
        <div className="mt-2">
          <Flex gap={8}>
            <Form.Item className="flex-auto input-container" label="Number:">
              <PhoneNumberInput name="workflowForwardingNumber" />
            </Form.Item>
            <div className="mt-10">#</div>
            <Form.Item name={'workflowForwardingDID'} className="flex-auto !mb-2" label="Extension (Optional):">
              <Input
                placeholder="e.g: 302"
                onKeyPress={event => {
                  const keyCode = event.keyCode || event.which;
                  const keyValue = String.fromCharCode(keyCode);
                  if (!/^\d+$/.test(keyValue)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Flex>
        </div>
      </div>

      <div className="border-b">
        <LabelWithDescription
          customClassName="mt-2 mb-2"
          label="Voice Response"
          description="Specify what you want your AI receptionist to say right before the call is transferred."
        />
        <Form.Item
          className=" mt-2 pt-2"
          name="voiceResponse"
          rules={[{ required: true, message: "Voice Response can't be blank" }]}
        >
          <Input placeholder="e.g. Sounds good, I am transferring you to the billing department right now. Please hold." />
        </Form.Item>
      </div>
    </Card>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={callWorkflow}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
        />
      </Spin>
    </>
  );
};

export default CallTransferingWorkflowFormEdit;
