import React, { useState, useRef } from 'react';
import { Form, Input, Button, Upload, notification, Typography, Spin } from 'antd';
import { UploadOutlined, AudioOutlined, CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { uploadVoiceClone } from '@/api/voice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import './VoiceLibrary.scss';
import { TVoiceModel } from '@/types/TVoiceModel';

const { Text } = Typography;

const CloneVoice: React.FC<{
  data:
    | {
        data: TVoiceModel[];
        total: number;
        lastEvaluatedKey: number;
      }
    | undefined;
  refetch: () => void;
}> = ({ refetch }) => {
  const currentUser = useAppSelector(getLoggedInUser);

  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [recordedFile, setRecordedFile] = useState<File | null>(null);
  const [recordedAudioURL, setRecordedAudioURL] = useState<string | null>(null);
  const [recording, setRecording] = useState<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const selectedProvider = 'cartesia';

  const { mutate: uploadVoiceCloneMutation, isPending } = useMutation({
    mutationKey: ['upload-clone-voice'],
    mutationFn: uploadVoiceClone,
    onSuccess: data => {
      notification.success({
        message: data.message || 'Voice cloned and attached to your receptionist successfully!',
      });

      setSelectedFile(null);
      setRecordedFile(null);
      setRecordedAudioURL(null);
      form.setFieldsValue({
        name: '',
        description: '',
      });
      refetch();
    },
    onError: (error: any) => {
      notification.error({
        message: error.message || 'An error occurred during the upload.',
      });
    },
  });

  const handleUpload = async () => {
    const { description, name } = form.getFieldsValue();
    if (!selectedFile && !recordedFile) {
      notification.error({ message: 'Please select a file or record a voice first.' });
      return;
    }

    // File validation
    const file = selectedFile || recordedFile;
    if (file) {
      // Check file size (1 MB = 1,000,000 bytes)
      const maxSize = 1 * 1000000; // 1 MB
      if (file.size > maxSize) {
        notification.error({ message: 'File size should not exceed 1 MB.' });
        return;
      }

      // Check file type
      const validTypes = ['audio/wav', 'audio/x-wav', 'audio/mpeg']; // .wav or .mp3 MIME types
      if (!validTypes.includes(file.type)) {
        notification.error({ message: 'Invalid file type. Only .mp3 or .wav files are allowed.' });
        return;
      }
    }

    const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;
    if (!baseUrl) return;

    const formData = new FormData();
    formData.append('username', currentUser?.number || '');
    formData.append('provider', selectedProvider);
    formData.append('name', name);
    formData.append('description', description);
    if (file?.name) {
      formData.append('fileName', file.name);
    }
    formData.append('isCloned', 'true');
    if (file) {
      formData.append('file', selectedFile || (recordedFile as Blob));
    } else {
      notification.error({ message: 'No file selected or recorded.' });
      return;
    }
    if (recordedFile) {
      formData.append('isRecording', 'true');
    }

    uploadVoiceCloneMutation(formData);
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = event => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const audioFile = new File([audioBlob], 'recorded_audio.wav', { type: 'audio/wav' });
        setRecordedFile(audioFile);
        setRecordedAudioURL(URL.createObjectURL(audioBlob));
        audioChunksRef.current = [];
      };
      mediaRecorderRef.current.start();
      setRecording(true);
    });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const removeRecordedVoice = () => {
    setRecordedAudioURL(null);
    setRecordedFile(null);
  };

  return (
    <Spin spinning={isPending}>
      <div className="max-w-7xl border-none md:px-4 mt-4">
        <Text className="block mb-4 text-sm text-gray-600">
          Create a custom voice by uploading an audio file or recording directly.
        </Text>
        <Form form={form} layout="vertical" className="space-y-4" onFinish={handleUpload}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please enter a name for your voice.' }]}>
            <Input placeholder="Custom Voice Name" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true, message: 'Please provide a description for your voice.' }]}
          >
            <Input.TextArea placeholder="Custom Voice Description" rows={2} />
          </Form.Item>
          <div className="flex space-x-2">
            <Upload
              beforeUpload={file => {
                setSelectedFile(file as File);
                return false;
              }}
              showUploadList={false}
              accept="audio/wav, audio/mpeg"
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            <Button
              onClick={recording ? stopRecording : startRecording}
              icon={<AudioOutlined />}
              type={recording ? 'primary' : 'default'}
              className={recording ? 'recording-button' : ''}
            >
              {recording ? 'Stop Recording' : 'Start Recording'}
            </Button>
          </div>
          {selectedFile && <Text className="block text-sm text-gray-600">Selected file: {selectedFile.name}</Text>}
          {recordedAudioURL && (
            <div className="mt-4 flex items-center gap-5">
              <audio controls src={recordedAudioURL}></audio>
              <Button onClick={removeRecordedVoice} icon={<CloseOutlined />} />
            </div>
          )}
          <Text className="block text-xs text-gray-500">(Max file size is 1 MB)</Text>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isPending} className="w-full">
              {isPending ? 'Uploading...' : 'Create Voice Clone'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default CloneVoice;
