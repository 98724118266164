import { useState, useEffect } from 'react';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import ResellerDashboard from '../ResellerDashboard';
import {
  Button,
  Card,
  ColorPicker,
  Form,
  GetProp,
  Input,
  notification,
  Switch,
  Upload,
  UploadProps,
  Slider,
  Typography,
} from 'antd';
import { LoadingOutlined, PlusOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UPDATE_RESELLER_LOGO, UpdateResellerBranding, UpdateResellerBrandingToggle } from '@/api/reseller';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { updateReseller } from '@/store/reseller/slice';
import { CustomModal } from '@/components/CustomModal';
import './ResellerBranding.scss';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const ResellerBranding = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const currentReseller = useAppSelector(getResellerData);
  const [loading, setLoading] = useState<boolean>(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string>('');
  const [showTwilioAccountId, setShowTwilioAccountId] = useState<boolean>(false);
  const [showTwilioAccessToken, setShowTwilioAccessToken] = useState<boolean>(false);
  const [logoWidth, setLogoWidth] = useState<number>(64);
  const [logoHeight, setLogoHeight] = useState<number>(64);
  const [logoAdjustModalVisible, setLogoAdjustModalVisible] = useState<boolean>(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentReseller) {
      form.setFieldsValue({
        resellerName: currentReseller.reseller_name,
        themeColor: currentReseller.theme_color,
        privacyPolicyURL: currentReseller.privacyPolicyURL,
        termsOfUseURL: currentReseller.termsOfUseURL,
        uploadedLogoS3Url: currentReseller.logo_s3,
        twilioAccountId: currentReseller.twilio_account_id,
        twilioAccessToken: currentReseller.twilio_access_token,
        customButton1Label: currentReseller.customButton1Label,
        customButton1URL: currentReseller.customButton1URL,
        customButton2Label: currentReseller.customButton2Label,
        customButton2URL: currentReseller.customButton2URL,
        purchaseRedirectLink: currentReseller.purchaseRedirectLink,
      });
      setLogoHeight(currentReseller.logoDimensions?.height || 64);
      setLogoWidth(currentReseller.logoDimensions?.width || 64);
    }
  }, [currentReseller, form]);

  const mutation = useMutation({
    mutationKey: ['updateResellerBranding'],
    mutationFn: UpdateResellerBranding,
    onError(data) {
      notification.error({
        message: data.message,
      });
    },
    onSuccess(data) {
      if (data.success) {
        queryClient.invalidateQueries({ queryKey: ['reseller'] });
        notification.success({
          key: 'update-reseller',
          message: 'Updated successfully',
          duration: 3,
        });
      }
    },
  });

  const handleSubmit = async (values: {
    resellerName: string;
    themeColor: string;
    uploadedLogoS3Url: string;
    emailAddress: string;
    privacyPolicyURL: string;
    termsOfUseURL: string;
    purchaseRedirectLink: string;
    logoDimensions: { width: number; height: number };
  }) => {
    const valuesWithEmail = {
      ...values,
      emailAddress: currentReseller.email_address,
      logoDimensions: { width: logoWidth, height: logoHeight },
    };
    await mutation.mutateAsync(valuesWithEmail);
    dispatch(
      updateReseller({
        logo_s3: values.uploadedLogoS3Url,
        logoUrl: previewImageUrl,
        logoDimensions: { width: logoWidth, height: logoHeight },
      })
    );
  };
  const updateResellerBrandingToggleMutation = useMutation({
    mutationKey: ['UpdateResellerBrandingToggle'],
    mutationFn: (newBrandedValue: boolean) =>
      UpdateResellerBrandingToggle({
        resellerEmail: currentReseller.email_address,
        branded: newBrandedValue,
      }),
    onSuccess: ({ success, branded }) => {
      if (success) {
        notification.success({
          key: 'update-reseller-branding',
          message: `Branding switched ${branded ? 'ON' : 'OFF'}`,
          duration: 3,
        });
      }
    },
  });

  const handleResellerBrandingToggle = (checked: boolean) => {
    dispatch(updateReseller({ branded: checked }));
    updateResellerBrandingToggleMutation.mutate(checked);
  };

  const handleChange: UploadProps['onChange'] = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      form.setFieldValue('uploadedLogoS3Url', info.file.response.url);
      getBase64(info.file.originFileObj as FileType, url => {
        setLoading(false);
        setPreviewImageUrl(url);
      });
      setLoading(false);
    }
  };

  const uploadButton = (
    <button type='button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className='mt-1 text-gray-500'>Upload</div>
    </button>
  );

  const handleColorChange = (value: any) => {
    form.setFieldValue('themeColor', value.toHexString());
    dispatch(updateReseller({ theme_color: value.toHexString() }));
  };

  const LogoPreview = () => (
    <div className='bg-gray-50 rounded-lg p-4 mt-4'>
      <h3 className='text-lg font-medium mb-4'>Logo Preview</h3>
      {previewImageUrl && (
        <div className='flex justify-center items-center bg-white rounded p-4 mb-4 min-h-24'>
          <img
            src={previewImageUrl}
            alt='logo preview'
            style={{
              width: `${logoWidth}px`,
              height: `${logoHeight}px`,
              objectFit: 'contain',
            }}
            className='max-w-full h-auto'
          />
        </div>
      )}
      <div className='space-y-4'>
        <div>
          <label className='block text-sm font-medium mb-2'>Width: {logoWidth}px</label>
          <Slider
            min={32}
            max={200}
            value={logoWidth}
            onChange={value => setLogoWidth(value)}
            step={1}
            keyboard={true}
            tooltip={{
              formatter: value => `${value}px`,
            }}
          />
        </div>
        <div>
          <label className='block text-sm font-medium mb-2'>Height: {logoHeight}px</label>
          <Slider
            min={32}
            max={200}
            value={logoHeight}
            onChange={value => setLogoHeight(value)}
            step={1}
            keyboard={true}
            tooltip={{
              formatter: value => `${value}px`,
            }}
          />
        </div>
      </div>
    </div>
  );
  return (
    <ResellerDashboard activeKey={ResellerSiderItem.BRANDING}>
      <CustomModal
        title='Adjust Logo'
        isModalOpen={logoAdjustModalVisible}
        cancelAction={() => {
          setLogoAdjustModalVisible(false);
          setLogoWidth(currentReseller.logoDimensions?.width || 64);
          setLogoHeight(currentReseller.logoDimensions?.height || 64);
        }}
        confirmAction={() => {
          setLogoAdjustModalVisible(false);
          handleSubmit(form.getFieldsValue());
        }}
      >
        <Typography.Text className='block mb-4'>
          Adjust the width and height of your logo to fit your branding needs.
          <br />
          <strong>Press submit to save settings.</strong>
        </Typography.Text>
        <LogoPreview />
      </CustomModal>
      <Card className='w-full'>
        <Form form={form} onFinish={handleSubmit} layout='vertical' className='space-y-6'>
          {/* Logo Upload and Business Name Section */}
          <div className='flex flex-col md:flex-row'>
            <div className='md:mr-7'>
              <Upload
                listType='picture-circle'
                className='w-full'
                showUploadList={false}
                action={UPDATE_RESELLER_LOGO}
                onChange={handleChange}
                name='logo'
                accept='image/*'
              >
                <div className='w-full aspect-square max-w-48 mx-auto flex justify-center items-center'>
                  {previewImageUrl ? (
                    <img
                      style={{
                        width: `${logoWidth}px`,
                        height: `${logoHeight}px`,
                        objectFit: 'contain',
                      }}
                      src={previewImageUrl}
                      alt='logo'
                      className='w-full h-full'
                    />
                  ) : (
                    uploadButton
                  )}
                </div>
              </Upload>
              {/* <LogoPreview /> */}
              <Button
                type='primary'
                onClick={() => {
                  setLogoAdjustModalVisible(true);
                }}
                className='mt-4 ml-3'
              >
                Adjust
              </Button>
            </div>
            <div className='w-full md:w-2/3'>
              <Form.Item label='Business name' name='resellerName'>
                <Input placeholder='Please enter business name' />
              </Form.Item>
            </div>
          </div>

          <Form.Item name='uploadedLogoS3Url' hidden></Form.Item>

          <Form.Item
            label='Purchase Redirect Link'
            name='purchaseRedirectLink'
            rules={[
              {
                type: 'url',
                message: 'Please enter a valid URL',
              },
            ]}
          >
            <Input placeholder='Enter the URL where customers will be redirected for purchase' />
          </Form.Item>

          {/* Theme and Branding Toggle Section */}
          <div className='flex flex-col md:flex-row gap-4'>
            <Form.Item label='Theme color' name='themeColor' className='w-full  md:w-1/2'>
              <ColorPicker
                className={`w-full border-none`}
                // get rid of square in the middle
                disabledAlpha={true}
                onChange={handleColorChange}
                style={{
                  background: currentReseller.theme_color,
                  border: 'none',
                  fontSize: 0,
                  borderWidth: 0,
                  display: 'block',
                }}
              />
            </Form.Item>
            {!currentReseller?.permanently_unbranded && (
              <div className='w-full md:w-1/2 flex flex-col md:flex-row md:items-center md:justify-between'>
                <span className='text-gray-500 mb-2 md:mb-0'>Toggle to On/Off Branding</span>
                <Switch
                  size='default'
                  loading={updateResellerBrandingToggleMutation.isPending}
                  onChange={handleResellerBrandingToggle}
                  checked={currentReseller.branded}
                />
              </div>
            )}
          </div>

          {/* Custom Buttons Section */}
          <div className='grid md:grid-cols-2 gap-4'>
            <Form.Item label='Custom Button 1 Label' name='customButton1Label'>
              <Input placeholder='Support' />
            </Form.Item>
            <Form.Item label='Custom Button 1 URL' name='customButton1URL'>
              <Input placeholder='www.resellersupportwebsite.com' />
            </Form.Item>
          </div>

          <div className='grid md:grid-cols-2 gap-4'>
            <Form.Item label='Custom Button 2 Label' name='customButton2Label'>
              <Input placeholder='Academy' />
            </Form.Item>
            <Form.Item label='Custom Button 2 URL' name='customButton2URL'>
              <Input placeholder='www.reselleracademywebsite.com' />
            </Form.Item>
          </div>

          {/* URLs Section */}
          <div className='grid md:grid-cols-2 gap-4'>
            <Form.Item label='Privacy Policy URL' name='privacyPolicyURL'>
              <Input placeholder='please enter privacy policy url' />
            </Form.Item>
            <Form.Item label='Terms of use URL' name='termsOfUseURL'>
              <Input placeholder='please enter terms of use url' />
            </Form.Item>
          </div>

          {/* Twilio Section */}
          <div className='grid md:grid-cols-2 gap-4'>
            <Form.Item label='Twilio account SID' name='twilioAccountId'>
              <Input.Password
                size='large'
                placeholder='Enter your Twilio Account ID'
                iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                visibilityToggle={{
                  visible: showTwilioAccountId,
                  onVisibleChange: setShowTwilioAccountId,
                }}
              />
            </Form.Item>
            <Form.Item label='Twilio auth token' name='twilioAccessToken'>
              <Input.Password
                size='large'
                placeholder='Enter your Twilio Access Token'
                iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                visibilityToggle={{
                  visible: showTwilioAccessToken,
                  onVisibleChange: setShowTwilioAccessToken,
                }}
              />
            </Form.Item>
          </div>

          {/* Submit Button */}
          <Form.Item className='flex justify-end'>
            <Button loading={loading || mutation.isPending} type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </ResellerDashboard>
  );
};

export default ResellerBranding;
