import { OverViewCardForm } from '@/pages/ReceptionistDashboard/Overview/Cards/OverviewCardForm';
import { Form, notification, Spin } from 'antd';
import { ManagePasswordForm } from './ManagePasswordForm';
import { useMutation } from '@tanstack/react-query';
import { updatePassword } from '@/api/user';
import { LoadingOutlined } from '@ant-design/icons';

const ManagePassword = () => {
  const [form] = Form.useForm();

  const mutation = useMutation({
    mutationKey: ['update-password'],
    mutationFn: updatePassword,
    onError: data => {
      form.setFields([
        {
          name: 'oldPassword',
          errors: [data.message],
        },
      ]);
    },
    onSuccess: () => {
      notification.success({
        message: 'Password has been updated successfully!',
      });
      form.resetFields();
    },
  });

  const handleSubmit = (values: { oldPassword: string; newPassword: string }) => {
    mutation.mutate(values);
  };

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          isFieldTouchReset={true}
          handleSubmit={handleSubmit}
          isFormLoading={mutation.isPending}
          form={form}
          formItems={<ManagePasswordForm />}
          initialValues={{ undefined }}
        />
      </Spin>
    </>
  );
};

export default ManagePassword;
