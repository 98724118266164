import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { openModal } from '@/store/modal/slice';
import { Button } from 'antd';
import { MODAL_IDS } from 'src/enums/EModal';

const ResellerPendingPaymentPromo = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-row items-center gap-5">
      <span className="mr-1">
        You have pending payments that require your attention. To enable auto-scaling features and add new receptionists
        to your account, please settle your outstanding balance.{' '}
      </span>
      <Button
        type="primary"
        onClick={() => {
          dispatch(openModal({ modalId: MODAL_IDS.CLEAR_PAYMENT_MODAL }));
        }}
      >
        Clear Payment
      </Button>
    </div>
  );
};

export default ResellerPendingPaymentPromo;
