import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchUserBranding, updateCampaignUser, uploadResellerLogo } from '@/api/novi/brandingApi';
import { notification } from 'antd';

export const getUserBranding = async (emailAddress: string) => {
  return useQuery({
    queryKey: ['userBranding', emailAddress],
    queryFn: () => fetchUserBranding(emailAddress),
    staleTime: 0,
  });
};

const useReseller = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUpdateUser = async (userData: {
    emailAddress: string;
    campaignUserName: string;
    uploadedLogoS3Url?: string;
    twilioAccountId?: string;
    twilioAccessToken?: string;
  }) => {
    setLoading(true);
    setError(null);

    try {
      await updateCampaignUser(userData);
      notification.success({
        message: 'User updated',
        description: 'User information has been updated successfully.',
      });
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    handleUpdateUser,
  };
};

export default useReseller;

export const useUploadResellerLogo = () => {
  return useMutation<string, Error, File>({
    mutationFn: (logo: File) => uploadResellerLogo(logo) as Promise<string>,
  });
};
