import React from 'react';

const ExploreFeaturesPage: React.FC = () => {
  const baseClass = 'contact-support';

  return (
    <div className={`${baseClass} overflow-y-hidden`}>
      <iframe
        src="https://www.myaifrontdesk.com/features?_gl=1*w3ug06*_ga*Njg5MDk0NDA4LjE3Mjk1NDAwMTc.*_ga_HB2EWC05TR*MTczMDE0NDM0Ni4yNC4wLjE3MzAxNDQzNDYuMC4wLjA.*_gcl_au*MTU3Nzk5MzgwNS4xNzI5NTQwMDE3LjExODU0Nzk4MzUuMTcyOTc4MjE5Ny4xNzI5NzgyMTk3"
        className={`${baseClass}__content overflow-y-hidden`}
      />
    </div>
  );
};

export default ExploreFeaturesPage;
