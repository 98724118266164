import { Tour, TourProps } from 'antd';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { setTour, unsetTour } from '@/store/resellerTour/slice';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerTourState } from '@/store/resellerTour/selector';
import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import MobileLogo from '@/assets/images/mobile-log.png';
import DesktopLogo from '@/assets/images/desktop-logo.png';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import WavingHandOutlinedIcon from '@mui/icons-material/WavingHandOutlined';
import { AppRoutes } from 'src/enums/ERoutes';
import { useNavigate } from 'react-router-dom';

const ResellerTour: React.FC<{
  allReceptionistRef?: React.RefObject<HTMLDivElement>;
  whitelabeledClientDashboardRef?: React.RefObject<HTMLDivElement>;
  whitelabeledClientRegistrationRef?: React.RefObject<HTMLDivElement>;
  brandingRef?: React.RefObject<HTMLDivElement>;
  tutorialRef?: React.RefObject<HTMLDivElement>;
}> = ({
  allReceptionistRef,
  whitelabeledClientDashboardRef,
  whitelabeledClientRegistrationRef,
  brandingRef,
  tutorialRef,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resellerTour = useAppSelector(getResellerTourState);
  const { isMobile } = useMobileDeviceCheck();
  const ResellerLogo = (
    <>
      <div className="w-3/5">
        {isMobile ? <img src={MobileLogo} alt="logo" /> : <img src={DesktopLogo} alt="logo" />}
      </div>
    </>
  );

  const handleNextPrevStep = ({ step, route }: { step: number; route: AppRoutes }) => {
    dispatch(
      setTour({
        currentStep: step,
        open: true,
      })
    );

    navigate(route);
  };

  const steps: TourProps['steps'] = [
    {
      title: (
        <div>
          {ResellerLogo}
          <span className="font-bold text-2xl flex items-center">
            <WavingHandOutlinedIcon className="mr-2" />
            Welcome to the Reseller Program!
          </span>
          {resellerTour.currentStep}
        </div>
      ),
      description: `Purchase whitelabeled receptionists at retail price. Resell them for whatever price you'd like. Let's begin the tour.`,
      target: null,
      onNext: () => handleNextPrevStep({ step: 1, route: AppRoutes.RESELLER_PORTAL }),
    },
    {
      title: <span className="font-bold text-xl">View all your Receptionists</span>,
      description: (
        <span>
          View all your receptionists in one place. You can click into any of your receptionists and instantly log-in
          via popup to manage many receptionists at once.
        </span>
      ),
      placement: 'right',
      onNext: () => handleNextPrevStep({ step: 2, route: AppRoutes.RESELLER_EMBED }),
      onPrev: () => handleNextPrevStep({ step: 1, route: AppRoutes.RESELLER_PORTAL }),
      //@ts-ignore
      target: () => allReceptionistRef.current,
    },
    {
      title: <span className="font-bold text-xl">Whitelabeled Client Dashboard</span>,
      description: (
        <span>
          Your clients can edit their receptionist, view logs, and more in the whitelabeled dashboard. Embed this into
          your own website as an iframe to allow your clients to manage their receptionist.
        </span>
      ),
      placement: 'right',
      onNext: () => handleNextPrevStep({ step: 3, route: AppRoutes.RESELLER_EMBED }),
      onPrev: () => handleNextPrevStep({ step: 2, route: AppRoutes.RESELLER_PORTAL }),
      //@ts-ignore
      target: () => whitelabeledClientDashboardRef?.current,
    },
    {
      title: <span className="font-bold text-xl">Whitelabeled Client Registration</span>,
      description: (
        <span>
          Your clients can self-serve register one of your white-labeled receptionists too! Embed this white-labeled
          registration page into your own website as an iframe, and send it to your clients to allow them to register.
        </span>
      ),
      placement: 'left',
      onNext: () => handleNextPrevStep({ step: 4, route: AppRoutes.RESELLER_BRANDING }),
      onPrev: () => handleNextPrevStep({ step: 3, route: AppRoutes.RESELLER_EMBED }),
      //@ts-ignore
      target: () => whitelabeledClientRegistrationRef?.current,
    },
    {
      title: <span className="font-bold text-xl">Branding Settings</span>,
      description: (
        <span>
          Change your company name and logo anytime. Any branding changes will immediately apply to all your
          receptionists, dashboards, notifications, and more.
        </span>
      ),
      placement: 'topLeft',
      onNext: () => handleNextPrevStep({ step: 5, route: AppRoutes.RESELLER_TUTORIALS }),
      onPrev: () => handleNextPrevStep({ step: 4, route: AppRoutes.RESELLER_BRANDING }),
      //@ts-ignore
      target: () => brandingRef?.current,
    },
    {
      title: <span className="font-bold text-xl">View Tutorial</span>,
      description: <span>Access product tutorials here. Replay this tutorial from here as well.</span>,
      placement: 'left',
      onNext: () => handleNextPrevStep({ step: 6, route: AppRoutes.RESELLER_PORTAL }),
      onPrev: () => handleNextPrevStep({ step: 5, route: AppRoutes.RESELLER_TUTORIALS }),
      //@ts-ignore
      target: () => tutorialRef?.current,
    },
    {
      title: <span className="font-bold text-xl">Need help setting up?</span>,
      description: <span>Let us guide you through it. Book an onboarding call here for free.</span>,
      placement: 'center',
      onNext: () => handleNextPrevStep({ step: 7, route: AppRoutes.RESELLER_PORTAL }),
      onPrev: () => handleNextPrevStep({ step: 6, route: AppRoutes.RESELLER_TUTORIALS }),
      target: () => null,
    },
    {
      title: (
        <div>
          {ResellerLogo}
          <span className="font-bold text-xl flex items-center">
            <CelebrationOutlinedIcon className="mr-2" />
            Tutorial Complete
          </span>
        </div>
      ),
      description: (
        <div>
          <span>For help, email contact@myaifrontdesk.com. This concludes the tutorial.</span>
          <br />
          Start by exploring the dashboard and registering your first client! You can replay this tutorial any time in
          the "tutorials" section.
        </div>
      ),
      placement: 'center',
      onFinish: () => {
        dispatch(unsetTour());
      },
      onPrev: () => handleNextPrevStep({ step: 7, route: AppRoutes.RESELLER_PORTAL }),
      target: () => null,
    },
  ];
  const handleClose = () => {
    dispatch(unsetTour());
  };

  return <Tour open={resellerTour.open} onClose={handleClose} steps={steps} current={resellerTour.currentStep} />;
};

export default ResellerTour;
