import React from 'react';
import Select, { SingleValue } from 'react-select';
import { options, genderOptions, languageOptions } from './filterOption';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';

interface Option {
  value: string;
  label: string;
}

interface SearchFilterProps {
  provider: string;
  gender: string;
  language: string;
  searchText: string;
  setSearchText: (text: string) => void;
  onFilterChange: (filterType: string, value: string) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  provider,
  gender,
  language,
  searchText,
  setSearchText,
  onFilterChange,
}) => {
  const handleSelectChange = (filterType: string) => (provider: SingleValue<Option>) => {
    if (provider) {
      onFilterChange(filterType, provider.value);
    }
  };

  return (
    <div>
      <div className="flex gap-5 flex-wrap">
        <label className="w-64">
          <span className="font-medium">Search</span>
          <Input
            value={searchText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
            className="w-full py-1.5 rounded-md"
            placeholder="Type something..."
          />
        </label>
        <label className="w-32">
          <span className="font-medium">Provider</span>
          <Select
            options={options}
            value={options.find(option => option.value === provider)}
            onChange={handleSelectChange('provider')}
          />
        </label>
        <label className="w-32">
          <span className="font-medium">Gender</span>
          <Select
            options={genderOptions}
            value={genderOptions.find(option => option.value === gender)}
            onChange={handleSelectChange('gender')}
          />
        </label>
        <label className="w-32">
          <div className="flex items-center gap-2">
            <span className="font-medium">Language</span>
            <Tooltip
              id="my-tooltip"
              className="!max-w-56 !max-h-40 !overflow-y-auto !opacity-100"
              title="By selecting Other Language you can enable access to different languages ie Spanish, French, German, Portuguese, Japanese, Mandarin, Arabic, Russian, Hindi, Dutch,"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>

          <Select<Option>
            options={languageOptions}
            value={languageOptions.find(option => option.value === language)}
            onChange={handleSelectChange('language')}
          />
        </label>
      </div>
    </div>
  );
};

export default SearchFilter;
