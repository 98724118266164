import './ContactSupport.scss';
import { useEffect } from 'react';
import { show } from '@intercom/messenger-js-sdk';

export const ContactSupport = () => {
  useEffect(() => {
    show();
  }, []);

  const baseClass = 'contact-support';
  return (
    <div className={baseClass}>
      <iframe
        src='https://calendly.com/d/cph3-yqw-sfb/invite-only-ai-front-desk-product-consultation'
        title='Calendly'
        className={`${baseClass}__content`}
      />
    </div>
  );
};
