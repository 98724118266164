import React, { useEffect, useState } from 'react';
import './PhoneNumberSettings.scss';
import ForwardCalls from '../Steps/ForwardCalls';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getProvisionedNumber, getTwilioBundleStatus } from 'src/api/reservedNumbers';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { getUser, updatePendingTestingStatus, updateStepsCompleted } from '@/api/user';
import { setInstructionSteps, updateUser } from '@/store/account/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { Button } from 'antd';

interface TestItOutProps {
  setOpened?: (open: boolean) => void;
  setOpenedOnboarding: (open: boolean) => void;
  debrand: boolean;
}

export const PhoneNumberSettings: React.FC<TestItOutProps> = ({ debrand, setOpenedOnboarding }) => {
  const [provisionedNumber, setProvisionedNumber] = useState<string | null>(null);
  const loggedInUser = useAppSelector(getLoggedInUser);
  const dispatch = useAppDispatch();

  const UpdateStepsDoneMutation = useMutation({
    mutationKey: ['updateStepsDone'],
    mutationFn: updateStepsCompleted,
    onSuccess(data: { success: boolean; updated_instruction_steps: number[] }) {
      dispatch(setInstructionSteps({ instructionSteps: data.updated_instruction_steps }));
    },
  });

  const UpdatePendingTestingStatusMutation = useMutation({
    mutationKey: ['updatePendingTestingStatus'],
    mutationFn: updatePendingTestingStatus,
  });
  const handleSetPendingTesting = (status: boolean) => {
    UpdatePendingTestingStatusMutation.mutate({ pendingTesting: status });
  };

  const updateStepsDone = (stepNumber: number) => {
    UpdateStepsDoneMutation.mutate({ stepNumber });
  };

  const getProvisionNumberMutation = useMutation({
    mutationKey: ['provisionedNumberApi'],
    mutationFn: getProvisionedNumber,
    onSuccess(data) {
      setProvisionedNumber(data.provisioned_number);
    },
  });
  const { refetch } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  });

  const getBundleIdMutation = useMutation({
    mutationKey: ['getBundleId'],
    mutationFn: getTwilioBundleStatus,
    onSuccess(data) {
      refetch();

      dispatch(updateUser({ user: { twilioBundleId: data.sid, twilioBundleStatus: data.status } }));

      if (!loggedInUser.twilioBundleId) {
        dispatch(updateUser({ user: { twilioBundleId: data.sid, twilioBundleStatus: 'pending-review' } }));
      }
    },
  });

  useEffect(() => {
    getProvisionNumberMutation.mutate();
    if (loggedInUser.twilioBundleId) {
      getBundleIdMutation.mutate(loggedInUser.twilioBundleId);
    }
  }, []);

  const baseClass = 'test-it-out-container';

  return (
    <div className={`${baseClass} flex flex-col`}>
      <div className={`${baseClass}__title`}>
        <h2 className="text-2xl font-bold">Your AI Phone Number</h2>
        <span>Manage your AI Phone Number here.</span>
      </div>
      <ForwardCalls
        debrand={debrand}
        provisionedNumber={provisionedNumber}
        setProvisionedNumber={setProvisionedNumber}
        getProvisionNumberMutation={getProvisionNumberMutation}
        getBundleIdMutation={getBundleIdMutation}
        setOpenedOnboarding={setOpenedOnboarding}
      />

      {provisionedNumber && !loggedInUser.instruction_steps?.includes(6) && (
        <Button
          type="primary"
          className="mt-4 max-w-20"
          onClick={() => {
            updateStepsDone(6);
            handleSetPendingTesting(false);
          }}
        >
          Done
        </Button>
      )}
    </div>
  );
};
