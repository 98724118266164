import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import './SettingMenu.scss';
import AccountSettings from './AccountSettings/AccountSettings';
import { useQuery } from '@tanstack/react-query';
import { getUser } from '@/api/user';
import ManagePassword from './ManagePassword/ManagePassword';
import ManageSubscription from './ManageSubscription/ManageSubscription';
import { getLoggedInUser } from '@/store/account/selector';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { DataUsageOutlined, PasswordOutlined, SettingsOutlined, SubscriptionsOutlined } from '@mui/icons-material';
import ManageUsage from './ManageSubscription/ManageUsage';

export const SettingMenu: React.FC<{ debrand: boolean; isSmallScreen: boolean }> = ({ debrand, isSmallScreen }) => {
  const loggedInUser = useAppSelector(getLoggedInUser);
  const { data, isLoading } = useQuery({
    queryKey: ['receptionist'],
    queryFn: getUser,
  });

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span>{isSmallScreen ? <SettingsOutlined /> : 'Account Settings ⚙️'}</span>,
      //@ts-ignore
      children: <AccountSettings isLoading={isLoading} business_name={data?.business_name} />,
    },
    {
      key: '2',
      label: <span>{isSmallScreen ? <PasswordOutlined /> : 'Manage Password 🔑'}</span>,
      children: <ManagePassword />,
    },
  ];

  if (!loggedInUser?.reseller_email && !debrand) {
    items.push({
      key: '3',
      label: <span>{isSmallScreen ? <SubscriptionsOutlined /> : 'Manage Subscription💲'}</span>,
      children: <ManageSubscription />,
    });
    items.push({
      key: '4',
      label: <span>{isSmallScreen ? <DataUsageOutlined /> : 'Manage Usage ⌛'}</span>,
      children: <ManageUsage />,
    });
  }

  const baseClass = 'setting-menu';

  return (
    <Tabs
      defaultActiveKey='1'
      tabPosition={isSmallScreen ? 'top' : 'left'}
      items={items}
      onChange={onChange}
      className={isSmallScreen ? '' : baseClass}
    />
  );
};
