import { Button } from 'antd';
import useAuth from '../../routes/hooks/useAuth';

export const Page404: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return (
    <>
      <h1>404</h1>
      <h2>Seems you lost</h2>
      <Button onClick={() => {}}>{isAuthenticated ? 'Dashboard' : 'Login'}</Button>
    </>
  );
};
