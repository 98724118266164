import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import PublicLayout from '@/components/Layout/PublicLayout';
import {
  APP_LANDING_PAGE,
  AppRoutes,
  BOOK_CONSULTATION_LANDING_PAGE,
  PRIVACY_POLICY_LANDING_PAGE,
  TERMS_OF_USE_LANDING_PAGE,
} from 'src/enums/ERoutes';
import apiClient from '@/utils/apiClient';
import { useNavigate } from 'react-router-dom';

const { Meta } = Card;
const { Title } = Typography;

interface BlogPost {
  id: string;
  name: string;
  'main-image': string;
  slug: string;
  datetime?: string;
}

interface BlogResponse {
  blog: {
    success: boolean;
    data: BlogPost[];
    lastEvaluatedKey: string | null;
    message?: string;
    error?: string;
  };
}

const BlogsPage: React.FC = () => {
  const [blogs, setBlogs] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [lastKey, setLastKey] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const navigationItems = [
    { key: 1, target: '_blank', url: APP_LANDING_PAGE, label: 'Product' },
    { key: 2, target: '_self', url: AppRoutes.RECEPTIONIST_LOGIN, label: 'Login' },
    { key: 3, target: '_self', url: AppRoutes.REGISTER, label: 'Register' },
    { key: 4, target: '_blank', url: BOOK_CONSULTATION_LANDING_PAGE, label: 'Book Consultation' },
    { key: 5, target: '_blank', url: APP_LANDING_PAGE, label: 'Contact' },
    { key: 6, target: '_blank', url: PRIVACY_POLICY_LANDING_PAGE, label: 'Privacy Policy' },
    { key: 7, target: '_blank', url: TERMS_OF_USE_LANDING_PAGE, label: 'Terms of Use' },
  ];

  const getBlogs = async (key: string | null): Promise<BlogResponse> => {
    try {
      let config: { params: { type: string; lastEvaluatedKey?: string } } = {
        params: {
          type: 'pagination',
        },
      };
      if (key) {
        config.params.lastEvaluatedKey = key;
      }

      const response = await apiClient.request({
        method: 'GET',
        endPoint: `/blogs`,
        ...config,
      });
      return response;
    } catch (err) {
      throw err;
    }
  };

  const loadMoreData = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await getBlogs(lastKey);
      if (response.blog.success) {
        if (response.blog.data.length === 0) {
          setHasMore(false);
        } else {
          setBlogs(prev => [...prev, ...response.blog.data]);
          setLastKey(response.blog.lastEvaluatedKey);
          if (!response.blog.lastEvaluatedKey) {
            setHasMore(false);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialLoad = async () => {
      try {
        const response = await getBlogs(null);
        if (response.blog.success) {
          setBlogs(response.blog.data);
          setLastKey(response.blog.lastEvaluatedKey);
          setHasMore(!!response.blog.lastEvaluatedKey);
        }
      } catch (error) {
        console.error('Error in initial load:', error);
        setHasMore(false);
      } finally {
        setInitialLoading(false);
      }
    };

    initialLoad();
  }, []);

  const handleCardClick = (_id: string, slug: string) => {
    navigate(`/blog/${slug}`);
  };

  if (initialLoading) {
    return (
      <div className='text-center p-5'>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <PublicLayout debrand={false} items={navigationItems}>
      <div className='w-full max-w-full mt-14 h-[calc(100vh-80px)] relative overflow-hidden'>
        <div id='scrollableDiv' className='h-full overflow-auto px-6 pt-6 pb-6 box-border'>
          <InfiniteScroll
            dataLength={blogs.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={
              loading && (
                <div className='text-center p-5'>
                  <Spin size='large' />
                </div>
              )
            }
            endMessage={
              blogs.length > 0 ? (
                <div className='text-center p-5'>
                  <Title level={5} className='text-[#999]'>
                    No more posts to load
                  </Title>
                </div>
              ) : null
            }
            scrollableTarget='scrollableDiv'
            className='overflow-visible'
          >
            <div className='max-w-full mx-auto'>
              <Row gutter={[24, 24]} className='m-0 w-full'>
                {blogs.map(post => (
                  <Col xs={24} sm={12} md={8} key={post.id} className='pb-6'>
                    <Card
                      hoverable
                      cover={
                        <div className='h-[200px] overflow-hidden'>
                          <img alt={post.name} src={post['main-image']} className='w-full h-full object-cover' />
                        </div>
                      }
                      onClick={() => handleCardClick(post.id, post.slug)}
                    >
                      <Meta
                        title={post.name}
                        description={
                          post.datetime &&
                          new Date(post.datetime).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })
                        }
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </PublicLayout>
  );
};

const Blogs = BlogsPage;
export default Blogs;
