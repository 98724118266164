import { Modal } from 'antd';
import './OfferModal.scss';
import gift from 'src/assets/images/gift.png';

type Props = {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const OfferModal = (props: Props) => {
  return (
    <Modal className='offer-modal' open={props.isModalOpen} onOk={props.handleOk} onCancel={props.handleCancel}>
      <div className='text-center'>
        <h1 className='offer-modal-title'>Congratulations!</h1>
        <img src={gift} className='offer-modal-image' />
        <p className='text-white text-xl mt-3 mb-0'>You have been choosen!</p>
        <h1 className='offer-modal-title'>Earn 2 free months!</h1>
        <p className='text-light text-lg'>How to earn & redeem?</p>
        <p className='mb-2 text-lg text-light'>Just follow these 3 steps and earn 2 free months:</p>
        <ol className='text-light'>
          <li>
            <b>Step 1:</b> Record a 30 second video about why you love <b>My AI Frontdesk.</b>
          </li>
          <li>
            <b>Step 2:</b> Post it on social media with hashtag <b>#myaifrontdesk</b>.
          </li>
          <li>
            <b>Step 3:</b> Share us the social media post link on email.{' '}
            <a href='mailto: support@myaifrontdesk.com'>support@myaifrontdesk.com</a>
          </li>
        </ol>
      </div>
    </Modal>
  );
};

export default OfferModal;
