import { fetchNumbersFn } from '@/api/reservedNumbers';
import { AvailableNumber } from '@/interfaces/IReservedNumber';
import { Button, notification } from 'antd';
import React, { useState, ChangeEvent } from 'react';

// Define TypeScript interfaces for props and available numbers

interface ChooseNumberModalProps {
  isOpen: boolean; // Determines if the modal is open
  onClose: () => void; // Callback function to close the modal
  selectedCountryCode: string; // Selected country code for fetching numbers
  bookNumberRequest: (number?: AvailableNumber | null) => Promise<void>; // Function to book a selected number
}

// Define the ChooseNumberModal functional component
const GetChooseNumberForm: React.FC<ChooseNumberModalProps> = ({ onClose, selectedCountryCode, bookNumberRequest }) => {
  // State hooks
  const [availableNumbers, setAvailableNumbers] = useState<{ locals: AvailableNumber[] }>({ locals: [] }); // State to store available numbers
  const [localNumber, setLocalNumber] = useState<string>(''); // State to store the inputted local number
  const [loading, setLoading] = useState<boolean>(false); // State to indicate if data is being loaded
  const [bookingLoading, setBookingLoading] = useState<boolean>(false); // State to indicate if booking is in progress
  const [queried, setQueried] = useState<boolean>(false); // State to track if numbers have been queried

  // Function to fetch the list of available numbers based on the country code and local number
  const getListOfNumbers = async () => {
    try {
      setLoading(true);
      const response = await fetchNumbersFn(selectedCountryCode, localNumber, setLoading, setQueried);
      setAvailableNumbers(response);
    } catch (error) {
      console.error(error); // Log any errors to the console

      notification.error({ message: 'Failed to fetch numbers' });
    } finally {
      setLoading(false); // Set loading to false after fetching is done
    }
  };
  // Function to handle closing the modal
  const handleClose = () => {
    // Reset states to initial values
    setAvailableNumbers({ locals: [] });
    setLocalNumber('');
    setLoading(false);
    setBookingLoading(false);
    setQueried(false);
    onClose(); // Call the onClose callback to close the modal
  };

  // Function to handle selecting a number
  const handleSelectNumber = async (number?: AvailableNumber | null) => {
    setBookingLoading(true); // Set booking loading to true while booking is in progress
    try {
      await bookNumberRequest(number); // Call the booking request function
      handleClose(); // Close the modal after booking
    } catch (error) {
      console.error(error); // Log any errors to the console
      notification.error({ message: 'Failed to book the number' });
    } finally {
      setBookingLoading(false); // Set booking loading to false after booking is done
    }
  };

  return (
    <div className='relative p-6  w-96 self-center mx-auto'>
      {/* Close button */}

      <div className='flex flex-col text-center'>
        <h2 className='mb-4 text-xl font-semibold'>Select a Number</h2>
        {/* Input for area code */}
        <input
          type='number'
          value={localNumber}
          maxLength={3}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLocalNumber(e.target.value)}
          placeholder='Enter 3-digit area code'
          className='w-full px-4 py-2 mb-4 border rounded'
        />
        {/* Button to fetch available numbers */}
        <Button onClick={getListOfNumbers} type='primary' loading={loading} className='mb-4'>
          {loading ? 'Fetching numbers...' : 'Fetch Numbers'}
        </Button>
        <Button onClick={() => handleSelectNumber(null)} type='primary' className='mb-4'>
          {'Get Random Number'}
        </Button>
        {/* List of available numbers */}
        <ul className={`space-y-2 ${availableNumbers.locals.length > 0 ? 'h-96' : ''} overflow-y-scroll`}>
          {availableNumbers.locals.map(number => (
            <li key={number.friendlyName}>
              <button
                onClick={() => handleSelectNumber(number)}
                className='w-full px-4 py-2 text-left bg-gray-200 rounded hover:bg-gray-300'
                disabled={bookingLoading}
              >
                {bookingLoading ? 'Booking...' : number.friendlyName}
              </button>
            </li>
          ))}
        </ul>
        {/* Message if no numbers are available */}
        {queried && availableNumbers.locals.length === 0 && (
          <p className='mt-4 text-red-500'>No numbers available. Please try a different area code.</p>
        )}
      </div>
    </div>
  );
};

export default GetChooseNumberForm;
