import { parsePhoneNumber } from 'libphonenumber-js';
import { Convert } from 'easy-currencies';
import ECountryToCurrency from 'src/enums/ECountryToCurrency';

// Get country code from phone number
export const getCountryCodeFromPhone = (phoneNumber: string): string | undefined => {
  const phoneNumberObj = parsePhoneNumber(phoneNumber);
  return phoneNumberObj?.country;
};

// Map country code to currency code
const getCurrencyCodeFromCountry = (countryCode: string) => {
  return ECountryToCurrency[countryCode];
};

// Convert USD to local currency
export const convertUSDtoLocalCurrency = async ({
  countryCode,
  amountInUSD,
}: {
  countryCode: string;
  amountInUSD: number;
}): Promise<{ convertedAmount: number; currencyCode: string } | null> => {
  try {
    const convert = await Convert().from('USD').fetch();
    const rates = convert.rates;
    const currencyCode = getCurrencyCodeFromCountry(countryCode);
    if (!currencyCode || !rates[currencyCode]) {
      console.warn('Currency not available for the provided country');
      return null;
    }

    const convertedAmount = amountInUSD * rates[currencyCode];
    return { convertedAmount, currencyCode };
  } catch (error) {
    console.error('Error converting currency:', error);
    return null;
  }
};

// Main function to change amount to currency format
export const changeToCurrency = async ({
  amountInUSD,
  phone,
}: {
  amountInUSD: number;
  phone: string | undefined;
}): Promise<string> => {
  let text = `$${amountInUSD.toFixed(2)}`;
  if (phone) {
    try {
      const countryCode = getCountryCodeFromPhone(phone);
      if (countryCode) {
        const res = await convertUSDtoLocalCurrency({ countryCode, amountInUSD });
        if (res) {
          const { convertedAmount, currencyCode } = res;
          text = `${currencyCode} ${convertedAmount.toFixed(2)}`;
        }
      } else {
        console.warn('Could not detect country from phone number');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  return text;
};
