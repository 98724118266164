export const getCalendarConflictingScenariosNotice = (data: any) => {
  const keywords = ['meet', 'appointment', 'schedule', 'calendar', 'meeting'];

  const checkKeywords = (text: string, keywords: string[]) => {
    return keywords.filter(keyword => text.toLowerCase().includes(keyword));
  };

  const analyzeFields = (data: any, keywords: string[]) => {
    let result: { key: string; message: string }[] = [];

    // Check intake_questions_workflows
    const intakeWorkflows = JSON.parse(data.intake_questions_workflows || '[]');
    intakeWorkflows.forEach((workflow: any, index: number) => {
      const foundKeywords = checkKeywords(JSON.stringify(workflow), keywords);
      if (foundKeywords.length > 0) {
        result.push({
          key: 'Intake Form Workflow',
          message: `Found the word(s) "${foundKeywords.join(', ')}" in intake questions workflows at intake form workflow # ${index + 1}.`,
        });
      }
    });

    // Check workflows
    const workflows = JSON.parse(data.workflows || '[]');
    workflows.forEach((workflow: any, index: number) => {
      const foundKeywords = checkKeywords(JSON.stringify(workflow), keywords);
      if (foundKeywords.length > 0) {
        result.push({
          key: 'Texting Workflow',
          message: `Found the word(s) "${foundKeywords.join(', ')}" in workflows at texting workflow # ${index + 1}.`,
        });
      }
    });

    // Check business_information
    const businessKeywords = checkKeywords(data.business_information || '', keywords);
    if (businessKeywords.length > 0) {
      result.push({
        key: 'Knowledge Base',
        message: `Found the word(s) "${businessKeywords.join(', ')}" in knowledge base.`,
      });
    }

    // Check callWorkflows
    const callWorkflows = JSON.parse(data.callWorkflows || '[]');
    callWorkflows.forEach((workflow: any, index: number) => {
      const foundKeywords = checkKeywords(JSON.stringify(workflow), keywords);
      if (foundKeywords.length > 0) {
        result.push({
          key: 'Call Transferring Workflow',
          message: `Found the word(s) "${foundKeywords.join(', ')}" in call workflows # ${index + 1}.`,
        });
      }
    });

    // Check ai_prompt
    const aiKeywords = checkKeywords(data.ai_prompt || '', keywords);
    if (aiKeywords.length > 0) {
      result.push({
        key: 'AI Prompt',
        message: `Found the word(s) "${aiKeywords.join(', ')}" in the AI prompt.`,
      });
    }

    return result.length > 0 ? result : [];
  };

  const notice = analyzeFields(data, keywords);
  return notice;
};
