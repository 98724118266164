import {
  cancelCampaignAPI,
  Client,
  fetchCampaignClientByEmail,
  fetchCampaignSettings,
  initiateCampaign,
  saveCampaignSettings,
  updateLeadDataFn,
  updateLeadDataPayload,
} from '@/api/novi/CamapaignPortalApis';
import { MutationFunction, useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

interface SaveCampaignSettingsParams {
  settings: any;
  user_id: string;
  id?: string;
}
interface UpdateLeadDataResponse {
  data?: {
    message?: string;
    lead?: {
      custom_fields?: any[]; // Adjust the type according to your data structure
    };
  };
}

// Custom hook to fetch a campaign client by email
export const useCampaignClient = (emailAddress: string | undefined) => {
  return useQuery<Client, Error>({
    queryKey: ['campaignClient', emailAddress],
    queryFn: () => {
      if (!emailAddress) {
        throw new Error('Email address is required');
      }
      return fetchCampaignClientByEmail(emailAddress);
    },
  });
};

export const useUpdateLeadData = (
  options?: UseMutationOptions<UpdateLeadDataResponse, Error, updateLeadDataPayload>
) => {
  return useMutation<UpdateLeadDataResponse, Error, updateLeadDataPayload>({
    mutationFn: (payload: updateLeadDataPayload) => updateLeadDataFn(payload) as Promise<UpdateLeadDataResponse>,
    ...options,
  });
};

export const useSaveCampaignSettings = (options?: UseMutationOptions<any, any, SaveCampaignSettingsParams>) => {
  return useMutation({
    mutationFn: ({ settings, user_id, id }: SaveCampaignSettingsParams) => saveCampaignSettings(settings, user_id, id),
    ...options, // Spread additional options such as onSuccess or onError
  });
};

export const getCampaignSettings = (campaign_id: string) => {
  return useQuery<any, Error>({
    queryKey: ['campaignSettings', campaign_id],
    queryFn: () => {
      return fetchCampaignSettings(campaign_id);
    },
  });
};

export const launchCampaign = (
  options?: UseMutationOptions<
    string,
    Error,
    {
      id: string;
      campaign_id: string;
      email: string;
    }
  >
) => {
  const mutationFn: MutationFunction<
    string,
    {
      id: string;
      campaign_id: string;
      email: string;
    }
  > = ({ id, campaign_id, email }) => initiateCampaign(id, campaign_id, email);

  return useMutation({
    mutationFn,
    ...options,
  });
};

export const cancelCampaign = (
  options?: UseMutationOptions<
    { message: string },
    Error,
    {
      id: string;
      campaign_id: string;
    }
  >
) => {
  const mutationFn: MutationFunction<
    { message: string },
    {
      id: string;
      campaign_id: string;
    }
  > = ({ id, campaign_id }) => cancelCampaignAPI(id, campaign_id);

  return useMutation({
    mutationFn,
    ...options,
  });
};
