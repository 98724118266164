import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { CustomModal } from '@/components/CustomModal';
import { PRICING_LANDING_PAGE } from 'src/enums/ERoutes';

const PricingPlansModal = () => {
  const dispatch = useAppDispatch();

  return (
    <CustomModal
      width={'70%'}
      title={'Pricing Plans'}
      modalId={MODAL_IDS.PRICING_PLAN_MODAL}
      onClose={() => {
        dispatch(closeModal());
      }}
      footer={null}
    >
      <div className="w-full h-[70vh] overflow-y-auto">
        <iframe src={PRICING_LANDING_PAGE} title="Pricing Plans" className="w-full h-full border-none"></iframe>
      </div>
    </CustomModal>
  );
};

export default PricingPlansModal;
