import React from 'react';
import { Button, Form, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { OverViewCardForm } from '../../Cards/OverviewCardForm';
import PhoneNumberInput from '@/components/Inputs/PhoneNumberInput';

interface props {
  open: boolean;
  setOpen: (val: boolean | null) => void;
  phones: string[];
  savePhones: (val: string[]) => void;
}

const AddPhones: React.FC<props> = ({ open, setOpen, phones, savePhones }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: { phones: string[] }) => {
    const parseplus = values.phones.map(item => {
      let val = item;
      if (!item.startsWith('+')) {
        val = `+${item}`;
      }
      return val;
    });
    setOpen(false);
    savePhones(parseplus);
  };

  const FormItems = () => (
    <>
      <Form.List name="phones" initialValue={[]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div key={key} className="flex flex-row gap-2 items-center">
                <Form.Item
                  className="flex-1"
                  name={name}
                  rules={[{ required: true, message: 'Missing phone number' }]}
                  label="Phone Number"
                >
                  <PhoneNumberInput name={name} />
                </Form.Item>

                {fields.length > 1 && (
                  <MinusCircleOutlined onClick={() => remove(name)} className="text-red-600 text-lg cursor-pointer" />
                )}
              </div>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Phone
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );

  return (
    <Modal
      title={<span className="text-lg">Add Phone Recipients</span>}
      open={open}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      width={400}
      footer={null}
    >
      <OverViewCardForm
        form={form}
        initialValues={{
          phones,
        }}
        formItems={<FormItems />}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default AddPhones;
