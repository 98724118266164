import apiClient from '@/utils/apiClient';

const NOVI_LEADS_ROUTE = '/api/leads';
interface Lead {
  name: string;
  email: string;
}

export const createDataFn = async (payload: { campaign_id: string; values: any }): Promise<void> => {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${NOVI_LEADS_ROUTE}/${payload.campaign_id}/createData`,
      body: payload.values,
    });
    return response;
  } catch (error) {
    console.error('Error creating leads', error);
    throw error;
  }
};

export async function uploadCsvFn(file: File, campaign_id: string): Promise<void> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('campaign_id', campaign_id);

  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${NOVI_LEADS_ROUTE}/${campaign_id}/uploadCsv`,
      body: formData,
    });
    return response;
  } catch (err) {
    console.error('Error uploading CSV:', err);
    throw err;
  }
}
export async function getLeadsFn(campaign_id: string): Promise<Lead[]> {
  try {
    const response = await apiClient.request({
      method: 'GET',
      endPoint: `${NOVI_LEADS_ROUTE}/${campaign_id}/getLeads`,
    });
    console.log('Leads fetched successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
}

export async function uploadGoogleSheetsFn(link: string, campaign_id: string): Promise<void> {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `${NOVI_LEADS_ROUTE}/${campaign_id}/uploadGoogleSheets`,
      body: { link, campaign_id },
    });
    return response;
  } catch (err) {
    console.error('Error uploading leads here:', err);
    throw err;
  }
}

export async function updateLeadDataFn(payload: {
  campaign_id: string;
  lead_id: string;
  [key: string]: any;
}): Promise<void> {
  try {
    const response = await apiClient.request({
      method: 'POST',
      endPoint: `/api/leads/${payload.campaign_id}/updateLead/${payload.lead_id}`,
      body: payload,
    });
    return response;
  } catch (err) {
    console.error('Error uploading leads here:', err);
    throw err;
  }
}

export async function deleteLeadFn(campaign_id: string, lead_ids: string[]): Promise<{ success: boolean }> {
  try {
    const response = await apiClient.request({
      method: 'DELETE',
      endPoint: NOVI_LEADS_ROUTE,
      body: { lead_ids, campaign_id },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting leads:', error);
    throw error;
  }
}
