import { useEffect, useState } from 'react';
import momentTimeZone from 'moment-timezone';

export const useLocalTimezone = (tz: string | null | undefined) => {
  const [timeZone, setTimeZone] = useState<string>('UTC');

  useEffect(() => {
    if (!tz || tz === 'na') {
      setTimeZone(momentTimeZone.tz.guess());
      return;
    }
    setTimeZone(tz);
  }, [tz]);

  return { timeZone, setTimeZone };
};
