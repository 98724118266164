import { useAppSelector } from '@/store/hooks/useAppSelector';

//@ts-ignore
import NotificationsV1 from '../../../v1/components/dashboard/Notifications/Notifications';
import { getLoggedInUser } from '@/store/account/selector';

export const Notifications = () => {
  const loggedInUser = useAppSelector(getLoggedInUser);

  return <NotificationsV1 username={loggedInUser.email} />;
};
