import React, { useState } from 'react';
import { Card, Typography, Button, message, Select, Table, Descriptions, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getResellerData } from '@/store/reseller/selector';
import { getImportedTwilioNumbers, removeClientNumber, assignClientNumber } from '@/api/reseller';
import { useMutation, useQuery } from '@tanstack/react-query';
import ResellerDashboard from '../ResellerDashboard';
import { ResellerSiderItem } from 'src/enums/IResellerSiderItems';
import { useGetResellerClients } from 'src/Hooks/resellerHooks';
import { CustomModal } from '@/components/CustomModal';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';

const { Title, Text } = Typography;
const { Option } = Select;

interface TwilioNumber {
  phoneNumber: string;
  friendlyName: string;
  sid: string;
  dateCreated: string;
  capabilities: {
    fax: boolean;
    voice: boolean;
    sms: boolean;
    mms: boolean;
  };
}

const ImportPhoneNumbers: React.FC = () => {
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const [isTwilioDetailsModalActive, setIsTwilioDetailsModalActive] = useState(false);
  const [isConfirmationModalActive, setIsConfirmationModalActive] = useState(false);
  const navigate = useNavigate();

  const resellerData = useAppSelector(getResellerData);

  const getClientsMutation = useGetResellerClients({
    shouldFetchReservedNumbers: true,
    onSuccess: data => {
      setClients(data.items);
    },
  });

  const {
    data: twilioNumberData,
    isFetching: isLoadingTwilioNumbers,
    refetch: refetchTwilioNumbers,
  } = useQuery<{ numbers: TwilioNumber[] }>({
    queryKey: ['twilioNumbers', resellerData?.email_address],
    queryFn: getImportedTwilioNumbers,
    enabled: !!resellerData?.email_address,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const updateLocalClients = (clientUsername: string, newReservedNumber: string | null) => {
    setClients(prevClients =>
      prevClients.map(client =>
        client.number === clientUsername ? { ...client, reservedNumber: newReservedNumber } : client
      )
    );
  };

  const resetForm = () => {
    setSelectedClient(null);
    setSelectedNumber(null);
  };

  const removeClientNumberMutation = useMutation({
    mutationFn: removeClientNumber,
    onSuccess: (_, variables) => {
      updateLocalClients(variables.clientUsername, null);
      message.success('Number removed successfully');
      setIsConfirmationModalActive(false);
      resetForm();
    },
    onError: () => {
      message.error('Error removing the phone number');
    },
  });

  const assignClientNumberMutation = useMutation({
    mutationFn: assignClientNumber,
    onSuccess: (_, variables) => {
      updateLocalClients(variables.clientUsername, variables.clientNumber);
      message.success('Number assigned successfully');
      resetForm();
    },
    onError: () => {
      message.error('Error assigning the phone number');
    },
  });

  const handleClientSelect = (value: string) => {
    const selected = clients.find(client => client.number === value);
    setSelectedClient(selected);
    setSelectedNumber(null);
  };

  const handleNumberSelect = (value: string) => {
    setSelectedNumber(value);
  };

  const handleApply = () => {
    if (!selectedNumber || !selectedClient?.number) {
      message.error('Please select a sub-account and a number');
      return;
    }
    assignClientNumberMutation.mutate({
      clientUsername: selectedClient.number,
      clientNumber: selectedNumber,
    });
  };

  const handleRemoveNumber = () => {
    if (!selectedClient?.number || !selectedClient?.reservedNumber) {
      message.error('Invalid operation');
      return;
    }
    removeClientNumberMutation.mutate({
      clientUsername: selectedClient.number,
      clientNumber: selectedClient.reservedNumber,
    });
  };

  const handleRefreshClients = () => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address);
    }
  };

  const handleRefreshNumbers = () => {
    refetchTwilioNumbers();
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email_address',
      key: 'email_address',
    },
    {
      title: 'Reserved Number',
      dataIndex: 'reservedNumber',
      key: 'reservedNumber',
    },
  ];

  React.useEffect(() => {
    if (resellerData.email_address) {
      getClientsMutation.mutate(resellerData.email_address);
    }
  }, [resellerData.email_address]);

  React.useEffect(() => {
    if (!resellerData.twilio_access_token || !resellerData.twilio_account_id) {
      setIsTwilioDetailsModalActive(true);
    }
  }, [resellerData]);

  return (
    <ResellerDashboard activeKey={ResellerSiderItem.IMPORT_NUMBER}>
      <div className='h-full p-6'>
        <Title level={2}>Imported Numbers Management</Title>
        <Text>
          Manage your assigned phone numbers below. Imported phone numbers from your twilio will be available here
        </Text>

        <div className='max-w-5xl'>
          <Card title='Assign Phone Number' className='my-3'>
            <div className='grid grid-cols-3 gap-4'>
              <div>
                <Text strong className='block mb-2'>
                  Sub-Account
                </Text>
                <div className='flex gap-2'>
                  {getClientsMutation.isPending ? (
                    <div className='flex justify-center flex-1'>
                      <Spin />
                    </div>
                  ) : (
                    <>
                      <Select
                        showSearch
                        className='flex-1'
                        placeholder='Select a sub-account'
                        optionFilterProp='children'
                        onChange={handleClientSelect}
                        value={selectedClient?.number}
                        loading={getClientsMutation.isPending}
                        filterOption={(input, option) =>
                          (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {clients.map(client => (
                          <Option key={client.number} value={client.number}>
                            {client.number}
                          </Option>
                        ))}
                      </Select>
                      <Button
                        icon={<ReloadOutlined />}
                        onClick={handleRefreshClients}
                        loading={getClientsMutation.isPending}
                        size='middle'
                      />
                    </>
                  )}
                </div>
                {selectedClient && (
                  <Descriptions column={1} size='small' className='mt-4'>
                    <Descriptions.Item label='Email'>{selectedClient.email_address}</Descriptions.Item>
                    {selectedClient.reservedNumber && (
                      <Descriptions.Item label='Current Number'>{selectedClient.reservedNumber}</Descriptions.Item>
                    )}
                  </Descriptions>
                )}
              </div>
              <div>
                <Text strong className='block mb-2'>
                  Action
                </Text>
                {selectedClient &&
                  (selectedClient.reservedNumber ? (
                    <Button danger onClick={() => setIsConfirmationModalActive(true)}>
                      Remove Number
                    </Button>
                  ) : (
                    <>
                      <div className='flex gap-2'>
                        {isLoadingTwilioNumbers ? (
                          <div className='flex justify-center flex-1'>
                            <Spin />
                          </div>
                        ) : (
                          <>
                            <Select
                              showSearch
                              className='flex-1'
                              placeholder='Select a phone number'
                              optionFilterProp='children'
                              onChange={handleNumberSelect}
                              value={selectedNumber}
                              filterOption={(input, option) =>
                                (option?.children as unknown as string)
                                  .toLowerCase()
                                  .includes(input.replace(/\s|-|\(|\)/g, '').toLowerCase())
                              }
                            >
                              {twilioNumberData?.numbers?.map(number => (
                                <Option key={number.sid} value={number.phoneNumber}>
                                  {number.phoneNumber}
                                </Option>
                              ))}
                            </Select>
                            <Button
                              icon={<ReloadOutlined />}
                              onClick={handleRefreshNumbers}
                              loading={isLoadingTwilioNumbers}
                              size='middle'
                            />
                          </>
                        )}
                      </div>

                      {selectedNumber && (
                        <Button
                          type='primary'
                          onClick={handleApply}
                          className='mt-3'
                          loading={assignClientNumberMutation.isPending}
                        >
                          Apply Changes
                        </Button>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </Card>

          <Card title='Sub Accounts' className='mt-6'>
            <Table
              dataSource={clients}
              columns={columns}
              rowKey='number'
              loading={getClientsMutation.isPending}
              pagination={{
                total: clients.length,
                pageSize: 10,
                showTotal: total => `Total ${total} items`,
              }}
            />
          </Card>
        </div>
      </div>

      <CustomModal
        title='Twilio information required'
        visible={isTwilioDetailsModalActive}
        confirmAction={() => navigate(AppRoutes.RESELLER_BRANDING)}
        cancelAction={() => {}} // Empty function to prevent closing on cancel
        cancelButtonProps={{ style: { display: 'none' } }} // Hide the cancel button
        closable={false} // Prevent closing by clicking outside or pressing ESC
        maskClosable={false}
      >
        <div className=''>
          <p>
            Please head over to the{' '}
            <Link to={AppRoutes.RESELLER_BRANDING}>
              <span className='text-blue-600 font-bold'>"Branding"</span>
            </Link>{' '}
            tab to add your twilio config
          </p>
          <Title level={4}>Where to find Twilio Account SID and Auth Token?</Title>
          <p className=''>
            For the instructions, see the{' '}
            <a
              className='text-blue-600'
              href='https://help.twilio.com/articles/14726256820123-What-is-a-Twilio-Account-SID-and-where-can-I-find-it-'
              target='_blank'
              rel='noreferrer'
            >
              <span className='text-blue-600 font-bold'>official twilio docs</span>
            </a>
          </p>
          {resellerData?.permanently_unbranded || resellerData?.branded ? (
            ''
          ) : (
            <>
              <p className='font-bold text-center'>OR</p>
              <p>
                Follow this{' '}
                <a
                  className='text-blue-600'
                  href='https://www.loom.com/share/de9348ff74c94de49e75551a4543d81a'
                  target='_blank'
                  rel='noreferrer'
                >
                  video
                </a>
              </p>
            </>
          )}
        </div>
      </CustomModal>
      <CustomModal
        title='Are you sure?'
        visible={isConfirmationModalActive}
        cancelAction={() => {
          setIsConfirmationModalActive(false);
          setSelectedNumber(null);
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setIsConfirmationModalActive(false);
              setSelectedNumber(null);
            }}
          >
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            danger
            onClick={handleRemoveNumber}
            loading={removeClientNumberMutation.isPending}
          >
            Remove
          </Button>,
        ]}
      >
        <p className=''>
          Are you sure you want to remove the phone number? This will disassociate the phone number from the account
          until to assign it back
        </p>
      </CustomModal>
    </ResellerDashboard>
  );
};

export default ImportPhoneNumbers;
