import { Button, Form, Input, Alert, notification } from 'antd';
import PublicLayout from '@/components/Layout/PublicLayout';
import './ResellerForgotPasswordForm.scss';
import { useMutation } from '@tanstack/react-query';
import { resellerUserExists as checkUserExists } from '@/api/reseller';
import { useState } from 'react';
import { TResellerForgotPassword } from '@/types/TResellerForgotPassword';

export const ResellerForgotPasswordForm: React.FC = () => {
  const [form] = Form.useForm<TResellerForgotPassword>();
  const [userExistsResponse, setUserExistsResponse] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Use the correct function name when using the mutation
  const { mutate: checkUserExistsMutation, isPending } = useMutation({
    mutationKey: ['resellerUserExists'],
    mutationFn: checkUserExists,
    onSuccess: data => {
      if (data.resellerUserExists) {
        setUserExistsResponse(true);
        setErrorMessage(null);
      } else {
        setUserExistsResponse(false);
        setErrorMessage('Incorrect username or email');
      }
    },
    onError: () => {
      setErrorMessage('An error occurred while checking the user');
      notification.error({
        message: 'Error',
        description: 'Please reach out to contact@myaifrontdesk.com for assistance.',
      });
    },
  });

  const submitUsernameOrEmail = (values: TResellerForgotPassword) => {
    checkUserExistsMutation({ email_address: values.email_address });
  };

  const handleInputChange = () => {
    setErrorMessage(null);
  };

  const baseClass = 'forgot-password-form-container';

  return (
    <PublicLayout debrand={false} items={[]}>
      <Form
        onFinish={submitUsernameOrEmail}
        form={form}
        layout='vertical'
        name='forgot-password-form'
        className={baseClass}
      >
        <Form.Item className={`${baseClass}__heading`}>
          <h2>Forgot Password</h2>
          <p>Enter your email address, and we will send you a link to reset your password.</p>
        </Form.Item>

        {userExistsResponse && (
          <Form.Item className={`${baseClass}__success-response`}>
            <Alert message='Password reset link has been sent to your email.' type='success' />
          </Form.Item>
        )}

        {userExistsResponse === false && errorMessage && (
          <Form.Item className={`${baseClass}__error-response`}>
            <Alert message={errorMessage} type='error' />
          </Form.Item>
        )}

        {!userExistsResponse && (
          <>
            <Form.Item
              label='Email Address'
              name='email_address'
              className={`${baseClass}__input-item`}
              normalize={value => value.trim()}
              rules={[
                {
                  required: true,
                  message: 'Email address is required!',
                },
              ]}
            >
              <Input placeholder='Email Address' onChange={handleInputChange} />
            </Form.Item>

            <Form.Item>
              <Button loading={isPending} htmlType='submit' className={`${baseClass}__submit-button`}>
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </PublicLayout>
  );
};
