import { Content } from 'antd/es/layout/layout';
import './ContentComponent.scss';
import { Analytics } from '@/pages/ReceptionistDashboard/Analytics';
import { Notifications } from '@/pages/ReceptionistDashboard/Notifications';
import { Overview } from '../Overview';
import CallLogs from '../LogsComponent/CallLogs/CallLogs';
import TextLogs from '../LogsComponent/TextLogs/TextLogs';
import VoiceMailLogs from '../LogsComponent/VoiceMailLogs/VoiceMailLogs';
import CrmDashboard from '../CrmDashboard/CrmDashboard';
import VoiceLibrary from '../VoiceLibrary/voiceLibrary';
import { TextingPlatform } from '../TextingPlatForm/index';
import { ContactSupport } from '../ContactSupport';
import SubscriptionManagement from '@/pages/SubscriptionManagemnt/SubscriptionManagement';
import IntakeForms from '../IntakeForms/IntakeForms';
import { ReceptionistDashboardSiderItem } from 'src/enums/EReceptionistDashboardSiderItem';
import { PhoneNumberSettings } from '../TestItOut/v2/PhoneNumberSettings';
import Home from '../Home/Home';
import ExploreFeaturesPage from '../ExploreFeatures';
interface ContentComponentProps {
  currentPage: string;
  setOpened: (open: boolean) => void;
  setOpenedOnboarding: (open: boolean) => void;
  debrand: boolean;
  apiWorkflowRef: React.RefObject<HTMLDivElement>;
  gcalRef: React.RefObject<HTMLDivElement>;
  intakeRef: React.RefObject<HTMLDivElement>;
}

const ContentComponent: React.FC<ContentComponentProps> = ({
  currentPage,
  debrand,
  apiWorkflowRef,
  gcalRef,
  intakeRef,
  setOpenedOnboarding,
}) => {
  const renderContent = () => {
    switch (currentPage) {
      case ReceptionistDashboardSiderItem.SETUP_INSTRUCTIONS:
        return <PhoneNumberSettings setOpenedOnboarding={setOpenedOnboarding} debrand={true} />;
      case ReceptionistDashboardSiderItem.HOME:
        return <Home />;
      case ReceptionistDashboardSiderItem.CONFIGURE:
        return <Overview debrand={debrand} apiWorkflowRef={apiWorkflowRef} gcalRef={gcalRef} intakeRef={intakeRef} />;
      case ReceptionistDashboardSiderItem.ANALYTICS:
        return <Analytics debrand={debrand} />;
      case ReceptionistDashboardSiderItem.VOICE_LIBRARY:
        return <VoiceLibrary />;
      case ReceptionistDashboardSiderItem.TEXTING_PLATFORM:
        return <TextingPlatform debrand={debrand} isNoviUser={false} />;
      case ReceptionistDashboardSiderItem.INTAKE_FORMS:
        return <IntakeForms />;
      case ReceptionistDashboardSiderItem.NOTIFICATIONS:
        return <Notifications />;
      case ReceptionistDashboardSiderItem.CALL_LOGS:
        return <CallLogs debrand={debrand} isNoviUser={false} />;
      case ReceptionistDashboardSiderItem.TEXT_LOGS:
        return <TextLogs />;
      case ReceptionistDashboardSiderItem.VOICE_MAIL_LOGS:
        return <VoiceMailLogs />;
      case ReceptionistDashboardSiderItem.CRM:
        return <CrmDashboard isNoviUser={false} />;
      case ReceptionistDashboardSiderItem.CONTACT_SUPPORT:
        return <ContactSupport />;
      case ReceptionistDashboardSiderItem.SUBSCRIPTION_MANAGEMENT:
        return <SubscriptionManagement />;
      case ReceptionistDashboardSiderItem.EXPLORE_FEATURES:
        return <ExploreFeaturesPage />;
      default:
        break;
    }
  };
  const baseClass = 'content-component';
  return (
    <Content className={baseClass}>
      <div className={`${baseClass}_content`}>{renderContent()}</div>
    </Content>
  );
};

export default ContentComponent;
