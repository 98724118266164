import React from 'react';
import classes from '../../Whitelabel/ResellerPortal.module.css';

const ModalWrapper = ({ close, children, heading = null, className }) => {
  return (
    <>
      <div
        onClick={() => {
          close(false);
        }}
        className={classes.modalOverlay}
      ></div>
      <div
        className={`fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-11/12 md:min-w-[40%] max-h-11/12 bg-white z-[101] overflow-y-auto p-10 ${className}`}
      >
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-bold">{heading}</p>
          <div
            className={'text-4xl cursor-pointer'}
            onClick={() => {
              close(false);
            }}
            style={{ zIndex: 100 }}
          >
            &times;
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default ModalWrapper;
