import { Table, TableProps } from 'antd';
import './ShortUrl.scss';
import { CallData, TShortUrl, TShortUrlAnalytics } from '@/types/TShortUrl';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

const baseClass = 'short-url';
const baseUrl = import.meta.env.VITE_APP_BASE_URL;

const COLUMNS: TableProps<TShortUrl>['columns'] = [
  {
    title: 'Original URL',
    dataIndex: 'originalURL',
    key: 'originalURL',
    render: (_: any, record: TShortUrl) => (
      <div className='flex items-center gap-1'>
        <a href={record?.originalURL} target='_blank' rel='noopener noreferrer'>
          {record?.originalURL?.length > 50 ? `${record.originalURL.slice(0, 50)}...` : record.originalURL}
        </a>
        <CopyableText text={record?.originalURL} hideText />
      </div>
    ),
  },
  {
    title: 'Total Clicks',
    dataIndex: 'totalClicks',
    key: 'totalClicks',
  },
];

const EXPANDED_ROW_RENDERER = (record: TShortUrl) => {
  const callColumns: TableProps<CallData>['columns'] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Clicks From Call',
      dataIndex: 'clicksFromCall',
      key: 'clicksFromCall',
    },
    {
      title: 'Shareable Link',
      dataIndex: 'shareableLink',
      key: 'shareableLink',
      render: (link: string) => (
        <a href={`${baseUrl}/call-information/${link}`} target='_blank' rel='noopener noreferrer'>
          Share Link
        </a>
      ),
    },
  ];

  return (
    <Table
      columns={callColumns}
      dataSource={record.calls}
      pagination={false}
      rowKey={call => call.shareableLink}
      className={`${baseClass}_expanded-table`}
    />
  );
};

export const ShortUrl = ({ analyticsData }: { analyticsData: TShortUrlAnalytics }) => {
  const analyticsDataSource: TShortUrl[] = Object.keys(analyticsData).map(key => ({
    key,
    originalURL: analyticsData[key].originalURL,
    totalClicks: analyticsData[key].totalClicks,
    calls: analyticsData[key].calls,
  }));

  return (
    <div className={`${baseClass}_table-container`}>
      <div className='my-2'>
        <LearnMore tutorialId={TUTORIALS.LINK_TRACKING} />
      </div>

      <Table
        columns={COLUMNS}
        dataSource={analyticsDataSource}
        expandable={{ expandedRowRender: EXPANDED_ROW_RENDERER }}
        rowKey='key'
        className={`${baseClass}_table`}
      />
    </div>
  );
};
