import React from 'react';
import { Tooltip, Typography } from 'antd';

const { Text } = Typography;

const EllipsisText: React.FC<{ text: string; length?: number; extraClass?: string }> = ({
  text,
  length = 8,
  extraClass = '',
}) => {
  return text.length > length ? (
    <Tooltip title={text} className="cursor-pointer">
      <Text className={extraClass} ellipsis>
        {generateEllipses({ text, length })}
      </Text>
    </Tooltip>
  ) : (
    <span className={extraClass}>{text}</span>
  );
};

export default EllipsisText;

// generate ellipsis utility
export const generateEllipses = ({ text, length }: { text: string; length: number }) => {
  const displayText = text.length > length ? `${text.substring(0, length)} ...` : text;
  return displayText;
};
