// @ts-nocheck
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { Card, Space, Button, Tag, Avatar } from 'antd';
import { EditOutlined, UserOutlined, CalendarOutlined } from '@ant-design/icons';
import { useUpdateLeadStatusOnKanban } from 'src/Hooks/UseHookForCrmData';
import { useState, useEffect } from 'react';

interface Lead {
  title: string;
  lead_id: string;
  username: string;
  current_status: string;
  first_contact: string;
  phone_number: string;
  last_contact: string;
  status_history: {
    timestamp: string;
    status: string;
    status_type: string;
  }[];
  notes: string;
  triggeredWorkflows: string[];
}

interface Group {
  id: string;
  name: string;
  definition_type: string;
  definition: string;
  workflow_name: string;
  created_at: string;
  username: string;
}

type BoardViewProps = {
  groups: Group[];
  leads: Lead[];
  setSelectedGroup: (group: Group) => void;
  setEditGroupModal: (value: boolean) => void;
  handleViewLead: (lead: Lead) => void;
  refetch: () => void;
};

const BoardView: React.FC<BoardViewProps> = ({
  refetch,
  groups,
  leads,
  setSelectedGroup,
  setEditGroupModal,
  handleViewLead,
}) => {
  const { mutate: UpdateLeadData } = useUpdateLeadStatusOnKanban();
  const [leadsState, setLeadsState] = useState<Lead[]>(leads);

  useEffect(() => {
    setLeadsState(leads);
  }, [leads]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const lead = leadsState.find(lead => lead.lead_id === draggableId);
    if (lead) {
      // Update the leads state optimistically
      const updatedLeads = [...leadsState];
      const movedLead = updatedLeads.find(l => l.lead_id === lead.lead_id);
      if (movedLead) {
        movedLead.current_status = destination.droppableId;
      }
      setLeadsState(updatedLeads);

      // Perform the API call
      UpdateLeadData(
        {
          newGroupId: destination.droppableId,
          lead_id: lead.lead_id,
          username: lead.username,
          phone_number: lead.phone_number,
        },
        {
          onSuccess: () => {
            // Refetch the leads data to ensure consistency
            refetch();
          },
          onError: () => {
            // Revert the leads state to the previous state
            setLeadsState(leads);
            console.log('Error updating lead status');
          },
        }
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='h-[calc(100vh-64px)] overflow-x-auto'>
        <div className='flex gap-6 p-4 h-full'>
          {groups.map(group => (
            <div key={group.id} className='flex-shrink-0 w-[320px]'>
              <Droppable droppableId={group.id}>
                {(provided, snapshot) => (
                  <Card
                    className={`h-full bg-gray-50 ${snapshot.isDraggingOver ? 'bg-blue-50' : ''}`}
                    title={
                      <Space className='w-full justify-between'>
                        <Space>
                          <Tag className='rounded-full px-3'>{group.name}</Tag>
                          <span className='text-gray-500'>
                            ({leadsState.filter(lead => lead.current_status === group.id).length})
                          </span>
                        </Space>
                        <Button
                          type='text'
                          icon={<EditOutlined />}
                          onClick={() => {
                            setSelectedGroup(group);
                            setEditGroupModal(true);
                          }}
                        />
                      </Space>
                    }
                  >
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className='space-y-4 h-[calc(100vh-200px)] overflow-y-auto'
                    >
                      {leadsState
                        .filter(lead => lead.current_status === group.id)
                        .map((lead, index) => (
                          <Draggable key={lead.lead_id} draggableId={lead.lead_id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <Card
                                  className={`shadow-sm ${snapshot.isDragging ? 'shadow-lg ring-2 ring-blue-500' : ''}`}
                                  onClick={() => handleViewLead(lead)}
                                >
                                  <Space direction='vertical' className='w-full' size='middle'>
                                    <Space className='w-full justify-between'>
                                      <Space>
                                        <Avatar icon={<UserOutlined />} className='bg-blue-500' />
                                        <span className='font-medium'>{lead.phone_number}</span>
                                      </Space>
                                    </Space>
                                    <Space className='text-gray-500'>
                                      <CalendarOutlined />
                                      <span>First Contact: {lead.first_contact}</span>
                                    </Space>
                                  </Space>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  </Card>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </div>
    </DragDropContext>
  );
};

export default BoardView;
