import { APP_LANDING_PAGE_FEATURES as LPFt } from './ERoutes';

export enum TUTORIALS {
  MAX_CALL_DURATION = `${LPFt}/adjustable-maximum-call-duration`,
  POST_CALL_WEBHOOK = `${LPFt}/advanced-post-call-webhooks-supercharging-your-ai-receptionist`,
  ANALYTICS = `${LPFt}/analytics-tab-comprehensive-call-insights`,
  API_WORKFLOW = `${LPFt}/api-workflows-real-time-data-integration`,
  AUTO_HANGUP = `${LPFt}/auto-hangup-feature`,
  AUTOPILOT_CRM = `${LPFt}/autopilot-crm-your-ai-receptionists-secret-weapon`,
  CALL_RECORDING = `${LPFt}/call-recordings-your-ais-memory-bank`,
  CALL_TRANSFERING_WORKFLOW = `${LPFt}/call-transferring-workflows-real-time-intelligent-call-routing`,
  ACTIVE_TIME_CONTROL = `${LPFt}/control-active-times`,
  EXTENSION_DIGITS = `${LPFt}/extension-digits-old-school-simplicity-meets-ai-smarts`,
  INTAKE_FORM_WORKFLOW = `${LPFt}/intake-form-workflows-automated-information-gathering`,
  LINK_TRACKING = `${LPFt}/link-tracking-the-hidden-pulse-of-your-business`,
  MINUTES_INCLUDED = `${LPFt}/minutes-included-free-for-most-fair-for-all`,
  NON_ENGLISH_LANGUAGE_SUPPORT = `${LPFt}/non-english-language-support`,
  PHONE_AND_TEXTING = `${LPFt}/phone-and-texting-features-your-ai-receptionists-swiss-army-knife`, //*//
  PICK_YOUR_AREA_CODE = `${LPFt}/pick-your-area-code`, //*//
  POST_CALL_NOTIFICATION = `${LPFt}/post-call-notifications-your-businesss-sixth-sense`, //*//
  PREMIUM_AI_MODELS = `${LPFt}/premium-ai-models-the-brains-behind-our-digital-receptionist`, //*//
  PRONUNCIATION_GUIDES = `${LPFt}/pronunciation-guides`,
  MAX_RECEPTIONIST_MINUTES = `${LPFt}/set-max-receptionist-minutes`,
  SHARABLE_CALL_LINK = `${LPFt}/shareable-call-links`,
  TEXTING_WORKFLOW = `${LPFt}/texting-workflows-intelligent-sms-during-calls`,
  NOTIFICATION_SYSTEM = `${LPFt}/the-notification-system-that-thinks`, //*//
  PHONE_AS_BOOKING_INTERFACE = `${LPFt}/the-phone-as-a-booking-interface-revolutionizing-customer-interactions`, //
  LOW_LATENCY = `${LPFt}/the-speed-of-thought`, //*//
  VOICE_LIBRARY = `${LPFt}/the-voice-library-your-businesss-secret-weapon`,
  VOICEMAILS = `${LPFt}/voicemail-ai-powered-message-taking`,
  ZAPIER_INTEGRATION = `${LPFt}/zapier-integration-a-game-changer`,
  GOOGLE_CALENDAR = `${LPFt}/google-calendar-workflows`,
}
