// StripeProvider.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLIC_KEY ||
    'pk_live_51MsKKKBeuiKgmCo1PUfwFl6ntHOnwQZoJh0cZsNIMXyW0eo2xNdFdDy1jdUcgVfuiPtZz4JfuJdNnECuuXxekOYp00fHiG7ACz'
);

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
