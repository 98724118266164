import { Alert, Button, Checkbox, Form, Input, notification, Popconfirm, Tooltip } from 'antd';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { OverViewCardForm } from '../OverviewCardForm';
import React, { useState } from 'react';
import { RefetchOptions, QueryObserverResult, useMutation } from '@tanstack/react-query';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { closeModal } from '@/store/modal/slice';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { deleteCalendar, updateCalendar } from '@/api/calendar';
import { TCalendar, TCalendarOtherDetails } from '@/types/TCalendar';
import NumberInput from '@/components/Inputs/NumberInput';
import { useNavigate } from 'react-router-dom';
import Spinner from '@/components/Spinner/Spinner';
import LearnMore from '@/components/Buttons/LearnMore';
import { TUTORIALS } from 'src/enums/ETutorials';

export const CalendarWorkflowForm: React.FC<{
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>;
  askOtherDetails: TCalendarOtherDetails[];
  onClose: (val: boolean) => void;
  calendar: TCalendar | undefined;
}> = ({ refetch, calendar, askOtherDetails }) => {
  const navigate = useNavigate();

  const [form] = Form.useForm<{
    lengthOfAppointment: number;
    noOfConcurrentEventsAllowed: number;
    gapBetweenAppointments: number;
    askOtherDetails: TCalendarOtherDetails[];
  }>();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const { mutate: updateCalendarFn, isPending } = useMutation({
    mutationKey: ['update-calendar'],
    mutationFn: updateCalendar,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Calendar updated successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
      navigate(window.location.pathname, { replace: true });
    },
  });

  const { mutate: deleteCalendarFn, isPending: isPendingDelete } = useMutation({
    mutationKey: ['delete-calendar'],
    mutationFn: deleteCalendar,
    onSuccess({ success }) {
      if (success) {
        notification.success({ message: 'Calendar removed successfully' });
        dispatch(closeModal());
      }
      refetch();
      navigate(window.location.pathname, { replace: true });
    },
  });

  const handleSubmit = (value: any) => {
    const cleanedRows = value.askOtherDetails.filter(
      (row: TCalendarOtherDetails) => row.field.trim() !== '' && row.description.trim() !== ''
    );
    const updatedCalendar = {
      ...calendar,
      lengthOfAppointment: value.lengthOfAppointment,
      noOfConcurrentEventsAllowed: value.noOfConcurrentEventsAllowed,
      gapBetweenAppointments: value.gapBetweenAppointments,
    };
    updateCalendarFn({
      // calendar details
      calendar: updatedCalendar,
      askOtherDetails: cleanedRows,
      calendarToWrite: calendar?.nameOfCalendar,
    });
  };

  // child components
  const renderLabelWithDescription = (label: string, description?: string, className: string = ''): JSX.Element => (
    <LabelWithDescription
      customClassName={`w-full ${className}`}
      label={<span className='text-sm font-semibold'>{label}</span>}
      description={description}
    />
  );

  const renderNumberInput = (name: string, min?: number, max?: number): JSX.Element => (
    <NumberInput name={name} min={min} max={max} />
  );

  const renderTooltip = (title: string, content: string): JSX.Element => (
    <Tooltip
      placement='top'
      title={
        <span>
          <span className='font-medium mr-1'>{title}:</span>
          {content}
        </span>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  const FormItems = () => (
    <>
      <Form.Item
        label={renderLabelWithDescription(
          'Calendar Workflow',
          'You can connect Google Calendar to your receptionist, so that a calendar event is directly attached when your customers schedule a meeting.',
          'mt-2'
        )}
      />
      <div className='mb-2'>
        <LearnMore tutorialId={TUTORIALS.PHONE_AS_BOOKING_INTERFACE} />
      </div>
      <div className='w-full flex flex-col md:flex-row justify-between gap-8'>
        <div className='w-1/2'>
          {renderLabelWithDescription('Calendar Name')}
          <div className='mb-4'>Google Calendar</div>
          {renderLabelWithDescription('Duration of the meeting (in minutes)', undefined, 'mb-0')}
          {renderNumberInput('lengthOfAppointment', 10, 300)}
        </div>
        <div className='w-1/2'>
          {renderLabelWithDescription('Number of concurrent events allowed', undefined, 'mb-0')}
          {renderNumberInput('noOfConcurrentEventsAllowed', undefined, 50)}
          {renderLabelWithDescription('Gap between appointments (in minutes)', undefined, 'mb-0')}
          {renderNumberInput('gapBetweenAppointments', 0, 300)}
        </div>
      </div>

      {renderLabelWithDescription(
        'Information to collect',
        'These are details you want to ask from the user who is interacting with your AI receptionist for booking a calendar meeting.',
        'mt-6'
      )}

      <Form.List name='askOtherDetails'>
        {(fields, { add, remove }) => (
          <>
            {fields.length > 0 && (
              <div className='w-full flex items-center mb-2 text-sm font-medium'>
                <div className='w-1/5 flex items-center gap-1 justify-start'>
                  Spell Out
                  {renderTooltip(
                    'Spell Out',
                    "If you want the AI receptionist to repeat the user's input for the provided field, specify here by checking the box."
                  )}
                </div>
                <div className='w-2/5 flex items-center gap-1 justify-start'>
                  Field
                  {renderTooltip('Field', 'The name of the field to be asked from the user (please avoid any spaces).')}
                </div>
                <div className='w-2/5 flex items-center gap-1 justify-start'>
                  Description
                  {renderTooltip(
                    'Description',
                    'A description related to the field for the AI receptionist to understand what information needs to be collected or asked.'
                  )}
                </div>
              </div>
            )}

            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className='flex gap-2 mb-2'>
                <Form.Item {...restField} name={[name, 'spellOut']} className='w-1/5 flex-auto' valuePropName='checked'>
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'field']}
                  className='w-2/5 flex-auto'
                  rules={[{ required: true, message: 'Missing field' }]}
                >
                  <Input placeholder={`First Name`} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'description']}
                  className='w-2/5 flex-auto'
                  rules={[{ required: true, message: 'Missing description' }]}
                >
                  <Input placeholder={`The caller's first name`} />
                </Form.Item>
                {fields.length > 0 && <MinusCircleOutlined className='pb-4' onClick={() => remove(name)} />}
              </div>
            ))}
            <Form.Item>
              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                Add Other Details
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      {askOtherDetails && askOtherDetails.length > 0 && (
        <div className='flex flex-col text-xs mt-2'>
          <span>
            For e.g, let's say, you placed <i>Field</i> name <b>city</b> and on <i>Description</i> placed{' '}
            <b>City of user who is interacting with AI assistant</b> and checked the box for <i>Spell out</i>. Then from
            the user, AI receptionist will ask for the city, e.g{' '}
            <i>
              <b>New York</b>
            </i>{' '}
            and do spelling check
          </span>
        </div>
      )}

      <Popconfirm
        title='Remove the calendar'
        description='Are you sure to remove this calendar?'
        onConfirm={() => deleteCalendarFn()}
        okText='Yes'
        cancelText='No'
      >
        <div className='flex items-center gap-1 cursor-pointer text-red-500 mt-4'>
          <DeleteOutlineRounded />
          Remove Calendar
        </div>
      </Popconfirm>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.askOtherDetails !== currentValues.askOtherDetails}
      >
        {({ getFieldValue }) =>
          getFieldValue('askOtherDetails')?.length > 0 && (
            <Alert
              className='mt-4'
              type='info'
              closable={false}
              message={
                <div className='flex flex-col text-xs'>
                  <span className='mt-2'>
                    Whatever information you request in <span className='font-semibold'>Information to Collect</span>{' '}
                    will be asked from the user, and these details will be sent via text message to you.
                  </span>
                </div>
              }
            />
          )
        }
      </Form.Item>
    </>
  );

  return (
    <div>
      <Spinner spinning={isPending || isPendingDelete}>
        <OverViewCardForm
          form={form}
          initialValues={{
            lengthOfAppointment: calendar?.lengthOfAppointment,
            noOfConcurrentEventsAllowed: calendar?.noOfConcurrentEventsAllowed,
            gapBetweenAppointments: calendar?.gapBetweenAppointments,
            askOtherDetails: askOtherDetails,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spinner>
    </div>
  );
};
