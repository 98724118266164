import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { Reseller } from '@/types/TReseller';

export const resellerState = (state: RootState) => state.reseller;

export const getResellerData = (state: RootState): Reseller => state.reseller.data;

export const getResellerAuthenticationStatus = createSelector([resellerState], reseller => reseller.isAuthenticated);

export const getResellerClients = createSelector([resellerState], reseller => reseller.clients);
