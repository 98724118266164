import React from 'react';
import Fade from 'react-reveal/Fade';

const ThemeColorSelectionScreen = ({
  onChange,
  heading,
  classes,
  setStepState,
  themeColor,
  buttonText,
  onNext,
  onSkip,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            {heading}
          </h3>

          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center', // Add this line
              marginBottom: '3rem',
            }}
          ></div>
          <input
            id="color"
            type="color"
            className={classes.input + ' ' + classes.centered}
            required
            value={themeColor}
            onChange={onChange}
            placeholder="e.g. john@johnsbarbershop.com"
          />

          {
            <button
              type="button"
              onClick={onNext}
              className={classes.button + ' ' + classes.centered}
              style={{
                marginTop: '2rem',
              }}
            >
              {buttonText}
            </button>
          }
          {/* <div
                      onClick={() => setStepState(5)}
                      className={classes.later}
                    >
                      I'll do this later
                    </div> */}
        </Fade>
      </div>
    </>
  );
};

export default ThemeColorSelectionScreen;
