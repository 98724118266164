import { useState } from 'react';
import { Alert, Form, notification, Spin } from 'antd';
import { LabelWithDescription } from '@/components/LabelWithdescription';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, useMutation } from '@tanstack/react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { closeModal } from '@/store/modal/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { User } from '@/types/TAccounts';
import TextArea from 'antd/es/input/TextArea';
import { updateUser } from '@/api/user';
import CopyableText from '@/components/LabelWithdescription/CopyableText';

interface QuestionAndAnswerFormProps {
  sampleQA?: string;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
}
export const QuestionAndAnswerForm: React.FC<QuestionAndAnswerFormProps> = ({ sampleQA, refetch }) => {
  const [form] = Form.useForm<{
    sampleQA: string;
  }>();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const mutation = useMutation({
    mutationKey: ['update-client'],
    mutationFn: updateUser,
    onSuccess: () => {
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Q&A has been saved successfully!',
        duration: 3,
      });
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    },
  });

  const handleSubmit = (value: any) => {
    mutation.mutate({
      sample_qa: value.sampleQA,
    });
  };

  const FormItems = () => (
    <>
      <Form.Item
        label={
          <div>
            <LabelWithDescription
              customClassName="mt-2 w-full"
              label={
                <div className="flex items-center gap-1">
                  Questions and Answers (Q&A)
                  <CopyableText text={sampleQA || ''} hideText />
                </div>
              }
            />
          </div>
        }
        name="sampleQA"
        className="!mb-0"
      >
        <TextArea rows={12} placeholder="Please enter your Q&A" />
      </Form.Item>{' '}
      <Alert
        className="mt-4"
        type="info"
        closable={false}
        message={
          <>
            <b>Please Note:</b>
            <p className="text-justify">Please write your Questions and Answers in separate paragraphs, e.g.:</p>
            <span className="flex flex-col">
              <span>Question: Where are you located?</span>
              <span>Answer: We are located at 132 Linkin Park, New York.</span>
            </span>
            <br />
            <span className="flex flex-col">
              <span>Question: How many other branches do you have?</span>
              <span>Answer: We don't have any subsidiary branches.</span>
            </span>
          </>
        }
      />
    </>
  );

  return (
    <div>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={{
            sampleQA,
          }}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </div>
  );
};
