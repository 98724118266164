// PaymentForm.js
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { serverURL } from '../../config';
import { CiCreditCard1 } from 'react-icons/ci';
import Button from '../../components/Button';
import toast from 'react-hot-toast';

const PaymentForm = ({ customerId, themeColor, email_address, setUser, close }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(serverURL + '/api/reseller/add-or-update-payment-method', {
        customerId,
        paymentMethodId: paymentMethod.id,
        email_address,
      });

      if (response.data.success) {
        setUser(prev => ({
          ...prev,
          customerId: { S: response.data.customer },
        }));
        toast.success('Payment method added successfully');
        close();
      } else {
        toast.error(response.data.error);
      }
    } catch (err) {
      setError('An error occurred while adding the payment method');
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-5 my-3">
      <CiCreditCard1 className="text-[100px] self-center" />
      <CardElement className="border p-2 rounded-md" />
      <Button
        text="Add Payment Method"
        themeColor={themeColor}
        type="submit"
        className="self-center min-w-72"
        loading={loading}
      />
      {error && <div className="text-white bg-[#ed3d3d] rounded-md p-2 text-center">{error}</div>}
    </form>
  );
};

export default PaymentForm;
