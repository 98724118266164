import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Input, Radio, Form, Select, Progress, Spin, message, Space } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import logo from '@/assets/images/logo.png';
import { checkResellerEmail, sendResponseEmail } from '@/api/resellerQualification';
import { useMutation } from '@tanstack/react-query';

interface FormItemProps {
  stepNumber: number;
  question: string;
  inputData: {
    type: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
    options?: string[] | { value: string; label: string }[];
    selected?: string;
    onSelect?: (value: string) => void;
  };
  onEnter: () => void;
  isActive: boolean;
  error?: string;
  checkingInformation?: boolean;
}

const FormItem: React.FC<FormItemProps> = ({
  stepNumber,
  question,
  inputData,
  onEnter,
  isActive,
  error,
  checkingInformation = false,
}) => {
  return (
    <motion.div
      className="flex flex-col justify-center items-center min-h-screen w-full px-4 py-8"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full max-w-3xl bg-white p-8 md:p-12">
        <div className="mb-6 flex items-center">
          <h2 className="text-2xl md:text-3xl font-bold text-blue-600 mr-3 leading-3">{stepNumber}.</h2>
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-800">{question}</h2>
        </div>
        <div className="mb-8">
          <Form.Item name={inputData.name} validateStatus={error ? 'error' : ''} help={error}>
            {inputData.type === 'text' && (
              <Input
                className="[&_input]:!h-14 !px-4 py-4"
                value={inputData.value}
                onChange={e => inputData.onChange(e.target.value)}
                placeholder="Type your answer here..."
              />
            )}
            {inputData.type === 'radio' && (
              <Radio.Group
                value={inputData.selected}
                onChange={e => inputData.onSelect && inputData.onSelect(e.target.value)}
              >
                <Space direction="vertical" size={16}>
                  {(inputData.options as string[]).map(option => (
                    <Radio key={option} value={option} className="text-base">
                      {option}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            )}
            {inputData.type === 'select' && (
              <Select
                className="[&_.ant-select-selector]:!h-14 h-14"
                value={inputData.value}
                onChange={value => inputData.onChange(value)}
              >
                {(inputData.options as { value: string; label: string }[]).map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </div>
        {isActive && (
          <Button className="h-11 w-[90px] p-2 text-lg" type="primary" onClick={onEnter} disabled={checkingInformation}>
            {stepNumber === 7 ? 'Submit' : 'Next'}
          </Button>
        )}
      </div>
    </motion.div>
  );
};

const steps: {
  [key: string]: any;
} = [
  {
    id: 1,
    question: "What's your Email?",
    name: 'email',
    type: 'text',
    value: '',
    validations: ['required', 'email'],
  },
  {
    id: 2,
    question: 'What is your monthly budget?',
    name: 'monthlyBudget',
    type: 'radio',
    options: ['0-$250', '$250-$750', '$750+'],
    validations: ['required'],
  },
  {
    id: 3,
    question: 'Do you already have potential clients in the pipeline?',
    name: 'clientsInPipeline',
    type: 'radio',
    options: ['Yes', 'No'],
    validations: ['required'],
  },
  {
    id: 4,
    question: 'Where are you located?',
    name: 'located',
    type: 'select',
    options: [
      { value: 'usa', label: 'United States' },
      { value: 'uk', label: 'United Kingdom' },
      { value: 'australia', label: 'Australia' },
      { value: 'canada', label: 'Canada' },
      { value: 'germany', label: 'Germany' },
      { value: 'france', label: 'France' },
      { value: 'italy', label: 'Italy' },
      { value: 'spain', label: 'Spain' },
      { value: 'netherlands', label: 'Netherlands' },
      { value: 'belgium', label: 'Belgium' },
      { value: 'sweden', label: 'Sweden' },
      { value: 'denmark', label: 'Denmark' },
      { value: 'norway', label: 'Norway' },
      { value: 'finland', label: 'Finland' },
      { value: 'switzerland', label: 'Switzerland' },
      { value: 'austria', label: 'Austria' },
      { value: 'poland', label: 'Poland' },
      { value: 'portugal', label: 'Portugal' },
      { value: 'greece', label: 'Greece' },
      { value: 'ireland', label: 'Ireland' },
      { value: 'other_europe', label: 'Other European Country' },
      { value: 'other', label: 'Other (Non-European)' },
    ],
    validations: ['required'],
  },
  {
    id: 5,
    question: 'Have you tested out the receptionist?',
    name: 'testedReceptionist',
    type: 'radio',
    options: ['Yes', 'No'],
    validations: ['required'],
  },
  {
    id: 6,
    question: 'Do you have a sales strategy for how you would resell our software?',
    name: 'strategyForResell',
    type: 'radio',
    options: ['Yes', 'No'],
    validations: ['required'],
  },
  {
    id: 7,
    question: 'How big is your team?',
    name: 'teamSize',
    type: 'radio',
    options: ['Solo Entrepreneur', '2-5', '5+'],
    validations: ['required'],
  },
];

const validationRules = {
  required: (value: string) => (value ? null : 'This field is required'),
  email: (value: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(value).toLowerCase()) ? null : 'Please enter a valid email address';
  },
};

interface StepperFormProps {
  fromAd?: boolean;
  fromEmail?: boolean;
}

const StepperForm: React.FC<StepperFormProps> = ({ fromAd, fromEmail }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [checkingInformation, setCheckingInformation] = useState(false);
  const navigate = useNavigate();

  const scores: {
    [key: string]: {
      [key: string]: number;
    };
  } = {
    monthlyBudget: {
      '0-$250': 0,
      '$250-$750': 25,
      '$750+': 35,
    },
    clientsInPipeline: {
      Yes: 30,
      No: 0,
    },
    testedReceptionist: {
      Yes: 10,
      No: 0,
    },
    strategyForResell: {
      Yes: 10,
      No: 0,
    },
    teamSize: {
      'Solo Entrepreneur': 10,
      '2-5': 20,
      '5+': 30,
    },
    located: {
      usa: 15,
      uk: 13,
      australia: 13,
      canada: 10,
      germany: 10,
      france: 10,
      italy: 10,
      spain: 10,
      netherlands: 10,
      belgium: 10,
      sweden: 10,
      denmark: 10,
      norway: 10,
      finland: 10,
      switzerland: 10,
      austria: 10,
      poland: 10,
      portugal: 10,
      greece: 10,
      ireland: 10,
      other_europe: 10,
      other: 0,
    },
  };

  const checkEmailMutation = useMutation({
    mutationFn: checkResellerEmail,
    onSuccess: (data: any) => {
      if (data.success && data.found) {
        message.success('Information found. Redirecting...');
        setRedirecting(true);
        setTimeout(() => {
          window.location.href = 'https://calendly.com/d/cpnx-hd4-kr7/ai-front-desk-white-label-additional-call';
        }, 2000);
      }
      setCheckingInformation(false);
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        handleSubmit();
      }
    },
    onError: () => {
      setCurrentStep(currentStep + 1);
      setCheckingInformation(false);
      message.error('Error checking email. Please try again.');
    },
  });

  const sendEmailMutation = useMutation({
    mutationKey: ['sendEmail'],
    mutationFn: sendResponseEmail,
  });

  const validateStep = (step: number) => {
    const currentField = steps[step];
    const value = formData[currentField.name];
    let error: string | null = null;

    if (currentField.validations) {
      for (const validation of currentField.validations) {
        const validationFunction = validationRules[validation as keyof typeof validationRules];
        if (validationFunction) {
          error = validationFunction(value);
          if (error) break;
        }
      }
    }

    setErrors({ ...errors, [currentField.name]: error });
    return !error;
  };

  const handleNext = async () => {
    if (validateStep(currentStep)) {
      const currentField = steps[currentStep];
      if (currentField.name === 'email') {
        try {
          setCheckingInformation(true);
          const value = formData[currentField.name];
          checkEmailMutation.mutate(value);
        } catch (err) {
          console.log(err);
          setCheckingInformation(false);
        }
      } else if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        handleSubmit();
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = (value: string) => {
    setFormData({ ...formData, [steps[currentStep].name]: value });
    setErrors({ ...errors, [steps[currentStep].name]: null });
  };

  const handleScroll = (event: WheelEvent) => {
    event.preventDefault();
    const delta = Math.sign(event.deltaY);
    if (delta > 0) {
      handleNext();
    } else if (delta < 0) {
      handlePrevious();
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    try {
      const isValid = steps.every((_: any, index: number) => validateStep(index));
      if (isValid) {
        let score = 0;
        for (const key in formData) {
          if (key === 'email') continue;
          score += scores[key as keyof typeof scores][formData[key]] || 0;
        }

        setRedirecting(true);
        sendEmailMutation.mutate({ answers: formData, score });
        setTimeout(() => {
          setSubmitLoading(false);
          if (score <= 50 || formData.monthlyBudget === '0-$250') {
            navigate('/affiliate-program');
          } else {
            navigate('/test-receptionist', { state: { fromAd, fromEmail } });
          }
        }, 2000);
      } else {
        setSubmitLoading(false);
        toast.error('Please fill all required fields correctly.');
      }
    } catch (err) {
      toast.error('Error submitting form. Contact Support');
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleScroll as unknown as EventListener, { passive: false });
    }
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleScroll as unknown as EventListener);
      }
    };
  }, [currentStep]);

  const progress = ((currentStep + 1) / steps.length) * 100;

  return (
    <Form form={form}>
      <div className="relative h-screen overflow-hidden" ref={containerRef}>
        <Progress
          rootClassName="!fixed top-0 left-0 right-0 z-10 [&_*]:!rounded-none flex justify-start !overflow-hidden"
          percent={progress}
          size={['100%', 4]}
          showInfo={false}
          strokeColor="#1890ff"
          trailColor="#f5f5f5"
        />
        {redirecting ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <Spin size="large" />
            <p className="mt-4 text-xl">Redirecting to the best option for you...</p>
          </div>
        ) : submitLoading ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <Spin size="large" />
            <p className="mt-4 text-xl">Submitting your information...</p>
          </div>
        ) : (
          <>
            <AnimatePresence mode="wait">
              <FormItem
                key={steps[currentStep].id}
                stepNumber={steps[currentStep].id}
                question={steps[currentStep].question}
                inputData={{
                  type: steps[currentStep].type,
                  name: steps[currentStep].name,
                  value: formData[steps[currentStep].name] || '',
                  onChange: handleChange,
                  options: steps[currentStep].options,
                  selected: formData[steps[currentStep].name] || '',
                  onSelect: handleChange,
                }}
                checkingInformation={checkingInformation}
                isActive={true}
                onEnter={handleNext}
                error={errors[steps[currentStep].name]}
              />
            </AnimatePresence>
            <div className="fixed top-8 left-8">
              <img src={logo} alt="logo" className="w-16" />
            </div>
            <div className="fixed bottom-8 right-8 flex flex-col space-y-4">
              <Button
                onClick={handlePrevious}
                disabled={currentStep === 0}
                icon={<UpOutlined />}
                style={{
                  minWidth: '0',
                  padding: '8px',
                  backgroundColor: 'white',
                }}
              />
              {checkingInformation ? (
                <Spin size="default" />
              ) : (
                <Button
                  onClick={handleNext}
                  disabled={checkingInformation}
                  icon={<DownOutlined />}
                  style={{
                    minWidth: '0',
                    padding: '8px',
                    backgroundColor: 'white',
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </Form>
  );
};

export default StepperForm;
