import { LabelWithDescription } from '@/components/LabelWithdescription';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { Button, Card, Flex, Form, Input, notification, Spin, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { OverViewCardForm } from '../OverviewCardForm';
import { QueryObserverResult, RefetchOptions, UseMutationResult } from '@tanstack/react-query';
import { TextingWorkflowFormInitialValues } from '@/types/TTextingWorkflowForm';
import { openModal } from '@/store/modal/slice';
import { MODAL_IDS } from 'src/enums/EModal';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getUserFeatures } from '@/store/account/selector';
import { closeModal } from '@/store/modal/slice';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import './TextingWorkflowInfo.scss';
import { User } from '@/types/TAccounts';

interface TextingWorkflowFormProps {
  initialValues?: TextingWorkflowFormInitialValues[];
  showAddFieldButton?: boolean;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<User, Error>>;
  actionType: 'ADD' | 'EDIT';
  mutation: UseMutationResult<{}, Error, any, unknown>;
  scenarioIndex?: number;
  debrand: boolean;
  isNoviUser: boolean;
}

const TextingWorkflowForm: React.FC<TextingWorkflowFormProps> = ({
  initialValues,
  showAddFieldButton = true,
  refetch,
  actionType,
  mutation,
  scenarioIndex,
  debrand,
  isNoviUser,
}) => {
  const [form] = Form.useForm();
  const [isFieldTouchReset, setIsFieldTouchReset] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const features = useAppSelector(getUserFeatures);

  useEffect(() => {
    if (initialValues && actionType === 'EDIT') {
      form.setFieldsValue(initialValues);
    }

    if (actionType === 'ADD') {
      form.resetFields();
    }

    return () => {
      form.resetFields();
    };
  }, [initialValues, form, actionType]);

  const handleSubmit = async (values: {
    workflows: {
      description: string;
      voiceResponse: string;
      textMessage: URL | string;
      askForPermissionOnText: boolean;
      shortURLEnabled: boolean;
      customTextFooter: string;
    }[];
  }) => {
    try {
      if (features?.workflows?.textWroflows?.value.toString() !== '-1') {
        if (initialValues?.length || 0 >= parseInt(features?.workflows?.textWroflows?.value.toString() || '1')) {
          dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }));
        }
      }
      let formData: {
        description: string;
        textMessage: string | URL;
        voiceResponse: string;
        shortURLEnabled: boolean;
        customTextFooter: string;
      }[] = [];

      // check here too

      const updatedScenario = values.workflows.map(scenario => ({
        description: scenario.description,
        textMessage: scenario.textMessage,
        voiceResponse: scenario.voiceResponse,
        askForPermissionOnText: scenario.askForPermissionOnText,
        shortURLEnabled: scenario.shortURLEnabled === undefined && !debrand ? true : scenario.shortURLEnabled,
        customTextFooter: scenario.customTextFooter,
      }));

      const scenario = values.workflows[0];

      //@ts-ignore
      if (initialValues?.workflows[0].previousWorkflowData) {
        //@ts-ignore
        scenario.previousWorkflowData = initialValues.workflows[0].previousWorkflowData;
      }
      //@ts-ignore
      const scenarioIndex = scenario?.index ?? 0;

      //@ts-ignore
      if (scenario?.previousWorkflowData) {
        //@ts-ignore
        formData = [...scenario.previousWorkflowData];
      } else {
        console.log('No previous workflow data found');
      }

      if (actionType === 'EDIT') {
        formData.splice(scenarioIndex, updatedScenario.length, ...updatedScenario);
      } else if (actionType === 'ADD') {
        formData = [...formData, ...updatedScenario];
      }

      await mutation.mutateAsync({ workflows: formData });
      refetch();
      notification.success({
        key: 'form-success',
        message: 'Details has been saved successfully!',
        duration: 3,
      });
      form.resetFields();
      setIsFieldTouchReset(false);
      dispatch(closeModal());
    } catch (err) {
      console.log('Error: ', err);
      notification.error({
        message: 'Something went wrong, please try again later!',
      });
    }
  };

  const baseClass = 'texting-workflow';

  const FormItems = () => (
    <Form.List name="workflows" initialValue={[{}]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Flex key={key} className="mb-2" gap={8}>
              <Card
                className="flex-1"
                title={
                  <div className="flex justify-between">
                    <span className="font-bold text-base">{`Scenario ${(scenarioIndex ?? 0) + index + 1}`}</span>
                    {fields.length > 1 && showAddFieldButton && (
                      <Button type="primary" danger icon={<DeleteOutline />} onClick={() => remove(name)}>
                        Delete
                      </Button>
                    )}
                  </div>
                }
              >
                {!debrand && (
                  <div className="border-b">
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Enable Short URLs"
                      description="Enable short URLs to keep track of the links and get analytics."
                    />
                    <Form.Item className=" mt-2 pt-2 " {...restField} name={[name, 'shortURLEnabled']}>
                      <Switch defaultChecked className=" mt-2 mb-4" />
                    </Form.Item>
                  </div>
                )}
                <div className="border-b">
                  <LabelWithDescription
                    customClassName="mt-2"
                    label="Scenario Description"
                    description="Describe the scenario in which you would like to send the caller a text message. The AI is intelligent enough to know when the scenario is triggered."
                  />
                  <Form.Item
                    className=" mt-2 pt-2 "
                    {...restField}
                    name={[name, 'description']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject("Description can't be blank");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="e.g. Send the caller a copy of the menu. Execute whenever caller asks for menu or prices."
                      autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                  </Form.Item>
                </div>

                <div className="border-b">
                  <LabelWithDescription
                    customClassName="mt-2 mb-2"
                    label="Text Message"
                    description="Specify the text you want your AI receptionist to send when the scenario is triggered."
                  />
                  <Form.Item
                    className="mt-2 pt-2"
                    {...restField}
                    name={[name, 'textMessage']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject("Text Message Can't be blank");
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        max: 1000,
                        message: 'Max 1000 characters allowed',
                      },
                    ]}
                  >
                    <Input.TextArea
                      className={`${baseClass}_input_text_area`}
                      aria-multiline
                      placeholder="e.g. Here is our menu: www.restaurant.com/menu"
                    />
                  </Form.Item>
                  <div className="text-blue-700 text-xs font-semibold mb-2">* Max 1000 characters allowed </div>
                </div>

                <div>
                  <LabelWithDescription
                    customClassName="mt-2"
                    label="Voice Response"
                    description="Specify what you want your AI receptionist to say after sending the text."
                  />
                  <Form.Item
                    className=" mt-2 pt-2 "
                    {...restField}
                    name={[name, 'voiceResponse']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value || value.trim() === '') {
                            return Promise.reject("Voice Response can't be blank");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="e.g. Great! I’ve just texted you a copy of the menu"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                    />
                  </Form.Item>
                </div>
                {debrand || isNoviUser ? (
                  <div>
                    <LabelWithDescription
                      customClassName="mt-2"
                      label="Custom Text Footer"
                      description="Add a custom text footer to your text message."
                    />
                    <Form.Item
                      className=" mt-2 pt-2 "
                      {...restField}
                      name={[name, 'customTextFooter']}
                      rules={[
                        {
                          max: 100,
                          message: 'Max 100 characters allowed',
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="e.g. Sent by AI Receptionist"
                        autoSize={{ minRows: 1, maxRows: 2 }}
                      />
                    </Form.Item>
                    <div className="text-blue-700 text-xs font-semibold mb-2">* Max 100 characters allowed </div>
                  </div>
                ) : null}
                <div className="border-b">
                  <LabelWithDescription
                    customClassName="mt-2"
                    label="Ask before sending Text SMS"
                    description="If checked in, above message will be sent when the caller confirms for the text sms"
                  />
                  <Form.Item className="pt-2 " {...restField} name={[name, 'askForPermissionOnText']}>
                    <Switch />
                  </Form.Item>
                </div>
              </Card>
            </Flex>
          ))}
          {showAddFieldButton && (
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  if (features?.workflows?.textWroflows?.value.toString() === '-1') {
                    add();
                    return;
                  }

                  if (
                    initialValues?.length ||
                    0 < parseInt(features?.workflows?.textWroflows?.value.toString() || '1')
                  ) {
                    add();
                  } else {
                    dispatch(openModal({ modalId: MODAL_IDS.SUBSCRIPTION_MODAL }));
                  }
                }}
                block
                icon={<PlusOutlined />}
              >
                Add Texting Workflow
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );

  return (
    <>
      <Spin spinning={mutation.isPending} indicator={<LoadingOutlined style={{ fontSize: '48px' }} spin />}>
        <OverViewCardForm
          form={form}
          initialValues={actionType === 'EDIT' && initialValues}
          formItems={<FormItems />}
          handleSubmit={handleSubmit}
          isFieldTouchReset={isFieldTouchReset}
        />
      </Spin>
    </>
  );
};

export default TextingWorkflowForm;
