import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';
import { NoviUser, NoviUserState } from '@/pages/Novi/types/NoviUser';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

const initialState: NoviUserState = {
  user: {
    email_address: '',
    auth_token: '',
    campaign_user_name: '',
    logo: '',
    password: '',
    purchase_quotes: [],
    twilio_access_token: '',
    twilio_account_id: '',
    termsAccepted: false,
  },
  currentPage: ENoviDashboardSider.MY_CAMPAIGNS,
  selectedCampaignId: null,
  selectedCampaign: null,
};

export const slice: Slice<
  NoviUserState,
  {
    setNoviUser: (state: NoviUserState, action: PayloadAction<NoviUser>) => void;
    setNoviCurrentPage: (state: NoviUserState, action: PayloadAction<ENoviDashboardSider>) => void;
    setNoviSelectedCampaignId: (state: NoviUserState, action: PayloadAction<string | null>) => void;
    setNoviSelectedCampaign: (state: NoviUserState, action: PayloadAction<any | null>) => void;
  },
  'noviUserAccount'
> = createSlice({
  name: 'noviUserAccount',
  initialState,
  reducers: {
    setNoviUser: (state: NoviUserState, action: PayloadAction<NoviUser>) => {
      state.user = {
        email_address: action.payload.email_address,
        auth_token: action.payload.auth_token,
        campaign_user_name: action.payload.campaign_user_name,
        logo: action.payload.logo,
        password: action.payload.password,
        purchase_quotes: action.payload.purchase_quotes,
        twilio_access_token: action.payload.twilio_access_token,
        twilio_account_id: action.payload.twilio_account_id,
        termsAccepted: action.payload.termsAccepted,
      };
    },
    setNoviCurrentPage: (state: NoviUserState, action: PayloadAction<ENoviDashboardSider>) => {
      state.currentPage = action.payload;
    },
    setNoviSelectedCampaignId: (state: NoviUserState, action: PayloadAction<string | null>) => {
      state.selectedCampaignId = action.payload;
    },
    setNoviSelectedCampaign: (state: NoviUserState, action: PayloadAction<any | null>) => {
      state.selectedCampaign = action.payload;
    },
  },
});

export const { setNoviUser, setNoviCurrentPage, setNoviSelectedCampaignId, setNoviSelectedCampaign } = slice.actions;

export default slice.reducer;
