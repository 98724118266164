// @ts-nocheck
import { useEffect, useState } from 'react';
import {
  Layout,
  Card,
  Table,
  Tag,
  Button,
  Input,
  Typography,
  Avatar,
  Space,
  Modal,
  Form,
  Select,
  Drawer,
  Timeline,
  message,
} from 'antd';
import {
  UserOutlined,
  PhoneOutlined,
  CalendarOutlined,
  MoreOutlined,
  SearchOutlined,
  FilterOutlined,
  PlusOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInUser } from '@/store/account/selector';
import { useFetchGroups, useUpdateLeadNotes, useUpdateLeadStatus } from 'src/Hooks/UseHookForCrmData';
import AddEditGroupModal from './Modals/AddEditGroupModal';
import BoardView from './Components/BoardView';
import NotesCard from './Components/Notes';
import { getLoggedInNoviUser, getSelectedCampaignId } from '@/store/novi/user/selector';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

interface Lead {
  title: string;
  lead_id: string;
  username: string;
  current_status: string;
  first_contact: string;
  phone_number: string;
  last_contact: string;
  status_history: [
    {
      timestamp: string;
      status: string;
      status_type: string;
    },
  ];
  notes: string[];
  triggeredWorkflows: string[];
}

interface Group {
  id: string;
  name: string;
  definition_type: string;
  definition: string;
  workflow_name: string;
  created_at: string;
  username: string;
}

const CrmDashboard: React.FC<{ isNoviUser: boolean }> = ({ isNoviUser }) => {
  let loggedInUser;
  let username;

  if (!isNoviUser) {
    loggedInUser = useAppSelector(getLoggedInUser);
    username = loggedInUser?.number || '';
  } else {
    username = useAppSelector(getSelectedCampaignId);
  }

  console.log(loggedInUser);
  console.log(username);

  const { data: groupsData, isLoading, refetch } = useFetchGroups(username);
  const { mutate: updateLeadStatus } = useUpdateLeadStatus();

  console.log(groupsData);

  const transformGroups = (apiGroups: any) => {
    return apiGroups.map((group: any) => ({
      id: group.group_id,
      name: group.group_name,
      definition_type: group.definition_type,
      definition: group.definition,
      workflow_name: group.workflow_name,
      created_at: group.created_at,
      username: group.username,
    }));
  };

  const formatStatusContent = (item: any): string => {
    return `Status changed to "${item.status}"`;
  };

  const transformLeads = (apiGroups: any) => {
    return apiGroups.flatMap((group: any) =>
      group.leads.map((lead: any) => ({
        title: lead.phone_number,
        lead_id: lead.lead_id,
        group_id: group.group_id,
        group_name: group.group_name,
        username: lead.username,
        current_status: lead.current_status,
        first_contact: new Date(lead.first_contact).toLocaleDateString(),
        phone_number: lead.phone_number,
        notes: lead.notes ? JSON.parse(lead.notes) : [],
        last_contact: new Date(lead.last_contact).toLocaleDateString(),
        status_history: lead?.status_history.length
          ? JSON.parse(lead?.status_history).map((item: any) => ({
              color: 'blue',
              children: (
                <div>
                  <Text strong>{formatStatusContent(item)}</Text>
                  <br />
                  <Text type='secondary'>{new Date(item.timestamp).toLocaleString()}</Text>
                </div>
              ),
            }))
          : [],
        triggeredWorkflows: lead.triggeredWorkflows,
      }))
    );
  };

  const [groups, setGroups] = useState<Group[]>([]);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [viewMode, setViewMode] = useState<'board' | 'list'>('board');
  const [searchText, setSearchText] = useState('');
  const [newGroupModal, setNewGroupModal] = useState(false);
  const [editGroupModal, setEditGroupModal] = useState(false);
  const [leadDrawer, setLeadDrawer] = useState(false);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  useEffect(() => {
    if (groupsData) {
      setGroups(transformGroups(groupsData));
      setLeads(transformLeads(groupsData));
    }
  }, [groupsData]);

  const handleViewLead = (lead: Lead) => {
    setSelectedLead(lead);
    setLeadDrawer(true);
  };

  const columns = [
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone',
      render: (phone: string) => (
        <Space>
          <PhoneOutlined className='text-blue-500' />
          <span>{phone}</span>
        </Space>
      ),
    },
    {
      title: 'Group',
      dataIndex: 'group_name',
      key: 'group_name',
      render: (status: string) => {
        return (
          <Tag color='blue' className='px-3 py-1 rounded-full'>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'First Contact',
      dataIndex: 'first_contact',
      key: 'first_contact',
      render: (date: string) => (
        <Space>
          <CalendarOutlined className='text-gray-500' />
          <span>{date}</span>
        </Space>
      ),
    },
    {
      title: 'Last Contact',
      dataIndex: 'last_contact',
      key: 'last_contact',
      render: (date: string) => (
        <Space>
          <CalendarOutlined className='text-gray-500' />
          <span>{date}</span>
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: unknown, record: Lead) => (
        <Space size='middle'>
          <Button type='primary' size='small' onClick={() => handleViewLead(record)}>
            View
          </Button>
          <Button type='text' icon={<MoreOutlined />} onClick={e => e.stopPropagation()} />
        </Space>
      ),
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout className='min-h-screen bg-white'>
      <Header className='bg-white px-6 flex items-center justify-between border-b'>
        <div className='flex items-center gap-8'>
          <Title level={4} className='m-0'>
            Autopilot CRM
          </Title>
          <Space size='middle'>
            <Button
              icon={<AppstoreOutlined />}
              type={viewMode === 'board' ? 'primary' : 'default'}
              onClick={() => setViewMode('board')}
            >
              Board
            </Button>
            <Button
              icon={<UnorderedListOutlined />}
              type={viewMode === 'list' ? 'primary' : 'default'}
              onClick={() => setViewMode('list')}
            >
              List
            </Button>
          </Space>
        </div>
        <Space size='middle'>
          <Input
            placeholder='Search leads...'
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <Button icon={<FilterOutlined />}>Filter</Button>
          <Button type='primary' icon={<PlusOutlined />} onClick={() => setNewGroupModal(true)}>
            New Group
          </Button>
        </Space>
      </Header>

      <Content className='p-6'>
        {viewMode === 'board' ? (
          <BoardView
            groups={groups}
            leads={leads}
            handleViewLead={handleViewLead}
            setEditGroupModal={setEditGroupModal}
            setSelectedGroup={setSelectedGroup}
            refetch={refetch}
          />
        ) : (
          <Card>
            <Table
              columns={columns}
              dataSource={leads}
              pagination={{
                total: leads.length,
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
              }}
              onRow={record => ({
                onClick: () => handleViewLead(record),
              })}
            />
          </Card>
        )}
      </Content>

      <AddEditGroupModal isOpen={newGroupModal} setIsBoardModalOpen={setNewGroupModal} type='add' username={username} />
      <AddEditGroupModal
        isOpen={editGroupModal}
        setIsBoardModalOpen={setEditGroupModal}
        type='edit'
        boardToEdit={selectedGroup}
        username={username}
      />

      <Drawer
        title='Lead Details'
        placement='right'
        width={600}
        open={leadDrawer}
        onClose={() => {
          setLeadDrawer(false);
          setSelectedLead(null);
        }}
      >
        {selectedLead && (
          <div className='space-y-6'>
            <Card>
              <Space direction='vertical' className='w-full'>
                <Space className='w-full justify-between'>
                  <Space>
                    <Avatar size={64} icon={<UserOutlined />} className='bg-blue-500' />
                    <div>
                      <Text strong className='text-lg'>
                        {selectedLead.phone_number}
                      </Text>
                    </div>
                  </Space>
                </Space>
              </Space>
            </Card>

            <Card title='Contact Information'>
              <Space direction='vertical' className='w-full'>
                <Space className='w-full justify-between'>
                  <Text>Status</Text>
                  <Tag color='blue' className='rounded-full'>
                    {selectedLead.current_status}
                  </Tag>
                </Space>
                <Space className='w-full justify-between'>
                  <Text>First Contact</Text>
                  <Text>{selectedLead.first_contact}</Text>
                </Space>
                <Space className='w-full justify-between'>
                  <Text>Last Contact</Text>
                  <Text>{selectedLead.last_contact}</Text>
                </Space>
              </Space>
            </Card>

            <NotesCard selectedLead={selectedLead} />

            <Card title='Activity Timeline'>
              <Timeline items={selectedLead.status_history} />
            </Card>

            <Card title='Update Lead Group'>
              <Form
                layout='vertical'
                initialValues={{
                  status: selectedLead.current_status,
                }}
                onFinish={values => {
                  updateLeadStatus({
                    current_status: values.status,
                    lead_id: selectedLead.lead_id,
                    username: selectedLead.username,
                    phone_number: selectedLead.phone_number,
                  });
                }}
              >
                <Form.Item name='status' label='Group'>
                  <Select>
                    {groups.map(group => (
                      <Option key={group.id} value={group.id}>
                        {group.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Button type='primary' htmlType='submit' block>
                    Update Lead
                  </Button>
                </Form.Item>
              </Form>
            </Card>

            <div className='flex justify-end'>
              <Button
                type='primary'
                danger
                onClick={() => {
                  Modal.confirm({
                    title: 'Delete Lead',
                    content: 'Are you sure you want to delete this lead? This action cannot be undone.',
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    onOk() {
                      setLeads(leads.filter(lead => lead.lead_id !== selectedLead.lead_id));
                      setLeadDrawer(false);
                      setSelectedLead(null);
                      message.success('Lead deleted successfully');
                    },
                  });
                }}
              >
                Delete Lead
              </Button>
            </div>
          </div>
        )}
      </Drawer>
    </Layout>
  );
};

export default CrmDashboard;
