import { Menu, MenuProps, Space } from 'antd';
import Sider from 'antd/es/layout/Sider';
import {
  AnalyticsOutlined,
  ArticleOutlined,
  BrandingWatermarkOutlined,
  CallOutlined,
  FeedOutlined,
  TextsmsOutlined,
  TextSnippetOutlined,
  VoicemailOutlined,
} from '@mui/icons-material';
import { HomeOutlined, LogoutOutlined, SettingOutlined, SoundOutlined } from '@ant-design/icons';
import { ENoviDashboardSider } from '@/pages/Novi/enums/ENoviDashboardSider';

import { useMobileDeviceCheck } from 'src/common/hooks/useMobile';
import { useAppSelector } from '@/store/hooks/useAppSelector';
import { getLoggedInNoviUser, getSelectedCampaignId } from '@/store/novi/user/selector';
import LocalStorageUtility from '@/utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/enums/ERoutes';

type SiderMenuProps = {
  currentPage: ENoviDashboardSider;
  handleMenuSelect: (key: ENoviDashboardSider) => void;
};

const SiderMenu: React.FC<SiderMenuProps> = ({ currentPage, handleMenuSelect }) => {
  const navigate = useNavigate();
  const { isMobile } = useMobileDeviceCheck();
  const selectedCampaignId = useAppSelector(getSelectedCampaignId);
  const noviUser = useAppSelector(getLoggedInNoviUser);

  const items: MenuProps['items'] = [
    {
      key: ENoviDashboardSider.MY_CAMPAIGNS,
      icon: <HomeOutlined />,
      label: ENoviDashboardSider.MY_CAMPAIGNS,
      //@ts-ignore
      children: selectedCampaignId
        ? [
            {
              key: ENoviDashboardSider.LEADS,
              icon: <HomeOutlined />,
              label: ENoviDashboardSider.LEADS,
            },
            {
              key: ENoviDashboardSider.CONFIGURE,
              icon: <SettingOutlined />,
              label: ENoviDashboardSider.CONFIGURE,
            },
            {
              key: ENoviDashboardSider.ANALYTICS,
              icon: <AnalyticsOutlined />,
              label: ENoviDashboardSider.ANALYTICS,
            },
            {
              key: ENoviDashboardSider.VOICE_LIBRARY,
              icon: <SoundOutlined />,
              label: ENoviDashboardSider.VOICE_LIBRARY,
            },
            {
              key: ENoviDashboardSider.INTAKE_FORMS,
              icon: <TextSnippetOutlined />,
              label: ENoviDashboardSider.INTAKE_FORMS,
            },
            {
              key: ENoviDashboardSider.TEXTING_PLATFORM,
              icon: <TextsmsOutlined />,
              label: ENoviDashboardSider.TEXTING_PLATFORM,
            },
            {
              key: ENoviDashboardSider.LOGS,
              icon: <ArticleOutlined />,
              label: ENoviDashboardSider.LOGS,
              children: [
                {
                  key: ENoviDashboardSider.CALL_LOGS,
                  icon: <CallOutlined />,
                  label: ENoviDashboardSider.CALL_LOGS,
                },
                {
                  key: ENoviDashboardSider.TEXT_LOGS,
                  icon: <FeedOutlined />,
                  label: ENoviDashboardSider.TEXT_LOGS,
                },
                {
                  key: ENoviDashboardSider.VOICE_MAIL_LOGS,
                  icon: <VoicemailOutlined />,
                  label: ENoviDashboardSider.VOICE_MAIL_LOGS,
                },
              ],
            },
            {
              key: ENoviDashboardSider.CRM,
              icon: <FeedOutlined />,
              label: ENoviDashboardSider.CRM,
            },
          ]
        : null,
    },
    {
      key: ENoviDashboardSider.BRANDING,
      icon: <BrandingWatermarkOutlined />,
      label: ENoviDashboardSider.BRANDING,
    },
    // {
    //   key: ENoviDashboardSider.OUTBOUND_ANALYTICS,
    //   icon: <BarChartOutlined />,
    //   label: ENoviDashboardSider.OUTBOUND_ANALYTICS,
    // },
    {
      type: 'divider',
      style: { margin: '16px' },
    },
    {
      key: 'LOGOUT',
      icon: <LogoutOutlined />,
      label: 'Logout',
    },
  ];

  const handleClick: MenuProps['onClick'] = e => {
    if (e.key === 'LOGOUT') {
      LocalStorageUtility.clearLocalData();
      navigate(AppRoutes.NOVI_PORTAL_LOGIN);
      return;
    }
    handleMenuSelect(e.key as ENoviDashboardSider);
  };

  const baseClass = 'sider-container';
  return (
    <Sider className={baseClass} collapsible={false} breakpoint={'md'} theme='light' width={isMobile ? 60 : 200}>
      <Space className={`${baseClass}__logo`} direction='vertical' align='center'>
        <img src={noviUser.logo} alt='logo' className='p-2' />
      </Space>
      <Menu items={items} selectedKeys={[currentPage]} onClick={handleClick} mode='inline' />
    </Sider>
  );
};

export default SiderMenu;
