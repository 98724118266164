export enum AIModelSound {
  AURA_ASTERIA_EN = 'aura-asteria-en',
  AURA_LUNA_EN = 'aura-luna-en',
  AURA_STELLA_EN = 'aura-stella-en',
  AURA_ATHENA_EN = 'aura-athena-en',
  AURA_ORION_EN = 'aura-orion-en',
  AURA_ARCAS_EN = 'aura-arcas-en',
  AURA_PERSEUS_EN = 'aura-perseus-en',
  AURA_ANGUS_EN = 'aura-angus-en',
  AURA_HELIOS_EN = 'aura-helios-en',
  AURA_ORPHEUS_EN = 'aura-orpheus-en',
  AURA_ZEUS_EN = 'aura-zeus-en',
}
