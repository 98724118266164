import React from 'react';

import classes from '../../../Portal/Portal.module.css';
import { serverURL } from '../../../config';
import toast from 'react-hot-toast';
import { IoIosWarning } from 'react-icons/io';
import Button from '../../../components/Button';

const WarningModal = ({ themeColor, close, text, heading, onApply, selectedClient, loading }) => {
  return (
    <>
      <div
        onClick={() => {
          close(false);
        }}
        className={classes.modalOverlay}
      ></div>
      <div className={classes.modal + ' '}>
        <div
          className={classes.close}
          onClick={() => {
            close(false);
          }}
          style={{ zIndex: 100 }}
        >
          &times;
        </div>

        <div className="flex flex-col gap-7">
          <IoIosWarning className="self-center text-5xl text-[red]" />

          <div className="flex flex-col gap-2 items-center">
            <p className="text-2xl font-bold">{heading}</p>
            <p className="text-center">{text}</p>
          </div>
          {/* <button
            onClick={onApply}
            className="
            bg-blue-500
            hover:bg-blue-700
            text-white
            font-bold
            py-2
            px-4
            rounded
          "
            disabled={loading}
          >
            {loading ? "Loading..." : "Proceed"}
          </button> */}
          <Button
            text="Proceed"
            themeColor={themeColor}
            type="button"
            onClick={onApply}
            className="min-w-72"
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default WarningModal;
