import React from 'react';

import classes from '../../../Portal/Portal.module.css';
import { serverURL } from '../../../config';
import toast from 'react-hot-toast';
import Button from '../../../components/Button';

const ChangePassModal = ({
  themeColor,
  selectedClient,
  setResellerChangePassModalOpen,
  setSelectedClient,
  updateClientDataInState,
}) => {
  const [loading, setLoading] = React.useState(false);

  const handlePasswordChangeSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await fetch(serverURL + '/api/reseller/update-managed-receptionist', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: { password: e.target.password.value },
          receptionist_username: selectedClient.number,
        }),
      });

      const data = await res.json();

      if (data.success) {
        toast.success('Password updated successfully');

        //update max_minutes on frontend as to not make an api call again to show updated data
        updateClientDataInState(selectedClient.number, {
          password: e.target.password.value,
        });
      } else {
        toast.error('Request Failed try again.');
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        onClick={() => {
          setResellerChangePassModalOpen(false);
          setSelectedClient(null);
        }}
        className={classes.modalOverlay}
      ></div>
      <div className={classes.modal + ' '}>
        <div
          className={classes.close}
          onClick={() => {
            setResellerChangePassModalOpen(false);
            setSelectedClient(null);
          }}
          style={{ zIndex: 100 }}
        >
          &times;
        </div>

        <div className="flex flex-col gap-7">
          <div className="flex flex-col gap-2 items-center">
            <p className="text-2xl font-bold">Change Password</p>
            <p className="text-center">
              This would be the new{' '}
              <span
                className="font-bold"
                style={{
                  color: themeColor,
                }}
              >
                {selectedClient?.business_name}'s
              </span>{' '}
              receptionist password.
            </p>
          </div>
          <form method="POST" onSubmit={handlePasswordChangeSubmit} className="flex flex-col gap-4 items-center">
            <div className="flex flex-row gap-2 items-center">
              <p>Password: </p>
              <input
                type="text"
                name="password"
                placeholder="Type password here..."
                defaultValue={selectedClient?.password}
              />
            </div>
            {/* <button
              type="submit"
              style={{
                fontSize: "16px",
                background: "#000",
                border: "1px solid #ccc",
                borderRadius: "6px",
                minHeight: 40,
                width: "50%",
                minWidth: 300,
                maxWidth: 300,
                color: "white",
                flex: 1,
                marginRight: "10px",
              }}
              onClick={() => {}}
            >
              Apply
            </button> */}
            <Button text="Apply" themeColor={themeColor} type="submit" className="w-1/2 min-w-72" loading={loading} />
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassModal;
