import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Typography, List, Card } from 'antd';
import { MenuOutlined, CloseOutlined, RightOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

// Assuming you have these imports
import logo from '@/assets/images/logo.png';
import thankYouVideo from '@/assets/videos/customoffer-thankyou.mp4';

const CustomOfferThankYou: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuItems = [
    { key: 'home', label: 'Home', href: 'https://www.myaifrontdesk.com/' },
    { key: 'tutorials', label: 'Tutorials', href: 'https://www.myaifrontdesk.com/tutorials' },
    { key: 'features', label: 'Features', href: 'https://www.myaifrontdesk.com/features' },
  ];

  return (
    <Layout className="min-h-screen">
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          background: isScrolled ? '#fff' : 'transparent',
          transition: 'all 0.3s',
          padding: '0 50px',
          height: 64,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img src={logo} alt="logo" style={{ height: 32, width: 32 }} />
          </motion.div>
          <Menu theme="light" mode="horizontal" style={{ display: 'flex', background: 'transparent' }}>
            {menuItems.map(item => (
              <Menu.Item key={item.key}>
                <a href={item.href} target="_blank" rel="noopener noreferrer">
                  {item.label}
                </a>
              </Menu.Item>
            ))}
          </Menu>
          <Button
            icon={isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={{ display: 'none' }}
          />
        </div>
      </Header>

      <Content style={{ padding: '0 50px', marginTop: 64 }}>
        <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Title level={1} style={{ textAlign: 'center' }}>
              Thank You for Your Trust!
            </Title>
            <Paragraph type="secondary" style={{ textAlign: 'center' }}>
              This is your first step on your journey with us.
            </Paragraph>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            style={{ marginBottom: 24 }}
          >
            <video src={thankYouVideo} controls style={{ width: '100%' }} />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <Paragraph style={{ fontSize: 18, textAlign: 'center' }}>
              We are delighted to wish you an official welcome to the{' '}
              <a href="https://www.myaifrontdesk.com/white-label">My AI Front Desk White Label Program</a>. You will
              find the decision to partner up with us to be one of the wisest business decisions you have ever made.
            </Paragraph>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            <Card title="What happens next?" style={{ marginTop: 24 }}>
              <List
                dataSource={[
                  'Expect an email from us that will take you to a client survey that you should fill out.',
                  'At the end of the survey, you will be prompted to book your first onboarding call with Marko, our Director of Growth & GTM.',
                  'Marko will walk you through everything you need to know.',
                  'Feel free to reach out if you have any questions or concerns.',
                ]}
                renderItem={(item, index) => (
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 1 + index * 0.1 }}
                  >
                    <List.Item>
                      <RightOutlined style={{ color: '#1890ff', marginRight: 8 }} />
                      {item}
                    </List.Item>
                  </motion.div>
                )}
              />
            </Card>
          </motion.div>
        </div>
      </Content>

      <Footer style={{ textAlign: 'center', background: '#001529', color: '#fff' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 1200,
            margin: '0 auto',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="logo" style={{ height: 32, width: 32 }} />
            <span style={{ marginLeft: 8, fontSize: 18, fontWeight: 'bold' }}>My AI Front Desk</span>
          </div>
          <div>
            <Button icon={<TwitterOutlined />} href="https://twitter.com/myaifrontdesk" target="_blank" type="link" />
            <Button
              icon={<LinkedinOutlined />}
              href="https://www.linkedin.com/company/aifrontdesk/"
              target="_blank"
              type="link"
            />
          </div>
        </div>
        <div style={{ marginTop: 24, borderTop: '1px solid #1f2937', paddingTop: 24 }}>
          <Typography.Text className="text-white text-bold">
            &copy; 2024 MyAIFrontDesk. All rights reserved.
          </Typography.Text>
        </div>
      </Footer>
    </Layout>
  );
};

export default CustomOfferThankYou;
