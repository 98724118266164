import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { toast } from 'react-hot-toast';
import Joyride, { STATUS, EVENTS, ACTIONS } from 'react-joyride';

import RegisterPageV3 from './../RegisterPageV3';
import logo from './../Blue_Logo.png';
import classes from './ResellerPortal.module.css';
import WhiteLabelReceptionistDirectory from '../Portal/PortalComponents/WhitelabelReceptionistDirectory';
import { useLocation } from 'react-router-dom';
import { serverURL } from '../config';
import axios from 'axios';
import isEmpty from '../utils/is-empty';
import GuideBanner from '../components/Banner/GuideBanner';
import { CircularProgress, Switch } from '@mui/material';
import { MdInfo } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import NoticeModal from '../components/resellerPortal/NoticeModal';
import PaymentForm from '../components/resellerPortal/StripeCardElement';
import StripeProvider from '../components/StripeProvider';
import ModalWrapper from '../components/modals/ModalWrapper';
import Button from '../components/Button';
import { useResellerGlobal } from '../context/resellerContext';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import MUIButton from '@mui/material/Button';

import { AccountCircle, MonetizationOn } from '@mui/icons-material';

import Loader from '../components/dashboard/Loader';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { getResellerData } from '../../store/reseller/selector';
import LocalStorageUtility from '../../utils/localStorage';

function ResellerPortal({ debrand = false, activeItem = 'Receptionist' }) {
  const isMobile = window.innerWidth <= 768;
  const [isRegistrationModal, setIsRegistrationModalActive] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(activeItem);
  const [imageUrl, setImageUrl] = useState('');
  const [flipThisBooleanToReloadClients, setFlipThisBooleanToReloadClients] = useState(false);
  const [uploadedLogoS3Url, setUploadedLogoS3Url] = useState('');
  const [refreshInt, setRefreshInt] = useState(0); // Incrementing this will update getStuff in ResellerLogin
  const [debrandModalActive, setDebrandModalActive] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [isBannerActive, setIsBannerActive] = useState(true);
  const location = useLocation();
  const [color, setColor] = useState('#91bef');
  const [updateAutoScaleLoading, setUpdateAutoScaleLoading] = useState(false);
  const [paymentMethodNoticeModal, setPaymentMethodNoticeModal] = useState(false);
  const [openPaymentMethodElement, setOpenPaymentMethodElement] = useState(false);
  const [billingOverview, setBillingOverview] = useState(null);
  const [billingOverviewLoading, setBillingOverviewLoading] = useState(false);
  const [billingOverviewError, setBillingOverviewError] = useState(null);

  const { branded } = useResellerGlobal();
  const resellerData = useAppSelector(getResellerData);

  const [{ steps, run, step }, setJoyrideState] = useState({
    run: false,
    step: 0,
    steps: [
      {
        target: 'body',
        content: (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <img src={logo} alt="My AI Front Desk Logo" style={{ width: '100px', height: '100px' }} />
            <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>👋 Welcome to the Reseller Program!</h2>
            <p style={{ fontSize: '18px' }}>
              Purchase whitelabeled receptionists at retail price. Resell them for whatever price you'd like. Let's
              begin the tour.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">Skip Tour</strong> },
        placement: 'center',
      },
      {
        content: (
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>Create a White Labeled Receptionist</h2>
            <p style={{ fontSize: '18px' }}>
              Start by configuring a new receptionist. You will do this manually for each whitelabeled receptionist you
              plan to resell.
            </p>
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        placement: 'auto',
        target: '.register-client',
      },
      {
        content: (
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>View all your Receptionists</h2>
            <p style={{ fontSize: '18px' }}>
              View all your receptionists in one place. <br />
              <br /> You can click into any of your receptionists and instantly log-in via popup to manage many
              receptionists at once.
            </p>
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        placement: 'auto',
        target: '.view-receptionists',
      },
      {
        content: (
          <div style={{ textAlign: 'center' }}>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              Whitelabeled Client Dashboard
            </h2>
            <p style={{ fontSize: '18px', marginTop: '20px' }}>
              Your clients can edit their receptionist, view logs, and more in the whitelabeled dashboard. <br /> <br />{' '}
              Embed this into your own website as an iframe to allow your clients to manage their receptionist.
            </p>
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        placement: 'auto',
        target: '.client-dashboard',
      },
      {
        content: (
          <div style={{ textAlign: 'center' }}>
            <h2
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              Whitelabeled Client Registration
            </h2>
            <p style={{ fontSize: '18px', marginTop: '20px' }}>
              Your clients can self-serve register one of your white-labeled receptionists too! <br /> <br /> Embed this
              white-labeled registration page into your own website as an iframe, and send it to your clients to allow
              them to register.
            </p>
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        placement: 'auto',
        target: '.client-registration',
      },
      {
        content: (
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>Branding Settings</h2>
            <p style={{ fontSize: '18px' }}>
              Change your company name and logo anytime. <br /> <br /> Any branding changes will immediately apply to
              all your receptionists, dashboards, notifications, and more.
            </p>
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        placement: 'auto',
        target: '.branding-settings',
      },
      {
        content: (
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>View Tutorial</h2>
            <p style={{ fontSize: '18px' }}>Access product tutorials here. Replay this tutorial from here as well.</p>
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        placement: 'auto',
        target: '.view-tutorial',
      },
      {
        content: (
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>Need help setting up?</h2>
            <p style={{ fontSize: '18px' }}>
              Let us guide you through it.{' '}
              <a href="https://www.myaifrontdesk.com/white-label" rel="noreferrer" target="_blank">
                Book an onboarding call here for free
              </a>
            </p>
          </div>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 10,
        placement: 'center',
        target: 'body',
      },
      {
        content: (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            {!debrand && branded && (
              <img src={logo} alt="My AI Front Desk Logo" style={{ width: '100px', height: '100px' }} />
            )}
            <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}> 🎉 Tutorial Complete </h2>
            {!debrand && branded && (
              <p style={{ fontSize: '18px' }}>
                For help, email{' '}
                <a href="mailto:contact@myaifrontdesk.com?subject=Contact Us&body=Please let us know how we can assist you">
                  {' '}
                  contact@myaifrontdesk.com.
                </a>{' '}
                This concludes the tutorial. <br /> <br /> Start by exploring the dashboard and registering your first
                client! You can replay this tutorial any time in the "tutorials" section.
              </p>
            )}
          </div>
        ),
        locale: { skip: <strong aria-label="skip">Skip Tour</strong> },
        placement: 'center',
        target: 'body',
      },
    ].filter(step => (debrand ? step.target !== '.client-dashboard' && step.target !== '.client-registration' : true)),
  });

  const handleAddPaymentMethod = () => {
    setPaymentMethodNoticeModal(false);
    setOpenPaymentMethodElement(true);
  };

  const refreshQuotesAndClients = async () => {
    setFlipThisBooleanToReloadClients(!flipThisBooleanToReloadClients);
    // console.log("Just flipped");
    try {
      const response = await fetch(`${serverURL}/get_reseller`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress: resellerData.email_address,
          password: resellerData.password,
        }),
      });

      const data = await response.json();

      if (data.success) {
        const resellerObject = data.reseller;
      }
    } catch (error) {
      console.log("Couldn't refresh reseller");
      localStorage.removeItem('RESELLER_PORTAL_USER');
      window.location.reload();
    }
  };

  const handleJoyrideCallback = data => {
    const { status, type, action, index } = data;
    // console.log(data);
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStep = index + (action === ACTIONS.PREV ? -1 : 1);
      let timeoutTime = 0;
      timeoutTime = 1000;
      if (nextStep === 0) {
        setSelectedItem('Receptionist');
      }
      if (nextStep === 1) {
        setSelectedItem('Receptionist');
      }
      if (nextStep === 2) {
        setSelectedItem('Receptionist');
      }
      if (nextStep === 3) {
        setSelectedItem('Embed');
      }
      if (nextStep === 4) {
        setSelectedItem('Embed');
      }
      if (nextStep === 5) {
        setSelectedItem('Branding');
      }
      if (nextStep === 6) {
        setSelectedItem('Tutorials');
      }
      if (nextStep === 7) {
        setSelectedItem('Receptionist');
      }
      if (nextStep === 4 || nextStep === 3) {
        setOpen(true);
      } else {
        setOpen(false);
      }
      setTimeout(() => {
        setJoyrideState(prev => ({
          ...prev,
          step: nextStep,
        }));
      }, timeoutTime);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
        localStorage.setItem('HAS_MY_AI_TUTORIAL_BEEN_WATCHED', true);
      }
      setOpen(false);
      setSelectedItem('Receptionist');
      setJoyrideState(prev => ({ ...prev, step: 0, run: false }));
    }
  };

  const handleCancelConfirm = async () => {
    try {
      if (resellerData?.subscriptionStatus !== 'active') {
        toast.error('Subscription is not active');
        return;
      }

      const response = await fetch(serverURL + '/stripeCancelOrUpdate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: resellerData?.email_address,
          action: 'subscription_cancel',
          reseller: true,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status !== 'success') {
          toast.error('Failed to cancel subscription');
        } else {
          try {
            window.location.href = data.redirectPaymentURL;
          } catch (error) {
            toast.error('Failed to redirect to payment portal');
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function updatePaymentInformation() {
    try {
      const response = await fetch(serverURL + '/stripeCancelOrUpdate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: resellerData?.email_address,
          action: 'payment_method_update',
          reseller: true,
        }), // Replace with the appropriate value
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status !== 'success') {
          toast.error('Failed to update payment information');
        } else {
          try {
            window.location.href = data.redirectPaymentURL;
          } catch (error) {
            toast.error('Failed to redirect to payment portal');
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleCustomerPortal = async () => {
    try {
      if (resellerData?.subscriptionStatus !== 'active') {
        toast.error('Subscription is not active');
        return;
      }

      const response = await fetch(serverURL + '/api/stripe/customerPortal/reseller', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress: resellerData?.email_address,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (!data.success) {
          toast.error('Failed to open customer portal');
        } else {
          try {
            window.location.href = data.data.redirectionURL;
          } catch (error) {
            toast.error('Failed to redirect to payment portal');
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBillingOverview = useCallback(async () => {
    try {
      setBillingOverviewLoading(true);
      setBillingOverviewError(null);
      const response = await axios.post(`${serverURL}/api/reseller/billing-overview`, {
        email_address: resellerData.email_address,
      });
      setBillingOverview(response.data);
    } catch (err) {
      console.error(err);
      setBillingOverviewError(err);
    } finally {
      setBillingOverviewLoading(false);
    }
  }, [resellerData]);

  useEffect(() => {
    getBillingOverview();
  }, [resellerData, getBillingOverview]);

  useEffect(() => {
    if (!isEmpty(resellerData)) {
      if (typeof resellerData === 'string') setColor(resellerData.parse(resellerData)?.theme_color || '#007bf');
      else setColor(resellerData?.theme_color || '#007bf');
    }
  }, [resellerData]);

  useEffect(() => {
    // if (
    //   location.state &&
    //   location.state.emailAddress &&
    //   location.state.password
    // ) {
    // setShowTutorial(true);
    const HAS_MY_AI_TUTORIAL_BEEN_WATCHED = localStorage.getItem('HAS_MY_AI_TUTORIAL_BEEN_WATCHED');
    if (!HAS_MY_AI_TUTORIAL_BEEN_WATCHED) setJoyrideState(prev => ({ ...prev, run: true }));
    // }
  }, [location]);

  useEffect(() => {
    setOpen(false);
  }, [selectedItem]);

  // Load reseller
  useEffect(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    // Download logo
    if (resellerData && resellerData.logo_s3) {
      fetch(`${serverURL}/download_reseller_logo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: resellerData.logo_s3,
        }),
      })
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);
          setImageUrl(objectUrl);
        })
        .catch(error => console.error(error));
    }
  }, [resellerData]);

  const Sidebar = (
    <div
      className={classes.sidebar}
      style={{
        flex: '0 0 300px', // Set a fixed width for the sidebar (e.g., 300px)
        position: isMobile ? 'fixed' : 'sticky',
        top: 0,
        left: 0,
        bottom: 0,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Quicksand, sans-serif',
        color: '#333',
        overflowY: 'auto', // Enable vertical scrolling if the content overflows
        zIndex: 100,
        height: !isMobile && '100vh',
        maxWidth: '264px',
        overflowX: 'hidden',
        background: '#fff',
      }}
    >
      <div style={{ padding: '16px' }}>
        {/* Sidebar content here */}

        {isMobile && (
          <div
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              zIndex: 200,
            }}
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        )}

        {!debrand && branded && (
          <img width={100} height={100} src={logo} alt="AI Receptionist" style={{ marginRight: '1rem' }} />
        )}
        <ul style={{ listStyle: 'none', padding: 0 }}>
          <li
            className="view-receptionists"
            style={{
              margin: '8px 0',
              color: selectedItem === 'Receptionist' ? color : '#333',
              fontWeight: selectedItem === 'Receptionist' ? 'bold' : 'normal',
              fontSize: '16px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              background: selectedItem === 'Receptionist' ? '#F0F0F0' : 'transparent',
            }}
            onClick={() => setSelectedItem('Receptionist')}
          >
            <span style={{ marginRight: '10px' }}>👩‍💼</span>
            My Receptionists
          </li>
          {!debrand && (
            <li
              style={{
                margin: '8px 0',
                color: selectedItem === 'Embed' ? color : '#333',
                fontWeight: selectedItem === 'Embed' ? 'bold' : 'normal',
                fontSize: '16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                background: selectedItem === 'Embed' ? '#F0F0F0' : 'transparent',
              }}
              onClick={() => setSelectedItem('Embed')}
            >
              <span style={{ marginRight: '10px' }}>🔗</span>
              Embed
            </li>
          )}
          <li
            className="branding-settings"
            style={{
              margin: '8px 0',
              color: selectedItem === 'Branding' ? color : '#333',
              fontWeight: selectedItem === 'Branding' ? 'bold' : 'normal',
              fontSize: '16px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              background: selectedItem === 'Branding' ? '#F0F0F0' : 'transparent',
            }}
            onClick={() => setSelectedItem('Branding')}
          >
            <span style={{ marginRight: '10px' }}>🔖</span>
            Branding
          </li>
          {!debrand && branded && (
            <li
              className="view-tutorial"
              style={{
                margin: '8px 0',
                color: selectedItem === 'Tutorials' ? color : '#333',
                fontWeight: selectedItem === 'Tutorials' ? 'bold' : 'normal',
                fontSize: '16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                background: selectedItem === 'Tutorials' ? '#F0F0F0' : 'transparent',
              }}
              onClick={() => setSelectedItem('Tutorials')}
            >
              <span style={{ marginRight: '10px' }}>📄</span>
              Tutorials
            </li>
          )}
          {!debrand && branded && (
            <li
              className="view-tutorial"
              style={{
                margin: '8px 0',
                color: selectedItem === 'Tutorials' ? color : '#333',
                fontWeight: selectedItem === 'Tutorials' ? 'bold' : 'normal',
                fontSize: '16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                background: selectedItem === 'Manage Subscription' ? '#F0F0F0' : 'transparent',
              }}
              onClick={() => setSelectedItem('Manage Subscription')}
            >
              <span style={{ marginRight: '10px' }}>📄</span>
              Subscription
            </li>
          )}
          {/* <li
            style={{
              margin: "8px 0",
              color: selectedItem === "Billing" ? color : "#333",
              fontWeight: selectedItem === "Billing" ? "bold" : "normal",
              fontSize: "16px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              background:
                selectedItem === "Billing" ? "#F0F0F0" : "transparent",
            }}
            onClick={() => setSelectedItem("Billing")}
          >
            <span style={{ marginRight: "10px" }}>💵</span>
            Billing
          </li> */}
          <li
            style={{
              margin: '8px 0',
              color: color,
              fontWeight: 'bold',
              fontSize: '16px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              background: 'transparent',
            }}
            onClick={() => {
              LocalStorageUtility.clearLocalData();
              window.history.replaceState({}, '');
              window.location.reload();
            }}
          >
            <span style={{ marginRight: '10px' }}>📤</span>
            Logout
          </li>
        </ul>
      </div>

      {
        <div
          style={{
            margin: '8px 0',
            color: '#333',
            fontSize: '0.6rem',
            textAlign: 'center',
            padding: '8px 16px',
            background: 'transparent',
            justifyContent: 'center',
            boxSizing: 'border-box',
            bottom: 0,
            width: '100%',
          }}
        >
          {!debrand && branded && 'Copyright © 2024 AI Front Desk, Inc.'}
          <br />
          All Rights Reserved.
        </div>
      }
    </div>
  );

  const handleUpdateAutoScale = async (e, checked) => {
    try {
      setUpdateAutoScaleLoading(true);
      const response = await fetch(serverURL + '/api/reseller/update-auto-scale-receptionist', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reseller_email: resellerData.email_address,
          auto_scale: checked,
          customerId: resellerData?.customerId,
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success('Auto scale updated');
      } else if (!data.success && !data.paymentMethod) {
        setPaymentMethodNoticeModal(true);
      } else {
        toast.error('Error Updating Auto Scale');
      }
    } catch (err) {
      toast.error(err?.message || 'Error Updating Auto Scale');
    } finally {
      setUpdateAutoScaleLoading(false);
    }
  };

  const TimelineItem = ({ icon, title, content, color }) => (
    <div className="flex items-start mb-4">
      <div className={`rounded-full p-2 mr-4 ${color}`}>{icon}</div>
      <div>
        <Typography variant="h6" className="font-semibold">
          {title}
        </Typography>
        <Typography>{content}</Typography>
      </div>
    </div>
  );

  const SubscriptionDashboard = ({ handleCustomerPortal, apiData }) => {
    return (
      <Card className="w-full shadow-lg rounded-xl overflow-hidden my-5">
        <CardContent className="p-6">
          <Grid container spacing={4}>
            {/* Subscription Overview */}
            <Grid item xs={12} md={6}>
              <Typography variant="h5" className="mb-4 font-bold text-gray-800">
                Subscription Overview
              </Typography>
              <div className="space-y-4">
                <TimelineItem
                  icon={<AccountCircle className="text-white" />}
                  title="Base Plan Status"
                  content={
                    <span
                      className={
                        apiData?.basePlanStatus === 'active'
                          ? 'text-green-500 font-bold'
                          : apiData?.basePlanStatus === 'canceled'
                            ? 'text-red-500 font-bold'
                            : 'text-yellow-500 font-bold'
                      }
                    >
                      {apiData?.basePlanStatus || 'N/A'}
                    </span>
                  }
                  color="bg-green-500"
                />
                <TimelineItem
                  icon={<MonetizationOn className="text-white" />}
                  title="Voice Usage Charge"
                  content={<span className="text-red-600 font-bold">${apiData?.voiceUsageCharge || 0}</span>}
                  color="bg-red-500"
                />
              </div>
            </Grid>

            {/* Billing Details Box */}
            <Grid item xs={12} md={6}>
              <Box className="bg-gradient-to-br from-blue-500 to-purple-600 text-white rounded-xl p-6 h-full flex flex-col justify-between">
                <div>
                  <Typography variant="h5" className="font-bold mb-2 flex items-center">
                    <span className="text-3xl mr-2">💳</span>
                    Billing Details
                  </Typography>
                  <Typography variant="body1" className="mb-4">
                    Manage your invoice history, billing cycle, and more.
                  </Typography>
                </div>
                <MUIButton
                  variant="contained"
                  className="bg-white text-blue-600 hover:bg-blue-50 mt-4 self-start"
                  onClick={handleCustomerPortal}
                >
                  View Billing Details
                </MUIButton>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // New Scrollable Component for Sub-Accounts
  const SubAccountsList = ({ subAccounts = [] }) => {
    return billingOverviewLoading ? (
      <Loader />
    ) : billingOverviewError !== null ? (
      <h1>Error Loading Sub Accounts data</h1>
    ) : (
      <Card className="w-full shadow-lg rounded-xl overflow-hidden my-5">
        <CardContent className="p-6">
          <Typography variant="h5" className="mb-4 font-bold text-gray-800">
            Sub Accounts
          </Typography>
          <div
            style={{
              maxHeight: '300px',
              overflowY: 'auto',
              padding: '8px',
              backgroundColor: '#f9f9f9',
              borderRadius: '8px',
            }}
          >
            {subAccounts?.length > 0 ? (
              subAccounts?.map((account, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: '#fff',
                    padding: '12px',
                    borderRadius: '8px',
                    marginBottom: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>{account.username}</strong>
                  </Typography>
                  <Typography variant="body2">
                    Free Minutes Left: {account.freeMinutesLeft} / {account.totalFreeMinutes}
                  </Typography>
                  <Typography variant="body2">Total Minutes Used: {account.totalMinutesUsed}</Typography>
                </div>
              ))
            ) : (
              <Typography variant="body2">No sub-accounts available.</Typography>
            )}
          </div>
        </CardContent>
      </Card>
    );
  };

  // Usage in the parent component
  const ParentComponent = ({ apiData, handleCustomerPortal }) => {
    return (
      <div>
        <SubscriptionDashboard handleCustomerPortal={handleCustomerPortal} apiData={apiData?.data} />
        <SubAccountsList subAccounts={apiData?.data?.subAccounts} />
      </div>
    );
  };

  return (
    <>
      {debrandModalActive && (
        <>
          <div
            onClick={async () => {
              await refreshQuotesAndClients();
              setDebrandModalActive(false);
            }}
            className={classes.modalOverlay}
          ></div>
          <div className={classes.modal}>
            <div
              className={classes.close}
              onClick={async () => {
                await refreshQuotesAndClients();
                setDebrandModalActive(false);
              }}
            >
              &times;
            </div>
            <h2>1 New Receptionist Created</h2>

            <p
              style={{
                marginBottom: '50px', // Add marginBottom
              }}
            >
              To view, edit, and share your receptionist click the "My Receptionists" tab on the left
            </p>

            <button
              style={{
                backgroundColor: color,
                color: 'white',
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                textAlign: 'left',
                position: 'absolute', // Add position absolute to button
                bottom: 0, // Align button to bottom
                right: 30, // Align button to left
                marginBottom: '24px', // Add marginBottom
                marginLeft: '12px', // Add marginLeft
              }}
              onClick={async () => {
                await refreshQuotesAndClients();
                setDebrandModalActive(false);
              }}
            >
              Okay
            </button>
          </div>
        </>
      )}
      {run && (
        <Joyride
          stepIndex={step}
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          run={run}
          scrollToFirstStep
          steps={steps}
          showSkipButton
          showCloseButton={false}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
        />
      )}
      <div className={classes.mainContainer} style={{ display: 'flex', minHeight: !isMobile && '100vh' }}>
        <div
          style={{
            flex: '1', // Take up the remaining width
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Quicksand, sans-serif',
            // width: "100%",
            minHeight: !isMobile && '100vh',
            padding: '16px',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            {isRegistrationModal && (
              <>
                <div
                  onClick={() => {
                    setIsRegistrationModalActive(false);
                  }}
                  className={classes.modalOverlay}
                ></div>
                <div className={classes.modal + ' ' + classes.wide}>
                  <div
                    className={classes.close}
                    onClick={() => {
                      setIsRegistrationModalActive(false);
                    }}
                  >
                    &times;
                  </div>

                  <RegisterPageV3
                    debrand
                    reseller_email={resellerData.email_address}
                    purchase_quotes={resellerData.purchase_quotes}
                    totalReceptionists={parseInt(resellerData.totalReceptionists || 0)}
                    reserveredReceptionists={parseInt(resellerData.reservedReceptionists || 0)}
                    unreseveredReceptionists={parseInt(resellerData.unreservedReceptionists || 0)}
                    reseller_name={resellerData.reseller_name}
                    debranded_registration_modal_active_setter={setIsRegistrationModalActive}
                    debrand_modal_instructions_setter={setDebrandModalActive}
                  />
                </div>
              </>
            )}
            {/* {selectedItem === "Overview" && (
              <Overview
                color={color}
                isMobile={isMobile}
                user={user}
                setUser={setUser}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                imageUrl={imageUrl}
                setDebrandModalActive={setDebrandModalActive}
                debrandModalActive={debrandModalActive}
                setRefreshInt={setRefreshInt}
                setUploadedLogoS3Url={setUploadedLogoS3Url}
                uploadedLogoS3Url={uploadedLogoS3Url}
                showTutorial={showTutorial}
                setShowTutorial={setShowTutorial}
              />
            )} */}

            {!debrand && selectedItem === 'Embed' && (
              <div className={clsx(classes.embedContainer)}>
                <div className={clsx(classes.card, 'client-dashboard')}>
                  <div>
                    <h4>Embedding the Client Dashboard</h4>
                    <p>
                      Click below to access the link to your unbranded client dashboard. Embed this into your own
                      website via iframe. Your clients can login here to view call logs and edit their receptionist
                      anytime.
                    </p>
                  </div>
                  <button
                    onClick={async () => {
                      try {
                        const response = await axios.post(serverURL + '/get-dashboard-link', {
                          email: resellerData?.email_address,
                        });

                        navigator.clipboard
                          .writeText(serverURL + '/unbranded_receptionist?reseller_code=' + response?.data?.link)
                          .then(() => {
                            toast.success('Link copied to clipboard');
                          });
                      } catch (err) {
                        console.log(err);
                        toast.error('Failed to copy');
                      }
                    }}
                  >
                    🔗 Copy URL
                  </button>
                </div>
                <div className={clsx(classes.card, 'client-registration')}>
                  <div>
                    <h4>Embedding the Registration Form</h4>
                    <p>
                      Click below to access the link to your unbranded client dashboard. Embed this into your own
                      website via iframe. We recommend putting this form behind a paywall on your own website, since
                      anyone that creates a receptionist via this form will consume one of your reserved receptionists.
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row flex-wrap items-start md:items-center gap-2 ">
                    <div
                      className="py-1 px-2 my-2 rounded-lg flex flex-row items-center justify-between flex-1 w-full "
                      style={{
                        backgroundColor: 'white',
                        border: `1px solid grey`,
                      }}
                    >
                      <div className="flex flex-row items-center gap-1">
                        <MdInfo
                          data-tooltip-id="autoscale-info"
                          size={25}
                          // color={`${getContrastingColor(color)}`}
                        />
                        <Tooltip
                          id="autoscale-info"
                          content="
                                When autoscale registration is enabled and you run out of receptionists, a new receptionist will be created automatically. This will be billed to your account.
                                "
                        />
                        <p
                          style={
                            {
                              // color: getContrastingColor(color),
                            }
                          }
                        >
                          Autoscale Registration{' '}
                        </p>
                      </div>
                      <div className="flex items-center justify-center h-9">
                        {updateAutoScaleLoading ? (
                          <div className="w-7 h-7 my-1 flex items-center justify-center">
                            <CircularProgress color="primary" size={20} thickness={5} />
                          </div>
                        ) : (
                          <Switch
                            size="medium"
                            checked={resellerData?.auto_scale_receptionists}
                            onChange={handleUpdateAutoScale}
                          />
                        )}
                      </div>
                    </div>
                    <button
                      className="w-full md:w-auto"
                      onClick={async () => {
                        try {
                          const response = await axios.post(serverURL + '/get-dashboard-link', {
                            email: resellerData?.email_address,
                          });

                          navigator.clipboard
                            .writeText(serverURL + '/unbranded_register?reseller_code=' + response?.data?.link)
                            .then(() => {
                              toast.success('Link copied to clipboard');
                            });
                        } catch (err) {
                          console.log(err);
                          toast.error('Failed to copy');
                        }
                      }}
                    >
                      🔗 Copy URL
                    </button>
                  </div>
                  {!resellerData.customerId && (
                    <Button
                      text={'Add Payment Method'}
                      onClick={() => {
                        setOpenPaymentMethodElement(true);
                      }}
                      type="button"
                      className={'w-full mt-2 text-xs md:text-base'}
                    />
                  )}
                </div>
              </div>
            )}

            {selectedItem === 'Receptionist' && resellerData && resellerData.email_address && (
              <WhiteLabelReceptionistDirectory
                color={color}
                user={resellerData}
                reseller_email={resellerData.email_address}
                reseller_name={resellerData.reseller_name}
                isRegistrationModal={isRegistrationModal}
                setIsRegistrationModalActive={setIsRegistrationModalActive}
                reloadClients={flipThisBooleanToReloadClients}
                setReservedCampaignLists={() => {}}
                setCampaigns={() => {}}
                debrand={debrand}
                totalReceptionists={parseInt(resellerData.totalReceptionists || 0)}
                reserveredReceptionists={parseInt(resellerData.reservedReceptionists || 0)}
                unreseveredReceptionists={parseInt(resellerData.unreservedReceptionists || 0)}
                debrand_modal_instructions_setter={setDebrandModalActive}
              />
            )}

            {selectedItem === 'Embed' && paymentMethodNoticeModal && (
              <NoticeModal
                heading="Payment Method Required"
                text="To enable autoscale registration, please add a payment method."
                close={() => setPaymentMethodNoticeModal(false)}
                themeColor={color}
                onApply={handleAddPaymentMethod}
                loading={false}
              />
            )}
            {openPaymentMethodElement && (
              <ModalWrapper
                close={() => {
                  setOpenPaymentMethodElement(false);
                }}
              >
                <StripeProvider>
                  <PaymentForm
                    customerId={resellerData.customerId}
                    themeColor={color}
                    email_address={resellerData.email_address}
                    close={() => {
                      setOpenPaymentMethodElement(false);
                    }}
                  />
                </StripeProvider>
              </ModalWrapper>
            )}

            {selectedItem === 'Branding' && (
              <Branding
                color={color}
                isMobile={isMobile}
                user={resellerData}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                imageUrl={imageUrl}
                setDebrandModalActive={setDebrandModalActive}
                debrandModalActive={debrandModalActive}
                setRefreshInt={setRefreshInt}
                setUploadedLogoS3Url={setUploadedLogoS3Url}
                uploadedLogoS3Url={uploadedLogoS3Url}
                showTutorial={showTutorial}
                setShowTutorial={setShowTutorial}
                privacyPolicyURL={resellerData?.privacyPolicyURL}
                termsOfUseURL={resellerData?.termsOfUseURL}
              />
            )}

            {selectedItem === 'Tutorials' && (
              <>
                <button
                  style={{
                    backgroundColor: color,
                    color: 'white',
                    padding: '10px 16px',
                    borderRadius: '4px',
                    border: 'none',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginTop: '16px',
                    width: '200px',
                    display: 'block',
                    height: '50px',
                    marginBottom: '12px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={() => {
                    setJoyrideState(prev => ({ ...prev, run: true }));
                  }}
                >
                  WATCH TUTORIAL
                </button>

                <div
                  style={{
                    width: '90%',
                    margin: '2rem auto',
                    padding: '1.5rem',
                    fontFamily: 'Quicksand, sans-serif',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    border: 'none', // Remove border around the container
                    borderRadius: '10px', // Set border radius for the container
                  }}
                >
                  <h2>Tutorials</h2>
                  <iframe
                    src="https://www.myaifrontdesk.com/tutorials"
                    style={{
                      width: '100%',
                      height: '1000px',
                      border: 'none', // Remove border around the iframe
                      borderRadius: '10px', // Set border radius for the iframe
                    }}
                  />
                </div>
              </>
            )}

            {selectedItem === 'Manage Subscription' && (
              <div style={{ width: '100%', padding: '16px' }}>
                {/* Header Tile */}
                <div
                  style={{
                    background: '#FFFFFF',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontFamily: 'Quicksand, sans-serif',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    color: '#333',
                    padding: '24px',
                    marginBottom: '16px',
                    backdropFilter: 'blur(5px)',
                  }}
                >
                  <h1
                    style={{
                      fontSize: '36px',
                      marginBottom: '10px',
                      fontWeight: 'bold',
                      color: '#333',
                      textAlign: 'center',
                    }}
                  >
                    Subscription Management
                  </h1>
                  <p
                    style={{
                      textAlign: 'center',
                      lineHeight: '1.6',
                      fontSize: '20px',
                      color: '#333',
                    }}
                  >
                    Manage your subscription below. Cancel or update your payment information as needed.
                  </p>
                </div>

                {/* Tiles Side by Side */}
                <div
                  style={{
                    display: 'flex',
                    gap: '16px',
                    marginTop: '32px',
                  }}
                >
                  {/* Tile 1: Cancel Subscription */}
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      padding: '16px',
                      position: 'relative',
                      minHeight: '15em',
                    }}
                  >
                    <h2>
                      <span style={{ fontSize: '28px', marginRight: '8px' }}>❌</span>
                      Cancel Subscription
                    </h2>
                    <p>Cancel your subscription anytime. </p>
                    <button
                      style={{
                        backgroundColor: '#FF0000', // Red color for cancel
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        border: 'none',
                        textAlign: 'left',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        marginBottom: '12px',
                        marginLeft: '12px',
                      }}
                      onClick={() => {
                        handleCancelConfirm();
                      }}
                    >
                      Cancel Now
                    </button>
                  </div>

                  {/* Tile 2: Update Payment Information */}
                  <div
                    style={{
                      flex: 1,
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      padding: '16px',
                      position: 'relative',
                      minHeight: '15em',
                    }}
                  >
                    <h2>
                      <span style={{ fontSize: '28px', marginRight: '8px' }}>💳</span>
                      Update Payment Information
                    </h2>
                    <p>Update your payment details securely.</p>
                    <button
                      style={{
                        backgroundColor: '#333',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        border: 'none',
                        textAlign: 'left',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        marginBottom: '12px',
                        marginLeft: '12px',
                      }}
                      onClick={updatePaymentInformation}
                    >
                      Update Now
                    </button>
                  </div>
                </div>

                {/* Subscription Dashboard */}
                <ParentComponent apiData={billingOverview} handleCustomerPortal={handleCustomerPortal} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResellerPortal;

// const Overview = ({
//   user,
//   setUser,
//   selectedItem,
//   setSelectedItem,
//   isMobile,
//   imageUrl,
//   setDebrandModalActive,
//   debrandModalActive,
//   setRefreshInt,
//   setUploadedLogoS3Url,
//   uploadedLogoS3Url,
//   showTutorial,
//   setShowTutorial,
//   color,
// }) => {
//   const navigate = useNavigate();

//   const [isBrandingModalActive, setIsBrandingModalActive] = useState(false);
//   const [isInfoModalActive, setIsInfoModalActive] = useState(false);
//   const [resellerLogo, setResellerLogo] = useState("");
//   const [isRegistrationModal, setIsRegistrationModalActive] = useState(false);
//   const [
//     showRegisterResellerInstructionsDialog,
//     setShowRegisterResellerInstructionsDialog,
//   ] = useState(false);
//   const [updatedResellerName, setUpdatedResellerName] = useState("");

//   useEffect(() => {
//     setUpdatedResellerName(user.reseller_name.S);
//   }, [user]);

//   const registerResellerInstructionsDialog = (
//     <div>
//       <div
//         style={{
//           display: showRegisterResellerInstructionsDialog ? "block" : "none",
//           position: "fixed",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           background: "white",
//           boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
//           borderRadius: "10px",
//           padding: "20px",
//           zIndex: "98",
//         }}
//       >
//         <button
//           style={{
//             position: "absolute",
//             top: "10px",
//             right: "10px",
//             background: "transparent",
//             border: "none",
//             cursor: "pointer",
//             fontSize: "18px",
//           }}
//           onClick={() => {
//             setShowRegisterResellerInstructionsDialog(false);
//           }}
//         >
//           X
//         </button>
//         <h2> 😁 Let's Purchase an Unbranded Receptionist! </h2>
//         <ul>
//           <li>
//             In the next step, you will purchase a new unbranded receptionist.
//           </li>
//           <li>After purchasing, we'll direct you back to this portal.</li>{" "}
//           <li>
//             You will be able to create the unbranded receptionist you have just
//             purchased.{" "}
//           </li>
//         </ul>
//         <br />
//         <button
//           style={{
//             background: "blue",
//             color: "white",
//             padding: "10px 20px",
//             borderRadius: "5px",
//             border: "none",
//             cursor: "pointer",
//             marginTop: "10px",
//           }}
//           onClick={async () => {
//             setShowRegisterResellerInstructionsDialog(false);

//             window.gtag("event", "resellerPurchaseClicked", {
//               event_category: "EditDemo.js",
//               event_label: "success",
//             });

//             try {
//               const response = await fetch(
//                 `${serverURL}/create-checkout-session-new-resellers`,
//                 {
//                   method: "POST",
//                   headers: {
//                     "Content-Type": "application/json",
//                   },
//                   body: JSON.stringify({
//                     resellerEmail: user.email_address.S,
//                   }),
//                 }
//               );

//               const data = await response.json();
//               window.location.href = data.redirectPaymentURL;
//             } catch (error) {
//               window.gtag("event", "couldntPurchase", {
//                 event_category: "EditDemo.js",
//                 event_label: "error",
//               });
//               console.error(error);
//             }
//           }}
//         >
//           Purchase Now 🎉
//         </button>
//       </div>
//     </div>
//   );

//   return (
//     <>
//       {isInfoModalActive && (
//         <>
//           <div
//             onClick={() => setIsInfoModalActive(false)}
//             className={classes.modalOverlay}
//           ></div>
//           <div className={classes.modal}>
//             <div
//               className={classes.close}
//               onClick={() => setIsInfoModalActive(false)}
//             >
//               &times;
//             </div>
//             <h2>Information:</h2>

//             <p>
//               Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis
//               debitis ullam veniam quisquam voluptatem laudantium ut
//               reprehenderit nisi earum eius, officiis officia, quidem
//               exercitationem provident iste id dolorem minima tempore.
//             </p>
//           </div>
//         </>
//       )}

//       {registerResellerInstructionsDialog}
//       {isBrandingModalActive && (
//         <>
//           <div
//             onClick={() => setIsBrandingModalActive(false)}
//             className={classes.modalOverlay}
//           ></div>
//           <div className={classes.modal}>
//             <div
//               className={classes.close}
//               onClick={() => setIsBrandingModalActive(false)}
//             >
//               &times;
//             </div>

//             <label htmlFor="business-name">Business name</label>
//             <input
//               id="business-name"
//               type="text"
//               value={updatedResellerName}
//               onChange={(e) => setUpdatedResellerName(e.target.value)}
//             />
//             <br />
//             <br />
//             <label htmlFor="business-logo" style={{ cursor: "pointer" }}>
//               Business logo
//               <br />
//               <div
//                 style={{
//                   margin: "6px 0 0",
//                   display: "block",
//                   textAlign: "center",
//                   border: "1px solid rgb(223, 225, 228)",
//                   maxWidth: "300px",
//                   padding: "10px",
//                   borderRadius: "4px",
//                   cursor: "pointer",
//                   fontSize: ".75rem",
//                 }}
//               >
//                 {resellerLogo?.name ? (
//                   resellerLogo?.name
//                 ) : imageUrl ? (
//                   <img className={classes.w100} src={imageUrl} alt="logo" />
//                 ) : (
//                   "Click to attach"
//                 )}
//               </div>
//               <input
//                 id="business-logo"
//                 type="file"
//                 onChange={(e) => {
//                   setResellerLogo(
//                     e.target.files.length > 0 ? e.target.files[0] : {}
//                   );
//                   // setUser((prev) => ({
//                   //   ...prev,
//                   //   debrand_name: e.target.debrand_name,
//                   // }))
//                 }}
//               />
//             </label>
//             <label
//               style={{
//                 backgroundColor: color,
//                 color: "white",
//                 padding: "8px 16px",
//                 borderRadius: "4px",
//                 border: "none",
//                 textAlign: "left",
//                 marginTop: "16px",
//                 width: "max-content",
//                 display: "block",
//                 cursor: "pointer",
//                 marginBottom: "12px",
//               }}
//               className={classes.uploadBtn}
//               htmlFor="business-logo"
//             >
//               Click to upload
//             </label>

//             <p>
//               <em> You will need to refresh the page to view your changes. </em>
//             </p>
//             <button
//               style={{
//                 backgroundColor: color,
//                 color: "white",
//                 padding: "8px 16px",
//                 borderRadius: "4px",
//                 border: "none",
//                 textAlign: "left",
//                 marginTop: "16px",
//                 marginLeft: "auto",
//                 width: "68px",
//                 display: "block",
//                 marginBottom: "12px",
//               }}
//               onClick={async () => {
//                 try {
//                   if (resellerLogo) {
//                     const formData = new FormData();
//                     formData.append("logo", resellerLogo);

//                     const logoResponse = await fetch(
//                       `${serverURL}/upload_reseller_logo`,
//                       {
//                         method: "POST",
//                         body: formData,
//                       }
//                     );

//                     if (!logoResponse.ok) {
//                       toast.error("Logo upload failed");
//                     } else {
//                       toast.success("Logo uploaded successfully");
//                       const responseData = await logoResponse.json();
//                       setUploadedLogoS3Url(responseData.url);
//                     }
//                   }
//                   const response = await fetch(
//                     `${serverURL}/update_reseller_business_name_and_logo`,
//                     {
//                       method: "POST",
//                       headers: {
//                         "Content-Type": "application/json",
//                       },
//                       body: JSON.stringify({
//                         emailAddress: user.email_address.S,
//                         resellerName: updatedResellerName,
//                         uploadedLogoS3Url: uploadedLogoS3Url,
//                       }),
//                     }
//                   );

//                   const data = await response.json();

//                   setIsBrandingModalActive(false);

//                   setRefreshInt((prev) => prev + 1);
//                 } catch (error) {
//                   console.error(error);
//                 }
//               }}
//             >
//               Save
//             </button>
//           </div>
//         </>
//       )}
//       <div
//         style={{
//           display: "grid",
//           gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
//           gap: "16px",
//           marginTop: "32px",
//         }}
//       >
//         {/* Tile 1 */}
//         <div
//           classname="registerNewClient"
//           style={{
//             backgroundColor: "#FFFFFF",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//             borderRadius: "8px",
//             padding: "16px",
//             position: "relative", // Add position relative to parent div
//             minHeight: "240px",
//             paddingBottom: "70px",
//           }}
//         >
//           <h2>
//             <span style={{ fontSize: "28px", marginRight: "8px" }}>📝</span>
//             Register a New Client
//           </h2>
//           <p>
//             Click "enter" below to begin registering a new client. You will do
//             this manually for each of your clients you onboard.{" "}
//           </p>

//           <button
//             style={{
//               backgroundColor: color,
//               color: "white",
//               padding: "8px 16px",
//               borderRadius: "4px",
//               border: "none",
//               textAlign: "left", // Align the button text to the left
//               position: "absolute", // Add position absolute to button
//               bottom: 0, // Align button to bottom
//               marginBottom: "12px", // Add marginBottom
//             }}
//             onClick={() => {
//               // 1: Check if user has a purchased receptionist lying around (i.e. check the Purchase_Quotes field)
//               var hasPurchasedReceptionist =
//                 JSON.parse(user.purchase_quotes.S).length > 0;
//               // 2: If user has a purchased receptionist, then allow them to register a new client
//               // 3: If user does not have a purchased receptionist, then force them to purchase a receptionist before accessing registration page
//               if (!hasPurchasedReceptionist) {
//                 setShowRegisterResellerInstructionsDialog(true);
//                 return;
//               }
//               setIsRegistrationModalActive(true);
//             }}
//           >
//             Enter
//           </button>
//         </div>

//         {/* Tile 2 */}
//         <div
//           style={{
//             backgroundColor: "#FFFFFF",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//             borderRadius: "8px",
//             padding: "16px",
//             position: "relative", // Add position relative to parent div
//             minHeight: "240px",
//             paddingBottom: "70px",
//           }}
//           classname="clientDashboard"
//         >
//           <h2>
//             <span style={{ fontSize: "28px", marginRight: "8px" }}>📈</span>
//             Whitelabeled Client Dashboard
//           </h2>
//           <p>
//             Click "enter" below to view your unbranded client dashboard. To
//             surface this to your clients, you can embed this in your own website
//             with an iframe.
//           </p>

//           <button
//             style={{
//               backgroundColor: color,
//               color: "white",
//               padding: "8px 16px",
//               borderRadius: "4px",
//               border: "none",
//               textAlign: "left", // Align the button text to the left
//               position: "absolute", // Add position absolute to button
//               bottom: 0, // Align button to bottom
//               marginBottom: "12px", // Add marginBottom
//             }}
//             onClick={() => {
//               navigate("/unbranded_receptionist");
//             }}
//           >
//             Enter
//           </button>
//         </div>

//         {/* Tile 3 */}
//         <div
//           style={{
//             backgroundColor: "#FFFFFF",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//             borderRadius: "8px",
//             position: "relative", // Add position relative to parent div
//             padding: "16px",
//             minHeight: "240px",
//             paddingBottom: "70px",
//           }}
//           classname="brandingSettings"
//         >
//           <h2>
//             <span style={{ fontSize: "28px", marginRight: "8px" }}>⚙</span>
//             Branding Settings
//           </h2>
//           <p>
//             Modify your business name and logo any time. This branding will be
//             applied on all your receptionists, dashboards, notifications, and
//             more.
//           </p>
//           <button
//             style={{
//               backgroundColor: color,
//               color: "white",
//               padding: "8px 16px",
//               borderRadius: "4px",
//               border: "none",
//               textAlign: "left",
//               position: "absolute", // Add position absolute to button
//               bottom: 0, // Align button to bottom
//               left: 0, // Align button to left
//               marginBottom: "12px", // Add marginBottom
//               marginLeft: "12px", // Add marginLeft
//             }}
//             onClick={() => {
//               setIsBrandingModalActive(true);
//             }}
//           >
//             Enter
//           </button>
//         </div>

//         {/* Tile 4 */}
//         <div
//           style={{
//             backgroundColor: "#FFFFFF",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//             borderRadius: "8px",
//             padding: "16px",
//             position: "relative", // Add position relative to parent div
//             minHeight: "240px",
//             paddingBottom: "70px",
//           }}
//           classname="manageReceptionists"
//         >
//           <h2>
//             <span style={{ fontSize: "28px", marginRight: "8px" }}>👩‍💼</span>
//             Manage Receptionists
//           </h2>
//           <p>View and manage your existing receptionist in one place. </p>
//           <button
//             style={{
//               backgroundColor: color,
//               color: "white",
//               padding: "8px 16px",
//               borderRadius: "4px",
//               border: "none",
//               textAlign: "left",
//               position: "absolute", // Add position absolute to button
//               bottom: 0, // Align button to bottom
//               left: 0, // Align button to left
//               marginBottom: "12px", // Add marginBottom
//               marginLeft: "12px", // Add marginLeft
//             }}
//             className="pulsating-button"
//             onClick={() => {
//               setSelectedItem("Receptionist");
//             }}
//           >
//             View
//           </button>
//         </div>

//         {/* Tile 5 */}
//         <div
//           style={{
//             backgroundColor: "#FFFFFF",
//             boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//             borderRadius: "8px",
//             padding: "16px",
//             position: "relative", // Add position relative to parent div
//             minHeight: "240px",
//             paddingBottom: "70px",
//           }}
//         >
//           <h2>
//             <span style={{ fontSize: "28px", marginRight: "8px" }}>🤙</span>
//             Contact Support
//           </h2>
//           <p>
//             Having trouble? Contact support now, it is included in your plan for
//             free.
//           </p>
//           <button
//             style={{
//               backgroundColor: color,
//               color: "white",
//               padding: "8px 16px",
//               borderRadius: "4px",
//               border: "none",
//               textAlign: "left",
//               position: "absolute", // Add position absolute to button
//               bottom: 0, // Align button to bottom
//               left: 0, // Align button to left
//               marginBottom: "12px", // Add marginBottom
//               marginLeft: "12px", // Add marginLeft
//             }}
//             onClick={() => {
//               // Just create an email to contact@myaifrontdesk.com with the subject clarifying
//               window.location.href = `mailto:contact@myaifrontdesk.com?subject=Whitelabel Reseller Inquiry`;
//             }}
//           >
//             Contact Now
//           </button>
//         </div>
//       </div>

//       {/* {(showTutorial && prod) ||
//         (true && (
//           <Joyride
//             steps={steps}
//             continuous={true}
//             showSkipButton={false}
//             showCloseButton={false}
//             hideCloseButton={true}
//             disableOverlayClose={true}
//             disableCloseOnEsc={true}
//           />
//         ))} */}
//     </>
//   );
// };

const Branding = ({
  color,
  selectedItem,
  setSelectedItem,
  isMobile,
  user,
  imageUrl,
  setDebrandModalActive,
  debrandModalActive,
  setRefreshInt,
  setUploadedLogoS3Url,
  uploadedLogoS3Url,
  showTutorial,
  setShowTutorial,
  privacyPolicyURL: privacyPolicyURLProp,
  termsOfUseURL: termsOfUseURLProp,
}) => {
  const navigate = useNavigate();

  const [isBrandingModalActive, setIsBrandingModalActive] = useState(false);
  const [isInfoModalActive, setIsInfoModalActive] = useState(false);
  const [resellerLogo, setResellerLogo] = useState('');
  const [isRegistrationModal, setIsRegistrationModalActive] = useState(false);
  const [showRegisterResellerInstructionsDialog, setShowRegisterResellerInstructionsDialog] = useState(false);
  const [updatedResellerName, setUpdatedResellerName] = useState('');
  const [themeColor, setThemeColor] = useState('#007bff');
  const { branded, toggleBranded, brandedLoading } = useResellerGlobal();
  const [privacyPolicyURL, setPrivacyPolicyURL] = useState(privacyPolicyURLProp || '');
  const [termsOfUseURL, setTermsOfUseURL] = useState(termsOfUseURLProp || '');

  useEffect(() => {
    setUpdatedResellerName(user.reseller_name);
    if (user?.theme_color) setThemeColor(user.theme_color);
  }, [user]);

  const registerResellerInstructionsDialog = (
    <div>
      <div
        style={{
          display: showRegisterResellerInstructionsDialog ? 'block' : 'none',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
          borderRadius: '10px',
          padding: '20px',
          zIndex: '98',
        }}
      >
        <button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px',
          }}
          onClick={() => {
            setShowRegisterResellerInstructionsDialog(false);
          }}
        >
          X
        </button>
        <h2> 😁 Let's Purchase an Unbranded Receptionist! </h2>
        <ul>
          <li>In the next step, you will purchase a new unbranded receptionist.</li>
          <li>After purchasing, we'll direct you back to this portal.</li>{' '}
          <li>You will be able to create the unbranded receptionist you have just purchased. </li>
        </ul>
        <br />
        <button
          onClick={async () => {
            setShowRegisterResellerInstructionsDialog(false);

            window.gtag('event', 'resellerPurchaseClicked', {
              event_category: 'EditDemo.js',
              event_label: 'success',
            });

            try {
              const response = await fetch(`${serverURL}/create-checkout-session-new-resellers`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  resellerEmail: user.email_address,
                }),
              });

              const data = await response.json();
              window.location.href = data.redirectPaymentURL;
            } catch (error) {
              window.gtag('event', 'couldntPurchase', {
                event_category: 'EditDemo.js',
                event_label: 'error',
              });
              console.error(error);
            }
          }}
          style={{
            background: 'blue',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginTop: '10px',
          }}
        >
          Purchase Now 🎉
        </button>
      </div>
    </div>
  );

  return (
    <>
      {isInfoModalActive && (
        <>
          <div onClick={() => setIsInfoModalActive(false)} className={classes.modalOverlay}></div>
          <div className={classes.modal}>
            <div className={classes.close} onClick={() => setIsInfoModalActive(false)}>
              &times;
            </div>
            <h2>Information:</h2>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis debitis ullam veniam quisquam
              voluptatem laudantium ut reprehenderit nisi earum eius, officiis officia, quidem exercitationem provident
              iste id dolorem minima tempore.
            </p>
          </div>
        </>
      )}

      {registerResellerInstructionsDialog}

      <>
        <label htmlFor="business-name">Business name</label>
        <br />
        <input
          id="business-name"
          type="text"
          value={updatedResellerName}
          style={{
            display: 'block',
            height: '40px',
            border: '1px solid #ccc',
            outline: 'none',
            padding: '0 10px',
            borderRadius: '4px',
          }}
          onChange={e => setUpdatedResellerName(e.target.value)}
        />
        <br />

        <label htmlFor="theme-color">Theme color</label>
        <br />
        <input
          id="theme-color"
          type="color"
          value={themeColor}
          style={{
            display: 'block',
            width: '100%',
            maxWidth: '280px',
            // height: "40px",
            border: '1px solid #ccc',
            outline: 'none',
            // padding: "0 10px",
            borderRadius: '4px',
          }}
          onChange={e => setThemeColor(e.target.value)}
        />
        <br />

        <label htmlFor="business-logo" style={{ cursor: 'pointer' }}>
          Business logo
          <br />
          <div
            style={{
              margin: '6px 0 0',
              display: 'block',
              textAlign: 'center',
              border: '1px solid rgb(223, 225, 228)',
              maxWidth: '300px',
              padding: '10px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '.75rem',
            }}
          >
            {resellerLogo?.name ? (
              resellerLogo?.name
            ) : imageUrl ? (
              <img className={classes.w100} src={imageUrl} alt="logo" />
            ) : (
              'Click to attach'
            )}
          </div>
          <input
            id="business-logo"
            type="file"
            onChange={e => {
              setResellerLogo(e.target.files.length > 0 ? e.target.files[0] : {});
            }}
          />
        </label>
        <label
          style={{
            backgroundColor: color,
            color: 'white',
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            textAlign: 'left',
            marginTop: '16px',
            width: 'max-content',
            display: 'block',
            cursor: 'pointer',
            marginBottom: '12px',
          }}
          className={classes.uploadBtn}
          htmlFor="business-logo"
        >
          Click to upload
        </label>

        <div className="flex flex-col gap-1">
          <label>Privacy Policy URL</label>
          <input
            value={privacyPolicyURL}
            onChange={e => {
              setPrivacyPolicyURL(e.target.value);
            }}
            className="rounded-sm max-w-[80%]"
            type="text"
          />
        </div>
        <div className="flex flex-col gap-1 mb-5 mt-3">
          <label>Terms of use URL</label>
          <input
            value={termsOfUseURL}
            onChange={e => {
              setTermsOfUseURL(e.target.value);
            }}
            className="rounded-sm max-w-[80%]"
            type="text"
          />
        </div>

        <p>
          <em> You will need to refresh the page to view your changes. </em>
        </p>
        <button
          style={{
            backgroundColor: color,
            color: 'white',
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            textAlign: 'left',
            marginTop: '16px',
            marginLeft: 'auto',
            width: '68px',
            display: 'block',
            marginBottom: '12px',
          }}
          onClick={async () => {
            let url;
            try {
              if (resellerLogo) {
                const formData = new FormData();
                formData.append('logo', resellerLogo);

                const logoResponse = await fetch(`${serverURL}/upload_reseller_logo`, {
                  method: 'POST',
                  body: formData,
                });

                if (!logoResponse.ok) {
                  toast.error('Logo upload failed');
                } else {
                  toast.success('Logo uploaded successfully');
                  const responseData = await logoResponse.json();
                  url = responseData.url;
                  setUploadedLogoS3Url(responseData.url);
                }
              }

              const response = await fetch(`${serverURL}/update_reseller_business_name_and_logo`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  emailAddress: user.email_address,
                  resellerName: updatedResellerName,
                  themeColor,
                  uploadedLogoS3Url: uploadedLogoS3Url || url,
                  privacyPolicyURL: privacyPolicyURL || '',
                  termsOfUseURL: termsOfUseURL || '',
                }),
              });

              const data = await response.json();

              setIsBrandingModalActive(false);

              setRefreshInt(prev => prev + 1);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          Save
        </button>
      </>
      <div className="flex flex-row items-center">
        <div className="flex items-center justify-center">
          <Switch
            data-tooltip-id="max_usage-info"
            size="medium"
            checked={branded}
            onChange={e =>
              toggleBranded({
                value: e.target.checked,
                resellerEmail: user.email_address,
                action: 'api',
              })
            }
            disabled={brandedLoading}
          />
          {brandedLoading && <p className="text-sm font-semibold">Updating...</p>}
        </div>
        <div className="flex flex-row items-center gap-1">
          {/* <MdInfo
              data-tooltip-id="max_usage-info"
              size={25}
              // color={`${getContrastingColor(color)}`}
            /> */}
          <Tooltip id="max_usage-info" content="Toggle the Branding on or off." />
        </div>
      </div>
    </>
  );
};
