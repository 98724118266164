import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { useAppDispatch } from '@/store/hooks/useAppDispatch';
import { updateResellerClients } from '@/store/reseller/slice';
import { fetchResellerClients } from '@/api/reseller';

interface UseGetResellerClientsOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  shouldFetchReservedNumbers?: boolean;
}

export const useGetResellerClients = (options: UseGetResellerClientsOptions = {}) => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: ['fetchResellerClients'],
    mutationFn: (email: string) => fetchResellerClients(email, options.shouldFetchReservedNumbers || false),
    onSuccess(data: any) {
      dispatch(updateResellerClients(data.items));
      options.onSuccess?.(data);
    },
    onError(error) {
      notification.error({
        message: 'Error fetching clients',
      });
      options.onError?.(error);
    },
  });
};
