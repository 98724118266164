import React, { useState } from 'react';
import { Layout } from 'antd';
import './PublicLayout.scss';
import Logo from '@/assets/images/desktop-logo.png';
import { Link } from 'react-router-dom';

const PublicLayout: React.FC<{
  children: React.ReactNode;
  debrand: boolean;
  items: {
    key: number;
    target: string;
    url: string;
    label: string;
  }[];
}> = ({ children, debrand, items }) => {
  const { Content } = Layout;
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const baseClass = 'public-layout';
  return (
    <Layout className={baseClass}>
      {debrand ? (
        ''
      ) : (
        <div className={`${baseClass}__header ${isSidebarActive ? 'active' : ''}`}>
          <div className={`${baseClass}__header__logo`}>
            <img src={Logo} />
          </div>
          <div className={`${baseClass}__header__nav-menu nav-menu`}>
            <div className="close" onClick={() => setIsSidebarActive(false)}>
              &times;
            </div>
            {items.map(item => (
              <div key={item.key} className={`${baseClass}__header__nav-menu__item`}>
                <Link target={item.target} to={item.url}>
                  {item.label}
                </Link>
              </div>
            ))}
          </div>
          <div className="hamburger" onClick={() => setIsSidebarActive(true)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
      <Content>{children}</Content>
    </Layout>
  );
};

export default PublicLayout;
