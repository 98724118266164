import React from 'react';
import Fade from 'react-reveal/Fade';

const UploadLogo = ({
  classes,
  uploadHeading,
  infoText,
  uploadedLogo,
  setUploadedLogo,
  serverURL,

  setUploadedLogoS3Url,
  setStepState,
  onChangeFunc,
  isCampaign,
  onNext,
  onSkip,
}) => {
  return (
    <>
      <div className={classes.box}>
        <Fade duration={1000} bottom>
          <h3
            className={classes.heading}
            style={{
              textAlign: 'center',
            }}
          >
            {uploadHeading}
          </h3>

          <div
            className={classes.subtitle}
            style={{
              textAlign: 'center',
            }}
          >
            {infoText}
          </div>

          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center', // Add this line
              marginBottom: '3rem',
            }}
          >
            <label
              style={{
                border: '2px dashed #ccc',
                padding: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              htmlFor="file"
            >
              {uploadedLogo?.name || 'Click to upload'}
            </label>
          </div>
          <input
            id="file"
            type="file"
            style={{
              display: 'none',
            }}
            // img must be under 2MB
            className={classes.input + ' ' + classes.centered}
            required
            onChange={e => {
              // limit the file size to 2MB
              if (e.target.files[0].size > 1 * 1024 * 1024) {
                alert('File size must be under 1MB');

                return;
              }
              onChangeFunc(e);
            }}
            placeholder="e.g. john@johnsbarbershop.com"
          />

          {/* { */}
          <button
            type="button"
            onClick={() => {
              onNext();
              if (!uploadedLogo) {
                onNext();
                return;
              }
              const formData = new FormData();
              formData.append('logo', uploadedLogo);
              // Make a server call to upload the logo
              fetch(`${serverURL}/upload_reseller_logo`, {
                method: 'POST',
                body: formData,
              })
                .then(logoResponse => {
                  if (!logoResponse.ok) {
                    toast.error('Logo upload failed');
                  } else {
                    toast.success('Logo uploaded successfully');
                    logoResponse.json().then(responseData => {
                      console.log('Logo URL: ' + responseData.url);
                      setUploadedLogoS3Url(responseData.url);
                    });
                  }
                })
                .catch(error => {
                  console.error(error);
                });
              onNext();
            }}
            className={classes.button + ' ' + classes.centered}
            style={{
              marginTop: '2rem',
            }}
          >
            Continue
          </button>
          {/* //   } */}
          <div onClick={onSkip} className={classes.later}>
            I'll do this later
          </div>
        </Fade>
      </div>
    </>
  );
};

export default UploadLogo;
