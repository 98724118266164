import { Card, Space, Tag, Timeline, Typography, Tabs, Empty } from 'antd';
import { CalendarOutlined, PhoneOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { outboundCallEvent } from '@/pages/Novi/types/Events';
import { CheckCircleOutlined } from '@mui/icons-material';
import ScheduledCallsDashboard from './ScheduledEvents/ScheduledCallsDashboard';

const { Text } = Typography;

const getStatusColor = (status: outboundCallEvent['event_state']) => {
  switch (status) {
    case 'SUCCESS':
      return 'green';
    case 'FAILED':
      return 'red';
    case 'PROCESSING':
      return 'blue';
    case 'FAILED_TERMINAL':
      return 'red';
    default:
      return 'default';
  }
};

const getStatusIcon = (status: outboundCallEvent['event_state']) => {
  switch (status) {
    case 'SUCCESS':
      return <CheckCircleOutlined className="text-green-500" />;
    case 'FAILED':
      return <CloseCircleOutlined className="text-red-500" />;
    case 'PROCESSING':
      return <LoadingOutlined className="text-blue-500" />;
    case 'FAILED_TERMINAL':
      return <CloseCircleOutlined className="text-red-500" />;
    default:
      return null;
  }
};

interface OutboundAnalyticsDashboardProps {
  scheduledEvent?: outboundCallEvent;
  immediateCallEvents?: outboundCallEvent[];
}

const OutboundAnalyticsDashboard: React.FC<OutboundAnalyticsDashboardProps> = ({
  scheduledEvent,
  immediateCallEvents = [],
}) => {
  const renderScheduledEvent = () => {
    if (!scheduledEvent) return <Empty description="No scheduled event" />;
    return (
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <ScheduledCallsDashboard scheduledEvent={scheduledEvent} />
      </Space>
    );
  };

  return (
    <div style={{ padding: 24 }}>
      <Tabs
        defaultActiveKey="scheduled"
        type="card"
        items={[
          {
            key: 'scheduled',
            label: (
              <Space>
                <CalendarOutlined />
                Scheduled Calls
              </Space>
            ),
            children: renderScheduledEvent(),
          },
          {
            key: 'immediate',
            label: (
              <Space>
                <PhoneOutlined />
                Immediate Calls ({immediateCallEvents.length})
              </Space>
            ),
            children:
              immediateCallEvents.length > 0 ? (
                <Timeline>
                  {immediateCallEvents.map((event, index) => (
                    <Timeline.Item
                      key={index}
                      color={getStatusColor(event.event_state)}
                      dot={getStatusIcon(event.event_state)}
                    >
                      <Card>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                          <div>
                            <Text strong>Event State:</Text>{' '}
                            <Tag color={getStatusColor(event.event_state)}>{event.event_state}</Tag>
                          </div>

                          <div>
                            <Text strong>Execution Time:</Text>{' '}
                            <Text>{new Date(event.event_execution_time).toLocaleString()}</Text>
                          </div>

                          {/* {formatVariables(event.variables)} */}
                          {/* {renderTranscript(event.transcript)} */}

                          {event.errors_blob && (
                            <Card type="inner" title="Error Details" style={{ borderColor: '#ff4d4f' }}>
                              <Text type="danger">{event.errors_blob}</Text>
                            </Card>
                          )}
                        </Space>
                      </Card>
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                <Empty description="No immediate call events" />
              ),
          },
        ]}
      />
    </div>
  );
};

export default OutboundAnalyticsDashboard;
