import useResellerAuth from '@/routes/hooks/useResellerAuth';
import ResellerLoginForm from '../pages/ResellerLogin/ResellerLoginForm';
import { Loading } from '@/components/Loading';
import { Outlet } from 'react-router-dom';

export const PrivateResellerRoute = () => {
  const { isAuthenticated, isLoading } = useResellerAuth();
  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <ResellerLoginForm />;
};

export default PrivateResellerRoute;
