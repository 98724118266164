import { List } from 'antd';
import './CallDetails.scss';
import CopyableText from '@/components/LabelWithdescription/CopyableText';
import { CallLog, CallScore, CallSentiment } from '@/interfaces/ILogs';
import SentimentsPanel from './SentimentGraph';

export const CallDetails = ({ callLog }: { callLog: CallLog | null }) => {
  const score: CallScore | null = callLog?.callScore ? JSON.parse(callLog?.callScore) : null;
  const sentiment: CallSentiment | null = callLog?.callSentiments ? JSON.parse(callLog?.callSentiments) : null;

  const scriptMessages = callLog?.transcript
    ?.split('\n')
    ?.map((message: string, index: number) => {
      if (message?.startsWith('assistant@')) {
        return { key: index, sender: 'Assistant', message: message.replace('assistant@', '') };
      } else if (message?.startsWith('user@')) {
        return { key: index, sender: 'User', message: message.replace('user@', '') };
      }
    })
    ?.filter(Boolean);

  return (
    <div className='max-h-[65vh] overflow-y-auto pt-2'>
      {callLog?.callSummary && (
        <div className='flex flex-col mb-6 gap-2'>
          <div className='flex items-center gap-1'>
            <span className='font-medium italic'>SUMMARY</span>
            <CopyableText text={callLog.callSummary} hideText />
          </div>
          <span className='text-gray-500 text-base'>{callLog.callSummary}</span>
        </div>
      )}
      <div>
        <div className='flex items-center gap-1'>
          <span className='font-medium italic'>TRANSCRIPT</span>
          <CopyableText text={callLog?.transcript || ''} hideText />
        </div>
        <List
          key={callLog?.transcript}
          className='call-log-details'
          itemLayout='horizontal'
          dataSource={scriptMessages}
          renderItem={item =>
            item && (
              <List.Item
                key={item.key}
                className='call-log-details__item'
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  flexDirection: item.sender == 'Assistant' ? 'row' : 'row-reverse',
                  textAlign: item.sender == 'Assistant' ? 'left' : 'right',
                  padding: '10px',
                }}
              >
                <List.Item.Meta
                  title={<strong className='text-base'>{item.sender}</strong>}
                  description={<span className='text-base'>{item.message}</span>}
                />
              </List.Item>
            )
          }
        />
      </div>
      {score && (
        <div className='flex flex-col my-6 gap-2 px-2'>
          <div className='flex items-center gap-1'>
            <span className='font-medium italic'>CALL SCORE</span>
            <CopyableText text={score?.value?.toString()} hideText />
          </div>
          <span className='text-gray-500 text-3xl px-2'>{score?.value}</span>
          <span className='text-gray-500 text-base px-2'>{score?.description}</span>
        </div>
      )}
      {sentiment && (
        <div className='flex flex-col my-6 gap-2 px-2'>
          <div className='flex items-center gap-1'>
            <span className='font-medium italic'>CALL SENTIMENTS</span>
          </div>
          <SentimentsPanel sentiments={sentiment} />
          <span className='text-gray-500 text-base px-2'>{sentiment?.description}</span>
        </div>
      )}
    </div>
  );
};
