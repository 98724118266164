import apiClient from '@/utils/apiClient';
const GET_RECEPTIONIST_RESELLER = '/api/v2/receptionist-dashboard/receptionist-reseller';

const DOWNLOAD_RESELLER_LOGO = '/download_reseller_logo';
const GET_RESELLER_BY_CODE = '/get-reseller-from-reseller-code';

export const getReceptionistReseller = async ({ resellerEmail }: { resellerEmail: string }) => {
  return await apiClient.request({
    method: 'GET',
    endPoint: `${GET_RECEPTIONIST_RESELLER}/${resellerEmail}`,
  });
};

export const getResellerLogo = async (payload: { key: string }) => {
  return await apiClient.request({
    method: 'POST',
    endPoint: DOWNLOAD_RESELLER_LOGO,
    body: payload,
    isBlobResponse: true,
  });
};
export const getResellerByCode = async (payload: { resellerCode: string }) => {
  return await apiClient.request({
    method: 'POST',
    endPoint: GET_RESELLER_BY_CODE,
    body: payload,
  });
};
